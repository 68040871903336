import React from 'react'
import {
  Flex,
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { MicAIOn } from '@components/icons'

export const DocAutomationSessionLimitReachedModalConnected = () => {
  const { isOpen } = useStoreState(
    state => state.modals.docAutomationSessionLimitReached
  )
  const { closeModal } = useStoreActions(
    (actions: any) => actions.modals.docAutomationSessionLimitReached
  )

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding="24px">
          <Flex flexDirection="column" textAlign="center">
            <Flex margin="0 auto">
              <MicAIOn fill="black" />
            </Flex>
            <Text fontWeight="bold" fontSize="24px">
              AI Notetaker
            </Text>
            <Box height="16px" />
            <Text>You've reached your monthly limit on notes.</Text>
            <Box height="16px" />
            <Text>Contact your admin to upgrade your plan.</Text>
          </Flex>
          <Box height="16px" />
          <Flex justifyContent="center">
            <Button bg="primary" width="300px" onClick={closeModal}>
              Got it
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
