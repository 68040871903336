import React from 'react'
import {
  Container,
  GridItem,
  Box,
  Text,
  Flex,
  Heading,
  useBoolean,
  Tooltip
} from '@chakra-ui/react'
import { LayoutGrid, Button } from '@blueprinthq/joy'

import { useClinicControllerGetAssignedCheckIns } from '~/clinician-api'

import { ClinicSelect, useClinicSelect } from '../../containers/clinic-select'
import { AddWorksheetsModal } from './components/add-worksheets-modal'
import { AssignedCheckInsTable } from './components/assigned-check-ins-table'
import { CircleQuestion } from '@components/icons'

export const LibraryWorksheets = () => {
  const [isModalOpen, modalAction] = useBoolean()

  const clinicSelect = useClinicSelect()

  const {
    data: assignedCheckIns = []
  } = useClinicControllerGetAssignedCheckIns(
    clinicSelect.selectedClinic?.id,
    {
      type: 'treatment_activity'
    },
    {
      query: {
        enabled: !!clinicSelect.selectedClinic?.id
      }
    }
  )

  return (
    <>
      <Container
        sx={{
          marginTop: 'large',
          paddingBottom: 'xxlarge'
        }}
      >
        <LayoutGrid>
          <GridItem
            colSpan={{
              base: 4,
              sm: 8,
              md: 12
            }}
          >
            <Box w="100%">
              <Box mt="xlarge">
                <Flex justify="space-between" alignItems="center" mb="small">
                  <Box w="100%">
                    <Heading mb="xxsmall">Worksheets</Heading>
                    <Text display={'flex'} alignItems={'center'}>
                      Manage the worksheets in your clinic's library.
                      <Tooltip label="Worksheets that are active in your library limit what clinicians can assign to a client.">
                        <Flex ml="xxsmall" alignItems="center">
                          <CircleQuestion fill="#A1A1A1" />
                        </Flex>
                      </Tooltip>
                    </Text>
                  </Box>
                  <Box>
                    <Button onClick={modalAction.toggle}>Manage</Button>
                  </Box>
                </Flex>
                <Box w="100%">
                  <ClinicSelect />
                </Box>
                <Box mt="small">
                  <AssignedCheckInsTable
                    checkIns={assignedCheckIns}
                    clinicId={clinicSelect.selectedClinic?.id}
                  />
                </Box>
              </Box>
            </Box>
          </GridItem>
        </LayoutGrid>
      </Container>
      <AddWorksheetsModal
        isOpen={isModalOpen}
        clinicId={clinicSelect.selectedClinic?.id}
        onClose={modalAction.off}
        existingCheckInIds={assignedCheckIns.map(ci => ci.id)}
        type="treatment_activity"
      />
    </>
  )
}
