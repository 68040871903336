import React, { useState } from 'react'
import {
  Button,
  HStack,
  Stack,
  Text,
  Textarea,
  useToast,
  IconButton,
  Box,
  Flex
} from '@chakra-ui/react'
import { useSessionControllerTrackEvent } from '~/clinician-api'
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined'

type FeedbackType = 'Poor' | 'Okay' | 'Great'

interface ProgressNoteFeedbackProps {
  sessionId: string
}

const numericalRatings: Record<FeedbackType, number> = {
  Poor: 0,
  Okay: 1,
  Great: 2
}

const placeholders: Record<FeedbackType, string> = {
  Poor: 'What was off about the note?',
  Okay: 'How could this note be improved?',
  Great: "We'd love to hear more!"
}

const ProgressNoteFeedback: React.FC<ProgressNoteFeedbackProps> = props => {
  const [selected, setSelected] = useState<FeedbackType | string | undefined>(
    undefined
  )
  const [feedback, setFeedback] = useState<string>('')
  const toast = useToast()

  const {
    mutate: trackEvent,
    isLoading: isSubmittingTrackEvent
  } = useSessionControllerTrackEvent()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    trackEvent(
      {
        id: props.sessionId,
        data: {
          eventType: 'progress_note_feedback_submitted',
          payload: {
            feedback,
            rating: numericalRatings[selected as FeedbackType],
            noteType: 'soap'
          }
        }
      },
      {
        onSuccess: () => {
          toast({
            title: 'Feedback submitted',
            description: 'Thanks for the feedback!',
            status: 'success',
            duration: 3000,
            isClosable: true
          })
          localStorage.setItem(`feedback-submitted/${props.sessionId}`, 'true')
        }
      }
    )
  }

  if (localStorage.getItem(`feedback-submitted/${props.sessionId}`)) {
    return null
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        textAlign="center"
        bottom="24px"
        background="white"
        width={selected ? '300px' : '230px'}
      >
        <HStack position="relative">
          <Text color="dark_gray">Rate this note:</Text>
          <Flex alignItems="center" gap="4px">
            <Flex>
              <IconButton
                aria-label="thumbs-up"
                variant="ghost"
                minWidth="0"
                height="0"
                color={selected === 'Great' ? 'primary' : 'dark_gray'}
                _focus={{
                  boxShadow: 'none'
                }}
                onClick={() => {
                  if (selected === 'Great') {
                    setSelected(undefined)
                  } else {
                    setSelected('Great')
                  }
                }}
                icon={<ThumbUpOutlinedIcon />}
              />
              <IconButton
                aria-label="thumbs-down"
                variant="ghost"
                minWidth="0"
                height="0"
                color={selected === 'Poor' ? 'primary' : 'dark_gray'}
                _focus={{
                  boxShadow: 'none'
                }}
                onClick={() => {
                  if (selected === 'Poor') {
                    setSelected(undefined)
                  } else {
                    setSelected('Poor')
                  }
                }}
                icon={<ThumbDownOutlinedIcon />}
              />
            </Flex>
          </Flex>
          {selected && (
            <Box
              position="absolute"
              top={10}
              bg="white"
              p="small"
              mt="24px"
              borderRadius="8px"
              border="1px solid"
              borderColor="pale_gray"
              boxShadow="0px 2px 8px 0px #00000014"
              style={{
                marginInlineStart: '0px'
              }}
              width="350px"
            >
              <Textarea
                placeholder={placeholders[selected as FeedbackType]}
                borderColor="pale_gray"
                onChange={e => setFeedback(e.target.value)}
              />
              <Flex mt="16px">
                <Button
                  flex="1"
                  type="submit"
                  isDisabled={!selected}
                  isLoading={isSubmittingTrackEvent}
                  variant="outline"
                  _hover={{ opacity: 0.7 }}
                  _focus={{ outline: 'none' }}
                >
                  Skip
                </Button>
                <Button
                  flex="1"
                  type="submit"
                  colorScheme="primary"
                  isDisabled={!selected}
                  isLoading={isSubmittingTrackEvent}
                >
                  Submit
                </Button>
              </Flex>
            </Box>
          )}
        </HStack>
      </Stack>
    </form>
  )
}

export default ProgressNoteFeedback
