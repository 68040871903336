import React, { useState, useEffect } from 'react'
import { Divider, Box, Flex, Text, VStack, Button } from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import { Clipboard } from '@components/icons'
import {
  AssessmentSuggestionDTO,
  WorksheetSuggestionDTO
} from '~/clinician-api/models'
import {
  getSessionControllerGetSuggestedContentQueryKey,
  useContentSuggestionControllerSubmitFeedback
} from '~/clinician-api'
import { useExperienceManager } from '@hooks'
import Feedback from './feedback'

interface ContentSuggestionItemProps {
  type: 'worksheet' | 'assessment'
  suggestion: (AssessmentSuggestionDTO | WorksheetSuggestionDTO) & {
    name: string
  }
  sessionId: string
  onAssign?: () => void
  isLoading?: boolean
}

export const ContentSuggestionItem = ({
  type,
  suggestion,
  sessionId,
  onAssign,
  isLoading
}: ContentSuggestionItemProps) => {
  const { isAssistAssessmentAssignmentEnabled } = useExperienceManager()
  const queryClient = useQueryClient()
  const [commentsOpen, setCommentsOpen] = useState(false)
  const [rating, setRating] = useState<null | string>(
    suggestion.feedback?.rating || null
  )
  const [comments, setComments] = useState<string>('')

  useEffect(() => {
    setRating(suggestion.feedback?.rating || null)
  }, [suggestion.feedback?.rating])

  useEffect(() => {
    setComments('')
    setCommentsOpen(false)
  }, [suggestion.contentSuggestionId])

  const {
    mutateAsync: submitFeedback
  } = useContentSuggestionControllerSubmitFeedback({
    mutation: {
      onSuccess: () => {
        const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
          sessionId
        )
        queryClient.invalidateQueries({ queryKey: suggestionListKey })
      }
    }
  })

  const handleFeedback = async (value: string) => {
    setRating(value)
    setComments('')
    setCommentsOpen(true)
    await submitFeedback({
      contentSuggestionId: suggestion.contentSuggestionId,
      data: { rating: value }
    })
  }

  return (
    <Box bg="white" borderRadius="8px" border="1px solid #E4E5E6">
      <VStack p={4} spacing={2} align="stretch">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Clipboard fill="black" />
            <Text ml={2} fontWeight="bold">
              {suggestion.name}
            </Text>
          </Flex>
          {type === 'assessment' && isAssistAssessmentAssignmentEnabled && (
            <Button
              variant="link"
              color="primary"
              textDecoration="none"
              _focus={{ outline: 'none' }}
              _hover={{ outline: 'none' }}
              onClick={onAssign}
              isLoading={isLoading}
            >
              Assign
            </Button>
          )}
        </Flex>
        <Box>
          <Text
            color="dark_gray"
            fontSize="12px"
            lineHeight="16px"
            title={suggestion.rationale}
          >
            {suggestion.rationale}
          </Text>
        </Box>
      </VStack>
      <Divider />
      <Feedback
        rating={rating}
        onFeedback={handleFeedback}
        commentsOpen={commentsOpen}
        setCommentsOpen={setCommentsOpen}
        comments={comments}
        setComments={setComments}
        submitFeedback={submitFeedback}
        suggestionId={suggestion.contentSuggestionId}
      />
    </Box>
  )
}
