import React, { useMemo, useCallback } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  Box,
  Heading,
  Stack,
  Text,
  VStack,
  useToast,
  Flex
} from '@chakra-ui/react'
import moment from 'moment'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import {
  TextField,
  Button,
  Select,
  PhoneAppInstalledIcon
} from '@blueprinthq/joy'
import { DateTime } from 'luxon'
import flagsmith from 'flagsmith'
import invert from 'lodash/invert'

import { ParticipantsSection, ReminderSection } from './components'
import { toSnakeCaseKeys, formatPhone } from '@utilities'
import { endpoints } from '../../api'
import { Loading } from '../../components'
import BPDateTimePicker from '../BPDateTimePicker'
import { NOTIFICATION_PREFERENCES } from '@constants/notificationPreferences'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { useExperienceManager } from '@hooks'
import { CLIENT_PRONOUNS } from '@constants/clientPronouns'

const pronounTypes = Object.keys(CLIENT_PRONOUNS)

const baseSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  pronoun: Yup.string()
    .optional()
    .nullable(),
  phoneNumber: Yup.string()
    .phone(['US', 'CA'], true, 'Invalid U.S. or Canadian phone number.')
    .optional(),
  email: Yup.string()
    .email('Invalid email')
    .nullable()
    .optional(),
  selectedNotificationPreference: Yup.object()
    .shape({
      id: Yup.string().nullable(),
      display: Yup.string().nullable()
    })
    .nullable()
    .optional(),
  medicalRecord: Yup.string()
    .nullable()
    .optional(),
  selectedInsurancePayer: Yup.object()
    .shape({
      id: Yup.string().nullable(),
      name: Yup.string().nullable()
    })
    .nullable()
    .optional()
})

const Account = props => {
  const toast = useToast()
  const { user_id: clientId } = props
  const user = useStoreState(store => store.auth.user)
  const queryClient = useQueryClient()
  const { isEvidenceBasedCareEnabled } = useExperienceManager()
  const isAssistEnabled = flagsmith.hasFeature(FlagsmithFeatures.ASSIST)

  const organizationId = user.clinic.organization_id

  const openUpsertParticipantModal = useStoreActions(
    actions => actions.modals.upsertParticipant.openModal
  )
  const closeUpsertParticipantModal = useStoreActions(
    actions => actions.modals.upsertParticipant.closeModal
  )

  const AccountSchema = useMemo(() => {
    let schema = baseSchema

    if (isEvidenceBasedCareEnabled) {
      schema = schema.shape({
        dateOfBirth: Yup.date()
          .max(moment(), 'Date of birth must be in the past')
          .required('Required')
      })
    }

    return schema
  }, [isEvidenceBasedCareEnabled])

  const {
    data: { nodes: insurancePayers }
  } = useQuery(
    [endpoints.getInsurancePayers.getCacheId(), organizationId],
    () =>
      endpoints.getInsurancePayers.request({
        organizationId,
        shortList: true,
        search: null
      }),
    {
      initialData: { nodes: [] }
    }
  )

  const {
    data: account,
    isLoading: isAccountLoading,
    refetch: refetchAccount
  } = useQuery([endpoints.getClinicianUserAccount.getCacheId(), clientId], () =>
    endpoints.getClinicianUserAccount.request({ id: clientId })
  )

  const { data: checkInReminders = [] } = useQuery(
    [endpoints.getClientCheckInReminders.getCacheId(), clientId],
    () => endpoints.getClientCheckInReminders.request({ clientId })
  )

  const {
    data: commPrefsData = {
      push: [],
      email: [],
      sms: [],
      isPushSubscribed: true,
      isEmailSubscribed: true,
      isSmsSubscribed: true
    }
  } = useQuery(
    [endpoints.getClientUserCommPreferences.getCacheId(), clientId],
    () => endpoints.getClientUserCommPreferences.request({ clientId })
  )

  const isCheckInRemindersVisible = checkInReminders.some(r => r.is_active)

  const selectedNotificationPreference = useMemo(
    () =>
      NOTIFICATION_PREFERENCES.find(
        ip => ip.id === account.notification_preference
      ),
    [NOTIFICATION_PREFERENCES, account.notification_preference]
  )

  const selectedInsurancePayer = useMemo(
    () => insurancePayers.find(ip => ip.id === account.insurance_payer_id),
    [insurancePayers, account.insurance_payer_id]
  )

  const { mutate: updateClientAccount, isError, error } = useMutation(
    endpoints.patchClinicianUserInfo.request,
    {
      onSuccess() {
        refetchAccount()
        toast({
          description: 'Client info updated!',
          status: 'success',
          duration: 3000
        })
      }
    }
  )

  const { mutate: migrateClientContactToParticipant } = useMutation(
    endpoints.postMigrateClientContactToParticipant.request,
    {
      onSuccess() {
        queryClient.invalidateQueries([
          endpoints.getClinicianUserAccount.getCacheId(),
          account.id
        ])
        queryClient.invalidateQueries([
          endpoints.getClientParticipants.getCacheId(),
          account.id
        ])
      }
    }
  )

  const getErrorMessage = ({ error }) => {
    if (!error) {
      return null
    }

    const defaultMessage =
      "We're sorry, something went wrong trying to process your request. Please refresh the page and try again."

    if (Array.isArray(error.details) && error.details.length) {
      return error.details[0].description || defaultMessage
    } else {
      return defaultMessage
    }
  }

  const isDeprecatedChildClientFlow = useMemo(() => {
    return (
      (account.email || account.phone_number) &&
      account.is_using_deprecated_child_flow
    )
  }, [account])

  const onClickMigrateContactToParticipant = useCallback(() => {
    const onSubmitMigrate = async ({
      firstName,
      lastName,
      email,
      phoneNumber,
      type
    }) => {
      const participantData = {
        firstName,
        lastName,
        email,
        phoneNumber,
        type: type.id
      }

      await migrateClientContactToParticipant({
        clientId,
        data: participantData
      })

      closeUpsertParticipantModal()
    }

    openUpsertParticipantModal({
      isMigration: true,
      participant: {
        email: account.email,
        phoneNumber: account.phone_number
      },
      onSubmit: onSubmitMigrate
    })
  }, [account, clientId])

  if (isAccountLoading) {
    return <Loading />
  }

  const isClientEnrolled = !!account.enrollment_timestamp

  return (
    <Box>
      <Heading mb="xsmall">Client</Heading>
      <Text mb="medium">Update information about your client.</Text>
      <Formik
        initialValues={{
          firstName: account.first_name,
          lastName: account.last_name,
          dateOfBirth: account.date_of_birth
            ? moment.utc(account.date_of_birth).format('MM/DD/YYYY')
            : '',
          pronoun: invert(CLIENT_PRONOUNS)[account.pronoun],
          phoneNumber: account.phone_number || '',
          email: account.email || '',
          selectedNotificationPreference,
          medicalRecord: account.medical_record || '',
          selectedInsurancePayer
        }}
        validationSchema={AccountSchema}
        onSubmit={async (values, actions) => {
          const {
            selectedNotificationPreference,
            selectedInsurancePayer,
            ...formVals
          } = values
          let vals = {
            ...formVals,
            dateOfBirth: values.dateOfBirth ? values.dateOfBirth : null,
            phoneNumber: values.phoneNumber.length
              ? formatPhone(values.phoneNumber)
              : null
          }
          const data = toSnakeCaseKeys(vals)
          data.notification_preference = selectedNotificationPreference
            ? selectedNotificationPreference.id
            : 'none'
          data.insurancePayerId = selectedInsurancePayer
            ? selectedInsurancePayer.id
            : null
          data.pronoun = CLIENT_PRONOUNS[values.pronoun]
          await updateClientAccount({ patientId: clientId, data })
          actions.setSubmitting(false)
        }}
      >
        {({ errors, touched, isSubmitting, dirty, values }) => (
          <Form>
            <VStack spacing="small" alignItems="flex-start">
              <Heading fontSize="md">Basic Info</Heading>
              <Stack
                mb={0}
                direction={{
                  base: 'column',
                  md: isEvidenceBasedCareEnabled ? 'row' : 'column'
                }}
                justify="space-between"
                w={'100%'}
                spacing="small"
              >
                <Field name="firstName">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      isInvalid={errors.firstName && touched.firstName}
                      errorText={errors.firstName}
                      isRequired
                    />
                  )}
                </Field>
                <Field name="lastName">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      isInvalid={errors.lastName && touched.lastName}
                      errorText={errors.lastName}
                      isRequired
                    />
                  )}
                </Field>
              </Stack>
              <Stack
                direction={{
                  base: 'column',
                  md: isEvidenceBasedCareEnabled ? 'row' : 'column'
                }}
                justify="space-between"
                w={'100%'}
                spacing="small"
              >
                {isEvidenceBasedCareEnabled ? (
                  <Field name="dateOfBirth">
                    {({ field }) => (
                      <BPDateTimePicker
                        {...field}
                        showDate
                        isRequired
                        label="Date of Birth"
                        isInvalid={errors.dateOfBirth && touched.dateOfBirth}
                        errorText={
                          (errors.dateOfBirth &&
                            errors.dateOfBirth.includes('Invalid') &&
                            'Invalid date') ||
                          errors.dateOfBirth
                        }
                      />
                    )}
                  </Field>
                ) : null}
                <Field name="pronoun">
                  {({ field, form }) => (
                    <Select
                      label="Pronouns"
                      options={pronounTypes}
                      value={field.value}
                      onChange={value => form.setFieldValue('pronoun', value)}
                    />
                  )}
                </Field>
              </Stack>
              {isDeprecatedChildClientFlow ? (
                <Flex
                  w="100%"
                  bg="black"
                  p="xsmall"
                  alignItems="center"
                  pl="small"
                  borderRadius="4px"
                >
                  <PhoneAppInstalledIcon fill="white" />
                  <Text ml="small" color="white">
                    Client must be 13 years old to use the Blueprint app
                  </Text>
                  <Button
                    ml="xxsmall"
                    color="white"
                    variant="ghost"
                    textDecoration="underline"
                    m="0px"
                    onClick={onClickMigrateContactToParticipant}
                  >
                    Migrate to Participant
                  </Button>
                </Flex>
              ) : null}
              {account.isChildAge &&
              !isDeprecatedChildClientFlow &&
              isEvidenceBasedCareEnabled ? (
                <Flex
                  w="100%"
                  bg="pale_gray"
                  p="small"
                  alignItems="center"
                  pl="small"
                  borderRadius="4px"
                >
                  <PhoneAppInstalledIcon fill="black" />
                  <Text ml="small" color="black">
                    Client must be 13 years old to use the Blueprint app
                  </Text>
                </Flex>
              ) : null}
              {!account.isChildAge || isDeprecatedChildClientFlow ? (
                <>
                  <Stack
                    direction={{
                      base: 'column',
                      md: 'row'
                    }}
                    justify="space-between"
                    w="100%"
                    spacing="small"
                  >
                    {((isAssistEnabled && isClientEnrolled) ||
                      isEvidenceBasedCareEnabled) && (
                      <Field name="email">
                        {({ field }) => (
                          <TextField
                            {...field}
                            label="Email Address"
                            isInvalid={errors.email && touched.email}
                            errorText={errors.email}
                            disabled={isDeprecatedChildClientFlow}
                          />
                        )}
                      </Field>
                    )}
                    {((isAssistEnabled && isClientEnrolled) ||
                      isEvidenceBasedCareEnabled) && (
                      <Field name="phoneNumber">
                        {({ field }) => (
                          <TextField
                            {...field}
                            label="Cell Phone"
                            isInvalid={
                              errors.phoneNumber && touched.phoneNumber
                            }
                            errorText={errors.phoneNumber}
                            disabled={isDeprecatedChildClientFlow}
                            type="tel"
                          />
                        )}
                      </Field>
                    )}
                  </Stack>
                  {isEvidenceBasedCareEnabled && (
                    <>
                      <ReminderSection
                        title="Assessment Reminders"
                        subtitle={`How ${values.firstName} receives assessment reminders.`}
                        messageType={'SCHEDULED_ASSESSMENT_ADMINISTRATION'}
                        switchesEnabled
                        account={account}
                        clientFirstName={values.firstName}
                        commPrefsData={commPrefsData}
                      />
                      <ReminderSection
                        title="Check-in Reminders"
                        subtitle={`How ${values.firstName} receives check-in reminders.`}
                        messageType={'SCHEDULED_CHECKIN_REMINDER'}
                        switchesEnabled={isCheckInRemindersVisible}
                        account={account}
                        clientFirstName={values.firstName}
                        commPrefsData={commPrefsData}
                      >
                        <Box>
                          {isCheckInRemindersVisible ? (
                            checkInReminders.map(reminder => (
                              <Box
                                py={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                borderTop="1px"
                                borderColor="light_gray"
                              >
                                <Box>{reminder.days_of_week.join(', ')}</Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                >
                                  <Box>
                                    <Text color="dark_gray">
                                      {DateTime.fromISO(
                                        reminder.time_of_day
                                      ).toLocaleString(DateTime.TIME_SIMPLE)}
                                    </Text>
                                  </Box>
                                </Box>
                              </Box>
                            ))
                          ) : (
                            <Text
                              align={'center'}
                            >{`${values.firstName} has no active check-in reminders.`}</Text>
                          )}
                        </Box>
                      </ReminderSection>
                      <ReminderSection
                        title="Safety Net Communication"
                        subtitle={`How ${values.firstName} receives safety net communication.`}
                        messageType={'CLIENT_SAFETY_NET'}
                        switchesEnabled
                        account={account}
                        clientFirstName={values.firstName}
                        commPrefsData={commPrefsData}
                      />
                    </>
                  )}
                </>
              ) : null}
              {isEvidenceBasedCareEnabled && (
                <>
                  <Heading fontSize="md">Medical Info</Heading>
                  <Field name="selectedInsurancePayer">
                    {({ field, form }) => (
                      <Select
                        {...field}
                        onChange={value =>
                          form.setFieldValue('selectedInsurancePayer', value)
                        }
                        options={insurancePayers}
                        valueKey="id"
                        labelKey="name"
                        label="Insurance"
                        isInvalid={
                          errors.selectedInsurancePayer &&
                          touched.selectedInsurancePayer
                        }
                        errorText={errors.selectedInsurancePayer}
                      />
                    )}
                  </Field>
                  <Field name="medicalRecord">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Medical Record Number"
                        isInvalid={
                          errors.medicalRecord && touched.medicalRecord
                        }
                        errorText={errors.medicalRecord}
                      />
                    )}
                  </Field>
                </>
              )}
              {isError && <Text color="severe">{getErrorMessage(error)}</Text>}
              <Button
                isDisabled={!dirty}
                mt="xsmall"
                size="lg"
                isLoading={isSubmitting}
                type="submit"
              >
                Save
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
      {isEvidenceBasedCareEnabled && <ParticipantsSection client={account} />}
    </Box>
  )
}

export default Account
