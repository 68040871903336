import React, { useState, useRef, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import {
  Box,
  Button,
  Heading,
  Text,
  Image,
  UnorderedList,
  ListItem,
  Stack,
  useToast
} from '@chakra-ui/react'

import { endpoints } from '../../../../api'
import LogoPreview from './logo-preview'
import { usePermissions } from '@hooks'

// No more than 1mb
const MAX_FILE_SIZE = 1 * 1024 * 1024

const CoBranding = ({ clinic, user }) => {
  const [logoUrl, setLogoUrl] = useState(clinic?.logo?.url || clinic?.logoUrl)
  const toast = useToast()

  const fileRef = useRef()
  const queryClient = useQueryClient()
  const { hasPermission } = usePermissions()

  useEffect(() => {
    setLogoUrl(clinic?.logo?.url || clinic?.logoUrl)
  }, [clinic])

  const { mutateAsync: uploadLogo } = useMutation(
    endpoints.postClinicLogo.request,
    {
      onSuccess(asset) {
        toast({
          description: 'Logo uploaded!',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        setLogoUrl(asset.url)
        queryClient.invalidateQueries(endpoints.postClinicLogo.getCacheId())
        queryClient.invalidateQueries(
          endpoints.getOrganizationClinics.getCacheId()
        )
      },
      onError(status) {
        toast({
          title: 'Error',
          description:
            status === 403
              ? 'You do not have permission to edit logo.'
              : 'Please try again or contact support at help@blueprint-health.com.',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  const { mutateAsync: deleteLogo } = useMutation(
    endpoints.deleteClinicLogo.request,
    {
      onSuccess(asset) {
        toast({
          description: 'Logo removed!',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        setLogoUrl()
        queryClient.invalidateQueries(endpoints.deleteClinicLogo.getCacheId())
        queryClient.invalidateQueries(
          endpoints.getOrganizationClinics.getCacheId()
        )
      },
      onError(status) {
        toast({
          title: 'Error',
          description:
            status === 403
              ? 'You do not have permission to edit logo.'
              : 'Please try again or contact support at help@blueprint-health.com.',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  const onChangeFile = event => {
    event.stopPropagation()
    event.preventDefault()
    if (event.target.files.length) {
      const file = event.target.files[0]
      if (file.size >= MAX_FILE_SIZE) {
        alert('File cannot be larger than 1mb.')
      } else {
        uploadLogo({
          clinicId: clinic.id,
          image: event.target.files[0]
        })
        // Need to reset input value so next 'onChange' will fire
        event.target.value = null
      }
    }
  }

  const canAddOrDeleteLogo = hasPermission('create:clinic:settings:logo')

  return (
    <>
      <Heading size="small" mb="xs">
        Clinic Logo
      </Heading>
      <Text mb="small">
        Display your clinic logo across the client experience.
      </Text>
      <Stack direction={['column', 'row']}>
        <Box position="relative" flex="3">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            rounded="lg"
            bg="primary"
            w={{ xs: '100%', sm: '380px' }}
            minW="380px"
            h="100%"
            mr="small"
          >
            <Image maxW="320px" maxH="64px" src={logoUrl} alt="" />
            {!logoUrl && (
              <Box
                w="260px"
                h="64px"
                borderWidth="2px"
                borderStyle="dashed"
                borderColor="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
                _hover={{ cursor: 'pointer' }}
                onClick={() => fileRef.current.click()}
              >
                <Text color="white">Add clinic logo</Text>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          bg="rgba(45,84,232, 0.04)"
          px="6"
          py="4"
          borderRadius="8px"
          flex="1"
        >
          <Text>Recommended:</Text>
          <UnorderedList>
            <ListItem>Size 640 X 128px</ListItem>
            <ListItem>PNG</ListItem>
            <ListItem>All white logo with transparent background.</ListItem>
          </UnorderedList>
        </Box>
      </Stack>

      {canAddOrDeleteLogo && (
        <Button
          size="lg"
          type="submit"
          marginTop={'small'}
          onClick={() => fileRef.current.click()}
        >
          Add Photo
        </Button>
      )}
      {canAddOrDeleteLogo && (
        <Button
          size="lg"
          type="submit"
          marginTop={'small'}
          isDisabled={!logoUrl}
          bg="#EB5164"
          onClick={() => deleteLogo({ clinicId: clinic.id })}
        >
          Delete Photo
        </Button>
      )}

      <LogoPreview
        heading="Preview of how the logo looks in the client apps."
        height="64px"
        logoUrl={logoUrl}
      />
      <LogoPreview
        heading="Preview of how the logo looks in emails to clients."
        height="112px"
        logoUrl={logoUrl}
        isEmailPreview
      />
      <input
        type="file"
        id="file"
        ref={fileRef}
        style={{ display: 'none' }}
        onChange={onChangeFile}
        accept="image/*"
      />
    </>
  )
}

export default CoBranding
