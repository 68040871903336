import React from 'react'
import { HStack, Text, Link } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

export const UnpaidBanner = ({ user, adminContact }) => {
  const history = useHistory()
  const hasAdminRole = user?.roles?.some(
    role =>
      role.name === 'clinicadmin' ||
      role.name === 'superadmin' ||
      role.name === 'orgadmin'
  )

  return (
    <HStack p="xsmall" w="100%" justify="center" spacing="4px" bg="#EB5164">
      <Text fontSize="14px" as="b" color="white">
        Overdue payment on your account.
      </Text>
      <Text fontSize="14px" color="white">
        To avoid disruption in service,
      </Text>
      {hasAdminRole ? (
        <Link
          _focus={{
            outline: 'none'
          }}
          fontSize="14px"
          onClick={() => history.push('/settings/billing?openModal=true')}
          color={'white'}
          style={{
            paddingLeft: '0px',
            paddingRight: '12px',
            paddingBottom: '2px',
            paddingTop: '2px',
            textDecoration: 'underline'
          }}
        >
          submit payment
        </Link>
      ) : (
        <a
          href={`mailto:${adminContact}?subject=Expired Payment Method Notification on Blueprint. Take a look?&body=A%20quick%20request/heads%20up.%20The%20Blueprint%20platform%20just%20notified%20me%20we%20have%20an%20issue%20with%20our%20payment%20method%20and%20we%20have%20an%20overdue%20payment.%20Could%20you%20take%20a%20quick%20look%3F%0A%0AHere%27s%20a%20link%20that%20will%20take%20you%20directly%20to%20the%20Blueprint%20billing%20page%3A%20%0Ahttps%3A//clinician.blueprint-health.com/settings/billing%0A%0AThanks%21`}
          style={{ textDecoration: 'none' }}
        >
          <Link
            _focus={{
              outline: 'none'
            }}
            color={'white'}
            fontSize="14px"
            style={{
              paddingLeft: '0px',
              paddingRight: '12px',
              paddingBottom: '2px',
              paddingTop: '2px',
              textDecoration: 'underline'
            }}
          >
            email your admin
          </Link>
        </a>
      )}
    </HStack>
  )
}
