import React, { createContext, useState, useEffect, FC } from 'react'

interface ExtensionContextType {
  ehrClientNames: string[]
  settings:
    | {
        isDraggable: boolean
        isAlwaysOpen: boolean
        copyNoteButtonText: string
      }
    | undefined
}

export const ExtensionContext = createContext<ExtensionContextType>(
  {} as ExtensionContextType
)

interface ExtensionProviderProps {
  children: React.ReactNode
}

export const ExtensionProvider: FC<ExtensionProviderProps> = ({ children }) => {
  const [ehrClientNames, setEhrClientNames] = useState<string[]>([])
  const [settings, setSettings] = useState()

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.type === 'BP_EHR_CLIENT_INFO') {
        const clientNames = event.data.ehrClientNames

        if (clientNames?.length) {
          // @ts-ignore
          const [firstClient, secondClient, thirdClient] = [
            ...new Set(clientNames)
          ] as string[]

          // maintain a list of the last 3 visited clients
          setEhrClientNames(
            [firstClient, secondClient, thirdClient].filter(Boolean)
          )
        }
      }

      if (event.data?.type === 'BP_SETTINGS') {
        setSettings(event.data.settings)
      }
    }

    window.addEventListener('message', handleMessage)

    // eslint-disable-next-line
    parent.postMessage({ type: 'BP_EXTENSION_CONTEXT_LOADED' }, '*')

    return () => window.removeEventListener('message', handleMessage)
  }, [])

  return (
    <ExtensionContext.Provider
      value={{
        ehrClientNames,
        settings
      }}
    >
      {children}
    </ExtensionContext.Provider>
  )
}
