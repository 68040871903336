import React from 'react'
import { Box, Flex, Text, VStack, useBreakpointValue } from '@chakra-ui/react'
import { MicAIOn, MicDictate, MicUpload } from '@components/icons'
import { useHistory } from 'react-router-dom'

export const SessionTypeCTA = ({
  id,
  Icon,
  title,
  description,
  onClick,
  isHorizontal,
  padding = 'small'
}) => {
  return (
    <Box
      id={id}
      borderRadius="12px"
      backgroundColor="#EAEEFD"
      onClick={onClick}
      border="1px solid #EAEEFD"
      _hover={{
        cursor: 'pointer',
        border: '1px solid',
        borderColor: 'primary'
      }}
      w="100%"
      h="100%"
      flex={isHorizontal ? 'auto' : '1'}
      padding={padding}
    >
      <Flex
        flexDirection={isHorizontal ? 'row' : 'column'}
        justifyContent={isHorizontal ? 'flex-start' : 'center'}
        alignItems="center"
        rowGap="xsmall"
        h="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          backgroundColor="primary"
          borderRadius="90px"
          mr={isHorizontal ? 'small' : '0px'}
          w="48px"
          minW="48px"
          minH="48px"
        >
          {Icon}
        </Flex>
        <Flex
          flexDirection="column"
          alignItems={isHorizontal ? 'flex-start' : 'center'}
        >
          <Text as="b">{title}</Text>
          <Text textAlign="center">{description}</Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export const SessionTypeCTAGroup = ({
  setUploadModalOpen,
  openDocAutomationSessionLimitReachedModal,
  openDocAutomationPlanModal,
  documentationAutomationFreeTierSessionLimitReached,
  isAdmin
}) => {
  const history = useHistory()
  const isHorizontal = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <VStack spacing={4} alignItems="stretch" mb={4}>
      <SessionTypeCTA
        id="pendo-sessions-start-session"
        Icon={<MicAIOn width="32px" height="32px" />}
        title="Record Audio"
        description="Use during live sessions"
        onClick={() => history.push('/start-session/select-client')}
        isHorizontal={isHorizontal}
      />
      <SessionTypeCTA
        id="pendo-sessions-start-session-dictate"
        Icon={<MicDictate width="32px" height="32px" />}
        title="Dictate Notes"
        description="Turn client visits into notes"
        onClick={() => history.push('/start-session/dictate/select-client')}
        isHorizontal={isHorizontal}
      />
      <SessionTypeCTA
        Icon={<MicUpload width="32px" height="32px" padding="0 0 0 5px" />}
        title="Upload Recording"
        description="Convert audio into notes"
        onClick={() => {
          if (documentationAutomationFreeTierSessionLimitReached) {
            if (isAdmin) {
              openDocAutomationPlanModal()
            } else {
              openDocAutomationSessionLimitReachedModal()
            }
          } else {
            setUploadModalOpen(true)
          }
        }}
        isHorizontal={isHorizontal}
      />
    </VStack>
  )
}
