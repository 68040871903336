import { endpoints } from '@api'
import { ArrowDownIcon, ArrowUpIcon, Button } from '@blueprinthq/joy'
import { Box, Collapse, Flex, Heading } from '@chakra-ui/react'
import { CLIENT_TYPE, PARTICIPANT_TYPE } from '@constants/index'
import { useAssignCheckInsModal } from '@containers/assign-check-ins-modal'
import {
  AssignSymptomsModal,
  AssignWorksheetsModal
} from '@containers/assign-measures-modal/components'
import { usePermissions } from '@hooks'
import { calculateAge, doesPassAgeRule } from '@utilities'
import { useStoreState } from 'easy-peasy'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import * as uuid from 'uuid'
import {
  Assessments,
  ClientInfo,
  Clinic,
  Clinician,
  EnrollmentFormTooltip,
  Invite,
  Participants,
  ParticipantsTooltip,
  SymptomTrackers,
  Worksheets
} from './components'
import { ProgramEnrollments } from './components/program-enrollments'

const emptyParticipant = {
  type: Object.values(PARTICIPANT_TYPE)[0],
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: ''
}

const filterDefaultAssessments = (assessments, clientAge) => {
  return assessments.filter(asmt => {
    if (asmt.is_default) {
      const { rules_for_default } = asmt
      const ageRules =
        rules_for_default && rules_for_default.age.comparisons.length
          ? rules_for_default.age.comparisons
          : null
      // 'Assign' assessment if passing ageRules OR ageRules is null.
      return (
        ageRules === null ||
        ageRules.every(rule => doesPassAgeRule(rule, clientAge))
      )
    }

    return false
  })
}

const mapAssessmentForDisplay = assessment => {
  return {
    id: assessment.id,
    assessmentId: assessment.assessment_id,
    title: `${assessment.full_name} ${
      assessment.name !== assessment.full_name
        ? '(' + assessment.name + ')'
        : ''
    }`,
    subtitle: assessment.disorder,
    numQuestions: assessment.num_questions,
    cadence: {
      unit: assessment.cadence_unit,
      value: assessment.cadence_value
    },
    isScreener: false
  }
}

const useAgeMemo = (dateOfBirth, clientType) => {
  return useMemo(() => {
    if (!isNaN(new Date(dateOfBirth))) {
      return calculateAge(new Date(dateOfBirth))
    }
    return clientType === CLIENT_TYPE.CHILD ? 10 : 30
  }, [dateOfBirth, clientType])
}

export const FormSections = ({ formHelpers, isPendingClient }) => {
  const { values, touched, errors, setFieldValue } = formHelpers
  const assignCheckInsModal = useAssignCheckInsModal()

  const { hasPermission } = usePermissions()
  const showClinicDropDown = hasPermission('create:org:activeclients:enroll')
  const showClinicianDropDown = hasPermission(
    'create:clinic:activeclients:enroll'
  )

  const { data: assessments, isLoading: isAssessmentsLoading } = useQuery(
    [
      endpoints.getClinicianAssessments.getCacheId(),
      values.clinician && values.clinician.id
    ],
    () =>
      endpoints.getClinicianAssessments.request({
        clinicianId: values.clinician && values.clinician.id
      }),
    {
      initialData: [],
      onSuccess(nextAssessments) {
        // The assigned clinician may not have access to already assigned assessments in their library, so we need to remap (to new clinicAssessmentIds) and filter out non-existing ones
        Object.entries(values.assessments).map(([ref, refAssessments]) => {
          const reassignedRefAssessments = refAssessments
            .map(refAssessment => {
              const assessment = nextAssessments.find(
                a => a.assessment_id === refAssessment.assessmentId
              )
              return assessment ? mapAssessmentForDisplay(assessment) : null
            })
            .filter(a => a !== null)

          setFieldValue(`assessments.${ref}`, reassignedRefAssessments)
        })
      }
    }
  )

  const { user } = useStoreState(state => state.auth)
  const organizationId = user?.clinic?.organization?.id
  const { data: programsData, isLoading: programsIsLoading } = useQuery(
    [endpoints.getPrograms.getCacheId(), organizationId],
    () => endpoints.getPrograms.request({ organizationId })
  )
  const programs = programsData?.programs || []
  const [isProgramsDrawerOpen, setIsProgramsDrawerOpen] = useState(false)

  const { data: modules, isLoading: isModulesLoading } = useQuery(
    [endpoints.getClinicianCheckInModules.getCacheId()],
    () => endpoints.getClinicianCheckInModules.request(),
    {
      initialData: []
    }
  )

  const age = useAgeMemo(values.dateOfBirth, values.clientType)

  const onAssignAssessments = useCallback(
    (selectedAssessments, assigneeRef) => {
      const assessmentsToAdd = selectedAssessments.map(sa => {
        const fullAssessment = assessments.find(a => a.id === sa.id)
        return mapAssessmentForDisplay(fullAssessment)
      })

      setFieldValue(
        `assessments.${assigneeRef}`,
        values.assessments[assigneeRef].concat(assessmentsToAdd)
      )
    },
    [assessments, values.assessments, setFieldValue]
  )

  const onUnassignAssessment = useCallback(
    (assessmentIdx, assigneeRef) => {
      const assigneeAsmtArray = [...values.assessments[assigneeRef]]
      assigneeAsmtArray.splice(assessmentIdx, 1)
      setFieldValue(`assessments.${assigneeRef}`, assigneeAsmtArray)
    },
    [values.assessments, setFieldValue]
  )

  const onRemoveAssessmentAssignee = useCallback(
    assigneeRef => {
      const assessments = {
        ...values.assessments
      }
      delete assessments[assigneeRef]
      setFieldValue('assessments', assessments)
    },
    [values.assessments, setFieldValue]
  )

  const onAssignCheckInModules = useCallback(
    selectedCheckInModules => {
      const checkInModulesToAdd = selectedCheckInModules.map(sm => {
        return {
          id: sm.id,
          title: sm.title,
          subtitle: sm.subtitle,
          numQuestions: sm.questionCount,
          type: sm.type,
          canToggle: sm.canToggle
        }
      })

      setFieldValue(
        'symptomTrackers',
        values.symptomTrackers.concat(
          checkInModulesToAdd.filter(m => m.type === 'symptom')
        )
      )

      setFieldValue(
        'worksheets',
        values.worksheets.concat(
          checkInModulesToAdd.filter(m => m.type === 'treatment_activity')
        )
      )
    },
    [modules, values.worksheets, values.symptomTrackers, setFieldValue]
  )

  const onAddParticipant = useCallback(() => {
    const ref = `participant:${uuid.v4()}`
    const newParticipant = {
      ...emptyParticipant,
      ref
    }
    setFieldValue('participants', values.participants.concat([newParticipant]))
    setFieldValue(`assessments.${ref}`, [])
  }, [values.participants, setFieldValue])

  const onRemoveParticipant = useCallback(
    ref => {
      const matchingParticipant = values.participants.find(p => p.ref === ref)
      setFieldValue(
        'participants',
        values.participants.filter(p => p.ref !== ref)
      )
      onRemoveAssessmentAssignee(matchingParticipant.ref)
    },
    [values.participants, setFieldValue, onRemoveAssessmentAssignee]
  )

  const resetConflict = useCallback(
    (conflict, handler, event) => {
      const index = values.conflicts.indexOf(conflict)

      if (index > -1) {
        values.conflicts.splice(index, 1)
      }

      formHelpers.setFieldValue('conflicts', values.conflicts)
      formHelpers.setFieldValue('errorMessage', '')

      if (event) {
        handler(event)
      }
    },
    [values.conflicts, formHelpers]
  )

  useEffect(() => {
    const clientType = age > 12 ? CLIENT_TYPE.ADULT : CLIENT_TYPE.CHILD
    setFieldValue('clientType', clientType)
  }, [age])

  useEffect(() => {
    const filteredDefaultAssessments = filterDefaultAssessments(
      assessments,
      age
    )
    const cleanedDefaultAssessments = filteredDefaultAssessments.map(
      mapAssessmentForDisplay
    )

    if (!isPendingClient) {
      setFieldValue('assessments.client', cleanedDefaultAssessments)
    }

    setFieldValue('nowOrLater', 'now')
  }, [values.clientType, isPendingClient, assessments, age, setFieldValue])

  useEffect(() => {
    if (!isPendingClient) {
      const cleanedModules = modules.map(m => ({
        ...m,
        numQuestions: m.num_questions,
        canToggle: m.can_toggle
      }))

      const defaultSymptomTrackers = cleanedModules.filter(
        m => m.type === 'symptom' && m.is_default_on
      )
      setFieldValue('symptomTrackers', defaultSymptomTrackers)

      const defaultWorksheets = cleanedModules.filter(
        m => m.type === 'treatment_activity' && m.is_default_on
      )
      setFieldValue('worksheets', defaultWorksheets)
    }
  }, [modules, isPendingClient, setFieldValue])

  const sections = useMemo(() => {
    const allSections = [
      {
        key: 'client_info',
        title: 'Client Info',
        rightComponent: null,
        content: (
          <ClientInfo
            formHelpers={{
              values: _.pick(values, [
                'medicalRecord',
                'firstName',
                'lastName',
                'dateOfBirth',
                'email',
                'phoneNumber',
                'conflicts',
                'clientType'
              ]),
              touched: _.pick(touched, [
                'firstName',
                'lastName',
                'dateOfBirth',
                'email',
                'phoneNumber'
              ]),
              errors: _.pick(errors, [
                'firstName',
                'lastName',
                'dateOfBirth',
                'email',
                'phoneNumber'
              ]),
              resetConflict
            }}
          />
        )
      },
      {
        key: 'participants',
        title: (
          <>
            Participants
            <ParticipantsTooltip firstName={values.firstName} />
          </>
        ),
        rightComponent: (
          <Button
            onClick={() => {
              onAddParticipant()
            }}
            variant="link"
          >
            Add Participant
          </Button>
        ),
        content: (
          <Participants
            formHelpers={{
              values: _.pick(values, ['participants', 'clientType']),
              touched: _.pick(touched, ['participants']),
              errors: _.pick(errors, ['participants']),
              setFieldValue
            }}
            onAddParticipant={onAddParticipant}
            onRemoveParticipant={onRemoveParticipant}
          />
        )
      },
      {
        key: 'assessments',
        title: (
          <>
            {`Assessments ${
              Object.values(values.assessments).flatMap(a => a || []).length
                ? `(${
                    Object.values(values.assessments).flatMap(a => a || [])
                      .length
                  })`
                : ''
            }`}
            <EnrollmentFormTooltip
              label={
                'Screeners and outcome measures designed to monitor client progress, typically on a fixed frequency (e.g., every week).'
              }
            />
          </>
        ),
        rightComponent: null,
        content: (
          <Assessments
            onAssignAssessments={onAssignAssessments}
            onUnassignAssessment={onUnassignAssessment}
            assessments={assessments}
            isPendingClient={isPendingClient}
            formHelpers={{
              values: _.pick(values, [
                'pendingClientUserId',
                'pendingClientId',
                'assessments',
                'clientType',
                'participants',
                'firstName',
                'lastName'
              ]),
              touched: _.pick(touched, ['assessments']),
              errors: _.pick(errors, ['assessments']),
              setFieldValue
            }}
          />
        )
      },
      {
        key: 'symptom_trackers',
        title: (
          <>
            Symptom Trackers
            <EnrollmentFormTooltip
              label={
                'Brief question sets freely available for clients to self-monitor specific groups of symptoms and experiences relevant to their mental health.'
              }
            />
          </>
        ),
        rightComponent: (
          <Button
            variant="link"
            color="primary"
            onClick={() => {
              assignCheckInsModal.open({
                clinicId: user?.clinic.id,
                // @ts-ignore
                activeCheckInIds: values.symptomTrackers.map(
                  (aw: { id: any }) => aw.id
                ),
                disableAssignment: true,
                type: 'symptom',
                onAssign: onAssignCheckInModules
              })
            }}
          >
            Assign Symptom Trackers
          </Button>
        ),
        content: (
          <SymptomTrackers
            formHelpers={{
              values: _.pick(values, ['symptomTrackers']),
              touched: _.pick(touched, ['symptomTrackers']),
              errors: _.pick(errors, ['symptomTrackers'])
            }}
          />
        )
      },
      {
        key: 'worksheets',
        title: (
          <>
            Worksheets
            <EnrollmentFormTooltip
              label={
                'Activity-based worksheets designed to help clients reinforce newly learned therapeutic skills and lessons outside of sessions.'
              }
            />
          </>
        ),
        rightComponent: (
          <Button
            variant="link"
            color="primary"
            onClick={() => {
              assignCheckInsModal.open({
                clinicId: user?.clinic.id,
                // @ts-ignore
                activeCheckInIds: values.worksheets.map(
                  (aw: { id: any }) => aw.id
                ),
                disableAssignment: true,
                type: 'worksheet',
                onAssign: onAssignCheckInModules
              })
            }}
          >
            Assign Worksheets
          </Button>
        ),
        content: (
          <Worksheets
            formHelpers={{
              values: _.pick(values, ['worksheets']),
              touched: _.pick(touched, ['worksheets']),
              errors: _.pick(errors, ['worksheets'])
            }}
          />
        )
      },
      {
        key: 'programs',
        title: (
          <>
            Programs
            <EnrollmentFormTooltip
              label={
                'Specific treatments or service lines within an organization, used for analyzing client outcomes.'
              }
            />
          </>
        ),
        rightComponent: isProgramsDrawerOpen ? (
          <ArrowUpIcon />
        ) : (
          <ArrowDownIcon />
        ),
        content: (
          <Collapse in={isProgramsDrawerOpen}>
            <ProgramEnrollments />
          </Collapse>
        ),
        isHidden: !programs?.length,
        onClickHeader: () => setIsProgramsDrawerOpen(!isProgramsDrawerOpen)
      },
      {
        key: 'clinic',
        title: 'Clinic',
        rightComponent: (
          <Clinic
            formHelpers={{
              values: _.pick(values, ['clinic', 'conflicts', 'organizationId']),
              touched: _.pick(touched, ['clinic']),
              errors: _.pick(errors, ['clinic']),
              resetConflict
            }}
          />
        ),
        content: null
      },
      {
        key: 'clinician',
        title: 'Clinician',
        rightComponent: (
          <Clinician
            formHelpers={{
              values: _.pick(values, ['clinician', 'clinic', 'conflicts']),
              touched: _.pick(touched, ['clinician']),
              errors: _.pick(errors, ['clinician']),
              resetConflict
            }}
          />
        ),
        content: null
      },
      {
        key: 'invite',
        title: (
          <>
            Invite{' '}
            <Invite
              formHelpers={{
                values: _.pick(values, ['dateOfInvite', 'nowOrLater']),
                touched: _.pick(touched, ['dateOfInvite']),
                errors: _.pick(errors, ['dateOfInvite'])
              }}
            />
          </>
        ),
        rightComponent: null,
        content: null
      }
    ]

    const filters = []
    if (formHelpers && values.clientType === CLIENT_TYPE.CHILD)
      filters.push('worksheets', 'symptom_trackers', 'invite')
    if (!showClinicDropDown) filters.push('clinic')
    if (!showClinicianDropDown) filters.push('clinician')

    if (filters.length > 0) {
      return allSections.filter(s => !filters.includes(s.key))
    }

    return allSections
  }, [
    values,
    touched,
    errors,
    showClinicDropDown,
    showClinicianDropDown,
    assessments,
    modules,
    isPendingClient,
    formHelpers,
    onAddParticipant,
    onAssignAssessments,
    onAssignCheckInModules,
    onRemoveParticipant,
    onUnassignAssessment,
    resetConflict,
    setFieldValue,
    programs,
    programsIsLoading,
    isProgramsDrawerOpen
  ])

  if (isAssessmentsLoading || isModulesLoading) return null

  return sections.map(
    s =>
      !s.isHidden && (
        <Flex
          id={`${s.key}_box`}
          bg="#ffffff"
          p="medium"
          mb="10px"
          key={s.key}
          direction="column"
          borderRadius="8px"
        >
          <Flex
            align="center"
            justify="space-between"
            onClick={s.onClickHeader}
          >
            <Heading userSelect={'none'} fontSize="24px">
              {s.title}
            </Heading>
            <Box>{s.rightComponent}</Box>
          </Flex>
          {s.content}
        </Flex>
      )
  )
}
