import React, { useEffect, useState } from 'react'
import { AudioCaptureContext } from './audio-capture-context'
import {
  MeetingStatus,
  useMeetingStatus,
  useMeetingManager,
  useToggleLocalMute,
  useAudioInputs,
  useDeviceLabelTriggerStatus,
  DeviceLabelTriggerStatus,
  DeviceLabels,
  useAudioVideo,
  useContentShareControls,
  useContentShareState,
  useAttendeeAudioStatus,
  useMeetingEvent
} from 'amazon-chime-sdk-component-library-react'
import { useToast } from '@chakra-ui/react'
import * as Chime from 'amazon-chime-sdk-js'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { useExperienceManager } from '@hooks'
import { getSessionControllerGetSessionRecapQueryKey } from '~/clinician-api'
import { datadogLogs } from '@datadog/browser-logs'

interface ChimeProviderProps {
  sessionId: string
  hasStartedRecording: boolean
  refetchRecap: () => void
  children: React.ReactNode
}

const ChimeProvider: React.FC<ChimeProviderProps> = ({
  sessionId,
  refetchRecap,
  hasStartedRecording,
  children
}) => {
  const [meetingId, setMeetingId] = useState(null)
  const [attendeeId, setAttendeeId] = useState(null)
  const [sharingAttendeeId, setSharingAttendeeId] = useState(null)
  const [hasMicAccess, setHasMicAccess] = useState(true)
  const [disableEchoCancelation, setDisableEchoCancelation] = useState(false)
  const [selectedDeviceId, setSelectedDeviceId] = useState('')
  const [isUsingHeadphones, setIsUsingHeadphones] = useState(false)
  const [isTelehealth, setIsTelehealth] = useState(false)
  const [hasDetectedAudio, setHasDetectedAudio] = useState<boolean>(false)
  const [audioLastDetectedAt, setAudioLastDetectedAt] = useState<number | null>(
    null
  )
  const [showMicWarning, setShowMicWarning] = useState<boolean>(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false)
  const [stopRecordingAfter, setStopRecordingAfter] = useState<string>('')
  const [
    isStopRecordingAfterEnabled,
    setIsStopRecordingAfterEnabled
  ] = useState<boolean>(false)

  const toast = useToast()

  const queryClient = useQueryClient()
  const { user } = useStoreState(state => state.auth)

  const volumeActions: any = useStoreActions(actions => actions.volume)

  const { devices } = useAudioInputs()
  const { muted, toggleMute } = useToggleLocalMute()
  const { signalStrength } = useAttendeeAudioStatus(attendeeId || '')
  const meetingManager = useMeetingManager()
  const meetingStatus = useMeetingStatus()
  const deviceTriggerStatus = useDeviceLabelTriggerStatus()
  const audioVideo = useAudioVideo()
  const contentShareControls = useContentShareControls()
  const contentShareState = useContentShareState()
  const meetingEvent = useMeetingEvent()

  const isRecording =
    !!meetingId &&
    meetingStatus === MeetingStatus.Succeeded &&
    hasStartedRecording

  const {
    documentationAutomationFreeTierSessionLimitReached,
    isAdmin
  } = useExperienceManager()
  const selectedAudioInputLabel = devices.find(
    i => i.deviceId === selectedDeviceId
  )?.label

  const logData = {
    sessionId,
    meetingStatus: MeetingStatus[meetingStatus],
    meetingId,
    hasMicAccess,
    selectedAudioInput: selectedDeviceId,
    selectedAudioInputLabel,
    documentationAutomationFreeTierSessionLimitReached,
    isAdmin
  }

  const {
    mutateAsync: createMeeting,
    isLoading: isCreateMeetingLoading
  } = useMutation(endpoints.createMeeting.request, {
    onSuccess: (data: any) => {
      setMeetingId(data.Meeting.MeetingId)
      queryClient.setQueryData(
        getSessionControllerGetSessionRecapQueryKey(sessionId),
        (oldData: any) => {
          return {
            ...oldData,
            chimeMeetingId: data?.Meeting?.MeetingId
          }
        }
      )
      refetchRecap()
    },
    onError: async (data: any) => {
      await stopRecording()
      toast({
        title:
          data.message ||
          'We are unable to record at this time. Please try again later.',
        status: 'error',
        isClosable: true,
        duration: 10000
      })
    }
  })

  const {
    mutateAsync: createSetupMeeting,
    isLoading: isCreateSetupMeetingLoading
  } = useMutation(endpoints.createSetupMeeting.request, {
    onSuccess: (data: any) => {
      setMeetingId(data.Meeting.MeetingId)
    }
  })

  const { mutateAsync: completeScribeOnboarding } = useMutation(
    endpoints.postUserAccount.request,
    {
      onSuccess() {
        queryClient.invalidateQueries(endpoints.getUserAccount.getCacheId())
      }
    }
  )

  const handleCompleteScribeOnboarding = async () => {
    if (!user?.has_onboarded_to_scribe) {
      await completeScribeOnboarding({
        data: { has_onboarded_to_scribe: true }
      })
    }
  }

  useEffect(() => {
    if (contentShareState.sharingAttendeeId) {
      // @ts-ignore
      setSharingAttendeeId(contentShareState.sharingAttendeeId)
    }
  }, [contentShareState?.sharingAttendeeId])

  useEffect(() => {
    // after starting recording, check for audio after 15 seconds
    const interval = setInterval(() => {
      if (isRecording && !audioLastDetectedAt && !muted) {
        setShowMicWarning(true)
      }
    }, 15000)

    return () => clearInterval(interval)
  }, [audioLastDetectedAt, isRecording])

  useEffect(() => {
    // after the first audio check, continue to check every 60 seconds
    const interval = setInterval(() => {
      if (
        isRecording &&
        !muted &&
        audioLastDetectedAt &&
        Date.now() - audioLastDetectedAt > 60
      ) {
        setShowMicWarning(true)
      }
    }, 60000)

    return () => clearInterval(interval)
  }, [audioLastDetectedAt, isRecording, muted])

  useEffect(() => {
    if (!audioVideo || !attendeeId) {
      return
    }

    const callback = (
      _: string,
      volume: number | null,
      __: boolean | null,
      ___: number | null
    ) => {
      if (volume && volume > 0) {
        setHasDetectedAudio(true)
        setShowMicWarning(false)
        // @ts-ignore
        if (isRecording) {
          setAudioLastDetectedAt(Date.now())
        }
      }
      volumeActions.setAudioLevel(volume)
    }

    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, callback)

    return () =>
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, callback)
  }, [attendeeId, audioVideo, volumeActions, isRecording])

  useEffect(() => {
    if (!audioVideo || !sharingAttendeeId) {
      return
    }

    const callback = (
      _: string,
      volume: number | null,
      __: boolean | null,
      ___: number | null
    ) => {
      if (volume && volume > 0) {
        setHasDetectedAudio(true)
        // @ts-ignore
        setAudioLastDetectedAt(Date.now())
        setShowMicWarning(false)
      }
      volumeActions.setAudioLevel(volume)
    }

    audioVideo.realtimeSubscribeToVolumeIndicator(sharingAttendeeId, callback)

    return () =>
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(
        sharingAttendeeId,
        callback
      )
  }, [sharingAttendeeId, audioVideo, volumeActions])

  useEffect(() => {
    if (deviceTriggerStatus === DeviceLabelTriggerStatus.DENIED) {
      setHasMicAccess(false)
    }
  }, [deviceTriggerStatus])

  useEffect(() => {
    const setDefaultDevice = async () => {
      const lastMicDeviceId = localStorage.getItem('microphoneDeviceId')
      const lastUsedDevice = devices.find(i => i.deviceId === lastMicDeviceId)
      const defaultDevice = lastUsedDevice || devices.find(i => !!i.deviceId)

      if (defaultDevice) {
        await meetingManager.startAudioInputDevice(defaultDevice.deviceId)
        setSelectedDeviceId(defaultDevice.deviceId)
      }
    }

    if (
      !selectedDeviceId &&
      meetingStatus === MeetingStatus.Succeeded &&
      !!meetingManager?.meetingId
    ) {
      setDefaultDevice()
    }
  }, [
    devices,
    selectedDeviceId,
    meetingStatus,
    meetingManager?.meetingId,
    meetingManager
  ])

  useEffect(() => {
    if (!meetingManager.meetingId) {
      setSelectedDeviceId('')
    }
  }, [meetingManager?.meetingId])

  useEffect(() => {
    const handleEchoCancelation = async () => {
      const audioInputDevice =
        // @ts-ignore
        meetingManager?.audioVideo?.deviceController?.activeDevices?.audio

      if (audioInputDevice) {
        audioInputDevice.autoGainControl = !disableEchoCancelation
        audioInputDevice.echoCancellation = !disableEchoCancelation
        // @ts-ignore
        await meetingManager.startAudioInputDevice(audioInputDevice)
      }
    }

    if (selectedDeviceId) {
      handleEchoCancelation()
    }
  }, [disableEchoCancelation, selectedDeviceId, meetingManager])

  useEffect(() => {
    if (meetingEvent) {
      datadogLogs.logger.info('[Chime] Meeting Event Received', {
        meetingEvent,
        ...logData
      })
    }
  }, [meetingEvent])

  useEffect(() => {
    datadogLogs.logger.info('[Session] Chime Meeting Status Changed', logData)
  }, [meetingStatus])

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Succeeded) {
      datadogLogs.logger.info('[Chime] Signal Strength Update', {
        signalStrength,
        ...logData
      })
    }
  }, [signalStrength, meetingStatus])

  const setChimeSelectedAudioInput = async (deviceId: string) => {
    localStorage.setItem('microphoneDeviceId', deviceId)
    await meetingManager.startAudioInputDevice(deviceId)
    setSelectedDeviceId(deviceId)
  }

  const handleJoinMeeting = async ({
    noteType,
    isTelehealth,
    isUsingHeadphones
  }: {
    noteType: string
    isTelehealth: boolean
    isUsingHeadphones: boolean
  }) => {
    const data: any = await createMeeting({
      sessionId,
      noteType,
      isTelehealth,
      isUsingHeadphones
    })

    const attendee = data.Attendees.find(
      (a: any) => a.ExternalUserId === user.id
    )
    setAttendeeId(attendee.AttendeeId)

    const configuration = new Chime.MeetingSessionConfiguration(
      data.Meeting,
      attendee
    )

    const options = {
      deviceLabels: DeviceLabels.Audio
    }

    if (meetingStatus !== MeetingStatus.Succeeded) {
      // do not attempt to join if setup meeting is already active
      await meetingManager.join(configuration, options)
      await meetingManager.start()
    }
  }

  const handleStartRecording = async ({
    noteType,
    isTelehealth,
    isUsingHeadphones
  }: {
    noteType: string
    isTelehealth: boolean
    isUsingHeadphones: boolean
  }) => {
    try {
      await handleJoinMeeting({ noteType, isTelehealth, isUsingHeadphones })
      await handleCompleteScribeOnboarding()
      datadogLogs.logger.info('[Session] Recording started', logData)
    } catch (err) {
      if (err instanceof Error && err?.name === 'NotAllowedError') {
        datadogLogs.logger.warn(
          '[Session] Unable to access microphone',
          {
            ...logData
          },
          err as Error
        )
        setHasMicAccess(false)
      } else {
        datadogLogs.logger.error(
          '[Session] Unable to start recording',
          {
            ...logData
          },
          err as Error
        )
        console.error(err)
      }
    }
  }

  const handleCreateSetupMeeting = async () => {
    const data: any = await createSetupMeeting({ sessionId })
    const attendee = data.Attendees.find(
      (a: any) => a.ExternalUserId === user.id
    )
    setAttendeeId(attendee.AttendeeId)
    const configuration = new Chime.MeetingSessionConfiguration(
      data.Meeting,
      attendee
    )
    const options = {
      deviceLabels: DeviceLabels.None // do not prompt for mic access here; we will manually trigger that
    }

    await meetingManager.join(configuration, options)
    await meetingManager.start()
  }

  const handleShareContent = async () => {
    if (!contentShareState.isLocalUserSharing) {
      datadogLogs.logger.info('[Session] Requesting audio share', logData)
      try {
        const mediaStream = await navigator.mediaDevices.getDisplayMedia({
          audio: true,
          // @ts-ignore
          monitorTypeSurfaces: 'exclude',
          selfBrowserSurface: ['include']
        })
        contentShareControls.toggleContentShare(mediaStream)
        datadogLogs.logger.info('[Session] Audio Share Succeeded', logData)
      } catch (err) {
        datadogLogs.logger.warn(
          '[Session] Audio Share Failed',
          {
            ...logData
          },
          err as Error
        )
      }
    }
  }

  const stopRecording = async () => {
    if (!!meetingId) {
      await meetingManager.leave()
      setMeetingId(null)
      volumeActions.setAudioLevel(0)
    }
  }

  const handleSetIsStopRecordingAfterEnabled = (enabled: boolean) => {
    if (enabled) {
      datadogLogs.logger.info(
        '[Audio Capture] Enable auto-stop recording',
        logData
      )
    } else {
      datadogLogs.logger.info(
        '[Audio Capture] Disable auto-stop recording',
        logData
      )
    }
    setIsStopRecordingAfterEnabled(enabled)
  }

  const contextValue = {
    isReady: meetingStatus === MeetingStatus.Succeeded,
    audioInputs: devices,
    selectedAudioInput: selectedDeviceId,
    setSelectedAudioInput: setChimeSelectedAudioInput,
    testAudioInputs: handleCreateSetupMeeting,
    hasMicAccess,
    setHasMicAccess,
    promptForDevicePermissions: () =>
      meetingManager.invokeDeviceProvider(DeviceLabels.Audio),
    isMuted: muted,
    toggleMute: toggleMute,
    disableEchoCancelation: setDisableEchoCancelation,
    isRecording:
      !!meetingId &&
      meetingStatus === MeetingStatus.Succeeded &&
      hasStartedRecording,
    isRecordingLoading:
      isCreateMeetingLoading ||
      isCreateSetupMeetingLoading ||
      (!!meetingId && meetingStatus !== MeetingStatus.Succeeded) ||
      (!!meetingId && !hasStartedRecording),
    startRecording: handleStartRecording,
    stopRecording,
    startContentShare: handleShareContent,
    stopContentShare: () => {},
    logData,
    isUsingHeadphones,
    setIsUsingHeadphones,
    isTelehealth,
    setIsTelehealth,
    hasDetectedAudio,
    showMicWarning,
    setShowMicWarning,
    isUploadComplete: true,
    setRecordingCutoffTime: (timestamp: string) => {},
    selectedAudioInputLabel,
    isSettingsOpen,
    setIsSettingsOpen,
    stopRecordingAfter,
    setStopRecordingAfter,
    isStopRecordingAfterEnabled,
    setIsStopRecordingAfterEnabled: handleSetIsStopRecordingAfterEnabled
  }

  return (
    <AudioCaptureContext.Provider value={contextValue}>
      {children}
    </AudioCaptureContext.Provider>
  )
}

export default ChimeProvider
