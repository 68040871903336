import React, { useEffect } from 'react'
import { HStack, GridItem, Box, useBreakpointValue } from '@chakra-ui/react'
import {
  RecordButton,
  SettingsButton,
  EndSessionButton,
  SessionPlanButton,
  DictationTipsButton,
  PrivateNotesButton,
  AssistButton
} from './toolbar-buttons'
import { useAudioCapture } from '@hooks'
import { SettingsDialog } from '../settings-dialog'
import { LayoutGrid } from '@blueprinthq/joy'
import { useExperienceManager } from '@hooks'

type FooterPropsT = {
  aiNotetakerEnabled: boolean
  isRecordingLoading: boolean
  startRecording: () => void
  hasStartedRecording: boolean
  onSessionEnd: () => void
  onShareAudio: () => void
  isDictation: boolean
}

const Footer: React.FC<FooterPropsT> = ({
  aiNotetakerEnabled,
  isRecordingLoading,
  startRecording,
  hasStartedRecording,
  onSessionEnd,
  onShareAudio,
  isDictation
}) => {
  const {
    setShowMicWarning,
    isSettingsOpen,
    setIsSettingsOpen
  } = useAudioCapture()

  const {
    isExtension,
    isWidget,
    isAssistEnabled,
    isEvidenceBasedCareEnabled
  } = useExperienceManager()

  const isSmallScreen = useBreakpointValue({ base: true, sm: false })

  useEffect(() => {
    if (isSettingsOpen) {
      setShowMicWarning(false)
    }
  }, [isSettingsOpen])

  const showAssistButton = !isEvidenceBasedCareEnabled || isAssistEnabled
  const showSessionPlanButton = !isAssistEnabled && isEvidenceBasedCareEnabled

  const isIntegratedUI = isExtension || isWidget

  return (
    <Box style={{ marginTop: 0 }}>
      {hasStartedRecording && (
        <SettingsDialog
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          onShareAudio={onShareAudio}
          isDictation={isDictation}
        />
      )}
      {isSmallScreen ? (
        <Box
          display="flex"
          justifyContent={isIntegratedUI ? 'center' : 'space-between'}
          position="fixed"
          bottom="0"
          h="60px"
          w="100%"
          bg="white"
          alignItems="center"
        >
          {aiNotetakerEnabled && (
            <RecordButton
              isRecordingLoading={isRecordingLoading}
              hasStartedRecording={hasStartedRecording}
              startRecording={startRecording}
            />
          )}
          {hasStartedRecording && (
            <SettingsButton onClick={() => setIsSettingsOpen(true)} />
          )}
          <EndSessionButton onClick={onSessionEnd} />
          {!isIntegratedUI && showAssistButton && <AssistButton />}
          {!isIntegratedUI && !isDictation && showSessionPlanButton && (
            <SessionPlanButton />
          )}
          {!isIntegratedUI && !isDictation && showSessionPlanButton && (
            <PrivateNotesButton />
          )}
          {!isIntegratedUI && isDictation && <DictationTipsButton />}
        </Box>
      ) : (
        <HStack position="fixed" bottom="0" h="60px" w="100%" bg="white">
          <LayoutGrid style={{ margin: '0 16px' }}>
            <GridItem
              colStart={{ base: 1, sm: 1, md: 1, lg: 6 }}
              colEnd={{ base: 6, sm: 6, md: 8, lg: 8 }}
            >
              <HStack
                justifyContent={{
                  sm: 'flex-start',
                  md: 'flex-end',
                  lg: 'center'
                }}
                gap="8px"
              >
                {aiNotetakerEnabled && (
                  <RecordButton
                    isRecordingLoading={isRecordingLoading}
                    hasStartedRecording={hasStartedRecording}
                    startRecording={startRecording}
                    isDictation={isDictation}
                  />
                )}
                {hasStartedRecording && (
                  <SettingsButton onClick={() => setIsSettingsOpen(true)} />
                )}
                <EndSessionButton onClick={onSessionEnd} />
              </HStack>
            </GridItem>
            <GridItem
              colStart={{ base: 7, sm: 10 }}
              colEnd={{ base: 13, sm: 13 }}
            >
              {!isIntegratedUI && (
                <HStack justifyContent="flex-end" gap="8px">
                  {!isDictation && showAssistButton && <AssistButton />}
                  {!isDictation && showSessionPlanButton && (
                    <SessionPlanButton />
                  )}
                  {!isDictation && showSessionPlanButton && (
                    <PrivateNotesButton />
                  )}
                  {isDictation && <DictationTipsButton />}
                </HStack>
              )}
            </GridItem>
          </LayoutGrid>
        </HStack>
      )}
    </Box>
  )
}

export default Footer
