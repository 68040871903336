import React, { useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { Box, VStack, HStack, Text } from '@chakra-ui/react'
import LockIcon from '@material-ui/icons/Lock'
import { Button } from '@blueprinthq/joy'
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const CheckoutForm = ({
  upsertSubscription,
  isSubmitting,
  setIsSubmitting,
  isError,
  amount,
  interval,
  subtotal
}) => {
  const { stripe_customer_id } = useStoreState(store => store.auth.user.clinic)

  const [error, setError] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const stripe = useStripe()
  const elements = useElements()

  const style = {
    base: {
      iconColor: '#c4f0ff',
      color: '#1a202c',
      fontWeight: '500',
      fontFamily: 'TofinoPersonal, Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#1a202c'
      },
      '::placeholder': {
        color: '#999999'
      }
    },
    invalid: {
      iconColor: '#e02031',
      color: '#e02031'
    }
  }

  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : null)
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    setError(false)
    setIsSubmitting(true)
    const {
      paymentMethod,
      paymentMethodError
    } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)
    })

    if (paymentMethodError || !paymentMethod) {
      setIsSubmitting(false)
      setError('Please complete all of the fields above and try again')
    } else {
      setError(null)
      upsertSubscription(stripe_customer_id, paymentMethod.id)
    }
  }

  return (
    <form id="stripe-payment-form" onSubmit={handleSubmit}>
      {isError && (
        <div className="errorMessage" role="alert">
          {isError}
        </div>
      )}
      <HStack mb="12px" justify="space-between">
        <Text color="#757575" fontSize="13px">
          Enter your payment details
        </Text>
        <HStack align="center">
          <Text color="#999999" fontSize="13px">
            <LockIcon fontSize="small" />
          </Text>
          <Text color="#757575" fontSize="13px">
            Secure form
          </Text>
        </HStack>
      </HStack>
      <VStack w="100%" spacing="24px">
        <Box
          w="100%"
          padding="16px"
          style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
        >
          <CardNumberElement
            options={{
              placeholder: 'Card Number',
              showIcon: true,
              style: style
            }}
            onChange={handleChange}
          />
        </Box>
        <HStack spacing="24px" w="100%">
          <Box
            w="100%"
            padding="16px"
            style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
          >
            <CardExpiryElement
              options={{ placeholder: 'MM / YY', showIcon: true, style: style }}
              onChange={handleChange}
            />
          </Box>
          <Box
            w="100%"
            padding="16px"
            style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
          >
            <CardCvcElement
              options={{ placeholder: 'CVC', showIcon: true, style: style }}
              onChange={handleChange}
            />
          </Box>
        </HStack>
        {error && (
          <div className="errorMessage" role="alert">
            {error}
          </div>
        )}
        <Button
          size="lg"
          my="small"
          isFullWidth
          type="submit"
          isLoading={isSubmitting}
          isDisabled={disabled}
        >
          Upgrade Plan
        </Button>
        <Text fontSize="13px">
          Each {interval}, you'll be charged ${amount} per Clinician seat until
          you cancel. You can add new Clinician seats at any time, and you'll be
          charged for those seats on a prorated basis on your next bill.
        </Text>
      </VStack>
    </form>
  )
}

export const Card = ({
  upsertSubscription,
  isSubmitting,
  setIsSubmitting,
  isError,
  amount,
  interval,
  subtotal
}) => {
  const options = {
    fonts: [
      {
        family: 'TofinoPersonal',
        src:
          'url(https://res.cloudinary.com/hellojoy/raw/upload/v1631114007/Fonts/tofino-personal/TofinoPersonal-Regular.otf)'
      }
    ]
  }

  return (
    <Box w="100%">
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          isError={isError}
          upsertSubscription={upsertSubscription}
          amount={amount}
          interval={interval}
          subtotal={subtotal}
        />
      </Elements>
    </Box>
  )
}
