import React, { useState, useCallback } from 'react'
import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
  Text,
  Box,
  DrawerCloseButton,
  DrawerFooter,
  VStack,
  Textarea,
  List,
  ListItem,
  Button,
  useToast,
  Flex
} from '@chakra-ui/react'
import { useParams, Link } from 'react-router-dom'
import { MagicWand, Refresh, ArrowRightIcon } from '@blueprinthq/joy'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { NoteSettings } from '@components/icons'
import { getSessionControllerGetProgressNotesForSessionQueryKey } from '~/clinician-api'
import { endpoints } from '@api'
import { useStoreState } from 'easy-peasy'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { capitalize } from 'lodash'

const getPreferenceLabel = (value: string, options: any) => {
  return options.find((o: any) => o.value === value)?.label
}

export const MagicEditDrawer = ({
  isOpen,
  closeDrawer,
  progressNoteId,
  preferences = {},
  openSettings
}: {
  isOpen: boolean
  closeDrawer: () => void
  progressNoteId: string
  preferences: Record<string, any>
  openSettings: () => void
}) => {
  const { user } = useStoreState(state => state.auth)

  const { sessionId }: { sessionId: string } = useParams()
  const queryClient = useQueryClient()
  const toast = useToast()

  const isNotePreferencesEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.PROGRESS_NOTE_PREFERENCE_SETTINGS
  )

  const [userInstructions, setUserInstructions] = useState('')

  const organizationId = user?.clinic?.organization?.id

  const { data, isLoading: isSettingsLoading } = useQuery(
    [endpoints.getProgressNoteSettings.getCacheId(), organizationId],
    () => endpoints.getProgressNoteSettings.request({ organizationId })
  )
  const { mutateAsync: executeRegenerateProgressNote, isLoading } = useMutation(
    endpoints.postRegenerateProgressNote.request,
    {
      onError: () => {
        toast({
          title: 'Error',
          description: 'There was an error regenerating this progress note',
          status: 'error',
          isClosable: true,
          duration: 2000,
          position: 'bottom-left'
        })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          getSessionControllerGetProgressNotesForSessionQueryKey(sessionId)
        )
      }
    }
  )

  const submitRegenerateProgressNote = useCallback(async () => {
    const data = {
      progressNoteId,
      userInstructions
    }
    await executeRegenerateProgressNote(data)

    closeDrawer()
  }, [progressNoteId, userInstructions])

  const handleGoToSettings = () => {
    closeDrawer()
    openSettings()
  }

  if (isSettingsLoading) return null

  // @ts-ignore
  const { preferenceOptions }: { preferenceOptions: any } = data

  return (
    <ChakraDrawer isOpen={isOpen} onClose={closeDrawer} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <Box
          height="95px"
          backgroundColor="primary"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px="small"
        >
          <Flex w="150px" height="80px" align="center">
            <MagicWand fill="white" />
            <Text
              as="span"
              fontWeight="bold"
              color="white"
              paddingLeft="xsmall"
            >
              Magic edit
            </Text>
          </Flex>
          <DrawerCloseButton color="white" position="static" _focus={{ outline: 'none' }} />
        </Box>
        <Box
          p="small"
          overflowY="scroll"
          height="100%"
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <VStack spacing="small" alignItems="flex-start">
            <Text>
              Something missing or wrong in this note? Tell our AI what to fix:
            </Text>
            <Textarea
              h="140px"
              placeholder="Enter your instructions here..."
              borderColor={'lightgray'}
              onChange={e => setUserInstructions(e.target.value)}
            />
            {!isNotePreferencesEnabled ? (
              <Box bg="#2D54E80A" borderRadius="sm" p="xsmall" pl="large">
                <Text textAlign="center" fontWeight="bold">
                  Example instructions
                </Text>
                <List styleType="disc">
                  <ListItem>Make the subjective section longer</ListItem>
                  <ListItem>Remove direct client quotes</ListItem>
                  <ListItem>Refer to the writer as "psychologist"</ListItem>
                  <ListItem>
                    Write out more specific interventions based on transcript
                  </ListItem>
                  <ListItem>Include more language on CBT</ListItem>
                  <ListItem>
                    Document the risk assessment, include that ct did not
                    exhibit any SI
                  </ListItem>
                  <ListItem>
                    Document more symptoms based on the diagnosis of xxx
                  </ListItem>
                </List>
              </Box>
            ) : (
              <Box px={4}>
                <Box px={4}>
                  <Text align="center" fontWeight="bold" mb={2}>
                    Example instructions
                  </Text>
                  <List styleType="disc">
                    <ListItem fontStyle="italic">
                      Include [specific topics discussed not yet in note]
                    </ListItem>
                    <ListItem fontStyle="italic">
                      Include [specific client quote not yet in note]
                    </ListItem>
                    <ListItem fontStyle="italic">
                      Detail [observations you have of the client]
                    </ListItem>
                    <ListItem fontStyle="italic">
                      Document [specifics not discussed in-session]
                    </ListItem>
                    <ListItem fontStyle="italic">
                      Include [client actions outside of session]
                    </ListItem>
                  </List>
                </Box>
              </Box>
            )}
          </VStack>
          {isNotePreferencesEnabled && (
            <Box bg="#2D54E80A" borderRadius="8px" py={4} px={5} w="100%">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <NoteSettings fill="black" />
                  <Text fontWeight="bold" ml={2}>
                    Your note preferences
                  </Text>
                </Box>
                <Box>
                  <Button
                    color="primary"
                    onClick={handleGoToSettings}
                    ml={2}
                    alignSelf="flex-end"
                    variant="link"
                    textDecoration="none"
                    rightIcon={<ArrowRightIcon fill="primary" />}
                    sx={{
                      '.chakra-button__icon': {
                        marginLeft: 0,
                        position: 'relative',
                        top: '1px'
                      }
                    }}
                    _focus={{ outline: 'none' }}
                  >
                    Go to settings
                  </Button>
                </Box>
              </Box>
              <Flex gap="16px" mt={4} lineHeight="18px">
                <Box flex="1">
                  <Box flex="1">
                    <Text fontSize="13px" as="span" fontWeight="bold">
                      Terminology:
                    </Text>
                    <Text fontSize="13px" as="span" ml={1}>
                      {getPreferenceLabel(
                        preferences.selfReference,
                        preferenceOptions?.selfReference?.options
                      )}
                      {', '}
                      {getPreferenceLabel(
                        preferences.patientReference,
                        preferenceOptions?.patientReference?.options
                      )}
                    </Text>
                  </Box>
                  <Box flex="1">
                    <Text fontSize="13px" as="span" fontWeight="bold">
                      Length of note:
                    </Text>
                    <Text fontSize="13px" as="span" ml={1}>
                      {getPreferenceLabel(
                        preferences.noteLength,
                        preferenceOptions?.noteLength?.options
                      )}
                    </Text>
                  </Box>
                  <Box flex="1">
                    <Text fontSize="13px" as="span" fontWeight="bold">
                      Medical Necessity:
                    </Text>
                    <Text fontSize="13px" as="span" ml={1}>
                      {getPreferenceLabel(
                        preferences.medicalNecessity,
                        preferenceOptions?.medicalNecessity?.options
                      )}
                    </Text>
                  </Box>
                  <Box flex="1">
                    <Text fontSize="13px" as="span" fontWeight="bold">
                      Diagnostic Symptoms:
                    </Text>
                    <Text fontSize="13px" as="span" ml={1}>
                      {getPreferenceLabel(
                        preferences.diagnosticSymptoms,
                        preferenceOptions?.diagnosticSymptoms?.options
                      )}
                    </Text>
                  </Box>
                </Box>
                <Box flex="1">
                  <Box flex="1">
                    <Text fontSize="13px" as="span" fontWeight="bold">
                      Action Items:
                    </Text>
                    <Text fontSize="13px" as="span" ml={1}>
                      {getPreferenceLabel(
                        preferences.actionItems,
                        preferenceOptions?.actionItems?.options
                      )}
                    </Text>
                  </Box>
                  <Box flex="1">
                    <Text fontSize="13px" as="span" fontWeight="bold">
                      Direct Quotes:
                    </Text>
                    <Text fontSize="13px" as="span" ml={1}>
                      {getPreferenceLabel(
                        preferences.directQuotes,
                        preferenceOptions?.directQuotes?.options
                      )}
                    </Text>
                  </Box>
                  {/* <Box flex="1">
                    <Text fontSize="13px" as="span" fontWeight="bold">
                      Formatting:
                    </Text>
                    <Text fontSize="13px" as="span" ml={1}>
                      {getPreferenceLabel(
                        preferences.formatting,
                        preferenceOptions?.formatting?.options
                      )}
                    </Text>
                  </Box> */}
                  <Box flex="1">
                    <Text fontSize="13px" as="span" fontWeight="bold">
                      Mental Status Exam:
                    </Text>
                    <Text fontSize="13px" as="span" ml={1}>
                      {preferences.mentalStatusExam.length === 0
                        ? 'None'
                        : preferences.mentalStatusExam
                            .map((i: string) => capitalize(i))
                            .join(', ')}
                    </Text>
                  </Box>
                </Box>
              </Flex>
            </Box>
          )}
        </Box>
        <DrawerFooter width="100%" px="small" py={0}>
          <Box w="100%">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              py={6}
            >
              <Button
                colorScheme="primary"
                variant="solid"
                type="submit"
                onClick={submitRegenerateProgressNote}
                isFullWidth
                size="lg"
                m={0}
                leftIcon={<Refresh fill="White" h="16px" w="16px" />}
                isLoading={isLoading}
                isDisabled={!userInstructions.trim()}
              >
                Generate new note
              </Button>
            </Box>
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  )
}
