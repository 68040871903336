import React from 'react'
import {
  Stack,
  Text,
  HStack,
  Button
} from '@chakra-ui/react'
import AssignedAssessments from './assigned/selector'
import Suggestions from './suggestions'
import Progress from './progress'
import { useTreatmentPlan, useExperienceManager } from '@hooks'
import { useAssistPanelContext } from '../context'
import { useQuery } from 'react-query'
import { endpoints } from '@api'

const Assessments = () => {
  const { client } = useTreatmentPlan()
  const { isAssistAssessmentAssignmentEnabled } = useExperienceManager()
  const { setOverlayPanel } = useAssistPanelContext()
  const patientId = client.id

  const { isLoading, data: assessmentScores } = useQuery(
    [endpoints.getPatientAssessmentScores.getCacheId(), patientId],
    () =>
      endpoints.getPatientAssessmentScores.request({
        patientId,
        includeFreeTextQuestions: true
      })
  )

  return (
    <Stack
      px="24px"
      spacing="0"
      flex="1"
      pt="24px"
      gap="24px"
      h="calc(100vh - 208px)"
      overflowY="scroll"
    >
      <Suggestions />
      {isAssistAssessmentAssignmentEnabled && (
        <>
          <Progress
            isLoading={isLoading}
            allAssessmentScores={assessmentScores}
            patient={client}
          />
          <Stack spacing="0">
            <HStack justifyContent="space-between">
              <Text fontWeight="bold">Assigned</Text>
              {false && (
                <Button
                  variant="link"
                  textDecoration="none"
                  onClick={() => setOverlayPanel('LIBRARY')}
                >
                  Browse library
                </Button>
              )}
            </HStack>
            <AssignedAssessments patient={client} user_id={client.id} />
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default Assessments
