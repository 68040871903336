import React from 'react'
import { ArrowDownIcon } from '@blueprinthq/joy'
import {
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button
} from '@chakra-ui/react'

interface IconSelectProps {
  selectedValue: string
  onChange: (selectedValue: string) => void
  options: { value: string; title: string; icon: JSX.Element }[]
}

export const IconSelect = ({
  selectedValue,
  onChange,
  options
}: IconSelectProps) => (
  <Menu placement="bottom-start" matchWidth autoSelect={false}>
    <MenuButton
      border="1px solid #E4E5E6"
      m="0"
      w="100%"
      h="48px"
      bg="white"
      borderRadius="6px"
      borderColor="#C9C9C9"
      as={Button}
      rightIcon={<ArrowDownIcon />}
      leftIcon={options.find(o => o.value === selectedValue)?.icon}
      _focus={{ border: 'solid 2px #2C54E7' }}
    >
      <Text color="black" textAlign="left" ml="2px">
        {options.find(o => o.value === selectedValue)?.title}
      </Text>
    </MenuButton>
    <MenuList zIndex="100">
      {options.map((o: { value: string; title: string; icon: JSX.Element }) => (
        <MenuItem
          key={o.value}
          onClick={() => onChange(o.value)}
          value={o.value}
          color="black"
          icon={o.icon}
        >
          {o.title}
        </MenuItem>
      ))}
    </MenuList>
  </Menu>
)
