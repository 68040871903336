import React from 'react'
import { Box, Text, SimpleGrid, Button, useToast } from '@chakra-ui/react'
import { useStoreActions } from 'easy-peasy'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { formatDollarAmount } from '@utilities'
import * as clinicianTracking from '@lib/clinician-tracking'

import moment from 'moment'

import { EvidenceBasedCare } from '@components/icons'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'

export const EvidenceBasedCareBox = ({
  billingData,
  showNewBillingFlow,
  selectedClinic
}: {
  billingData: any
  showNewBillingFlow: boolean
  selectedClinic: any
}) => {
  const hasActiveEbcPlan = Boolean(billingData?.activePlan)
  const hasCanceledEbcPlan = Boolean(billingData?.canceledEBCSubscription)
  const hasAnyEbcPlan = hasActiveEbcPlan || hasCanceledEbcPlan
  const ebcBillingEnabled = flagsmith.hasFeature(FlagsmithFeatures.EBC_BILLING)

  const toast = useToast()
  const queryClient = useQueryClient()

  const handleOpenModal = useStoreActions(
    (actions: any) => actions.modals.choosePlan.openModal
  )
  const openEbcMarketingModal = useStoreActions(
    (actions: any) => actions.modals.ebcMarketingModal.openModal
  )
  const openEbcUpgradePlanModal = useStoreActions(
    (actions: any) => actions.modals.ebcUpgradePlan.openModal
  )
  const openCancelIndividualPlanModal = useStoreActions(
    (actions: any) => actions.modals.cancelIndividualPlan.openModal
  )

  const {
    data: {
      providers,
      meta: { totalCount }
    }
  }: any = useQuery(
    [
      endpoints.getOrganizationProvidersV2.getCacheId(),
      1,
      selectedClinic && selectedClinic.organization_id,
      selectedClinic && selectedClinic.id
    ],
    () =>
      endpoints.getOrganizationProvidersV2.request({
        organizationId: selectedClinic.organization_id,
        clinicId: selectedClinic.id,
        page: 1,
        perPage: 50
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      placeholderData: {
        providers: [],
        meta: {
          page: 1,
          perPage: 50,
          totalCount: 0
        }
      },
      enabled: selectedClinic !== null
    }
  )

  const totalEbcProviders = providers.filter(
    (provider: any) => provider.has_ebc_access === true
  ).length

  const { mutateAsync: reactivatePlan, isLoading } = useMutation(
    endpoints.patchIndividualBillingPlan.request,
    {
      onSuccess() {
        toast({
          title: `Your Evidence-Based Care plan has been reactivated.`,
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        )
        queryClient.invalidateQueries(endpoints.getClinicBilling.getCacheId())
      },
      onError(err: any) {
        toast({
          title: `We ran into an issue reactivating your Evidence-Based Care plan.`,
          description:
            'Please try again or contact support at help@blueprint-health.com.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  const renderManageAndCancel = (plan: any) => (
    <>
      {hasCanceledEbcPlan && !hasActiveEbcPlan ? (
        plan.endedAt ? (
          <Text>
            Expired on {moment.unix(plan.endedAt).format('MMMM D, YYYY')}
          </Text>
        ) : (
          <Text color="light_gray">
            Expires on {moment.unix(plan.cancelAt).format('MMMM D, YYYY')}
          </Text>
        )
      ) : billingData?.canCancel ? (
        <Text
          _focus={{ outline: 'none' }}
          fontSize={'16px'}
          color={'#EB5164'}
          _hover={{
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
          onClick={() =>
            openCancelIndividualPlanModal({
              planType: 'ebc',
              planName: plan.planName,
              clinicId: selectedClinic.id,
              subscriptionId: plan.subscriptionId
            })
          }
        >
          Cancel Plan
        </Text>
      ) : (
        <Text
          _focus={{ outline: 'none' }}
          fontSize={'16px'}
          color={'primary'}
          _hover={{
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
          onClick={() => {
            //@ts-ignore
            window.Intercom(
              'showNewMessage',
              "Hi, I'd like to change my Evidence-based care plan."
            )
          }}
        >
          Contact support
        </Text>
      )}
    </>
  )

  const renderCanceledPlanContent = () => {
    return (
      <>
        {showNewBillingFlow && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '220px',
              justifyContent: 'center'
            }}
          >
            {billingData?.canceledEBCSubscription?.endedAt ? (
              <Button
                onClick={() =>
                  openEbcUpgradePlanModal({
                    hasAnyEbcPlan,
                    clinicId: selectedClinic.id
                  })
                }
              >
                Subscribe
              </Button>
            ) : (
              <Button
                isLoading={isLoading}
                onClick={() => {
                  clinicianTracking.trackEvent(
                    `Billing page evidence-based care plan -> Clicked reactivate canceled plan`
                  )
                  return reactivatePlan({
                    data: {
                      action: 'reactivate',
                      subscriptionId:
                        billingData?.canceledEBCSubscription?.subscriptionId,
                      planType: 'ebc'
                    }
                  })
                }}
              >
                Reactivate
              </Button>
            )}
          </Box>
        )}
      </>
    )
  }

  const renderActivePlanContent = () => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          <Text fontSize={'16px'}>Next invoice</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {billingData.nextDueInvoice &&
              moment
                .unix(
                  billingData.nextDueInvoice.next_payment_attempt !== null
                    ? billingData.nextDueInvoice.next_payment_attempt
                    : billingData.nextDueInvoice.period_end
                )
                .format('MMM D, YYYY')}
          </Text>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          <Text fontSize={'16px'}>Invoice total</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {billingData.nextDueInvoice &&
              formatDollarAmount(billingData.nextDueInvoice.amount_due / 100)}
          </Text>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          <Text fontSize={'16px'}>Total users</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {ebcBillingEnabled ? totalEbcProviders : totalCount}
          </Text>
        </Box>
      </>
    )
  }

  const renderHasAnyEBCPlanContent = () => {
    const plan = billingData?.activePlan || billingData?.canceledEBCSubscription

    return (
      <>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 0'
          }}
        >
          <Text fontSize={'16px'}>Plan</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {plan.planName &&
              plan.planName +
                ` (${plan.billingInterval === 'month' ? 'Monthly' : 'Annual'})`}
          </Text>
          {showNewBillingFlow ? (
            renderManageAndCancel(plan)
          ) : (
            <Text
              _focus={{ outline: 'none' }}
              fontSize={'16px'}
              color={'primary'}
              _hover={{
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => {
                if (billingData?.canChangeEBCPlan) {
                  handleOpenModal()
                } else {
                  //@ts-ignore
                  window.Intercom(
                    'showNewMessage',
                    "Hi, I'd like to change my Evidence-based care plan."
                  )
                }
              }}
            >
              {billingData?.canChangeEBCPlan
                ? 'Change plan'
                : 'Contact support'}
            </Text>
          )}
        </Box>
        {hasActiveEbcPlan
          ? renderActivePlanContent()
          : renderCanceledPlanContent()}
      </>
    )
  }

  const renderHasNoEBCPlansContent = () => (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 0'
        }}
      >
        <Text fontSize={'16px'} fontWeight={'bold'}>
          Evidence-Based Care
        </Text>
        <Text fontSize={'16px'} fontWeight={430}>
          Start measuring outcomes and assigning homework between sessions.
        </Text>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '220px'
        }}
      >
        <Button
          onClick={() => openEbcMarketingModal({ clinicId: selectedClinic.id })}
        >
          Start a 30 day free trial
        </Button>
      </Box>
    </>
  )

  return (
    <SimpleGrid
      mb={'small'}
      columns={[1, 1, hasAnyEbcPlan ? 5 : 3]}
      p={'24px'}
      borderRadius={'4px'}
      border={'1px solid #E4E5E6'}
      display={{ base: '', md: 'flex' }}
      justifyContent={'center'}
      gap={'24px'}
      alignSelf={'stretch'}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <EvidenceBasedCare
          //@ts-ignore
          fill={'#2D54E8'}
          width={'40px'}
          height={'40px'}
        />
      </Box>
      {hasAnyEbcPlan
        ? renderHasAnyEBCPlanContent()
        : renderHasNoEBCPlansContent()}
    </SimpleGrid>
  )
}
