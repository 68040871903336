import React, { Component } from 'react'
import { Assessment } from '../handlers'
import { isEmpty } from 'lodash'
import { Loading } from '../components'

export default class AssessmentPreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      assessment_id: props.computedMatch.params.assessment_id,
      assessment_obj: {}
    }
  }
  componentDidMount() {
    this.getAssessment()
  }

  getAssessment = () => {
    const { api } = this.props
    const { assessment_id } = this.state
    const preview_path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/clinicians/assessment?assessment_id=${assessment_id}&includeFreeTextQuestions=true`
    api
      .GET(preview_path)
      .then(json => {
        this.setState({
          assessment_obj: json[0]
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  render() {
    const { assessment_obj } = this.state
    return isEmpty(assessment_obj) ? (
      <div style={{ height: '100vh' }}>
        <Loading />
      </div>
    ) : (
      <Assessment
        assessments={[assessment_obj]}
        preview={true}
        {...this.props}
      />
    )
  }
}
