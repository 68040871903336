import React from 'react'
import { Box } from '@chakra-ui/react'

export const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      bg="primary"
      display="flex"
      flexDirection="column"
      height="100%"
      overflowY="auto"
    >
      {children}
    </Box>
  )
}
