import React, { useEffect, useState } from 'react'
import {
  Button,
  TextField,
  Checkbox,
  ArrowLeftIcon,
  RadioGroup,
  Radio
} from '@blueprinthq/joy'
import {
  HStack,
  VStack,
  Box,
  Text,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Divider,
  Avatar,
  Flex,
  Spinner,
  Center
} from '@chakra-ui/react'
import { cloudinaryAssetToUrl } from '@utilities'
import { Pagination } from '@material-ui/lab'

export const SupervisorGroupsModal = ({
  setShowSupervisor,
  provider,
  clinicians,
  handleSelectClinician,
  selectedClinicians,
  setSelectedClinicians,
  submitSupervisor,
  existing,
  updateSupervisorClinicians,
  handleAllClinciansSubmit,
  perPage,
  totalCount,
  handlePageChange,
  isProvidersLoading,
  page,
  handleClinicianSearch,
  clinicianSearchQuery,
  isUpdateSupervisorModalLoading
}) => {
  const [radioValue, setRadioValue] = useState(
    selectedClinicians.length === clinicians.length ? 'all' : 'choose'
  )

  const isExistingSupervisor = provider
    ? provider.roles.map(role => role.name).includes('supervisor')
    : false

  useEffect(() => {
    if (
      selectedClinicians.length === 0 &&
      existing.length === 0 &&
      !clinicianSearchQuery
    ) {
      setRadioValue('all')
    }
    if (
      selectedClinicians.length !== clinicians.length &&
      existing.length !== 0
    ) {
      setRadioValue('choose')
    }
  }, [selectedClinicians])

  function clinicianExistsById(clinicians, clinicianId) {
    return clinicians.some(clinician => clinician.id === clinicianId)
  }

  function matchAndFilterClinicians(
    supervisorClinicianArray,
    fullCliniciansList
  ) {
    return supervisorClinicianArray
      .map(supervisorClinician => {
        return fullCliniciansList.find(
          clinician => clinician.id === supervisorClinician.clinician_id
        )
      })
      .filter(clinician => clinician !== undefined)
  }

  return (
    <>
      <ModalHeader
        fontSize="20px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <ArrowLeftIcon
          position="absolute"
          onClick={() => {
            setShowSupervisor(false)
            const existingClinicians = matchAndFilterClinicians(
              existing,
              clinicians
            )
            setSelectedClinicians(existingClinicians)
          }}
          height={'50px'}
          w={'50px'}
          left="14px"
          padding={'8px'}
          borderRadius="8px"
          _hover={{
            cursor: 'pointer',
            backgroundColor: 'pale_gray',
            transition: 'background-color 0.2s ease-in-out'
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        />

        <Text textAlign="center" fontSize={'20px'} flex="1">
          Supervisor permissions
        </Text>
      </ModalHeader>

      <ModalBody>
        {provider && (
          <Text mb={'16px'} fontSize="medium" pl={1} pb={2}>
            Manage which Clinicians{' '}
            <Text as="span" fontWeight="bold">
              {provider.first_name + ' ' + provider.last_name}
            </Text>{' '}
            is supervising:
          </Text>
        )}

        <RadioGroup
          value={radioValue}
          stackProps={{
            flexDirection: 'column',
            spacing: 0,
            align: 'flex-start'
          }}
          onChange={() => {
            setRadioValue(radioValue === 'all' ? 'choose' : 'all')
          }}
        >
          <Radio key={'all'} value="all" colorScheme="blue">
            All Clinicians
          </Radio>
          <div style={{ marginTop: 4 }}></div>
          <Radio key={'choose'} value="choose" colorScheme="blue">
            Choose Clinicians (
            {radioValue === 'all' ? 0 : selectedClinicians.length} selected)
          </Radio>
        </RadioGroup>

        {radioValue !== 'all' && (
          <>
            {' '}
            <TextField
              id="search-patients"
              className="outlinedInput"
              type="search"
              onChange={e => {
                handleClinicianSearch(e.target.value)
              }}
              placeholder="Search clinicians"
              fullWidth
              borderRadius={'4px'}
              borderColor={'pale_gray'}
              marginTop={'16px'}
              autoComplete="off"
            />
            <div style={{ marginTop: '16px' }}>
              <hr
                style={{
                  width: 'calc(100% + 50px)',
                  margin: '0',
                  height: '1px',
                  opacity: 0.3,
                  backgroundColor: '#A1A1A1',
                  border: 'none',
                  marginLeft: '-25px'
                }}
              />
            </div>
          </>
        )}

        <Box maxH="calc(60vh - 170px)" overflowY="auto" pr={2}>
          <VStack align="stretch" spacing={0}>
            {!isProvidersLoading &&
              clinicians.length !== 0 &&
              radioValue !== 'all' &&
              clinicians.map((user, index) => {
                const isChecked = clinicianExistsById(
                  selectedClinicians,
                  user.id
                )
                return (
                  <React.Fragment key={index}>
                    <HStack spacing={4} p={1}>
                      <Checkbox
                        isChecked={isChecked}
                        onChange={() => {
                          handleSelectClinician(user)
                        }}
                      >
                        <HStack spacing={4}>
                          <Avatar
                            src={cloudinaryAssetToUrl(user.avatar, {
                              transformations: {
                                resize: {
                                  height: 128,
                                  width: 128,
                                  type: 'scale'
                                }
                              }
                            })}
                            name={`${user.first_name} ${user.last_name}`}
                            bg="pale_gray"
                            size="md"
                            mr="xsmall"
                            color="black"
                            fontWeight="bold"
                          />
                          )
                          <VStack align="start" spacing={0}>
                            <Text fontWeight="bold">
                              {user.first_name + ' ' + user.last_name}
                            </Text>
                            <Text fontSize="sm" color="black">
                              {user.email}
                            </Text>
                          </VStack>
                        </HStack>
                      </Checkbox>
                    </HStack>
                    {index < clinicians.length - 1 && <Divider />}
                  </React.Fragment>
                )
              })}
            {isProvidersLoading && (
              <Center mt={5}>
                <Spinner color="primary" />
              </Center>
            )}
          </VStack>
        </Box>
        {!isProvidersLoading &&
          radioValue !== 'all' &&
          clinicians.length !== 1 &&
          Math.ceil(totalCount / perPage) !== 1 && (
            <Flex mt={2} justifyContent="center">
              <Pagination
                page={page}
                count={Math.ceil(totalCount / perPage)}
                size="large"
                variant="outlined"
                showFirstButton
                showLastButton
                onChange={handlePageChange}
              />
            </Flex>
          )}
      </ModalBody>
      <ModalFooter
        p={'20px'}
        pt={0}
        borderTop={'1px solid'}
        borderColor={'pale_gray'}
        display="block"
      >
        <Button
          disabled={
            (!isUpdateSupervisorModalLoading &&
              selectedClinicians.length === 0 &&
              radioValue === 'choose') ||
            (selectedClinicians.length === 0 &&
              existing.length === 0 &&
              radioValue === 'all') ||
            (existing.length === 0 &&
              isExistingSupervisor &&
              radioValue === 'all') ||
            (selectedClinicians.length === existing.length &&
              radioValue === 'choose')
          }
          isLoading={isUpdateSupervisorModalLoading}
          loadingText="Saving"
          colorScheme="blue"
          isFullWidth
          onClick={() => {
            if (isExistingSupervisor) {
              if (radioValue === 'all' && provider) {
                handleAllClinciansSubmit()
              } else if (radioValue === 'choose' && provider) {
                updateSupervisorClinicians()
              }
            } else if (existing.length === 0 || !isExistingSupervisor) {
              if (radioValue === 'all' && provider) {
                setSelectedClinicians([])
              } else if (radioValue === 'all' && !provider) {
                setSelectedClinicians([])
              }
              submitSupervisor()
            }
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </>
  )
}
