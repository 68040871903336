export const FlagsmithFeatures = Object.freeze({
  CLIENT_PRONOUNS: 'client_pronouns',
  ASSIST: 'assist',
  EHR_INTEGRATION_ISSUES: 'ehr_integration_issues',
  EHR_APPOINTMENTS: 'ehr_appointments',
  EHR_INTEGRATION_CLIENT_OPT_IN: 'ehr_integration_client_opt_in',
  SHOW_APPOINTMENT_WORKFLOWS: 'show_appointment_workflows',
  SHOW_IS_DEMO_CLIENTS_IN_START_SESSION:
    'show_is_demo_clients_in_start_session',
  AUDIT_REPORT: 'audit_report',
  USE_DEMO_NOTES_WITH_EBC_CONTENT: 'use_demo_notes_with_ebc_content',
  TRANSCRIPT_COPY: 'transcript_copy',
  ALWAYS_SHOW_SAMPLE_SESSIONS: 'always_show_sample_sessions',
  EBC_BILLING: 'ebc_billing',
  CHIME_DISABLED: 'chime_disabled',
  TREATMENT_PLAN_ACCESS: 'treatment_plan_access',
  SUGGESTED_ASSESSMENTS: 'suggested_assessments',
  SHOW_LIBRARY_NAV: 'show_library_nav',
  DIAGNOSIS_SUGGESTIONS: 'has_diagnosis_suggestions',
  PROGRESS_NOTE_PREFERENCE_SETTINGS: 'progress_note_preference_settings',
  PRESCRIBER_NOTES: 'prescriber_notes',
  TX_PLAN_REGENERATION: 'tx_plan_regeneration',
  AGE_RANGE: 'age_range',
  EDIT_CHECK_INS: 'edit_check_ins',
  CLIENT_CONSENT: 'client_consent',
  ASSIST_ASSESSMENT_ASSIGNMENT_ENABLED: 'assist_assessment_assignment_enabled',
  ASSIST_SUGGESTION_STRENGTH_ENABLED: 'assist_suggestion_strength_enabled',
})
