import React from 'react'
import { PanelBody, PanelHeader, OverlayPanelHeader } from '@handlers/sessions/components'
import Panel from '../panel'
import Library from '../assessments/library'
import AssignAssessment from '../assessments/assign'
import AssessmentResults from '../assessments/results'
import { useAssistPanelContext } from '../context'
import { propNames } from '@chakra-ui/react'

interface Props {
  client: any
  data?: {
    assessmentId?: string
    assessmentScoreId?: string
  }
}

const panelConfig = {
  LIBRARY: {
    title: "Assessments",
    component: (props: Props) => (
      <Library client={props.client} />
    ),
  },
  ASSIGN_ASSESSMENT: {
    title: "Assign Assessment",
    component: () => ( 
      <AssignAssessment />
    ),
  },
  ASSESSMENT_RESULTS: {
    title: "Assessment Results",
    component: (props: Props) => (
      <AssessmentResults
        client={props.client}
        preselectedAssessmentId={props.data?.assessmentId}
        preselectedAssessmentScoreId={props.data?.assessmentScoreId}
      />
    ),
  },
}

export type PanelKey = keyof typeof panelConfig

interface PanelSwitcherProps {
  client: any
}

const PanelSwitcher: React.FC<PanelSwitcherProps> = ({ client }) => {
  const { panel, data, setOverlayPanel } = useAssistPanelContext()

  if (!panel || !(panel in panelConfig)) return null
  
  const config = panelConfig[panel]
  
  return (
    <Panel>
      <OverlayPanelHeader title={config.title} onBack={() => setOverlayPanel(null)} />
      <PanelBody
        px="0"
        height={{ base: 'auto', sm: 'auto', md: 'auto' }}
        overflowY={{ base: 'auto', sm: 'auto', md: 'hidden' }}
      >
        {config.component({ client, data })}
      </PanelBody>
    </Panel>
  )
}

export default PanelSwitcher