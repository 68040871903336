import React from 'react'
import { Flex } from '@chakra-ui/react'

const Panel = ({ children }: any) => {
  return (
    <Flex
      bg="white"
      borderRadius="8px"
      overflow="hidden"
      flexDirection="column"
      w={{
        base: 'auto',
        sm: 'auto',
        md: '690px'
      }}
    >
      {children}
    </Flex>
  )
}

export default Panel