import React, { useState } from 'react'
import { useQuery } from 'react-query'
import {
  Flex,
  Text,
  Button,
  Select,
  useBreakpointValue,
  useToast,
  VStack,
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverTrigger,
  PopoverArrow,
  Link
} from '@chakra-ui/react'
import ContentCopyIcon from '@material-ui/icons/FileCopy'
import { MagicEditDrawer } from './magic-edit-drawer'
import { MagicWand } from '@blueprinthq/joy'
import { TrashIcon } from '@icons'
import { ProgressNoteFeedback } from '../components'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { getSessionControllerGetProgressNotesForSessionQueryKey } from '~/clinician-api'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import { useExperienceManager } from '@hooks'
import { Link as RouterLink } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'

const DeleteConfirmation = ({ isOpen, onClose, onConfirm, isLoading }: any) => {
  return (
    <DialogContainer onClose={onClose} isOpen={isOpen}>
      <DialogHeader text="Are you sure?" onClose={onClose} />
      <DialogBody>
        <VStack spacing={6}>
          <Text textAlign="center">
            This note (and all associated versions of the note) will be deleted.
            This cannot be undone.
          </Text>
          <VStack w="100%">
            <Button
              bg="error"
              isFullWidth
              size="lg"
              onClick={onConfirm}
              isLoading={isLoading}
            >
              Delete note
            </Button>
            <Button
              _focus={{ outline: 'none' }}
              variant="outline"
              isFullWidth
              size="lg"
              onClick={onClose}
            >
              Cancel
            </Button>
          </VStack>
        </VStack>
      </DialogBody>
    </DialogContainer>
  )
}

interface SessionTranscriptResponse {
  sessionTranscript: {
    id: string
  } | null
}

const ProgressNoteHeader = ({
  header,
  noteType,
  handleCopyAllToClipboard,
  isCopyAllDisabled,
  version,
  progressNoteId,
  progressNotes,
  setSelectedProgressNoteId,
  selectedProgressNoteId,
  sessionId
}: any) => {
  const { user } = useStoreState(state => state.auth)
  const [organizationId, setOrganizationId] = useState(
    user?.clinic?.organization?.id
  )
  const { isAdmin, isDocumentationAutomationEnabled } = useExperienceManager()
  const [isMagicEditDrawerOpen, setIsMagicEditDrawerOpen] = useState(false)
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false })
  const toast = useToast()
  const queryClient = useQueryClient()
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  )
  const popoverDelay = useBreakpointValue({
    base: 0,
    sm: 0,
    md: 500
  })

  const {
    mutate: deleteSessionProgressNotes,
    isLoading: isDeletingSessionProgressNotes
  } = useMutation(endpoints.deleteSessionProgressNotes.request, {
    onSuccess: async () => {
      setIsDeleteConfirmationOpen(false)
      toast({
        description: 'Notes deleted!',
        status: 'success',
        isClosable: true,
        duration: 2000
      })
      await queryClient.invalidateQueries(
        getSessionControllerGetProgressNotesForSessionQueryKey(sessionId)
      )
    }
  })

  const { data: sessionTranscriptData } = useQuery<SessionTranscriptResponse>(
    [endpoints.getSessionTranscript.getCacheId(), sessionId],
    () =>
      endpoints.getSessionTranscript.request({ sessionId }) as Promise<
        SessionTranscriptResponse
      >
  )

  const {
    data: progressNoteSettingsData,
    isLoading: progressNoteSettingsIsLoading
  } = useQuery<{ transcriptsEnabled: boolean }>(
    [endpoints.getProgressNoteSettings.getCacheId(), organizationId],
    () =>
      endpoints.getProgressNoteSettings.request({ organizationId }) as Promise<{
        transcriptsEnabled: boolean
      }>
  )

  const transcriptsEnabled = progressNoteSettingsData?.transcriptsEnabled

  const isMagicEditEnabled =
    !!sessionTranscriptData?.sessionTranscript?.id &&
    isDocumentationAutomationEnabled

  const hasMultipleVersions = progressNotes?.length > 1

  const renderMagicEditPopoverBody = () => {
    if (transcriptsEnabled) {
      return ''
    }

    if (isAdmin) {
      return (
        <Flex>
          <Text>
            Turn on transcript retention in{' '}
            <Link
              as={RouterLink}
              to="/settings/progress-notes"
              color="primary"
              _hover={{ color: 'primary' }}
              target="_blank"
              _focus={{ outline: 'none' }}
            >
              settings
            </Link>
            .
          </Text>
        </Flex>
      )
    }

    return <Text>Ask your admin to turn on transcript retention.</Text>
  }

  const renderMagicEditButton = () => (
    <Button
      id={`magic-edit-${noteType}-notes-tag`}
      borderRadius="24px"
      onClick={() => setIsMagicEditDrawerOpen(true)}
      variant="outline"
      m={0}
      isFullWidth
      disabled={!isMagicEditEnabled}
      _hover={{ opacity: isMagicEditEnabled ? 0.7 : 'none' }}
      _focus={{ outline: 'none' }}
    >
      <Flex w="110px" align="center" justify="space-between">
        <MagicWand />
        <Text>Magic edit</Text>
      </Flex>
    </Button>
  )

  return (
    <Flex
      flexDirection="column"
      mt={{
        base: '24px',
        sm: '24px',
        md: '16px'
      }}
      gap="16px"
    >
      <Flex
        flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
        gap={{
          base: hasMultipleVersions ? '16px' : '0px',
          sm: hasMultipleVersions ? '16px' : '0px',
          md: hasMultipleVersions ? '8px' : '0px'
        }}
        justifyContent="space-between"
        alignItems="space-between"
      >
        <Flex
          gap={{
            base: '16px',
            sm: '16px',
            md: '16px'
          }}
          flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
          alignItems="center"
          width="100%"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            maxH="30px"
            w={{ base: '100%', sm: '100%', md: 'auto' }}
          >
            <Flex>
              Template:
              <Text ml="8px" as="span" fontWeight="bold">
                {header}
              </Text>
            </Flex>
            <Button
              variant="ghost"
              borderRadius="24px"
              p="0px"
              _hover={{ opacity: 0.7 }}
              _focus={{ outline: 'none' }}
              onClick={() => setIsDeleteConfirmationOpen(true)}
              display={{
                base: 'flex',
                sm: 'flex',
                md: 'none'
              }}
            >
              <Flex align="center" justify="space-between" gap="8px">
                <TrashIcon fill="#EB5164" />
                <Text>Delete</Text>
              </Flex>
            </Button>
          </Flex>
          <Flex w={{ base: '100%', sm: '100%', md: 'auto' }}>
            {hasMultipleVersions && (
              <Select
                value={selectedProgressNoteId}
                borderColor="pale_gray"
                onChange={e => setSelectedProgressNoteId(e.target.value)}
                _hover={{ cursor: 'pointer' }}
              >
                {progressNotes.map((pn: any, index: number) => {
                  return (
                    <option key={pn.id} value={pn.id}>
                      {`v${progressNotes.length - index} `}
                    </option>
                  )
                })}
              </Select>
            )}
          </Flex>
        </Flex>
        <Flex gap="8px">
          <Button
            variant="ghost"
            borderRadius="24px"
            px="0px"
            paddingTop="0px"
            _hover={{ opacity: 0.7 }}
            _focus={{ outline: 'none' }}
            onClick={() => setIsDeleteConfirmationOpen(true)}
            display={{
              base: 'none',
              sm: 'none',
              md: 'flex'
            }}
          >
            <Flex
              w="110px"
              align="center"
              justify="space-between"
              gap="8px"
              paddingTop="0px"
            >
              <TrashIcon fill="#EB5164" />
              <Text>Delete</Text>
            </Flex>
          </Button>
          {isMagicEditEnabled
            ? renderMagicEditButton()
            : isDocumentationAutomationEnabled && (
                <Popover openDelay={popoverDelay} trigger="hover">
                  <PopoverContent
                    _focus={{ outline: 'none' }}
                    borderColor="pale_gray"
                  >
                    <PopoverArrow />
                    <PopoverBody>
                      Magic Edit requires a transcript.
                      {renderMagicEditPopoverBody()}
                    </PopoverBody>
                  </PopoverContent>
                  <PopoverTrigger>
                    <Flex w="100%">{renderMagicEditButton()}</Flex>
                  </PopoverTrigger>
                </Popover>
              )}
          <Button
            id={`pendo-copy-all-${noteType}-notes-tag`}
            borderRadius="24px"
            isFullWidth
            isDisabled={isCopyAllDisabled}
            onClick={handleCopyAllToClipboard}
            m={0}
          >
            <ContentCopyIcon style={{ fontSize: 16, marginRight: 8 }} />
            Copy all
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <ProgressNoteFeedback sessionId={sessionId} />
      </Flex>
      <MagicEditDrawer
        isOpen={isMagicEditDrawerOpen}
        closeDrawer={() => setIsMagicEditDrawerOpen(false)}
        progressNoteId={progressNoteId}
        preferences={{}}
        openSettings={() => {}}
      />
      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        isLoading={isDeletingSessionProgressNotes}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={() => deleteSessionProgressNotes({ id: sessionId })}
      />
    </Flex>
  )
}

export default ProgressNoteHeader
