import React, {
  useState,
  useCallback,
  useContext,
  Dispatch,
  SetStateAction
} from 'react'
import {
  Box,
  Divider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Container,
  Stack,
  Flex
} from '@chakra-ui/react'
import Prep from './prep'
import TreatmentPlan from './treatment-plan'
import Assessments from './assessments'
import { Worksheets } from './worksheets'
import Library from './assessments/library'
import {
  PanelBody,
  PanelHeader,
  OverlayPanelHeader
} from '@handlers/sessions/components'
import { TreatmentPlanProvider } from '@context'
import { useTreatmentPlan } from '@hooks'
import { CompassOutlined } from '@components/icons'
import AssistPanelProvider, { useAssistPanelContext } from './context'
import Panel from './panel'
import OverlayPanel from './overlay-panel'

const InSessionAssistPanel = ({ toggleAssist }: any) => {
  const { treatmentPlanQuery, isUnaccepted, client } = useTreatmentPlan()
  const {
    switchTab,
    activeTab,
    panel: activeOverlayPanel
  } = useAssistPanelContext()

  const TabConfig = [
    { name: 'Prep', component: <Prep client={client} /> },
    { name: 'Assessments', component: <Assessments /> },
    { name: 'Worksheets', component: <Worksheets /> },
    {
      name: 'Treatment Plan',
      component: <TreatmentPlan />,
      showIndicator: isUnaccepted
    }
  ]

  if (activeOverlayPanel) {
    return <OverlayPanel client={client} />
  }

  return (
    <Panel>
      <PanelHeader
        icon={<CompassOutlined fill="black" />}
        title={`${client.first_name} ${client.last_name}`}
        onClose={toggleAssist}
      />
      <PanelBody
        px="0px"
        overflowY={{
          base: 'auto',
          sm: 'auto',
          md: 'hidden'
        }}
        height={{
          base: 'auto',
          sm: 'auto',
          md: 'auto'
        }}
      >
        <Tabs isLazy index={activeTab} onChange={switchTab}>
          <Container>
            <Stack flex="1">
              <TabList
                sx={{
                  display: 'flex',
                  textWrap: 'nowrap'
                }}
                gap="32px"
                borderBottom="none"
                overflowX="auto"
                overflowY="hidden"
              >
                {TabConfig.map((tab, index) => (
                  <Tab
                    key={tab.name}
                    justifyContent="flex-start"
                    px="0"
                    color="dark_gray"
                    fontWeight="460"
                    borderBottom="6px solid"
                    whiteSpace="nowrap"
                    _focus={{ outline: 'none' }}
                    _selected={{
                      color: '#282828',
                      borderBottomColor: 'primary'
                    }}
                    position="relative"
                  >
                    <Box
                      h="8px"
                      w="8px"
                      bg="primary"
                      borderRadius="50%"
                      position="absolute"
                      top="5px"
                      right="-4px"
                      display={tab.showIndicator ? 'block' : 'none'}
                    />
                    {tab.name}
                  </Tab>
                ))}
              </TabList>
              <Divider color="#E4E5E6" style={{ marginTop: '0px' }} />
            </Stack>
          </Container>
          <TabPanels>
            {TabConfig.map((tab, index) => (
              <TabPanel key={index} p={0}>
                {tab.component}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </PanelBody>
    </Panel>
  )
}

const WrappedInSessionAssistPanel = ({
  client,
  toggleAssist
}: {
  client: any
  toggleAssist: any
}) => {
  return (
    <AssistPanelProvider>
      <TreatmentPlanProvider client={client}>
        <InSessionAssistPanel toggleAssist={toggleAssist} />
      </TreatmentPlanProvider>
    </AssistPanelProvider>
  )
}

export default WrappedInSessionAssistPanel
