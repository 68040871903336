import { DateTime } from 'luxon'

export function formatTimePassedText(date: DateTime) {
  const now = DateTime.now()

  if (now.toISODate() === date.toLocal().toISODate()) {
    return `Today at ${date.toLocaleString(DateTime.TIME_SIMPLE)}`
  } else if (now.minus({ day: 1 }).toISODate() === date.toLocal().toISODate()) {
    return `Yesterday at ${date.toLocaleString(DateTime.TIME_SIMPLE)}`
  } else {
    return `${date.toLocal().toFormat('LLL d')} at ${date.toLocaleString(
      DateTime.TIME_SIMPLE
    )}`
  }
}

export function formatDuration(startedAt: string, completedAt: string) {
  const start = DateTime.fromISO(startedAt).set({ second: 0, millisecond: 0 })
  const end = DateTime.fromISO(completedAt).set({ second: 0, millisecond: 0 })
  const duration = Math.round(end.diff(start, 'minutes').minutes)
  return duration === 1 ? '1 min' : `${duration} min`
}
