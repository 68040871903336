import React from 'react'
import { Box, Stack } from '@chakra-ui/react'

import UnionSVG from '../../images/brand/union.svg'
import { HeaderV2 } from './components'
import { ExtensionWrapper, NavBar } from '../extension'

type Props = {
  isExtension: boolean
  isWidget: boolean
  isExtensionMinimized: boolean
  hasStartedRecording: boolean
  children: any
}

export const SessionWrapper = ({
  isExtension,
  isWidget,
  isExtensionMinimized,
  hasStartedRecording,
  children
}: Props) =>
  isExtension || isWidget ? (
    <ExtensionWrapper>
      <NavBar hasStartedRecording={hasStartedRecording} />
      {!isExtensionMinimized && children}
    </ExtensionWrapper>
  ) : (
    <Stack
      bg="primary"
      backgroundRepeat="no-repeat"
      backgroundImage={UnionSVG}
      backgroundPosition="left top"
      height={{ base: '100%', md: '100vh' }}
    >
      <HeaderV2 />
      {children}
    </Stack>
  )
