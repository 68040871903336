import React, { useEffect } from 'react'
import {
  MeetingProvider,
  MeetingManager
} from 'amazon-chime-sdk-component-library-react'
import { ConsoleLogger, LogLevel } from 'amazon-chime-sdk-js'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Loading } from '@components'
import { endpoints } from '@api'
import { useSessionControllerGetSessionRecap } from '~/clinician-api'
import { SessionActivityV2 } from './session-activity-v2'
import { AudioCaptureProvider, PanelManagerProvider } from '@context'
import { useExperienceManager, useComponentRenderTracker } from '@hooks'

const logger = new ConsoleLogger('chime', LogLevel.WARN)
const manager = new MeetingManager(logger)

export function SessionActivity({
  isDictation = false
}: {
  isDictation: boolean
}) {
  useComponentRenderTracker('Viewed Session Page')

  const {
    sessionId,
    id: clientId
  }: { sessionId: string; id: string } = useParams()

  const history = useHistory()
  // format later so we can retain the DT obj for other things

  const { isDocumentationAutomationEnabled } = useExperienceManager()

  const {
    data: recap,
    isLoading: isLoadingSessionRecap,
    refetch: refetchRecap
  } = useSessionControllerGetSessionRecap(sessionId)

  const { data: client, isLoading: isClientLoading }: any = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), clientId],
    () =>
      // @ts-ignore
      endpoints.getClinicianUserAccount.request({ id: clientId })
  )

  const { data: waitlist, isLoading: isWaitlistLoading } = useQuery(
    endpoints.getFeatureWaitlist.getCacheId(),
    endpoints.getFeatureWaitlist.request
  )

  useEffect(() => {
    if (recap && recap.completedAt) {
      history.push(`/patient/${clientId}/completed-session/${sessionId}`)
    }
  }, [recap])

  if (isLoadingSessionRecap || isClientLoading || isWaitlistLoading || !recap)
    return <Loading />

  const isOnAssistWaitlist = (waitlist as any)?.includes('assist')

  return (
    <MeetingProvider meetingManager={manager}>
      <PanelManagerProvider
        isDictation={isDictation}
        isOnAssistWaitlist={isOnAssistWaitlist}
      >
        <AudioCaptureProvider
          sessionId={sessionId}
          refetchRecap={refetchRecap}
          hasStartedRecording={!!recap?.recordingStartedAt}
          isChime={!!recap.isChime}
        >
          <SessionActivityV2
            recap={recap}
            refetchRecap={refetchRecap}
            sessionId={sessionId}
            client={client}
            aiNotetakerEnabled={isDocumentationAutomationEnabled}
            isDictation={isDictation}
          />
        </AudioCaptureProvider>
      </PanelManagerProvider>
    </MeetingProvider>
  )
}
