import React, { useCallback } from 'react'
import { Box, useToast, Tooltip } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { queryClient } from '../../../index'
import { useMutation, useQuery } from 'react-query'
import { endpoints } from '@api'
import _ from 'lodash'
import moment from 'moment'

const EhrIntegrationOptInComponent = ({ pendingClientData }) => {
  const toast = useToast()
  const organizationId = pendingClientData?.organization_id

  const { data, isLoading: isProgramsLoading } = useQuery(
    [endpoints.getPrograms.getCacheId(), organizationId],
    () =>
      endpoints.getPrograms.request({
        organizationId
      }),
    {
      enabled: !!organizationId
    }
  )

  const integrateWithBlueprintProgram = data?.programs?.find(
    p => p.name.toLowerCase() === 'integrate with blueprint'
  )

  const clientIsAssignedToProgram = Boolean(
    pendingClientData?.programs?.find(
      p => p.id === integrateWithBlueprintProgram?.id && p.endDate === null
    )
  )

  const {
    mutateAsync: executeOptClientIn,
    isLoading: isOptInLoading
  } = useMutation(endpoints.createProgramEnrollment.request, {
    onSuccess: async () => {
      toast({
        title: 'Client will be enrolled and invited at their next appointment',
        status: 'success',
        isClosable: true,
        duration: 3000
      })
      await queryClient.invalidateQueries(
        endpoints.getClinicianUserAccount.getCacheId()
      )
    },
    onError: () => {
      toast({
        title: 'There was an error opting this client in to enrollment',
        status: 'error',
        isClosable: true,
        duration: 3000
      })
    }
  })

  const {
    mutateAsync: executeOptClientOut,
    isLoading: isOptOutLoading
  } = useMutation(endpoints.deleteProgramEnrollment.request, {
    onSuccess: async () => {
      toast({
        title:
          'Client will NOT be enrolled or invited at their next appointment',
        status: 'success',
        isClosable: true,
        duration: 3000
      })
      await queryClient.invalidateQueries(
        endpoints.getClinicianUserAccount.getCacheId()
      )
    },
    onError: () => {
      toast({
        title: 'There was an error opting this client out of enrollment',
        status: 'error',
        isClosable: true,
        duration: 3000
      })
    }
  })

  const onClick = useCallback(() => {
    if (Boolean(integrateWithBlueprintProgram)) {
      // if client is assigned to integrate with blueprint program, opt out
      if (clientIsAssignedToProgram) {
        return executeOptClientOut({
          clientId: pendingClientData.pendingClientId,
          programId: integrateWithBlueprintProgram?.id
        })
      }
      // otherwise, opt in
      return executeOptClientIn({
        clientId: pendingClientData.pendingClientId,
        programId: integrateWithBlueprintProgram?.id,
        startDate: moment.utc().format('YYYY-MM-DD'),
        endDate: null
      })
    } else {
      toast({
        title:
          'Organization does not have an "Integrate with Blueprint" program',
        status: 'error',
        isClosable: true,
        duration: 5500
      })

      console.log('org programs: ', data?.programs, isProgramsLoading)
      console.log('orgId: ', organizationId)
    }
  }, [clientIsAssignedToProgram, isProgramsLoading])

  const isLoading = isOptInLoading || isOptOutLoading || isProgramsLoading

  return (
    <Box>
      <Tooltip
        maxWidth="200px"
        placement="bottom-start"
        label="Opt a client in or out of being enrolled and invited to use Blueprint 24 hours before their next appointment"
        hasArrow
        shouldWrapChildren
      >
        <Button
          minW={150}
          backgroundColor={clientIsAssignedToProgram && 'error'}
          onClick={onClick}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Opt{clientIsAssignedToProgram ? ' Out' : ' In'}
        </Button>
      </Tooltip>
    </Box>
  )
}

export const EhrIntegrationOptInButton = React.memo(
  EhrIntegrationOptInComponent,
  (props, nextProps) => _.isEqual(props, nextProps)
)
