import {
  CheckInContentDtoV2NodesItem,
  CheckInScoreAnswerV1Dto,
  CheckInMultipleChoiceNode,
  CheckInLikertNode
} from '~/clinician-api/models'

export function mapCheckInAnswerToValue(
  answer: CheckInScoreAnswerV1Dto | undefined,
  n: CheckInContentDtoV2NodesItem
): string | undefined {
  let value: string | undefined = answer?.value

  if (n.type === 'multiple_choice' && value !== undefined) {
    const node = n as CheckInMultipleChoiceNode

    if (node.multiselect && Array.isArray(value)) {
      value = ((value as unknown) as string[])
        .map((id: string) => node.answers.find(a => a.id === id)?.label)
        .join(', ')
    } else {
      // @ts-ignore
      value = node.answers.find(a => a.id === value)?.label || node.answers.find(a => a.value?.toString() === value)?.label
    }
  } else if (n.type === 'likert' && value !== undefined) {
    const node = n as CheckInLikertNode
    value = node.answers.find(a => a.value.toString() === value)?.label
  }

  return value
}

