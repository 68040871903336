import React from 'react'
import { Flex, Link as CLink, useBreakpointValue } from '@chakra-ui/react'

export const ConsentModule = ({ isGetStarted }) => {
  const Title = ({ children }) => {
    let SpanDiv = useBreakpointValue({ base: 'div', md: 'span' })
    if (!isGetStarted) SpanDiv = 'div'
    return <SpanDiv style={{ marginRight: '8px' }}>{children}</SpanDiv>
  }

  return (
    <Flex direction={['column', 'column', 'column', 'row']}>
      <Title>Client Consent:</Title>
      <CLink
        id={
          isGetStarted
            ? 'pendo-sessions-get-started-consent'
            : 'pendo-sessions-consent'
        }
        data-click-event-name="Clicked Consent Template Link"
        href="https://docs.google.com/document/d/1YOpiLkjcj5c68Tf15jexv0ISiRLWGQOHCNzoOptA2Qc/edit"
        isExternal
        color="primary"
        _focus={{ outline: 'none' }}
      >
        View Template
      </CLink>
    </Flex>
  )
}
