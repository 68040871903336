import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Assessment } from '../handlers'
import { Loading } from '../components'

class AssessmentTakeNow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      assessment_ids: [],
      assessments: [],
      user_id: props.match.params.id
    }
  }
  componentDidMount() {
    this.setState(
      {
        assessment_ids: this.props.match.params.assessment_ids.split(',')
      },
      () => {
        this.getAssessments()
      }
    )
  }
  getAssessments = () => {
    const { api } = this.props
    const { user_id } = this.state
    const { assessment_ids } = this.state
    const preview_path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/clinicians/clients/${user_id}/assessments?assessment_ids=${assessment_ids}&includeFreeTextQuestions=true`
    api
      .GET(preview_path)
      .then(json => {
        this.setState({
          assessments: json
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  render() {
    const { assessments } = this.state
    return assessments.length ? (
      <Assessment assessments={assessments} preview={false} {...this.props} />
    ) : (
      <div style={{ height: '100vh' }}>
        <Loading />
      </div>
    )
  }
}

export default withRouter(AssessmentTakeNow)
