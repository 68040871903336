import React, { useState } from 'react'
import { formatDuration } from '@handlers/sessions/util'
import { Flex, Text, Link, Button, Box, useToast } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { useMutation } from 'react-query'
import { endpoints } from '@api'
import { DateTime } from 'luxon'

function toInputDateTimeFormat(datetime: DateTime): string {
  return datetime.toFormat('yyyy-LL-dd') + 'T' + datetime.toFormat('HH:mm')
}

const Details = ({
  client,
  session,
  refresh,
  progressNote,
  sessionType
}: any) => {
  const toast = useToast()
  const [startedAt, setStartedAt] = useState(
    DateTime.fromISO(session.startedAt)
  )
  const [completedAt, setCompletedAt] = useState(
    DateTime.fromISO(session.completedAt || session.startedAt)
  )
  const [isEditingStartTime, setIsEditingStartTime] = useState(false)
  const [isEditingEndTime, setIsEditingEndTime] = useState(false)
  const [isEditingSessionDate, setIsEditingSessionDate] = useState(false)

  const { mutate: updateTimestamps }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.patchSessionTimestampOverrides.request,
    {
      onSuccess: () => {
        refresh()
        setIsEditingStartTime(false)
        setIsEditingEndTime(false)
        setIsEditingSessionDate(false)
      }
    }
  )

  const onUpdateTimestamps = () => {
    if (startedAt > completedAt) {
      toast({
        status: 'error',
        description: 'Time must be before end date.'
      })
    } else if (completedAt < startedAt) {
      toast({
        status: 'error',
        description: 'Time must be after start date.'
      })
    } else {
      updateTimestamps({
        sessionId: session.id,
        data: {
          startedAtOverride: startedAt,
          completedAtOverride: completedAt
        }
      })
    }
  }

  return (
    <Flex flexDirection="column" gap="16px">
      <Flex>
        <Text fontWeight="bold">Overview</Text>
      </Flex>
      <Flex flexDirection="column" gap="2px">
        <Text>
          <b>Client:</b>&nbsp;
          <Link color="primary" as={RouterLink} to={`/patient/${client.id}`}>
            {client.first_name} {client.last_name}
          </Link>
        </Text>
        <Flex>
          <Text>
            <b>Session Date:</b>&nbsp;
            {!isEditingSessionDate && startedAt.toFormat('EEE MMM d, yyyy')}
          </Text>
          {!isEditingSessionDate && (
            <Link
              ml="xsmall"
              color="primary"
              onClick={() => setIsEditingSessionDate(!isEditingSessionDate)}
            >
              Edit
            </Link>
          )}
        </Flex>
        {isEditingSessionDate && (
          <>
            <input
              type="date"
              style={{
                border: '1px solid #E4E5E6',
                borderRadius: '5px',
                padding: '5px 5px',
                maxWidth: '252px'
              }}
              value={startedAt.toFormat('yyyy-MM-dd')}
              onChange={e => {
                const dateValues = e.target.value.split('-')
                setStartedAt(
                  startedAt.set({
                    year: Number(dateValues[0]),
                    month: Number(dateValues[1]),
                    day: Number(dateValues[2])
                  })
                )
                setCompletedAt(
                  completedAt.set({
                    year: Number(dateValues[0]),
                    month: Number(dateValues[1]),
                    day: Number(dateValues[2])
                  })
                )
              }}
            />
            <Flex>
              <Button
                size="sm"
                variant="outline"
                ml="0"
                onClick={() => {
                  setIsEditingSessionDate(false)
                  setStartedAt(
                    DateTime.fromISO(session.startedAt).setZone('UTC')
                  )
                }}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                onClick={() =>
                  updateTimestamps({
                    sessionId: session.id,
                    data: {
                      startedAtOverride: startedAt,
                      completedAtOverride: completedAt
                    }
                  })
                }
              >
                Save
              </Button>
            </Flex>
          </>
        )}
        {!session.fileUploadId && (
          <>
            <Flex>
              <Text>
                <b>Start Time:</b>&nbsp;
                {!isEditingStartTime &&
                  startedAt.toFormat('h:mma')}
              </Text>
              {!isEditingStartTime && (
                <Link
                  ml="xsmall"
                  color="primary"
                  onClick={() => setIsEditingStartTime(!isEditingStartTime)}
                >
                  Edit
                </Link>
              )}
            </Flex>
            {isEditingStartTime && (
              <>
                <input
                  type="datetime-local"
                  style={{
                    border: '1px solid #E4E5E6',
                    borderRadius: '5px',
                    padding: '5px 5px',
                    maxWidth: '252px'
                  }}
                  value={toInputDateTimeFormat(startedAt)}
                  max={toInputDateTimeFormat(completedAt)}
                  onChange={e => {
                    const nextStartedAt = DateTime.fromISO(e.target.value)
                    if (!nextStartedAt.isValid) return;
                    setStartedAt(nextStartedAt)
                  }}
                />
                <Flex>
                  <Button
                    size="sm"
                    variant="outline"
                    ml="0"
                    onClick={() => {
                      setIsEditingStartTime(false)
                      setStartedAt(DateTime.fromISO(session.startedAt))
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    onClick={onUpdateTimestamps}
                  >
                    Save
                  </Button>
                </Flex>
              </>
            )}
            <Flex>
              <Text>
                <b>End Time:</b>&nbsp;
                {!isEditingEndTime && completedAt.toFormat('h:mma')}
              </Text>
              {!isEditingEndTime && (
                <Link
                  ml="xsmall"
                  color="primary"
                  onClick={() => setIsEditingEndTime(!isEditingEndTime)}
                >
                  Edit
                </Link>
              )}
            </Flex>
            {isEditingEndTime && (
              <>
                <input
                  type="datetime-local"
                  style={{
                    border: '1px solid #E4E5E6',
                    borderRadius: '5px',
                    padding: '5px 5px',
                    maxWidth: '252px'
                  }}
                  min={toInputDateTimeFormat(startedAt)}
                  max={toInputDateTimeFormat(startedAt.plus({ days: 1 }))}
                  value={toInputDateTimeFormat(completedAt)}
                  onChange={e => {
                    const nextCompletedAt = DateTime.fromISO(e.target.value)
                    if (!nextCompletedAt.isValid) return;
                    setCompletedAt(nextCompletedAt)
                  }}
                />
                <Flex>
                  <Button
                    size="sm"
                    variant="outline"
                    ml="0"
                    onClick={() => {
                      setIsEditingEndTime(false)
                      setCompletedAt(DateTime.fromISO(session.completedAt))
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    onClick={onUpdateTimestamps}
                  >
                    Save
                  </Button>
                </Flex>
              </>
            )}
            {session.completedAt && (
              <Text>
                <b>Duration:</b>&nbsp;
                {formatDuration(session.startedAt, session.completedAt)}
              </Text>
            )}
          </>
        )}
      </Flex>
      <Flex flexDirection="column" gap="2px">
        <Text>
          <b>Input:</b>&nbsp;
          {session.fileUploadId
            ? 'Upload'
            : !!session.recordingStartedAt || !!session.sampleType
            ? 'Session recording'
            : 'Assessment, Homework, Interventions'}
        </Text>
        <Text>
          <b>Type:</b>&nbsp;
          {sessionType}
        </Text>
        <Text>
          <b>Setting:</b>&nbsp;
          {session.isTelehealth ? 'Telehealth' : 'In-person'}
        </Text>
      </Flex>
      <Box>
        {progressNote?.createdAt && (
          <Text
            as="em"
            fontSize="xs"
            color="darkgray"
          >{`Note generated on ${DateTime.fromISO(progressNote.createdAt)
            .setZone('UTC')
            .toFormat('EEE MMM d, yyyy')}`}</Text>
        )}
      </Box>
    </Flex>
  )
}

export default Details
