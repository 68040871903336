import moment from 'moment'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import flagsmith from 'flagsmith'
import { ArrowRightIcon } from '@blueprinthq/joy'
import { Pagination } from '@material-ui/lab'
import { Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'

import { FlagsmithFeatures } from '@constants/flagsmith'
import { useClinicControllerGetClientList } from '~/clinician-api'
import { useExperienceManager } from '@hooks'
import { ClientListDto } from '~/clinician-api/models'

type ClientTableProps = {
  clinicId: string
  clinicianId: string | null
}

interface CellProps {
  children: React.ReactNode
  width?: string
}

const Cell = ({ children, width }: CellProps) => (
  <Td verticalAlign="middle" borderColor="#E4E5E6" width={width}>
    {children}
  </Td>
)

const HeaderCell = ({ children }: { children?: React.ReactNode }) => (
  <Th verticalAlign="middle" borderColor="#E4E5E6">
    {children}
  </Th>
)

export const ClientTable = ({ clinicId, clinicianId }: ClientTableProps) => {
  const [currentPage, setCurrentPage] = React.useState(1)

  useEffect(() => {
    setCurrentPage(1)
  }, [clinicId, clinicianId])

  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  const isAssistEnabled = flagsmith.hasFeature(FlagsmithFeatures.ASSIST)

  const history = useHistory()

  const {
    data: clientList = {
      clients: [],
      pageCount: 1,
      currentPage: 1
    } as ClientListDto,
    isLoading
  } = useClinicControllerGetClientList(
    clinicId,
    { clinicianId: clinicianId!, page: currentPage, pageSize: 50 },
    {
      query: { placeholderData: { clients: [], pageCount: 1, currentPage: 1 } }
    }
  )

  if (isLoading || clientList === undefined) return <div>Loading</div>

  const navToClient = (clientId: string) => {
    history.push(`/patient/${clientId}/settings/sessions`)
  }

  return (
    <div>
      <Table
        border="1px solid"
        borderBottom="none"
        borderColor="#E4E5E6"
        borderRadius="4px"
        style={{ borderCollapse: 'separate' }}
        marginBottom="medium"
        size="sm"
      >
        <Thead>
          <Tr height="32px">
            <HeaderCell>First Name</HeaderCell>
            <HeaderCell>Last Name</HeaderCell>
            {isEvidenceBasedCareEnabled && <HeaderCell>DOB</HeaderCell>}
            {isAssistEnabled && <HeaderCell>Phone</HeaderCell>}
            <HeaderCell></HeaderCell>
          </Tr>
        </Thead>
        <Tbody>
          {clientList?.clients!.map((client, i) => (
            <Tr
              cursor="pointer"
              key={i}
              onClick={() => {
                navToClient(client.id)
              }}
              height="38px"
            >
              <Cell>{client.firstName}</Cell>
              <Cell>{client.lastName}</Cell>
              {isEvidenceBasedCareEnabled && (
                <Cell>{moment(client.dateOfBirth).format('MM/DD/YYYY')}</Cell>
              )}
              {isAssistEnabled && <Cell>{client.phoneNumber}</Cell>}
              <Cell width="40px">
                <ArrowRightIcon size="sm" />
              </Cell>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box display="flex" flexDir="column" alignItems="center">
        <Pagination
          page={currentPage}
          count={clientList.pageCount}
          onChange={(evt, newPage) => {
            setCurrentPage(newPage)
          }}
          color="primary"
          variant="outlined"
          size="large"
          showFirstButton
          showLastButton
        />
      </Box>
    </div>
  )
}
