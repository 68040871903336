/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ClientListDto,
  ClinicControllerGetClientListParams,
  CheckInDtoV2,
  ClinicControllerGetCheckInsParams,
  ClinicControllerGetAssignedCheckInsParams,
  AssignCheckInBodyDto,
  SessionDraftDto,
  SessionCreateBodyDto,
  CompletedSessionDto,
  SessionPatchDto,
  SessionProgressNotesDto,
  PatchNoteDto,
  StartInterventionsBodyDto,
  PatientInterventionDto,
  SessionPsychotherapyNoteBodyDto,
  SessionRecapResponse,
  TrackEventDto,
  SessionPlannedInterventionsBodyDto,
  SampleSessionDto,
  CreateSampleSessionDto,
  StartUploadDto,
  CompleteUploadDto,
  MarkSessionCompleteBodyDto,
  SessionFromUploadedAudioBodyDto,
  SuggestedContentDTO,
  SessionPrepDto,
  AssistEnrollDto,
  CompletedSessionListItemDto,
  SessionDto,
  PatientCheckInScoresDto,
  PatientConsentDto,
  PatientControllerGetConsentParams,
  LibraryControllerV1GetAllCheckInsParams,
  CheckInBodyDto,
  SubmitFeedbackDto,
  ConsentSettingDTO,
  PatchConsentSettingDTO
} from './models'
import { hellojoyApiInstance, ErrorType } from '../../axios-instance'


export const clinicControllerGetClientList = (
    clinicId: string,
    params?: ClinicControllerGetClientListParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<ClientListDto>(
      {url: `/v1/clinician-api/clinic/${clinicId}/client-list`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getClinicControllerGetClientListQueryKey = (clinicId: string,
    params?: ClinicControllerGetClientListParams,) => [`/v1/clinician-api/clinic/${clinicId}/client-list`, ...(params ? [params]: [])];

    
export type ClinicControllerGetClientListQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetClientList>>>
export type ClinicControllerGetClientListQueryError = ErrorType<void>

export const useClinicControllerGetClientList = <TData = Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError = ErrorType<void>>(
 clinicId: string,
    params?: ClinicControllerGetClientListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerGetClientListQueryKey(clinicId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetClientList>>> = ({ signal }) => clinicControllerGetClientList(clinicId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const clinicControllerGetCheckIns = (
    clinicId: string,
    params?: ClinicControllerGetCheckInsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/check-ins`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getClinicControllerGetCheckInsQueryKey = (clinicId: string,
    params?: ClinicControllerGetCheckInsParams,) => [`/v1/clinician-api/clinic/${clinicId}/library/check-ins`, ...(params ? [params]: [])];

    
export type ClinicControllerGetCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>>
export type ClinicControllerGetCheckInsQueryError = ErrorType<void>

export const useClinicControllerGetCheckIns = <TData = Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError = ErrorType<void>>(
 clinicId: string,
    params?: ClinicControllerGetCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerGetCheckInsQueryKey(clinicId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>> = ({ signal }) => clinicControllerGetCheckIns(clinicId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const clinicControllerGetAssignedCheckIns = (
    clinicId: string,
    params?: ClinicControllerGetAssignedCheckInsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/check-ins/assigned`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getClinicControllerGetAssignedCheckInsQueryKey = (clinicId: string,
    params?: ClinicControllerGetAssignedCheckInsParams,) => [`/v1/clinician-api/clinic/${clinicId}/library/check-ins/assigned`, ...(params ? [params]: [])];

    
export type ClinicControllerGetAssignedCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>>
export type ClinicControllerGetAssignedCheckInsQueryError = ErrorType<void>

export const useClinicControllerGetAssignedCheckIns = <TData = Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError = ErrorType<void>>(
 clinicId: string,
    params?: ClinicControllerGetAssignedCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerGetAssignedCheckInsQueryKey(clinicId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>> = ({ signal }) => clinicControllerGetAssignedCheckIns(clinicId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const clinicControllerToggleCheckInDefault = (
    clinicId: string,
    checkInId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/check-ins/${checkInId}/toggle-default`, method: 'put'
    },
      );
    }
  


    export type ClinicControllerToggleCheckInDefaultMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>>
    
    export type ClinicControllerToggleCheckInDefaultMutationError = ErrorType<unknown>

    export const useClinicControllerToggleCheckInDefault = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>, TError,{clinicId: string;checkInId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>, {clinicId: string;checkInId: string}> = (props) => {
          const {clinicId,checkInId} = props ?? {};

          return  clinicControllerToggleCheckInDefault(clinicId,checkInId,)
        }

      return useMutation<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>, TError, {clinicId: string;checkInId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const clinicControllerAssignCheckIn = (
    clinicId: string,
    assignCheckInBodyDto: AssignCheckInBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/assign`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: assignCheckInBodyDto
    },
      );
    }
  


    export type ClinicControllerAssignCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>>
    export type ClinicControllerAssignCheckInMutationBody = AssignCheckInBodyDto
    export type ClinicControllerAssignCheckInMutationError = ErrorType<unknown>

    export const useClinicControllerAssignCheckIn = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>, TError,{clinicId: string;data: AssignCheckInBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>, {clinicId: string;data: AssignCheckInBodyDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerAssignCheckIn(clinicId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>, TError, {clinicId: string;data: AssignCheckInBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const clinicControllerUnassignCheckIn = (
    clinicId: string,
    assignCheckInBodyDto: AssignCheckInBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/unassign`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: assignCheckInBodyDto
    },
      );
    }
  


    export type ClinicControllerUnassignCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>>
    export type ClinicControllerUnassignCheckInMutationBody = AssignCheckInBodyDto
    export type ClinicControllerUnassignCheckInMutationError = ErrorType<unknown>

    export const useClinicControllerUnassignCheckIn = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>, TError,{clinicId: string;data: AssignCheckInBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>, {clinicId: string;data: AssignCheckInBodyDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerUnassignCheckIn(clinicId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>, TError, {clinicId: string;data: AssignCheckInBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerPostSession = (
    sessionCreateBodyDto: SessionCreateBodyDto,
 
) => {
      return hellojoyApiInstance<SessionDraftDto>(
      {url: `/v1/clinician-api/session`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionCreateBodyDto
    },
      );
    }
  


    export type SessionControllerPostSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostSession>>>
    export type SessionControllerPostSessionMutationBody = SessionCreateBodyDto
    export type SessionControllerPostSessionMutationError = ErrorType<void>

    export const useSessionControllerPostSession = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostSession>>, TError,{data: SessionCreateBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostSession>>, {data: SessionCreateBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerPostSession(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPostSession>>, TError, {data: SessionCreateBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerGetCompletedSessionDetails = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CompletedSessionDto>(
      {url: `/v1/clinician-api/session/${id}`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetCompletedSessionDetailsQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}`];

    
export type SessionControllerGetCompletedSessionDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>>
export type SessionControllerGetCompletedSessionDetailsQueryError = ErrorType<void>

export const useSessionControllerGetCompletedSessionDetails = <TData = Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetCompletedSessionDetailsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>> = ({ signal }) => sessionControllerGetCompletedSessionDetails(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerPatchSession = (
    id: string,
    sessionPatchDto: SessionPatchDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json'},
      data: sessionPatchDto
    },
      );
    }
  


    export type SessionControllerPatchSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPatchSession>>>
    export type SessionControllerPatchSessionMutationBody = SessionPatchDto
    export type SessionControllerPatchSessionMutationError = ErrorType<unknown>

    export const useSessionControllerPatchSession = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchSession>>, TError,{id: string;data: SessionPatchDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPatchSession>>, {id: string;data: SessionPatchDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPatchSession(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPatchSession>>, TError, {id: string;data: SessionPatchDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerGetProgressNotesForSession = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SessionProgressNotesDto>(
      {url: `/v1/clinician-api/session/${id}/progress-notes`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetProgressNotesForSessionQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}/progress-notes`];

    
export type SessionControllerGetProgressNotesForSessionQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>>
export type SessionControllerGetProgressNotesForSessionQueryError = ErrorType<void>

export const useSessionControllerGetProgressNotesForSession = <TData = Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetProgressNotesForSessionQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>> = ({ signal }) => sessionControllerGetProgressNotesForSession(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerPatchProgressNote = (
    id: string,
    noteId: string,
    patchNoteDto: PatchNoteDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/progress-notes/${noteId}`, method: 'patch',
      headers: {'Content-Type': 'application/json'},
      data: patchNoteDto
    },
      );
    }
  


    export type SessionControllerPatchProgressNoteMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>>
    export type SessionControllerPatchProgressNoteMutationBody = PatchNoteDto
    export type SessionControllerPatchProgressNoteMutationError = ErrorType<unknown>

    export const useSessionControllerPatchProgressNote = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>, TError,{id: string;noteId: string;data: PatchNoteDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>, {id: string;noteId: string;data: PatchNoteDto}> = (props) => {
          const {id,noteId,data} = props ?? {};

          return  sessionControllerPatchProgressNote(id,noteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>, TError, {id: string;noteId: string;data: PatchNoteDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerStartInterventions = (
    id: string,
    startInterventionsBodyDto: StartInterventionsBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/start-interventions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: startInterventionsBodyDto
    },
      );
    }
  


    export type SessionControllerStartInterventionsMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerStartInterventions>>>
    export type SessionControllerStartInterventionsMutationBody = StartInterventionsBodyDto
    export type SessionControllerStartInterventionsMutationError = ErrorType<unknown>

    export const useSessionControllerStartInterventions = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerStartInterventions>>, TError,{id: string;data: StartInterventionsBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerStartInterventions>>, {id: string;data: StartInterventionsBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerStartInterventions(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerStartInterventions>>, TError, {id: string;data: StartInterventionsBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerGetStartedInterventions = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientInterventionDto[]>(
      {url: `/v1/clinician-api/session/${id}/started-interventions`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetStartedInterventionsQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}/started-interventions`];

    
export type SessionControllerGetStartedInterventionsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>>
export type SessionControllerGetStartedInterventionsQueryError = ErrorType<void>

export const useSessionControllerGetStartedInterventions = <TData = Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetStartedInterventionsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>> = ({ signal }) => sessionControllerGetStartedInterventions(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerDeleteIntervention = (
    id: string,
    patientInterventionId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/interventions/${patientInterventionId}`, method: 'delete'
    },
      );
    }
  


    export type SessionControllerDeleteInterventionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>>
    
    export type SessionControllerDeleteInterventionMutationError = ErrorType<unknown>

    export const useSessionControllerDeleteIntervention = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>, TError,{id: string;patientInterventionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>, {id: string;patientInterventionId: string}> = (props) => {
          const {id,patientInterventionId} = props ?? {};

          return  sessionControllerDeleteIntervention(id,patientInterventionId,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>, TError, {id: string;patientInterventionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerUpdatePsychotherapyNote = (
    id: string,
    sessionPsychotherapyNoteBodyDto: SessionPsychotherapyNoteBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/psychotherapy-note`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionPsychotherapyNoteBodyDto
    },
      );
    }
  


    export type SessionControllerUpdatePsychotherapyNoteMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>>
    export type SessionControllerUpdatePsychotherapyNoteMutationBody = SessionPsychotherapyNoteBodyDto
    export type SessionControllerUpdatePsychotherapyNoteMutationError = ErrorType<unknown>

    export const useSessionControllerUpdatePsychotherapyNote = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>, TError,{id: string;data: SessionPsychotherapyNoteBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>, {id: string;data: SessionPsychotherapyNoteBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerUpdatePsychotherapyNote(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>, TError, {id: string;data: SessionPsychotherapyNoteBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerGetSessionRecap = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SessionRecapResponse>(
      {url: `/v1/clinician-api/session/${id}/recap`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetSessionRecapQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}/recap`];

    
export type SessionControllerGetSessionRecapQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>>
export type SessionControllerGetSessionRecapQueryError = ErrorType<void>

export const useSessionControllerGetSessionRecap = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetSessionRecapQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>> = ({ signal }) => sessionControllerGetSessionRecap(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerTrackEvent = (
    id: string,
    trackEventDto: TrackEventDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/track-event`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: trackEventDto
    },
      );
    }
  


    export type SessionControllerTrackEventMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerTrackEvent>>>
    export type SessionControllerTrackEventMutationBody = TrackEventDto
    export type SessionControllerTrackEventMutationError = ErrorType<unknown>

    export const useSessionControllerTrackEvent = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerTrackEvent>>, TError,{id: string;data: TrackEventDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerTrackEvent>>, {id: string;data: TrackEventDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerTrackEvent(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerTrackEvent>>, TError, {id: string;data: TrackEventDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerPostPlannedInterventions = (
    id: string,
    sessionPlannedInterventionsBodyDto: SessionPlannedInterventionsBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/planned-interventions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionPlannedInterventionsBodyDto
    },
      );
    }
  


    export type SessionControllerPostPlannedInterventionsMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>>
    export type SessionControllerPostPlannedInterventionsMutationBody = SessionPlannedInterventionsBodyDto
    export type SessionControllerPostPlannedInterventionsMutationError = ErrorType<unknown>

    export const useSessionControllerPostPlannedInterventions = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>, TError,{id: string;data: SessionPlannedInterventionsBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>, {id: string;data: SessionPlannedInterventionsBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPostPlannedInterventions(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>, TError, {id: string;data: SessionPlannedInterventionsBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerCreateSampleSession = (
    createSampleSessionDto: CreateSampleSessionDto,
 
) => {
      return hellojoyApiInstance<SampleSessionDto>(
      {url: `/v1/clinician-api/session/sample`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: createSampleSessionDto
    },
      );
    }
  


    export type SessionControllerCreateSampleSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>>
    export type SessionControllerCreateSampleSessionMutationBody = CreateSampleSessionDto
    export type SessionControllerCreateSampleSessionMutationError = ErrorType<void>

    export const useSessionControllerCreateSampleSession = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>, TError,{data: CreateSampleSessionDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>, {data: CreateSampleSessionDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerCreateSampleSession(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>, TError, {data: CreateSampleSessionDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerStartUpload = (
    startUploadDto: StartUploadDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/start-upload`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: startUploadDto
    },
      );
    }
  


    export type SessionControllerStartUploadMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerStartUpload>>>
    export type SessionControllerStartUploadMutationBody = StartUploadDto
    export type SessionControllerStartUploadMutationError = ErrorType<unknown>

    export const useSessionControllerStartUpload = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerStartUpload>>, TError,{data: StartUploadDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerStartUpload>>, {data: StartUploadDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerStartUpload(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerStartUpload>>, TError, {data: StartUploadDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerCompleteUpload = (
    completeUploadDto: CompleteUploadDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/complete-upload`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: completeUploadDto
    },
      );
    }
  


    export type SessionControllerCompleteUploadMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>>
    export type SessionControllerCompleteUploadMutationBody = CompleteUploadDto
    export type SessionControllerCompleteUploadMutationError = ErrorType<unknown>

    export const useSessionControllerCompleteUpload = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>, TError,{data: CompleteUploadDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>, {data: CompleteUploadDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerCompleteUpload(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>, TError, {data: CompleteUploadDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerPostDiscardSession = (
    id: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/discard-session`, method: 'post'
    },
      );
    }
  


    export type SessionControllerPostDiscardSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>>
    
    export type SessionControllerPostDiscardSessionMutationError = ErrorType<unknown>

    export const useSessionControllerPostDiscardSession = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  sessionControllerPostDiscardSession(id,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerPostMarkComplete = (
    id: string,
    markSessionCompleteBodyDto: MarkSessionCompleteBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/mark-complete`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: markSessionCompleteBodyDto
    },
      );
    }
  


    export type SessionControllerPostMarkCompleteMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>>
    export type SessionControllerPostMarkCompleteMutationBody = MarkSessionCompleteBodyDto
    export type SessionControllerPostMarkCompleteMutationError = ErrorType<unknown>

    export const useSessionControllerPostMarkComplete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>, TError,{id: string;data: MarkSessionCompleteBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>, {id: string;data: MarkSessionCompleteBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPostMarkComplete(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>, TError, {id: string;data: MarkSessionCompleteBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerCreateFromUploadedAudio = (
    sessionFromUploadedAudioBodyDto: SessionFromUploadedAudioBodyDto,
 
) => {
      return hellojoyApiInstance<CompletedSessionDto>(
      {url: `/v1/clinician-api/session/from-uploaded-audio`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionFromUploadedAudioBodyDto
    },
      );
    }
  


    export type SessionControllerCreateFromUploadedAudioMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>>
    export type SessionControllerCreateFromUploadedAudioMutationBody = SessionFromUploadedAudioBodyDto
    export type SessionControllerCreateFromUploadedAudioMutationError = ErrorType<void>

    export const useSessionControllerCreateFromUploadedAudio = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>, TError,{data: SessionFromUploadedAudioBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>, {data: SessionFromUploadedAudioBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerCreateFromUploadedAudio(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>, TError, {data: SessionFromUploadedAudioBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerPostContentSuggestions = (
    id: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/generate-suggestions`, method: 'post'
    },
      );
    }
  


    export type SessionControllerPostContentSuggestionsMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>>
    
    export type SessionControllerPostContentSuggestionsMutationError = ErrorType<unknown>

    export const useSessionControllerPostContentSuggestions = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  sessionControllerPostContentSuggestions(id,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerGetSuggestedContent = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SuggestedContentDTO>(
      {url: `/v1/clinician-api/session/${id}/content-suggestions`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetSuggestedContentQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}/content-suggestions`];

    
export type SessionControllerGetSuggestedContentQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>>
export type SessionControllerGetSuggestedContentQueryError = ErrorType<void>

export const useSessionControllerGetSuggestedContent = <TData = Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetSuggestedContentQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>> = ({ signal }) => sessionControllerGetSuggestedContent(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerGetSessionPrep = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SessionPrepDto>(
      {url: `/v1/clinician-api/session/${id}/prep`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetSessionPrepQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}/prep`];

    
export type SessionControllerGetSessionPrepQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>>
export type SessionControllerGetSessionPrepQueryError = ErrorType<void>

export const useSessionControllerGetSessionPrep = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetSessionPrepQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>> = ({ signal }) => sessionControllerGetSessionPrep(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const patientControllerAssistEnroll = (
    patientId: string,
    assistEnrollDto: AssistEnrollDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/assist-enroll`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: assistEnrollDto
    },
      );
    }
  


    export type PatientControllerAssistEnrollMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerAssistEnroll>>>
    export type PatientControllerAssistEnrollMutationBody = AssistEnrollDto
    export type PatientControllerAssistEnrollMutationError = ErrorType<unknown>

    export const usePatientControllerAssistEnroll = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerAssistEnroll>>, TError,{patientId: string;data: AssistEnrollDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerAssistEnroll>>, {patientId: string;data: AssistEnrollDto}> = (props) => {
          const {patientId,data} = props ?? {};

          return  patientControllerAssistEnroll(patientId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof patientControllerAssistEnroll>>, TError, {patientId: string;data: AssistEnrollDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const patientControllerGetCompletedSessions = (
    patientId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CompletedSessionListItemDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/completed-sessions`, method: 'get', signal
    },
      );
    }
  

export const getPatientControllerGetCompletedSessionsQueryKey = (patientId: string,) => [`/v1/clinician-api/patient/${patientId}/completed-sessions`];

    
export type PatientControllerGetCompletedSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetCompletedSessions>>>
export type PatientControllerGetCompletedSessionsQueryError = ErrorType<void>

export const usePatientControllerGetCompletedSessions = <TData = Awaited<ReturnType<typeof patientControllerGetCompletedSessions>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetCompletedSessions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientControllerGetCompletedSessionsQueryKey(patientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetCompletedSessions>>> = ({ signal }) => patientControllerGetCompletedSessions(patientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientControllerGetCompletedSessions>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const patientControllerGetSessionList = (
    patientId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SessionDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/session-list`, method: 'get', signal
    },
      );
    }
  

export const getPatientControllerGetSessionListQueryKey = (patientId: string,) => [`/v1/clinician-api/patient/${patientId}/session-list`];

    
export type PatientControllerGetSessionListQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetSessionList>>>
export type PatientControllerGetSessionListQueryError = ErrorType<void>

export const usePatientControllerGetSessionList = <TData = Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientControllerGetSessionListQueryKey(patientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetSessionList>>> = ({ signal }) => patientControllerGetSessionList(patientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const patientControllerGetCheckInScores = (
    patientId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientCheckInScoresDto>(
      {url: `/v1/clinician-api/patient/${patientId}/check-ins/scores`, method: 'get', signal
    },
      );
    }
  

export const getPatientControllerGetCheckInScoresQueryKey = (patientId: string,) => [`/v1/clinician-api/patient/${patientId}/check-ins/scores`];

    
export type PatientControllerGetCheckInScoresQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>>
export type PatientControllerGetCheckInScoresQueryError = ErrorType<void>

export const usePatientControllerGetCheckInScores = <TData = Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientControllerGetCheckInScoresQueryKey(patientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>> = ({ signal }) => patientControllerGetCheckInScores(patientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const patientControllerGetConsent = (
    patientId: string,
    params?: PatientControllerGetConsentParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientConsentDto>(
      {url: `/v1/clinician-api/patient/${patientId}/consent`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getPatientControllerGetConsentQueryKey = (patientId: string,
    params?: PatientControllerGetConsentParams,) => [`/v1/clinician-api/patient/${patientId}/consent`, ...(params ? [params]: [])];

    
export type PatientControllerGetConsentQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetConsent>>>
export type PatientControllerGetConsentQueryError = ErrorType<void>

export const usePatientControllerGetConsent = <TData = Awaited<ReturnType<typeof patientControllerGetConsent>>, TError = ErrorType<void>>(
 patientId: string,
    params?: PatientControllerGetConsentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetConsent>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientControllerGetConsentQueryKey(patientId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetConsent>>> = ({ signal }) => patientControllerGetConsent(patientId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientControllerGetConsent>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const libraryControllerV1GetAllCheckIns = (
    params?: LibraryControllerV1GetAllCheckInsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/library/check-ins/all`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getLibraryControllerV1GetAllCheckInsQueryKey = (params?: LibraryControllerV1GetAllCheckInsParams,) => [`/v1/clinician-api/library/check-ins/all`, ...(params ? [params]: [])];

    
export type LibraryControllerV1GetAllCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof libraryControllerV1GetAllCheckIns>>>
export type LibraryControllerV1GetAllCheckInsQueryError = ErrorType<void>

export const useLibraryControllerV1GetAllCheckIns = <TData = Awaited<ReturnType<typeof libraryControllerV1GetAllCheckIns>>, TError = ErrorType<void>>(
 params?: LibraryControllerV1GetAllCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof libraryControllerV1GetAllCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getLibraryControllerV1GetAllCheckInsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof libraryControllerV1GetAllCheckIns>>> = ({ signal }) => libraryControllerV1GetAllCheckIns(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof libraryControllerV1GetAllCheckIns>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const libraryControllerV1GetDraftCheckIn = (
    checkInId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/draft`, method: 'get', signal
    },
      );
    }
  

export const getLibraryControllerV1GetDraftCheckInQueryKey = (checkInId: string,) => [`/v1/clinician-api/library/check-ins/${checkInId}/draft`];

    
export type LibraryControllerV1GetDraftCheckInQueryResult = NonNullable<Awaited<ReturnType<typeof libraryControllerV1GetDraftCheckIn>>>
export type LibraryControllerV1GetDraftCheckInQueryError = ErrorType<void>

export const useLibraryControllerV1GetDraftCheckIn = <TData = Awaited<ReturnType<typeof libraryControllerV1GetDraftCheckIn>>, TError = ErrorType<void>>(
 checkInId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof libraryControllerV1GetDraftCheckIn>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getLibraryControllerV1GetDraftCheckInQueryKey(checkInId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof libraryControllerV1GetDraftCheckIn>>> = ({ signal }) => libraryControllerV1GetDraftCheckIn(checkInId, signal);

  const query = useQuery<Awaited<ReturnType<typeof libraryControllerV1GetDraftCheckIn>>, TError, TData>(queryKey, queryFn, {enabled: !!(checkInId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const libraryControllerV1SaveDraftCheckIn = (
    checkInId: string,
    checkInBodyDto: CheckInBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/draft`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: checkInBodyDto
    },
      );
    }
  


    export type LibraryControllerV1SaveDraftCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof libraryControllerV1SaveDraftCheckIn>>>
    export type LibraryControllerV1SaveDraftCheckInMutationBody = CheckInBodyDto
    export type LibraryControllerV1SaveDraftCheckInMutationError = ErrorType<unknown>

    export const useLibraryControllerV1SaveDraftCheckIn = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof libraryControllerV1SaveDraftCheckIn>>, TError,{checkInId: string;data: CheckInBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof libraryControllerV1SaveDraftCheckIn>>, {checkInId: string;data: CheckInBodyDto}> = (props) => {
          const {checkInId,data} = props ?? {};

          return  libraryControllerV1SaveDraftCheckIn(checkInId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof libraryControllerV1SaveDraftCheckIn>>, TError, {checkInId: string;data: CheckInBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const libraryControllerV1CreateCheckIn = (
    checkInBodyDto: CheckInBodyDto,
 
) => {
      return hellojoyApiInstance<unknown>(
      {url: `/v1/clinician-api/library/check-ins/create`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: checkInBodyDto
    },
      );
    }
  


    export type LibraryControllerV1CreateCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof libraryControllerV1CreateCheckIn>>>
    export type LibraryControllerV1CreateCheckInMutationBody = CheckInBodyDto
    export type LibraryControllerV1CreateCheckInMutationError = ErrorType<void | CheckInDtoV2>

    export const useLibraryControllerV1CreateCheckIn = <TError = ErrorType<void | CheckInDtoV2>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof libraryControllerV1CreateCheckIn>>, TError,{data: CheckInBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof libraryControllerV1CreateCheckIn>>, {data: CheckInBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  libraryControllerV1CreateCheckIn(data,)
        }

      return useMutation<Awaited<ReturnType<typeof libraryControllerV1CreateCheckIn>>, TError, {data: CheckInBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const libraryControllerV1PublishDraftCheckIn = (
    checkInId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/publish-draft`, method: 'post'
    },
      );
    }
  


    export type LibraryControllerV1PublishDraftCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof libraryControllerV1PublishDraftCheckIn>>>
    
    export type LibraryControllerV1PublishDraftCheckInMutationError = ErrorType<unknown>

    export const useLibraryControllerV1PublishDraftCheckIn = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof libraryControllerV1PublishDraftCheckIn>>, TError,{checkInId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof libraryControllerV1PublishDraftCheckIn>>, {checkInId: string}> = (props) => {
          const {checkInId} = props ?? {};

          return  libraryControllerV1PublishDraftCheckIn(checkInId,)
        }

      return useMutation<Awaited<ReturnType<typeof libraryControllerV1PublishDraftCheckIn>>, TError, {checkInId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const libraryControllerV1ToggleCheckInIsCore = (
    checkInId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/toggle-core`, method: 'put'
    },
      );
    }
  


    export type LibraryControllerV1ToggleCheckInIsCoreMutationResult = NonNullable<Awaited<ReturnType<typeof libraryControllerV1ToggleCheckInIsCore>>>
    
    export type LibraryControllerV1ToggleCheckInIsCoreMutationError = ErrorType<unknown>

    export const useLibraryControllerV1ToggleCheckInIsCore = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof libraryControllerV1ToggleCheckInIsCore>>, TError,{checkInId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof libraryControllerV1ToggleCheckInIsCore>>, {checkInId: string}> = (props) => {
          const {checkInId} = props ?? {};

          return  libraryControllerV1ToggleCheckInIsCore(checkInId,)
        }

      return useMutation<Awaited<ReturnType<typeof libraryControllerV1ToggleCheckInIsCore>>, TError, {checkInId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const contentSuggestionControllerDismiss = (
    contentSuggestionId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/content-suggestion/${contentSuggestionId}/dismiss`, method: 'patch'
    },
      );
    }
  


    export type ContentSuggestionControllerDismissMutationResult = NonNullable<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>>
    
    export type ContentSuggestionControllerDismissMutationError = ErrorType<unknown>

    export const useContentSuggestionControllerDismiss = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>, TError,{contentSuggestionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>, {contentSuggestionId: string}> = (props) => {
          const {contentSuggestionId} = props ?? {};

          return  contentSuggestionControllerDismiss(contentSuggestionId,)
        }

      return useMutation<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>, TError, {contentSuggestionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const contentSuggestionControllerAccept = (
    contentSuggestionId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/content-suggestion/${contentSuggestionId}/accept`, method: 'patch'
    },
      );
    }
  


    export type ContentSuggestionControllerAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>>
    
    export type ContentSuggestionControllerAcceptMutationError = ErrorType<unknown>

    export const useContentSuggestionControllerAccept = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>, TError,{contentSuggestionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>, {contentSuggestionId: string}> = (props) => {
          const {contentSuggestionId} = props ?? {};

          return  contentSuggestionControllerAccept(contentSuggestionId,)
        }

      return useMutation<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>, TError, {contentSuggestionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const contentSuggestionControllerSubmitFeedback = (
    contentSuggestionId: string,
    submitFeedbackDto: SubmitFeedbackDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/content-suggestion/${contentSuggestionId}/feedback`, method: 'patch',
      headers: {'Content-Type': 'application/json'},
      data: submitFeedbackDto
    },
      );
    }
  


    export type ContentSuggestionControllerSubmitFeedbackMutationResult = NonNullable<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>>
    export type ContentSuggestionControllerSubmitFeedbackMutationBody = SubmitFeedbackDto
    export type ContentSuggestionControllerSubmitFeedbackMutationError = ErrorType<unknown>

    export const useContentSuggestionControllerSubmitFeedback = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>, TError,{contentSuggestionId: string;data: SubmitFeedbackDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>, {contentSuggestionId: string;data: SubmitFeedbackDto}> = (props) => {
          const {contentSuggestionId,data} = props ?? {};

          return  contentSuggestionControllerSubmitFeedback(contentSuggestionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>, TError, {contentSuggestionId: string;data: SubmitFeedbackDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const organizationControllerGetOrganizationConsentSetting = (
    organizationId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<ConsentSettingDTO>(
      {url: `/v1/clinician-api/organization/${organizationId}/consent-setting`, method: 'get', signal
    },
      );
    }
  

export const getOrganizationControllerGetOrganizationConsentSettingQueryKey = (organizationId: string,) => [`/v1/clinician-api/organization/${organizationId}/consent-setting`];

    
export type OrganizationControllerGetOrganizationConsentSettingQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>>
export type OrganizationControllerGetOrganizationConsentSettingQueryError = ErrorType<unknown>

export const useOrganizationControllerGetOrganizationConsentSetting = <TData = Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError = ErrorType<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationControllerGetOrganizationConsentSettingQueryKey(organizationId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>> = ({ signal }) => organizationControllerGetOrganizationConsentSetting(organizationId, signal);

  const query = useQuery<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError, TData>(queryKey, queryFn, {enabled: !!(organizationId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const organizationControllerUpdateOrganizationConsentSetting = (
    organizationId: string,
    patchConsentSettingDTO: PatchConsentSettingDTO,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/organization/${organizationId}/consent-setting`, method: 'patch',
      headers: {'Content-Type': 'application/json'},
      data: patchConsentSettingDTO
    },
      );
    }
  


    export type OrganizationControllerUpdateOrganizationConsentSettingMutationResult = NonNullable<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>>
    export type OrganizationControllerUpdateOrganizationConsentSettingMutationBody = PatchConsentSettingDTO
    export type OrganizationControllerUpdateOrganizationConsentSettingMutationError = ErrorType<unknown>

    export const useOrganizationControllerUpdateOrganizationConsentSetting = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>, TError,{organizationId: string;data: PatchConsentSettingDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>, {organizationId: string;data: PatchConsentSettingDTO}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  organizationControllerUpdateOrganizationConsentSetting(organizationId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>, TError, {organizationId: string;data: PatchConsentSettingDTO}, TContext>(mutationFn, mutationOptions)
    }
    
