import React from 'react'
import { Button, Text } from '@chakra-ui/react'

const FeedbackButton = ({
  value,
  label,
  icon: Icon,
  color = "#2D54E8",
  onClick,
  isActive,
}: {
  value: string;
  label: string;
  icon: React.ElementType;
  color?: string;
  onClick: () => void;
  isActive: boolean;
}) => {
  return (
    <Button
      flex="1"
      m="0"
      variant="ghost"
      _focus={{ outline: 'none' }}
      gap="6px"
      onClick={onClick}
      opacity={isActive ? 1 : 0.3}
    >
      <Icon fill={color} size="20" />
      <Text color="primary" fontSize="12px">
        {label}
      </Text>
    </Button>
  )
}

export default FeedbackButton
