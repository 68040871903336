import React from 'react'
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption
} from '@chakra-ui/react'
import { ChevronDown } from '@components/icons'
import { keyBy } from 'lodash'

type Props = {
  optionValue: string
  onChange: (value: string | string[]) => void
  progressNoteTypes: any
}

export const NoteTypeDropdown = ({
  optionValue,
  onChange,
  progressNoteTypes
}: Props) => {
  const noteTypesById = keyBy(progressNoteTypes, 'id')
  const therapistNotes = progressNoteTypes.filter(
    (o: any) => !o.hideFromDefaultOptions && o.noteGroup === 'therapists'
  )

  const prescriberNotes = progressNoteTypes.filter(
    (o: any) => !o.hideFromDefaultOptions && o.noteGroup === 'prescribers'
  )

  return (
    <Menu closeOnSelect>
      <MenuButton
        w="100%"
        as={Button}
        value={optionValue}
        border="1px solid rgb(228, 229, 230)"
        borderColor="#C9C9C9"
        m={0}
        color="black"
        background="white"
        borderRadius="6px"
        height="48px"
        paddingInlineStart={4}
        rightIcon={<ChevronDown />}
        textAlign="start"
      >
        {noteTypesById[optionValue as keyof typeof noteTypesById]?.display}
      </MenuButton>
      <MenuList
        paddingTop="0"
        maxHeight="300px"
        width="400px"
        overflowY="scroll"
        borderRadius="0px"
        zIndex={1000}
      >
        {therapistNotes.length > 0 && (
          <MenuOptionGroup
            defaultValue={optionValue}
            backgroundColor="primary"
            margin="0"
            color="white"
            height="50px"
            title="For Therapists"
            type="radio"
            paddingLeft="20px"
            alignItems="center"
            display="flex"
            onChange={onChange}
          >
            {therapistNotes.map(
              (type: { id: string; display: string }, index: number) => (
                <MenuItemOption
                  value={type.id}
                  paddingLeft="20px"
                  key={`note-type-option-therapists-${index}`}
                >
                  {type.display}
                </MenuItemOption>
              )
            )}
          </MenuOptionGroup>
        )}
        {prescriberNotes.length > 0 && (
          <MenuOptionGroup
            defaultValue={optionValue}
            backgroundColor="primary"
            margin="0"
            color="white"
            height="50px"
            title="For Prescribers"
            type="radio"
            paddingLeft="20px"
            alignItems="center"
            display="flex"
            onChange={onChange}
          >
            {prescriberNotes.map(
              (type: { id: string; display: string }, index: number) => (
                <MenuItemOption
                  value={type.id}
                  paddingLeft="20px"
                  key={`note-type-option-prescribers-${index}`}
                >
                  {type.display}
                </MenuItemOption>
              )
            )}
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  )
}
