import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Heading, Text } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { isMobile } from 'react-device-detect'
import { useMutation, useQueryClient } from 'react-query'
import { useStoreActions } from 'easy-peasy'
import { endpoints } from '@api'
import { useStyles } from '../styles'

const UnarchivePatientModal = ({ patient, isOpen, onClose }) => {
  const queryClient = useQueryClient()
  const [errorMsg, setErrorMsg] = useState(null)
  const classes = useStyles()

  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const {
    mutate: executeUnarchivePatient,
    isLoading: isUnarchiveLoading
  } = useMutation(endpoints.postCliniciansUsersUnarchive.request, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(endpoints.getPatientList.getCacheId()),
        queryClient.invalidateQueries(
          endpoints.getClinicianUserActiveAssessments.getCacheId()
        ),
        queryClient.invalidateQueries(
          endpoints.getCheckInModulesForUser.getCacheId()
        ),
        queryClient.invalidateQueries(
          endpoints.getUnreadNotificationCount.getCacheId()
        )
      ])
      await queryClient.invalidateQueries(
        endpoints.getClinicianUserAccount.getCacheId()
      )
      queryClient.invalidateQueries([
        endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
        patient.id
      ])
      setSnackbarMessage({
        variant: 'success',
        message: `${patient.first_name} ${patient.last_name} was successfully unarchived!`
      })
      onClose()
    },
    onError: () =>
      setErrorMsg(
        'Oops, something went wrong. Please refresh the page and try again.'
      )
  })

  const submit = () => executeUnarchivePatient({ clientId: patient.id })

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth={true}
      fullScreen={isMobile}
    >
      <div className={classes.dialog}>
        <DialogTitle className={classes.centerText}>
          <Heading className={classes.centerText}>Unarchive Client</Heading>
        </DialogTitle>
        <DialogContent className="content">
          <Text align="center" mb="3">
            Are you sure you&apos;d like to unarchive this client?
          </Text>
          <Text align="center">
            Client will be re-assigned your clinic&apos;s default assessments.
            You can adjust these by navigating to Assessments in the client
            preferences menu.
          </Text>
          {errorMsg && <p className="errorMessage">{errorMsg}</p>}
          <div className={classes.buttonContainer}>
            <Button onClick={onClose} size="lg" variant="outline" width="100%">
              Cancel
            </Button>
            <Button
              width="100%"
              isLoading={isUnarchiveLoading}
              size="lg"
              onClick={submit}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default UnarchivePatientModal
