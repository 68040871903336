import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { AddCircleIcon, CheckCircle } from '@blueprinthq/joy'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Input,
  Text,
  VStack,
  HStack,
  Link,
  Container,
  Stack,
  Divider,
  Button
} from '@chakra-ui/react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { MeasureTypes } from '@constants/measureTypes'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { useTreatmentPlan } from '@hooks'
import { useAssistPanelContext } from '../../context'
import { AssessmentProvider, useAssessmentLibraryContext } from './context'

const AssessmentRow = ({
  assessmentId,
  clinicAssessmentId,
  title,
  subtitle,
  numQuestions,
  previewUrl,
  takeNowUrl,
  isSelected,
  onSelect,
  onTakeNow,
  isAssigned,
  isLast
}: any) => {
  const icon = useMemo(() => {
    let i = (
      <AddCircleIcon
        onClick={() => onSelect(clinicAssessmentId)}
        role="button"
        aria-label="Select Assessment"
        tabIndex={0}
        cursor="pointer"
        fill="primary"
        sx={{ marginLeft: '0'}}
      />
    )

    if (isAssigned) {
      i = <CheckCircle w="24px" h="24px" fill="#E4E5E6" />
    } else if (isSelected) {
      i = (
        <CheckCircle
          onClick={() => onSelect(clinicAssessmentId)}
          w="24px"
          h="24px"
          role="button"
          aria-label="Deselect Assessment"
          tabIndex={0}
          cursor="pointer"
          fill="primary"
          sx={{ marginLeft: '0'}}
        />
      )
    }

    return i
  }, [assessmentId, isAssigned, isSelected, onSelect])

  return (
    <Stack spacing="0">
      <HStack py="8px" justifyContent="space-between">
        <Stack spacing="0">
          <Text fontWeight="bold">{title}</Text>
          <Text color="dark_gray">{subtitle} | {numQuestions} questions</Text>
        </Stack>
        <HStack gap="12px" spacing="0">
          <Button
            m="0"
            as={Link}
            variant="link"
            textDecoration="none"
            href={takeNowUrl}
            isExternal
            _hover={{ textDecoration: 'none' }}
          >
            Take Now
          </Button>
          {icon}
        </HStack>
      </HStack>
      {!isLast && <Divider m="0"  />}
    </Stack>
  )
}

const Library = () => {
  const {
    filteredAssessments,
    setSearchText,
    searchText,
    onSelect,
    selectedAssessments,
    countOfAssessmentsToAssign,
    assignClientAssessmentsMutation,
    isClientEnrolled,
    assignableAssessments
  } = useAssessmentLibraryContext()
  const { setOverlayPanel } = useAssistPanelContext()
  const onAssign = async () => {
    if (isClientEnrolled) {
      await assignClientAssessmentsMutation.mutateAsync()
      setOverlayPanel(null)
    } else {
      const assessmentIds = assignableAssessments.filter((a: any) => selectedAssessments.includes(a.id)).map((a: any) => a.assessment_id)
      setOverlayPanel("ASSIGN_ASSESSMENT", { assessmentIds })
    }
  }

  return (
    <Stack flex="1" spacing="0" overflowY="hidden">
      <Flex
        h="100px"
        align="center"
        flex="1"
        borderBottom="1px solid"
        borderColor="pale_gray"
        p="24px"
      >
        <Input
          placeholder="Search assessments"
          onChange={e => setSearchText(e.currentTarget.value)}
          value={searchText}
        />
      </Flex>
      <Stack px="24px" h="calc(100vh - 358px)" overflowY="scroll" spacing="0">
        {filteredAssessments.map((a, index) => (
          <AssessmentRow
            {...a}
            onSelect={onSelect}
            isLast={index === filteredAssessments.length - 1}
          />
        ))}
      </Stack>
      <HStack
        p="24px"
        borderTop="1px solid"
        borderColor="pale_gray"
        position="sticky"
      >
        <Button
          m="0"
          disabled={!countOfAssessmentsToAssign}
          size="lg"
          isFullWidth
          isLoading={assignClientAssessmentsMutation.isLoading}
          onClick={onAssign}
        >
          {countOfAssessmentsToAssign ? (
            `Assign ${countOfAssessmentsToAssign} Assessments`
          ) : (
            'Assign Assessments'
          )}
        </Button>
      </HStack>
    </Stack>
  )
}

const WrappedLibrary = ({ client }: { client: any }) => {
  return (
    <AssessmentProvider client={client}>
      <Library />
    </AssessmentProvider>
  )
}

export default WrappedLibrary
