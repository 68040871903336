import { usePermissions } from './use-permissions'
import { readClients } from '../constants/permissions'
import { useStoreState } from 'easy-peasy'
import { useExperienceManager } from '@hooks'
import { FlagsmithFeatures } from '@constants/flagsmith'
import flagsmith from 'flagsmith'

export function useNavLinks(isLegacyPlan) {
  const { hasPermission } = usePermissions()
  const { user } = useStoreState(state => state.auth)
  const {
    isEvidenceBasedCareEnabled,
    isDocumentationAutomationEnabled
  } = useExperienceManager()
  const isClinical = readClients.every(perm => hasPermission(perm))
  const hasAdminRole = user?.roles.some(
    role =>
      role.name === 'clinicadmin' ||
      role.name === 'superadmin' ||
      role.name === 'orgadmin'
  )

  const showClientConsent = flagsmith.hasFeature(
    FlagsmithFeatures.CLIENT_CONSENT
  )

  const linkGroups = [
    {
      group: 'My Account',
      links: [
        {
          key: 'profile',
          display: 'Profile',
          path: '/settings/account',
          enabled: true
        },
        {
          key: 'notifications',
          display: 'Notifications',
          path: '/settings/notifications',
          enabled: isClinical && isEvidenceBasedCareEnabled
        },
        {
          key: 'assessment_frequencies',
          display: 'Assessments',
          path: '/settings/assessments',
          enabled: isClinical && isEvidenceBasedCareEnabled
        }
      ]
    },
    {
      group: 'Organization',
      links: [
        {
          key: 'general',
          display: 'General',
          path: '/settings/general',
          enabled: isClinical
        },
        {
          key: 'users',
          display: 'Users',
          path: '/settings/users',
          enabled: isClinical
        },
        {
          key: 'programs',
          display: 'Programs',
          path: '/settings/programs',
          enabled: isClinical && isEvidenceBasedCareEnabled
        },
        {
          key: 'progress_notes',
          display: 'Progress Notes',
          path: '/settings/progress-notes',
          enabled: isDocumentationAutomationEnabled
        },
        {
          key: 'safety-net',
          display: 'Safety Net',
          path: '/settings/safety-net',
          enabled: isClinical && isEvidenceBasedCareEnabled
        },
        {
          key: 'workflows',
          display: 'Workflows',
          path: '/settings/workflows',
          enabled: isClinical && isEvidenceBasedCareEnabled
        },
        {
          key: 'consent',
          display: 'Client Consent',
          path: '/settings/consent',
          enabled: hasAdminRole && showClientConsent
        },
        {
          key: 'billing',
          display: 'Billing',
          path: '/settings/billing',
          enabled: hasAdminRole
        }
      ]
    }
  ]

  return {
    settingsLinkGroups: linkGroups
      .map(g => ({
        ...g,
        links: g.links.filter(l => l.enabled)
      }))
      .filter(g => g.links.length > 0)
  }
}
