import React, { useMemo, useState, useCallback, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Input,
  Text,
  VStack,
  Box,
  Divider
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useQuery } from 'react-query'

import { endpoints } from '@api'

interface AssignWorksheetModalProps {
  clientId: string
  isOpen: boolean
  handleCloseModal: () => void
}

export const AssignWorksheetModal = ({
  clientId,
  isOpen,
  handleCloseModal
}: AssignWorksheetModalProps) => {
  const [selectedWorksheets, setSelectedWorksheets] = useState([])
  const [searchText, setSearchText] = useState('')

  const { data: worksheets }: any = useQuery(
    [endpoints.getCheckInModulesForUser.getCacheId(), clientId],
    // @ts-ignore
    () => endpoints.getCheckInModulesForUser.request({ id: clientId }),
    {
      // @ts-ignore
      placeholderData: []
    }
  )

  const modifiedData = useMemo(() => {
    return worksheets
      .map((worksheet: any) => ({
        ...worksheet,
        // @ts-ignore
        isSelected: selectedWorksheets.includes(worksheet.id)
      }))
      .filter((worksheet: any) =>
        `${worksheet.title.toLowerCase()} ${worksheet.subtitle.toLowerCase()}`.includes(
          searchText.toLowerCase()
        )
      )
  }, [worksheets, selectedWorksheets, searchText])

  return (
    <>
      <Modal
        size="2xl"
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            textTransform="capitalize"
            fontSize="24px"
            borderBottom="1px solid #E4E5E6"
          >
            Assign Worksheets
          </ModalHeader>
          <ModalCloseButton mr="xsmall" mt="10px" borderRadius="25px" />
          <ModalBody pt="0">
            <Flex h="100px" align="center">
              <Input
                placeholder={`Search worksheets`}
                onChange={e => setSearchText(e.currentTarget.value)}
                value={searchText}
              />
            </Flex>
            <VStack divider={<Divider color="pale_gray" />}>
              {worksheets.map((worksheet: any) => (
                <Flex justify="space-between" key={worksheet.id} w="100%">
                  <Box ml="medium">{worksheet.title}</Box>
                  <Box>
                    <Button variant="link">Add</Button>
                  </Box>
                </Flex>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export const AssignWorksheetModalConnected = () => {
  const { isOpen, clientId } = useStoreState(
    state => state.modals.assignWorksheets
  )
  const handleCloseModal = useStoreActions(
    //@ts-ignore
    actions => actions.modals.assignWorksheets.closeModal
  )

  return clientId ? (
    <AssignWorksheetModal
      isOpen={isOpen}
      clientId={clientId}
      handleCloseModal={handleCloseModal}
    />
  ) : null
}
