import React from 'react'
import { Box, Flex, HStack, Text, IconButton } from '@chakra-ui/react'
import { X as XIcon, ArrowLeft } from '@components/icons'

type PanelHeaderPropsT = {
  title: string
  onClose: () => void
  icon?: React.ReactNode
  p?: string
}

const PanelHeader: React.FC<PanelHeaderPropsT> = ({
  title,
  onClose,
  icon,
  p
}) => (
  <HStack h="72px" justifyContent="space-between" p={p || '24px'}>
    <Flex>
      {icon && <Box mr="8px">{icon}</Box>}
      <Text fontWeight="bold">{title}</Text>
    </Flex>
    <Flex>
      <IconButton
        display="flex"
        variant="unstyled"
        icon={<XIcon fill="black" />}
        aria-label="Close"
        onClick={onClose}
      />
    </Flex>
  </HStack>
)

type OverlayPanelHeaderPropsT = {
  title: string
  onBack: () => void
}

export const OverlayPanelHeader: React.FC<OverlayPanelHeaderPropsT> = ({ title, onBack }) => (
  <HStack 
    h="72px" 
    justifyContent="space-between" 
    p="24px"
    border="1px solid"
    borderColor="pale_gray"
  >
    <Flex flex="1" >
      <IconButton
        m="0"
        minW="0"
        icon={<ArrowLeft fill="black" />}
        aria-label="Back"
        variant="ghost"
        _focus={{ outline: 'none' }}
        onClick={onBack}
      />
      <Text 
        justifyContent="center" 
        fontWeight="bold"
        display="flex"
        alignItems="center"
        flex="1"
      >
        {title}
      </Text>
      <Flex w="40px" />
    </Flex>
  </HStack>)

export default PanelHeader
