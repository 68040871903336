import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { DateTime } from 'luxon'

interface HeaderProps {
  date: string
  name: string
}

export function Header({ date, name }: HeaderProps) {
  return (
    <Flex direction={'column'} width={'100%'}>
      <Text
        fontSize={'16px'}
        fontWeight={'bold'}
        color={'#757575'}
        letterSpacing={'widest'}
        textTransform="uppercase"
      >
        {`SESSION - ${date}`}
      </Text>
      <Flex direction={'row'} alignItems={'center'}>
        <Text fontSize={'42px'} fontWeight={'bold'} letterSpacing={'wide'}>
          {name}
        </Text>
      </Flex>
    </Flex>
  )
}
