import React from 'react'
import {
  Box,
  Text,
  SimpleGrid,
  Button,
  HStack,
  useToast
} from '@chakra-ui/react'
import { useStoreActions } from 'easy-peasy'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { useExperienceManager } from '@hooks'
import moment from 'moment'
import { MicAIOn } from '@components/icons'
import { formatDollarAmount } from '@utilities'
import * as clinicianTracking from '@lib/clinician-tracking'

export const DocAutomationBox = ({
  billingData,
  showNewBillingFlow,
  selectedClinic
}: {
  billingData: any
  showNewBillingFlow: boolean
  selectedClinic: any
}) => {
  const hasActiveDocAutomationPlan = Boolean(
    billingData?.activeDocAutomationPlan
  )
  const hasCanceledDocAutomationPlan = Boolean(
    billingData?.canceledDocAutomationSubscription
  )
  const hasAnyDocAutomationPlan =
    hasActiveDocAutomationPlan || hasCanceledDocAutomationPlan

  const { isDocumentationAutomationFreePlan } = useExperienceManager()

  const toast = useToast()
  const queryClient = useQueryClient()

  const openDAModal = useStoreActions(
    (actions: any) =>
      actions.modals.documentationAutomationMarketingModal.openModal
  )
  const openManageScribePlanModal = useStoreActions(
    (actions: any) => actions.modals.manageScribePlan.openModal
  )
  const openCancelIndividualPlanModal = useStoreActions(
    (actions: any) => actions.modals.cancelIndividualPlan.openModal
  )

  const { mutateAsync: reactivatePlan, isLoading } = useMutation(
    endpoints.patchIndividualBillingPlan.request,
    {
      onSuccess() {
        toast({
          title: `Your Documentation Automation plan has been reactivated.`,
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        queryClient.invalidateQueries(
          endpoints.getPaywallValidation.getCacheId()
        )
        queryClient.invalidateQueries(endpoints.getClinicBilling.getCacheId())
      },
      onError(err: any) {
        toast({
          title: `We ran into an issue reactivating your Documentation Automation plan.`,
          description:
            'Please try again or contact support at help@blueprint-health.com.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        console.error(err)
      }
    }
  )

  const renderHasAnyDAPlanContent = () => {
    const plan =
      billingData?.activeDocAutomationPlan ||
      billingData?.canceledDocAutomationSubscription

    return (
      <>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 0'
          }}
        >
          <Text fontSize={'16px'}>Plan</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {plan.planName +
              ` (${plan.billingInterval === 'month' ? 'Monthly' : 'Annual'})`}
          </Text>
          {showNewBillingFlow ? (
            renderManageAndCancel(plan)
          ) : (
            <Text
              _focus={{ outline: 'none' }}
              fontSize={'16px'}
              color={'primary'}
              _hover={{
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => {
                if (isDocumentationAutomationFreePlan) {
                  openManageScribePlanModal({ clinicId: selectedClinic.id })
                } else {
                  //@ts-ignore
                  window.Intercom(
                    'showNewMessage',
                    "Hi, I'd like to change my Documentation Automation plan."
                  )
                }
              }}
            >
              {isDocumentationAutomationFreePlan
                ? 'Upgrade'
                : 'Contact support'}
            </Text>
          )}
        </Box>
        {hasActiveDocAutomationPlan
          ? renderActivePlanContent()
          : renderCanceledPlanContent()}
      </>
    )
  }

  const renderCanceledPlanContent = () => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          {billingData?.canceledDocAutomationSubscription.endedAt ? null : (
            <>
              <Text fontSize={'16px'}>Notes used</Text>
              <Text fontSize={'16px'} fontWeight={'bold'}>
                {billingData?.canceledDocAutomationSubscription.sessionsUsed +
                  '/' +
                  billingData?.canceledDocAutomationSubscription
                    .sessionsAllotted}
              </Text>
            </>
          )}
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '220px',
            justifyContent: 'center'
          }}
        >
          {billingData?.canceledDocAutomationSubscription?.endedAt ? (
            <Button
              onClick={() =>
                openManageScribePlanModal({ clinicId: selectedClinic.id })
              }
            >
              Choose Plan
            </Button>
          ) : (
            <Button
              isLoading={isLoading}
              onClick={() => {
                clinicianTracking.trackEvent(
                  `Billing page doc automation plan -> Clicked reactivate canceled plan`
                )
                return reactivatePlan({
                  data: {
                    action: 'reactivate',
                    subscriptionId:
                      billingData?.canceledDocAutomationSubscription
                        ?.subscriptionId,
                    planType: 'da'
                  }
                })
              }}
            >
              Reactivate
            </Button>
          )}
        </Box>
      </>
    )
  }

  const renderActivePlanContent = () => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          <Text fontSize={'16px'}>Next invoice</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {billingData.nextDueDocAutomationInvoice &&
              moment
                .unix(
                  billingData.nextDueDocAutomationInvoice
                    .next_payment_attempt !== null
                    ? billingData.nextDueDocAutomationInvoice
                        .next_payment_attempt
                    : billingData.nextDueDocAutomationInvoice.period_end
                )
                .format('MMM D, YYYY')}
          </Text>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          <Text fontSize={'16px'}>Invoice total</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {billingData?.nextDueDocAutomationInvoice &&
              formatDollarAmount(
                billingData?.nextDueDocAutomationInvoice.amount_due / 100
              )}
          </Text>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '160px'
          }}
        >
          <Text fontSize={'16px'}>Notes used</Text>
          <Text fontSize={'16px'} fontWeight={'bold'}>
            {billingData?.activeDocAutomationPlan.sessionsUsed +
              '/' +
              billingData?.activeDocAutomationPlan.sessionsAllotted}
          </Text>
        </Box>
      </>
    )
  }

  const renderManageAndCancel = (plan: any) => (
    <>
      {hasCanceledDocAutomationPlan && !hasActiveDocAutomationPlan ? (
        plan.endedAt ? (
          <Text>
            Expired on {moment.unix(plan.endedAt).format('MMMM D, YYYY')}
          </Text>
        ) : (
          <Text color="light_gray">
            Expires on {moment.unix(plan.cancelAt).format('MMMM D, YYYY')}
          </Text>
        )
      ) : billingData?.canCancel ? (
        <HStack spacing="small">
          <Text
            _focus={{ outline: 'none' }}
            fontSize={'16px'}
            color={'primary'}
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() =>
              openManageScribePlanModal({ clinicId: selectedClinic.id })
            }
          >
            Manage Plan
          </Text>
          <Text
            _focus={{ outline: 'none' }}
            fontSize={'16px'}
            color={'#EB5164'}
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() =>
              openCancelIndividualPlanModal({
                planType: 'scribe',
                planName: plan.planName,
                clinicId: selectedClinic.id,
                subscriptionId: plan.subscriptionId
              })
            }
          >
            Cancel Plan
          </Text>
        </HStack>
      ) : (
        <Text
          _focus={{ outline: 'none' }}
          fontSize={'16px'}
          color={'primary'}
          _hover={{
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
          onClick={() => {
            if (isDocumentationAutomationFreePlan) {
              openManageScribePlanModal({ clinicId: selectedClinic.id })
            } else {
              //@ts-ignore
              window.Intercom(
                'showNewMessage',
                "Hi, I'd like to change my Documentation Automation plan."
              )
            }
          }}
        >
          {isDocumentationAutomationFreePlan ? 'Upgrade' : 'Contact support'}
        </Text>
      )}
    </>
  )

  const renderHasNoDAPlansContent = () => (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 0'
        }}
      >
        <Text fontSize={'16px'} fontWeight={'bold'}>
          Documentation Automation
        </Text>
        <Text fontSize={'16px'} fontWeight={430}>
          Use an audio recorder during session to automate progress notes.
        </Text>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '220px'
        }}
      >
        <Button onClick={openDAModal}>Try for free</Button>
      </Box>
    </>
  )

  return (
    <SimpleGrid
      mb={'small'}
      columns={[1, 1, hasAnyDocAutomationPlan ? 5 : 3]}
      p={'24px'}
      borderRadius={'4px'}
      border={'1px solid #E4E5E6'}
      display={{ base: '', md: 'flex' }}
      justifyContent={'center'}
      gap={'24px'}
      alignSelf={'stretch'}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <MicAIOn fill={'#2D54E8'} width={'40px'} height={'40px'} />
      </Box>
      {hasAnyDocAutomationPlan
        ? renderHasAnyDAPlanContent()
        : renderHasNoDAPlansContent()}
    </SimpleGrid>
  )
}
