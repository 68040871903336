import React from 'react'
import { endpoints } from '@api'
import { usePermissions } from '@hooks'
import { useStoreState } from 'easy-peasy'
import { createContext, useContext, useState } from 'react'
import { useQuery } from 'react-query'

interface ClinicSelectContextProps {
  clinics: any[]
  selectedClinic?: any | null
  setSelectedClinic: (clinic: any) => void
}

export const ClinicSelectContext = createContext<ClinicSelectContextProps>(
  {} as ClinicSelectContextProps
)

interface ClinicSelectProviderProps {
  children: React.ReactNode
}

export const ClinicSelectProvider = ({
  children
}: ClinicSelectProviderProps) => {
  const { user } = useStoreState(state => state.auth)
  const { hasPermission } = usePermissions()

  const [selectedClinic, setSelectedClinic] = useState(user?.clinic)

  const { data: clinics } = useQuery(
    [
      endpoints.getOrganizationClinics.getCacheId(),
      selectedClinic && selectedClinic.organization_id
    ],
    () =>
      endpoints.getOrganizationClinics.request({
        organizationId: user.clinic.organization_id
      }),
    {
      initialData: [],
      enabled: hasPermission('read:org:clinics:list'),
      onSuccess(data) {
        // default to the currently selected clinic, otherwise use the user's clinic
        setSelectedClinic(
          // @ts-ignore
          data.find(clinic => clinic.id === selectedClinic?.id) ||
          // @ts-ignore
            data.find(clinic => clinic.id === user.clinic.id)
        )
      }
    }
  )

  return (
    <ClinicSelectContext.Provider
      value={{
        // @ts-ignore
        clinics,
        selectedClinic,
        setSelectedClinic
      }}
    >
      {children}
    </ClinicSelectContext.Provider>
  )
}

export const useClinicSelect = () => useContext(ClinicSelectContext)
