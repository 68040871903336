import React from 'react'
import { useQuery } from 'react-query'
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Select,
  CloseButton,
  Radio,
  RadioGroup,
  Link,
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import { TextField } from '@blueprinthq/joy'
import { Formik, Form, Field, FieldProps, FieldArray, FormikProps } from 'formik'
import { useCadenceOptions } from '../use-cadence-options'
import { Clipboard } from '@components/icons'
import { endpoints } from '@api'
import * as Yup from 'yup'
import { YupPhone } from '@constants/formValidation'
import { formatCadence } from '@utilities'

interface AssignableAssessment {
  clinicAssessmentId: string
  cadenceValue: number
  cadenceUnit: string
  suggestionId?: string
}

interface AssistEnrollmentFormProps {
  assessments: AssignableAssessment[]
  client: { id: string }
  onSubmit: (values: any) => void
  children: (props: any) => React.ReactNode
}

interface AssistEnrollmentFormValues {
  deliveryMethod: string
  email: string
  phone: string
  assessments: AssignableAssessment[]
}

interface AssistEnrollmentFormFieldsProps {
  client: { first_name: string }
  formikProps: any
  assessments: any[]
}

const validationSchema = Yup.object().shape({
  deliveryMethod: Yup.string().required('Required'),
  email: Yup.string().when('deliveryMethod', {
    is: (value: string) => value === 'email' || value === 'both',
    then: Yup.string().email('Invalid email').required('Email is required'),
  }),
  phone: Yup.string().when('deliveryMethod', {
    is: (value: string) => value === 'text' || value === 'both',
    then: YupPhone.required('Phone is required'),
  }),
  assessments: Yup.array().required('Required'),
})


export const AssistEnrollmentForm = ({
  assessments,
  client,
  onSubmit,
  children
}: AssistEnrollmentFormProps) => {
  const {
    isLoading: areAssessmentsLoading,
    data: clientAssessmentsGroupedByAssignee,
  } = useQuery(
    [endpoints.getAllAssessmentsForClientByAssignee.getCacheId(), client.id],
    () =>
      endpoints.getAllAssessmentsForClientByAssignee.request({ id: client.id }),
    {
      placeholderData: [{ assessments: [] }],
      enabled: !!client.id
    }
  )

  // @ts-ignore
  const allAssessments = clientAssessmentsGroupedByAssignee[0].assessments

  if (areAssessmentsLoading) return null

  const initialValues = {
    deliveryMethod: 'email',
    email: '',
    phone: '',
    assessments: assessments.map((a: any) => ({
      clinicAssessmentId: a.clinicAssessmentId,
      cadence: formatCadence(a.cadenceValue, a.cadenceUnit),
      suggestionId: a.suggestionId
    }))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps: FormikProps<AssistEnrollmentFormValues>) => (
        <Form>
           {children({ formikProps, allAssessments })}
        </Form>
      )}
    </Formik>
  )
}

export const AssistEnrollmentFormFields = ({
  client,
  formikProps,
  assessments = []
}: AssistEnrollmentFormFieldsProps) => {
  const { cadenceOptions } = useCadenceOptions()
  const { values, errors, setFieldValue, touched } = formikProps

  return (
    <Box>
      <Text fontWeight="bold" fontSize="24px" mb="13px">
        Enroll Client and Assign Assessments
      </Text>
      <Text fontSize="16px" mb="29px">
        Choose how and when to invite {client.first_name} to Blueprint and assign their first assessments. Once you click "Enroll and Assign", {client.first_name} will be contacted immediately.
      </Text>
      <VStack align="stretch" spacing="32px">
        <Box>
          <Text fontWeight="bold" mb="16px">
            Delivery Method:
          </Text>
          <RadioGroup
            name="deliveryMethod"
            value={values.deliveryMethod}
            onChange={(value) => setFieldValue('deliveryMethod', value)}
            mb={8}
          >
            <HStack spacing={5}>
              <Field as={Radio} name="deliveryMethod" value="email">
                Email
              </Field>
              <Field as={Radio} name="deliveryMethod" value="text">
                Text Message
              </Field>
              <Field as={Radio} name="deliveryMethod" value="both">
                Both
              </Field>
            </HStack>
          </RadioGroup>
          <HStack gap={4} justifyContent="space-between" mb={4}>
            {['email', 'both'].includes(values.deliveryMethod) && (
              <Field name="email">
                {({ field }: FieldProps) => (
                  <TextField
                    label="email"
                    simple
                    placeholder="Email Address"
                    isInvalid={errors.email && touched.email}
                    errorText={errors.email}
                    {...field}
                  />
                )}
              </Field>
            )}
            {['text', 'both'].includes(values.deliveryMethod) && (
              <Field name="phone">
                {({ field }: FieldProps) => (
                  <Box width="100%">
                    <InputMask
                      mask="(999) 999-9999"
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                    >
                      {() => (
                        <TextField
                          {...field}
                          simple
                          leftIcon={'+1'}
                          type="tel"
                          errorText={errors[field.name]}
                          isInvalid={
                            errors[field.name] && touched[field.name]
                          }
                          label="Phone Number"
                        />
                      )}
                    </InputMask>
                  </Box>
                )}
              </Field>
            )}
          </HStack>
          <Box>
            <Text>Want to see what it will look like for your client? <Link color="primary">View preview</Link></Text>
          </Box>
        </Box>
        <Box>
          <Text fontWeight="bold" mb="16px">
            Assessments:
          </Text>
          <VStack align="stretch" spacing="13px">
            <FieldArray
              name="assessments"
              render={arrayHelpers => {
                return values.assessments.map((assignment: AssignableAssessment, index: number) => {
                  const assessment = assessments.find((a: any) => assignment.clinicAssessmentId === a.id)
                  return (
                    <Flex
                      key={assignment.clinicAssessmentId}
                      flexDir="row"
                      justifyContent="left"
                      gap="small"
                      border="1px solid"
                      borderColor="pale_gray"
                      borderRadius="4px"
                      p="small"
                    >
                      <Flex flexDir="column" justifyContent="center">
                        <Clipboard />
                      </Flex>
                      <Flex flex="1" flexDir="column" justifyContent="center">
                        <Text fontWeight="bold">{assessment?.name}</Text>
                        <Text textColor="gray">
                          {assessment?.disorder} |{' '}
                          {assessment?.content?.sections[0]?.questions?.length} questions
                        </Text>
                      </Flex>
                      <Flex flexDirection="column" justifyContent="center">
                        <Field name={`assessments[${index}].cadence`}>
                          {({ field }: FieldProps) => (
                            <Select
                              border="1px solid"
                              borderColor="pale_gray"
                              borderRadius="4px"
                              {...field}
                            >
                              {cadenceOptions.map(cadence => {
                                return (
                                  <option key={cadence} value={cadence}>
                                    {cadence}
                                  </option>
                                )
                              })}
                            </Select>
                          )}
                        </Field>
                      </Flex>
                      {values.assessments.length > 1 && (
                        <Flex alignItems="center">
                          <CloseButton
                            onClick={() => arrayHelpers.remove(index)}
                            _focus={{ outline: 'none' }}
                          />
                        </Flex>
                      )}
                    </Flex>
                  )
                })
              }}
            />
          </VStack>
        </Box>
      </VStack>
    </Box>
  )
}
