import React, { useState, useMemo } from 'react'
import { Box, Heading, Text, HStack, VStack, Flex } from '@chakra-ui/react'
import moment from 'moment'

import { usePatientControllerGetConsent } from '~/clinician-api'
import { ReviewConsentModal } from './components'

const Consent = (props: any) => {
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false)

  const { user_id: patientId, patient } = props

  const { data: consentData } = usePatientControllerGetConsent(patientId, {
    clinicId: patient.clinic_id
  })

  const consentStatus = (status: string | null | undefined) => {
    switch (status) {
      case 'given':
        return 'Has given consent'
      case 'declined':
        return 'Has declined consent'
      case 'pending':
        return 'Awaiting consent'

      default:
        return 'Has not given consent yet'
    }
  }

  const consentCollectionMethod = (status: string | null | undefined) => {
    switch (status) {
      case 'in_person':
        return 'Collected in session'
      case 'email':
        return 'Collected via email'
      case 'sms':
        return 'Collected via text message'

      default:
        return 'Collected before Blueprint managed consent'
    }
  }

  const consent = useMemo(() => {
    const status = {
      display: 'Status',
      value: consentStatus(consentData?.status)
    }
    const signedBy = {
      display: 'Signed by',
      value: consentData?.signedBy || '--'
    }
    const dateObtained = {
      display: 'Date obtained',
      value: consentData?.obtainedAt
        ? moment.utc(consentData?.obtainedAt).format('M/DD/YYYY')
        : '--'
    }
    const method = {
      display: 'Method',
      value: consentData?.collectionMethod
      //@ts-ignore
        ? consentCollectionMethod(consentData?.collectionMethod)
        : '--'
    }
    const review = {
      display: 'Review',
      value: consentData?.status === 'given' ? 'View Document' : '--'
    }

    return [status, signedBy, dateObtained, method, review]
  }, [consentData])

  return (
    <Box>
      <Heading mb="xsmall">Consent</Heading>
      <Text mb="medium">Review client consent information.</Text>
      <Flex>
        <VStack align="left">
          {consent.map((field, i) => (
            <Text key={i} fontWeight="bold">
              {field.display}
            </Text>
          ))}
        </VStack>
        <VStack align="left" ml="xxlarge">
          {consent.map((field, i) => {
            if (field.display === 'Signed by') {
              return (
                <HStack key={i} spacing="xsmall">
                  <Text>{field.value}</Text>
                  {consentData?.isGuardian && (
                    <Text color="gray">(Parent/Guardian)</Text>
                  )}
                </HStack>
              )
            }
            if (field.value === 'View Document') {
              return (
                <Text
                  key={i}
                  color="primary"
                  _hover={{
                    cursor: 'pointer'
                  }}
                  onClick={() => setIsReviewModalOpen(true)}
                >
                  {field.value}
                </Text>
              )
            }
            return <Text key={i}>{field.value}</Text>
          })}
        </VStack>
      </Flex>
      <ReviewConsentModal
        onClose={() => setIsReviewModalOpen(false)}
        isOpen={isReviewModalOpen}
        data={{
          consentCopy: consentData?.copy,
          signedBy: consentData?.signedBy,
          dateObtained: consentData?.obtainedAt
        }}
      />
    </Box>
  )
}

export default Consent
