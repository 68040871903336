import React from 'react'

import { Wrapper } from './wrapper'
import { NavBar } from './nav-bar'
import SelectClient from '../start-session/select-client'
import { useExperienceManager } from '@hooks'

export const ExtensionStartSession = () => {
  const { isExtensionMinimized } = useExperienceManager()

  return (
    <Wrapper>
      <NavBar />
      {!isExtensionMinimized && <SelectClient />}
    </Wrapper>
  )
}
