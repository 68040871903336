import React from 'react'
import { Divider, Text, Box } from '@chakra-ui/react'
import Loading from '@components/loading'
import _ from 'lodash'
import moment from 'moment'

import { Session } from './session'
import { EmptyState } from './empty-state'

export const SessionContainer = ({ isLoading, data }) => {
  if (isLoading) {
    return <Loading />
  }

  const groupedSessions = _.groupBy(data.nodes, s =>
    moment(s.isRecordingInProgress ? s.startedAt : s.completedAt)
      .local()
      .format('YYYY-MM-DD')
  )

  const dates = Object.keys(groupedSessions)

  return dates.length === 0 ? (
    <Box>
      <Text mb={4}>
        Today
        <Text as="span" ml={1} color="#A1A1A1">
          {moment()
            .local()
            .format('MMM D')}
        </Text>
      </Text>
      <EmptyState />
    </Box>
  ) : (
    dates.map((date, idx) => {
      const sessions = groupedSessions[date]
      return (
        <Box key={idx} mb={8}>
          <Text mb={4}>
            {moment()
              .local()
              .format('MM-DD-YY') ===
            moment(date)
              .local()
              .format('MM-DD-YY')
              ? 'Today'
              : moment(date)
                  .local()
                  .format('dddd')}
            <Text as="span" ml={1} color="#A1A1A1">
              {moment(date)
                .local()
                .format('MMM D')}
            </Text>
          </Text>
          <Box
            border="1px solid"
            borderColor="pale_gray"
            borderRadius="8px"
            p="small"
          >
            {sessions.map((session, i) => {
              const isLast = i === sessions.length - 1
              const isFirst = i === 0
              return (
                <React.Fragment key={session.id}>
                  <Session
                    sx={{
                      padding:
                        isFirst && sessions.length > 1
                          ? '0px 0px 16px'
                          : isLast && sessions.length > 1
                          ? '16px 0px 0px'
                          : sessions.length > 1
                          ? '16px 0px'
                          : '0px'
                    }}
                    sessionId={session.id}
                    patientId={session.patientId}
                    fullName={session.client.fullName}
                    startedAt={moment(
                      session.recordingStartedAt || session.startedAt
                    )
                      .local()
                      .format()}
                    completedAt={moment(session.completedAt)
                      .local()
                      .format()}
                    hasAudio={session.hasAudio}
                    isUpload={session.isUpload}
                    clinicianName={session.clinician.fullName}
                    inProgress={session.isRecordingInProgress}
                    isDictation={session.isDictation}
                  />
                  {!isLast && <Divider />}
                </React.Fragment>
              )
            })}
          </Box>
        </Box>
      )
    })
  )
}
