import React from 'react'
import { AudioLevel } from './microphone-selector'
import {
  HStack,
  Button,
  VStack,
  Text,
  Box,
  Stack,
  Flex
} from '@chakra-ui/react'
import { MicAIOn, MicAIOff, MicOff, MicDictate } from '@components/icons'
import { useAudioCapture, useExperienceManager } from '@hooks'
import { useStoreActions } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

type AINotetakerPanelPropsT = {
  sessionId: string
  hasStartedRecording: boolean
  startRecording: () => void
  isRecordingLoading: boolean
  aiNotetakerEnabled: boolean
  recordingDuration: number
  isDictation: boolean
  recordingStartedAt: string | null | undefined
  clientId: string
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}

export const TryForFree: React.FC<{
  width?: string
}> = ({ width = '164px' }) => {
  const openModal = useStoreActions(
    (actions: any) =>
      actions.modals.documentationAutomationMarketingModal.openModal
  )

  return (
    <Button
      h="40px"
      width={width}
      bg="#282828"
      fontWeight="430"
      m={0}
      variant="body"
      onClick={openModal}
      _focus={{
        outline: 'none'
      }}
    >
      Try for free
    </Button>
  )
}

const UpgradePlan: React.FC<{}> = () => {
  const { isExtension, isWidget } = useExperienceManager()

  const openModal = useStoreActions(
    (actions: any) => actions.modals.manageScribePlan.openModal
  )

  return (
    <Button
      w="164px"
      bg="#282828"
      fontWeight="430"
      variant="body"
      onClick={() =>
        isExtension || isWidget
          ? window.open(
              `${process.env.REACT_APP_CLINICIAN_APP_URL}/sessions?upgrade=true`,
              '_blank'
            )
          : openModal
      }
      _focus={{
        outline: 'none'
      }}
    >
      Upgrade
    </Button>
  )
}

const AINotetakerPanel: React.FC<AINotetakerPanelPropsT> = ({
  hasStartedRecording,
  startRecording,
  isRecordingLoading,
  aiNotetakerEnabled,
  recordingDuration,
  isDictation,
  recordingStartedAt,
  clientId
}) => {
  const {
    documentationAutomationFreeTierSessionLimitReached,
    isAdmin,
    isWidget
  } = useExperienceManager()
  const {
    hasMicAccess,
    isMuted,
    isStopRecordingAfterEnabled,
    setIsStopRecordingAfterEnabled,
    stopRecordingAfter
  } = useAudioCapture()
  const history = useHistory()

  const cutoffTime = moment(recordingStartedAt).add(
    stopRecordingAfter,
    'minutes'
  )

  const minutesLeft = Math.ceil(cutoffTime.diff(moment.utc(), 'seconds') / 60)

  const freeTierSessionLimitReached =
    documentationAutomationFreeTierSessionLimitReached && !hasStartedRecording

  const renderMicIcon = () => {
    if (isDictation) return <MicDictate width="88px" height="88px" />
    if (!hasMicAccess)
      return <MicOff width="88px" height="88px" fill="#EB5164" />
    if (!hasStartedRecording) return <MicAIOn />
    if (isMuted) return <MicAIOff />
    return <MicAIOn />
  }

  return (
    <HStack justifyContent="center" color="white">
      <Stack gap="13px" alignItems="center" textAlign="center">
        {renderMicIcon()}
        <Text
          as="h3"
          lineHeight="normal"
          fontWeight="bold"
          fontSize="24px"
          style={{ marginTop: 0 }}
        >
          {isDictation ? 'Dictate Notes' : 'AI Notetaker'}
        </Text>
        {hasStartedRecording || !hasMicAccess ? (
          <VStack gap="9px">
            <HStack gap="9px">
              <Box
                h="16px"
                w="16px"
                bg={isMuted || !hasMicAccess ? '#EB5164' : '#68E19F'}
                borderRadius="full"
              />
              <Text
                as="h4"
                lineHeight="normal"
                style={{ marginInlineStart: '0px' }}
              >
                {hasMicAccess
                  ? isMuted
                    ? 'Microphone muted'
                    : `Recording ${formatTime(recordingDuration)}`
                  : 'Microphone access blocked'}
              </Text>
            </HStack>
            <HStack maxWidth="320px">
              <Text color="rgba(255, 255, 255, 0.70)">
                {hasMicAccess
                  ? isMuted
                    ? 'Your notes won’t include the conversation that took place during this period.'
                    : isDictation
                    ? 'Turn your thoughts into notes.'
                    : 'A progress note will be generated after you end the session.'
                  : 'Access to microphone is blocked. You will need to allow access in your browser and possibly reload the page.'}
              </Text>
            </HStack>
            <AudioLevel />
            {isStopRecordingAfterEnabled && stopRecordingAfter && !isDictation && (
              <Flex
                px={2}
                py={1}
                w="320px"
                h="48px"
                color="black"
                bg="white"
                borderRadius="4px"
                alignItems="center"
                justifyContent="center"
              >
                <Text>Recording will stop in {minutesLeft} min</Text>
                <Button
                  ml={2}
                  variant="link"
                  onClick={() => setIsStopRecordingAfterEnabled(false)}
                  textDecoration="none"
                  _hover={{ textDecoration: 'none' }}
                >
                  Cancel
                </Button>
              </Flex>
            )}
          </VStack>
        ) : (
          <VStack gap="16px" style={{ marginTop: 0 }}>
            <Text color="rgba(255, 255, 255, 0.70)">
              {freeTierSessionLimitReached
                ? "You've reached your monthly limit on notes."
                : 'Let AI write your progress notes.'}
            </Text>
            {aiNotetakerEnabled ? (
              freeTierSessionLimitReached ? (
                <UpgradePlan />
              ) : (
                <>
                  <Button
                    w="164px"
                    bg="#282828"
                    fontWeight="430"
                    variant="body"
                    isLoading={isRecordingLoading}
                    data-click-event-name="Session -> Clicked Start Recording"
                    onClick={() => {
                      startRecording()
                    }}
                    _focus={{
                      outline: 'none'
                    }}
                    id="pendo-session-panel-start-recording"
                  >
                    {isDictation ? 'Start dictating' : 'Start recording'}
                  </Button>
                  {isWidget && (
                    <Text
                      as="u"
                      _hover={{ cursor: 'pointer' }}
                      mt="0"
                      onClick={() =>
                        history.push(`/widget/patient/${clientId}/sessions`)
                      }
                    >
                      View previous sessions
                    </Text>
                  )}
                </>
              )
            ) : isAdmin ? (
              <TryForFree />
            ) : (
              <Text style={{ marginTop: 0 }} color="rgba(255, 255, 255, 0.70)">
                Contact your admin to upgrade your plan.
              </Text>
            )}
          </VStack>
        )}
      </Stack>
    </HStack>
  )
}

export default AINotetakerPanel
