import React, { useState } from 'react'
import { Stack, HStack, Text, IconButton, Skeleton } from '@chakra-ui/react'
import { ArrowLeftIcon, ArrowRightIcon } from '@blueprinthq/joy'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { useTreatmentPlan } from '@hooks'
import { useSessionControllerGetSuggestedContent } from '~/clinician-api'
import ContentSuggestionCard from '@handlers/sessions/components/shared/assist/assessments/suggestion-card'

export const Suggestions = () => {
  const { client } = useTreatmentPlan()
  const [suggestedContentIndex, setSuggestedContentIndex] = useState(0)

  const lastSessionQuery: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId()],
    async () => {
      return await endpoints.getClinicianClientLastSession.request({
        id: client.id
      })
    }
  )

  const sessionId = lastSessionQuery?.data?.id

  const suggestionsQuery = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      },
      enabled: !!sessionId
    }
  })

  const suggestion =
    suggestionsQuery?.data?.suggestedWorksheets?.[suggestedContentIndex]

  if (suggestionsQuery.isLoading) {
    return (
      <Stack>
        <HStack justifyContent="space-between">
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" width="100px" />
        </HStack>
        <Skeleton height="113px" borderRadius="8px" />
      </Stack>
    )
  }

  if (!suggestion) {
    return (
      <Stack spacing="0" gap="8px">
        <HStack justifyContent="space-between">
          <Text fontWeight="bold">Suggestions</Text>
        </HStack>
        <HStack
          spacing="0"
          p="16px"
          gap="12px"
          border="1px solid"
          borderColor="pale_gray"
          borderRadius="8px"
        >
          <Text>No suggestions available.</Text>
        </HStack>
      </Stack>
    )
  }

  const count = suggestionsQuery?.data?.suggestedAssessments?.length || 0
  const onPrevious = () => setSuggestedContentIndex(prev => prev - 1)
  const onNext = () => setSuggestedContentIndex(prev => prev + 1)

  return (
    <Stack spacing="0" gap="8px">
      <HStack justifyContent="space-between">
        <Text fontWeight="bold">Suggestions</Text>
        <HStack>
          <Text fontSize="12px">
            {suggestedContentIndex + 1} of {count}
          </Text>
          <IconButton
            size="xs"
            aria-label="left"
            variant="outline"
            borderRadius="0px"
            icon={<ArrowLeftIcon />}
            isDisabled={suggestedContentIndex === 0}
            onClick={onPrevious}
          />
          <IconButton
            size="xs"
            aria-label="right"
            variant="outline"
            borderRadius="0px"
            icon={<ArrowRightIcon />}
            isDisabled={suggestedContentIndex === count - 1}
            onClick={onNext}
          />
        </HStack>
      </HStack>
      <ContentSuggestionCard
        type="assessment"
        suggestion={{ ...suggestion, name: suggestion.worksheetName }}
        sessionId={sessionId}
      />
    </Stack>
  )
}
