import React from 'react'
import {
  Flex,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider
} from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import { TreatmentPlan } from '..'
import { endpoints } from '@api'
import { useQuery } from 'react-query'
import AssistPanel from '../assist-panel'

const Sidebar = ({ client, isNoteNull, sessionId }: any) => {
  const clientId = client.id

  const {
    isDocumentationAutomationEnabled,
    isAssistEnabled,
    isEvidenceBasedCareEnabled
  } = useExperienceManager()

  const {
    data: treatmentPlanData,
    isLoading: isTreatmentPlanLoading
  } = useQuery([endpoints.getClientTreatmentPlan.getCacheId(), clientId], () =>
    endpoints.getClientTreatmentPlan.request({ clientId })
  )

  const showTxPlanIndicator =
    !isTreatmentPlanLoading &&
    !(treatmentPlanData as any)?.treatmentPlan?.isLoading &&
    (treatmentPlanData as any)?.treatmentPlan &&
    !(treatmentPlanData as any)?.treatmentPlan?.acceptedAt

  return (
    <Flex
      display={{
        base: 'none',
        sm: 'none',
        md: 'block'
      }}
      height="100%"
      borderLeft="1px solid"
      borderColor="pale_gray"
      width="384px"
      maxW="384px"
    >
      {isDocumentationAutomationEnabled && !isNoteNull && (
        <Flex justifyContent="center" flexDirection="column">
          <Tabs h="59px" pt="16px" defaultIndex={isAssistEnabled ? 1 : 0}>
            <TabList
              sx={{
                display: 'flex',
                textWrap: 'nowrap'
              }}
              gap="32px"
              borderBottom="none"
              overflowX="auto"
              overflowY="hidden"
              px="32px"
            >
              {[
                {
                  name: 'Treatment Plan',
                  indicator: showTxPlanIndicator
                },
                ...(!isEvidenceBasedCareEnabled || isAssistEnabled
                  ? [{ name: 'Assist' }]
                  : [])
              ].map((tab, index) => (
                <Tab
                  key={tab.name}
                  justifyContent="flex-start"
                  px="0"
                  color="dark_gray"
                  fontWeight="460"
                  borderBottom="6px solid"
                  whiteSpace="nowrap"
                  _focus={{ outline: 'none' }}
                  _selected={{
                    color: '#282828',
                    borderBottomColor: 'primary'
                  }}
                  position="relative"
                >
                  <Box
                    h="8px"
                    w="8px"
                    bg="primary"
                    borderRadius="50%"
                    position="absolute"
                    top="5px"
                    right="-4px"
                    display={tab.indicator ? 'block' : 'none'}
                  />
                  {tab.name}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel p="32px">
                <TreatmentPlan client={client} />
              </TabPanel>
              <TabPanel
                p="32px"
                height="calc(100vh - 140px)"
                overflowY="scroll"
              >
                <AssistPanel sessionId={sessionId} client={client} />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Divider color="#E4E5E6" />
        </Flex>
      )}
    </Flex>
  )
}

export default Sidebar