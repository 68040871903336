import React from 'react'
import { Box } from '@chakra-ui/react'
import { NavBar, ExtensionWrapper } from './'
import { SessionNotesV2 } from '../sessions/session-notes-v2'

import _ from 'lodash'

export function ExtensionSessionNotes() {
  return (
    <ExtensionWrapper>
      <NavBar showBackButton />
      <Box backgroundColor="white" height="100%">
        <SessionNotesV2 />
      </Box>
    </ExtensionWrapper>
  )
}
