import React, { useState } from 'react'
import { useQuery } from 'react-query'
import {
  Container,
  GridItem,
  Text,
  Button,
  Box,
  Alert,
  AlertTitle,
  AlertDescription,
  Link
} from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import NotificationContainer from './components/notification-container'
import CaseloadInsightsContainer from './components/caseload-insights-container'
import AvatarNotification from './components/avatar-notification'
import { endpoints } from '@api'
import { MicAIOn } from '@components/icons'
import { ClinicianOverride } from '@components'
import { usePermissions, useUserServices, useExperienceManager } from '@hooks'
import { TryForFree } from '@handlers/sessions/components/ai-notetaker-panel'
import UnionSVG from '../../images/brand/union-v2.svg'
import { Redirect, Link as RLink } from 'react-router-dom'
import { useComponentRenderTracker } from '@hooks/utilities/use-component-render-tracker'

const LearnMoreLink = () => {
  return (
    <Link
      href="https://help.blueprint-health.com/en/articles/8558032-overview-ai-notetaker"
      isExternal
      sx={{
        textDecoration: 'underline'
      }}
    >
      Learn More
    </Link>
  )
}

const ClinicianDashboard = () => {
  useComponentRenderTracker('Viewed Dashboard')

  const [overrideClinicianId, setOverrideClinicianId] = useState(null)
  const { hasPermission } = usePermissions()
  const {
    isEvidenceBasedCareEnabled,
    isDocumentationAutomationEnabled,
    isAdmin
  } = useExperienceManager()

  const { data: user } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  if (!isEvidenceBasedCareEnabled) {
    return <Redirect to="/sessions" />
  }

  if (!user) return null

  const hasSupervisorRole = user?.roles?.some(
    role =>
      role.name === 'supervisor_clinician' ||
      role.name === 'supervisor' ||
      role.name === 'clinicadmin'
  )

  const hasAdminRole = user?.roles?.some(role => role.name === 'orgadmin')

  return (
    <Container
      sx={{
        mt: 'medium'
      }}
    >
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 12,
            md: 7,
            lg: 7
          }}
          colStart={{
            base: 0,
            md: 1,
            lg: 1
          }}
          order={{ base: 2, md: 1 }}
        >
          <NotificationContainer overrideClinicianId={overrideClinicianId} />
        </GridItem>
        <GridItem
          rowSpan={2}
          order={{ base: 1, md: 2 }}
          colSpan={{
            base: 12,
            md: 5,
            lg: 5
          }}
          colStart={{
            base: 0,
            md: 8,
            lg: 8
          }}
        >
          <Box>
            {hasPermission('*:any:*:*') || hasSupervisorRole || hasAdminRole ? (
              <Box w="100%" mb={6}>
                <ClinicianOverride
                  user={user}
                  isSuperAdmin={hasPermission('*:any:*:*')}
                  canViewOrgDropdown={hasAdminRole}
                  onChange={id => setOverrideClinicianId(id)}
                />
              </Box>
            ) : (
              <Box w="100%" mb={6} h="40px" d={{ base: 'none', md: 'block' }} />
            )}
            <Box mb={8}>
              <Box mb={6}>
                <Alert
                  status="success"
                  bg="primary"
                  borderRadius="8px"
                  shadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
                  backgroundRepeat="no-repeat"
                  backgroundImage={UnionSVG}
                  backgroundPosition="-10px"
                  backgroundSize="contain"
                >
                  <Box h="88px" w="88px" ml={2}>
                    <MicAIOn />
                  </Box>
                  <Box ml={2} p={4} w="100%">
                    <Box>
                      <AlertTitle color="white" fontSize="lg" mb={2}>
                        AI Notetaker
                      </AlertTitle>
                      <AlertDescription color="white">
                        {isDocumentationAutomationEnabled ? (
                          <Box>
                            <Text>Let AI write your progress notes.</Text>
                            <Box mt={4} display="flex" alignItems="center">
                              <Box w="148px" h="40px">
                                <Button
                                  bg="#282828"
                                  m={0}
                                  w="100%"
                                  variant="body"
                                  as={RLink}
                                  to="/start-session/select-client"
                                  _focus={{
                                    outline: 'none'
                                  }}
                                  id="pendo-dashboard-start-session"
                                >
                                  Start Session
                                </Button>
                              </Box>
                              <Box ml={4}>
                                <LearnMoreLink />
                              </Box>
                            </Box>
                          </Box>
                        ) : isAdmin ? (
                          <Box>
                            <Box display="flex">
                              <Text mr={1}>
                                Let AI write your progress notes.
                              </Text>
                            </Box>
                            <Box mt={4} display="flex" alignItems="center">
                              <TryForFree width="127px" />
                              <Box ml={6}>
                                <LearnMoreLink />
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box display="flex">
                              <Text mr={1}>
                                Let AI write your progress notes.
                              </Text>
                              <LearnMoreLink />
                            </Box>
                            <Box my={4} display="flex" alignItems="center">
                              <Text color="white" fontWeight="bold">
                                Contact your admin to upgrade your plan.
                              </Text>
                            </Box>
                          </Box>
                        )}
                      </AlertDescription>
                    </Box>
                  </Box>
                </Alert>
              </Box>
              {!user.avatar && (
                <Box>
                  <AvatarNotification user={user} />
                </Box>
              )}
            </Box>
            <CaseloadInsightsContainer
              overrideClinicianId={overrideClinicianId}
            />
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}

export default ClinicianDashboard
