import React from 'react'

const LightBulb = ({ fill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_398_1081)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.04 2.49994L20.45 3.90994L18.66 5.70994L17.25 4.29994L19.04 2.49994ZM11 2.99994H13V-6.10352e-05H11V2.99994ZM20 11.9999H23V9.99994H20V11.9999ZM11 14.9899V17.9499H13V14.9899L14 14.4099C15.23 13.6899 16 12.3699 16 10.9499C16 8.73994 14.21 6.94994 12 6.94994C9.79 6.94994 8 8.73994 8 10.9499C8 12.3699 8.76 13.6899 10 14.4099L11 14.9899ZM15 19.9499L9 19.9499V16.1399C7.21 15.0999 6 13.1699 6 10.9499C6 7.63994 8.69 4.94994 12 4.94994C15.31 4.94994 18 7.63994 18 10.9499C18 13.1699 16.79 15.0999 15 16.1399V19.9499ZM1 11.9999H4L4 9.99994H1V11.9999ZM4.97 2.48994L6.76 4.28994L5.36 5.68994L3.56 3.89994L4.97 2.48994ZM9 23C9 23.55 9.45 24 10 24H14C14.55 24 15 23.55 15 23V22H9V23Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_398_1081">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LightBulb
