import React, { useContext } from 'react'
import {
  Flex,
  Text,
  UnorderedList,
  ListItem,
  Container,
  Button,
  Stack,
  HStack
} from '@chakra-ui/react'
import { useSessionControllerGetSessionPrep } from '~/clinician-api'
import { useParams } from 'react-router-dom'
import { useAssistPanelContext } from '../context'
import { ActionConfig } from './config'
import { ItemT, ClientT } from './types'
import BeforeYouBeginItem from './before-you-begin-item'
import Empty from './empty'
import Loading, { SessionPrepLoading } from './loading'

const Prep = ({ client }: { client: ClientT }) => {
  const { sessionId } = useParams<{ sessionId: string }>()
  const { switchTab, setOverlayPanel } = useAssistPanelContext()

  const {
    data: sessionPrep,
    isLoading: isSessionPrepLoading
  } = useSessionControllerGetSessionPrep(sessionId, {
    query: {
      refetchInterval: (data: any) => {
        if (data?.isLoading) {
          return 5000
        }

        return false
      }
    }
  })

  const isLoading = isSessionPrepLoading || sessionPrep?.isLoading

  const isEmpty =
    !sessionPrep?.lastSessionRecap?.length &&
    !sessionPrep?.thisSessionFocusAreas?.length &&
    !sessionPrep?.beforeYouBegin?.items?.length

  if (isLoading) {
    return <Loading />
  }

  if (isEmpty) {
    return <Empty />
  }

  return (
    <Container>
      <Flex
        flexDirection="column"
        gap="16px"
        py="16px"
        flex="1"
        h="calc(100vh - 208px)"
      >
        <>
          {!!sessionPrep?.lastSessionRecap?.length && (
            <>
              <Flex flexDirection="column">
                <Text fontWeight="bold">Last session recap:</Text>
                <UnorderedList>
                  {sessionPrep?.lastSessionRecap.map((recap: string) => (
                    <ListItem key={recap}>{recap}</ListItem>
                  ))}
                </UnorderedList>
              </Flex>
            </>
          )}
          {!!sessionPrep?.thisSessionFocusAreas?.length && (
            <>
              <Flex flexDirection="column">
                <Text fontWeight="bold">This session focus areas:</Text>
                <UnorderedList>
                  {sessionPrep?.thisSessionFocusAreas.map(
                    (focusArea: string) => (
                      <ListItem key={focusArea}>{focusArea}</ListItem>
                    )
                  )}
                </UnorderedList>
              </Flex>
            </>
          )}
          {!!sessionPrep?.beforeYouBegin?.isLoading ? (
            <SessionPrepLoading />
          ) : (
            !!sessionPrep.beforeYouBegin?.items?.length && (
              <Flex flexDirection="column" gap="8px">
                <Text fontWeight="bold">Before you begin:</Text>
                <Stack spacing="0" gap="8px">
                  {sessionPrep.beforeYouBegin?.items?.map((item: ItemT) => {
                    const {
                      text,
                      subtext,
                      icon: IconComponent,
                      backgroundColor,
                      textColor,
                      subtextColor,
                      buttonText,
                      handleClick
                    } = ActionConfig[item.action]

                    const { data } = item

                    return (
                      <BeforeYouBeginItem
                        key={item.action}
                        backgroundColor={backgroundColor}
                        textColor={textColor}
                        subtextColor={subtextColor}
                        icon={<IconComponent />}
                        buttonText={buttonText}
                        text={text({ client })}
                        subtext={subtext({ client, item })}
                        handleClick={() =>
                          handleClick({ setOverlayPanel, switchTab, data })
                        }
                      />
                    )
                  })}
                </Stack>
              </Flex>
            )
          )}
        </>
      </Flex>
    </Container>
  )
}

export default Prep
