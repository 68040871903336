import React from 'react'

const EndSession = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon / End Call" clipPath="url(#clip0_365_1347)">
      <path
        id="Vector"
        d="M17.1598 9.05147L17.1598 12.1627C17.1598 12.5587 17.379 12.8911 17.7043 13.0608C18.7579 13.5911 19.7478 14.2982 20.6317 15.1821C21.0206 15.571 21.657 15.571 22.0459 15.1821L24.5208 12.7072C24.9097 12.3183 24.9097 11.6819 24.5208 11.293C17.8811 4.65327 7.11892 4.65327 0.479185 11.293C0.0902762 11.6819 0.0902767 12.3183 0.479185 12.7072L2.94699 15.175C3.3359 15.5639 3.97229 15.5639 4.3612 15.175C5.23801 14.2982 6.23503 13.584 7.28862 13.0537C7.62096 12.8911 7.84017 12.5446 7.84017 12.1627L7.84017 9.05147C10.8666 8.07566 14.1405 8.06859 17.1598 9.05147Z"
        fill="#EB5164"
      />
    </g>
    <defs>
      <clipPath id="clip0_365_1347">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default EndSession
