import React from 'react'
import { Heading, Center, Box, Flex, Divider } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { State } from 'easy-peasy'
import { Page, HorizontalFilterBar, InfoTooltip } from '../components'
import {
  OrganizationSelector,
  DateRangePicker,
  BaselineScorePicker
} from '../components/filters'
import { AgeRangeSelector } from '../components/age-range-selector'
import { ClinicianSelector } from '../components/clinician-selector'
import { AssessmentSelector, OutcomesContent } from './components'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { usePermissions } from '@hooks'
import { ProgramFilter } from './components/program-filter'
import { ClinicMultiSelector } from '../components/clinic-multi-selector'
import { InsightsModel } from 'src/store/model/insights'

const FilterBar: React.FC = () => {
  const { hasPermission } = usePermissions()
  const { updateFiltersV2 } = useStoreActions(
    actions => actions.insights
  ) as any
  const state = useStoreState(
    (state: State<InsightsModel>) => state.insights.filtersV2
  )
  const {
    allClinicIds,
    organizationId,
    clinicIds,
    clinicianId,
    ages,
    startDate,
    endDate,
    baselineScoreMin,
    baselineScoreMax,
    programIds
  } = state || {}

  const { selectedAssessment } = useStoreState(state => state.insights)

  const handleClearAllFilters = () => {
    updateFiltersV2({
      clinicianId: null,
      ages: [],
      clinicIds: allClinicIds,
      startDate: null,
      endDate: null,
      baselineScoreMin: null,
      baselineScoreMax: null
    })
  }

  const handleClinicChange = (clinicIds: string[]) =>
    updateFiltersV2({ clinicIds })

  const handleClinicianChange = (clinicianId: string | null) =>
    updateFiltersV2({ clinicianId }) as any

  const handleAgeChange = (ages: any[]) => updateFiltersV2({ ages })

  const handleFromDateChange = (date: string | null) =>
    updateFiltersV2({ startDate: date })
  const handleToDateChange = (date: string | null) =>
    updateFiltersV2({ endDate: date })

  const handleBaselineScoreChange = (
    baselineScoreMin: number | null,
    baselineScoreMax: number | null
  ) => updateFiltersV2({ baselineScoreMin, baselineScoreMax })

  const handleProgramChange = (programIds: string[]) =>
    updateFiltersV2({ programIds })

  const datePickerHelperText =
    'Enabling this filter only shows clients that completed their first assessment within the date range selected.'

  const baselineScoreHelperText =
    'Enabling this filter only shows clients that completed their first assessment within the baseline score selected.'

  if (!clinicIds || !organizationId) {
    return null
  }

  return (
    <HorizontalFilterBar onClearAll={handleClearAllFilters} sx={{}}>
      <AssessmentSelector
        hideCounts={!hasPermission('read:clinic:reports:outcomes')}
      />
      <Center height="50px">
        <Divider orientation="vertical" />
      </Center>
      <ProgramFilter
        organizationId={organizationId}
        onChange={handleProgramChange}
        selectedPrograms={programIds || []}
      />
      {hasPermission('read:org:reports:outcomes') && (
        <ClinicMultiSelector
          organizationId={organizationId}
          selectedClinicIds={clinicIds}
          onChange={handleClinicChange}
        />
      )}
      {hasPermission('read:clinic:reports:outcomes') && (
        <ClinicianSelector
          allClinicIds={allClinicIds}
          organizationId={organizationId}
          clinicIds={clinicIds}
          clinicianId={clinicianId}
          onChange={handleClinicianChange}
        />
      )}
      <AgeRangeSelector ages={ages} onChange={handleAgeChange} />
      <DateRangePicker
        label="Treatment Start"
        fromDate={startDate}
        toDate={endDate}
        fromLabel="Start Date"
        toLabel="End Date"
        onFromDateChange={handleFromDateChange}
        onToDateChange={handleToDateChange}
        helperText={datePickerHelperText}
      />
      {selectedAssessment && (
        <BaselineScorePicker
          minScore={baselineScoreMin}
          maxScore={baselineScoreMax}
          totalScore={selectedAssessment.totalScore}
          onChange={handleBaselineScoreChange}
          helperText={baselineScoreHelperText}
        />
      )}
    </HorizontalFilterBar>
  )
}

const Header: React.FC = () => {
  const { hasPermission } = usePermissions()
  const { updateFiltersV2 } = useStoreActions(
    actions => actions.insights
  ) as any
  const state = useStoreState(
    (state: State<InsightsModel>) => state.insights.filtersV2
  )
  const { organizationId } = state || {}

  useQuery(
    [endpoints.getOrganizationClinicsV2.getCacheId(), organizationId],
    () => endpoints.getOrganizationClinicsV2.request({ organizationId }),
    {
      enabled: !!organizationId,
      placeholderData: [],
      refetchOnWindowFocus: false,
      onSuccess: allClinics => {
        updateFiltersV2({ allClinicIds: allClinics?.map(clinic => clinic.id) })
      }
    }
  )

  const handleOrganizationChange = (organizationId: string) =>
    updateFiltersV2({ organizationId })
  const tooltip =
    'Using the results from completed measures, we can calculate the average treatment response across various populations, as it pertains to this measure.'

  return (
    <Flex my="3" justify="space-between" alignItems="center">
      <Flex alignItems="center" flex="1">
        <Heading my="5">
          Treatment Outcomes
          <span style={{ display: 'inline-block' }}>
            <Box ml="3" mt="1">
              <InfoTooltip text={tooltip} />
            </Box>
          </span>
        </Heading>
      </Flex>
      <Flex flexWrap="wrap">
        {hasPermission('read:any:*:*') && (
          <Box w="275px" mr="3">
            <OrganizationSelector
              organizationId={organizationId}
              onChange={handleOrganizationChange}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

export function InsightsOutcomesV2() {
  return (
    <Page>
      <Header />
      <FilterBar />
      <OutcomesContent />
    </Page>
  )
}
