import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
  Container,
  GridItem,
  Flex,
  Text,
  Box,
  useBreakpointValue,
  Stack
} from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import { LayoutGrid } from '@blueprinthq/joy'
import { endpoints } from '@api'
import { useStoreActions } from 'easy-peasy'
import { useHistory } from 'react-router-dom'

import { MicAIOn, Upload, MicDictate, MicUpload } from '@components/icons'
import { ConsentModule, SampleSessionsModule } from './components'
import { UploadModalV2 } from '../sessions/components/upload-modal-v2'
import { useComponentRenderTracker } from '@hooks'
import { SessionTypeCTA } from './components/session-type-cta-group'

export const SessionGetStarted = () => {
  useComponentRenderTracker('Viewed Sessions Get Started Page')
  const {
    isAdmin,
    isDocumentationAutomationEnabled,
    documentationAutomationFreeTierSessionLimitReached
  } = useExperienceManager()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const history = useHistory()

  const isCtaHorizontal = useBreakpointValue({
    base: true,
    md: false
  })

  const ctaPadding = useBreakpointValue({
    base: '32px',
    md: 'small'
  })

  const openDocAutomationPlanModal = useStoreActions(
    actions => actions.modals.manageScribePlan.openModal
  )

  const openDocAutomationSessionLimitReachedModal = useStoreActions(
    actions => actions.modals.docAutomationSessionLimitReached.openModal
  )

  const { data: user } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  useEffect(() => {
    if (!isDocumentationAutomationEnabled) {
      history.replace('/sessions')
    }
  }, [isDocumentationAutomationEnabled])

  if (!user) return null

  return (
    <Container mt={'32px'}>
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 12,
            sm: 6,
            md: 8
          }}
          colStart={{
            base: 0,
            sm: 2,
            md: 3
          }}
        >
          <Flex
            direction="column"
            align="space-between"
            justify="center"
            mb={{ base: 6, lg: 10 }}
            mt={{ base: 0, lg: 8 }}
          >
            <Text as="h1" fontSize="lg" fontWeight="bold" align="center">
              {`👋 ${user?.first_name}`}
            </Text>
            <Text align="center">
              Choose from the options below to get started
            </Text>
          </Flex>
          <Stack
            direction={['column', 'column', 'row', 'row']}
            spacing={{ base: '16px', md: '20px' }}
            h={{ base: 'auto', md: '210px' }}
            w="100%"
            mb={{ base: '32px', md: '40px' }}
          >
            <SessionTypeCTA
              id="pendo-sessions-get-started-start-session"
              onClick={() =>
                history.push('/start-session/select-client?signup=true')
              }
              Icon={<MicAIOn width="32px" height="32px" />}
              title="Record Audio"
              description="Use during live sessions"
              isHorizontal={isCtaHorizontal}
              padding={ctaPadding}
            />
            <SessionTypeCTA
              id="pendo-sessions-start-session-dictate"
              Icon={<MicDictate width="32px" height="32px" />}
              title="Dictate Notes"
              description="Turn client visits into notes"
              onClick={() =>
                history.push('/start-session/dictate/select-client')
              }
              isHorizontal={isCtaHorizontal}
              padding={ctaPadding}
            />
            <SessionTypeCTA
              Icon={
                <MicUpload width="32px" height="32px" padding="0 0 0 5px" />
              }
              title="Upload Recording"
              description="Convert audio into notes"
              onClick={() => {
                if (documentationAutomationFreeTierSessionLimitReached) {
                  if (isAdmin) {
                    openDocAutomationPlanModal()
                  } else {
                    openDocAutomationSessionLimitReachedModal()
                  }
                } else {
                  setUploadModalOpen(true)
                }
              }}
              isHorizontal={isCtaHorizontal}
              padding={ctaPadding}
            />
          </Stack>

          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            mb="medium"
          >
            <SampleSessionsModule
              isGetStarted
              containerProps={{ mb: '30px' }}
            />
            {isAdmin && <ConsentModule isGetStarted />}
          </Flex>
        </GridItem>
      </LayoutGrid>
      <UploadModalV2
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
      />
    </Container>
  )
}
