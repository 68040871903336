import React from 'react'
import moment from 'moment'
import {
  mergeWith,
  merge,
  uniqBy,
  meanBy,
  groupBy,
  sortBy,
  reverse
} from 'lodash'
import { setConfig, buildImageUrl } from 'cloudinary-build-url'

import {
  newPatientTitle,
  newPreEnrollPatientBody,
  newPatientBody,
  unarchiveTitle,
  unarchiveBody,
  unarchiveTransferTitle,
  unarchiveTransferBody,
  transferTitle,
  transferBody,
  noOpTitle,
  noOpBody,
  subtext
} from './constants/Copy'
import { api } from './api'
import { ClientStatusBuckets } from '@constants/clientStatusBuckets'
import { CadenceUnits } from '@constants/cadenceUnits'
import gPhoneNumber from 'google-libphonenumber'

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance()

setConfig({
  cloudName: 'hellojoy'
})

const formatDateForPatientList = (date, shouldShowTime) => {
  if (!date) {
    return '--'
  }
  return (
    moment(date).fromNow() +
    ' (' +
    moment(date).format(`M/D${shouldShowTime ? ' h:mma' : ''}`) +
    ')'
  )
}

const renderSubstringAsLink = (string, substring, linkSource) => {
  let matches = RegExp(`(.*\n*)(${substring})(.*\n*)`, 'gi').exec(string)
  if (matches && matches.length) {
    return (
      <span>
        {matches['1']}
        <a
          style={{
            textDecoration: 'underline',
            color: 'inherit'
          }}
          href={linkSource}
        >
          {matches['2']}
        </a>
        {matches['3']}
      </span>
    )
  }
  return string
}

const generateCopy = (responseData, name, otherInfo = {}) => {
  const action = responseData.action_taken
  const commsSent = responseData.comms_sent
  const copy = {}

  switch (action) {
    case 'ENROLL_NEW_PATIENT':
    case 'ENROLL_PENDING_PATIENT':
      copy.title = newPatientTitle(name)
      copy.message = newPatientBody(name, commsSent)
      return copy

    case 'PRE_ENROLL_NEW_PATIENT':
    case 'PRE_ENROLL_PENDING_PATIENT': {
      copy.title = newPreEnrollPatientBody(
        name,
        formatDateOfInvite(otherInfo.dateOfInvite)
      )
      return copy
    }

    case 'UNARCHIVE':
      copy.title = unarchiveTitle(name)
      copy.message = unarchiveBody(name)
      return copy

    case 'UNARCHIVE_TRANSFER':
      copy.title = unarchiveTransferTitle(name)
      copy.message = unarchiveTransferBody(name)
      return copy

    case 'TRANSFER':
      copy.title = transferTitle(name)
      copy.message = transferBody(name)
      copy.subtext = subtext()
      return copy

    case 'NO_OP':
      copy.title = noOpTitle(name)
      copy.message = noOpBody(name)
      copy.subtext = subtext()
      return copy
  }
}

const formatScoreToLabel = (total_score, mapping) => {
  // used in full PDF and outcome section modal
  if (!total_score || !mapping || mapping.length === 0) {
    return total_score
  } else {
    for (var i = 0; i < mapping.length; i++) {
      if (total_score >= mapping[i].min && total_score < mapping[i].max) {
        return mapping[i].title
      }
    }
  }
}

// SF 6/30/2021 to handle situations where assessment content has changed
// since users' responses were saved in assessment_score,
// filter out responses to questions no longer in the assessment
const _filterOutOrphanedResponses = (
  userResponses,
  currentAssessmentQuestions
) => {
  return userResponses.filter(r =>
    currentAssessmentQuestions.map(q => q.key).includes(r.key)
  )
}

const formatAssessmentAnswers = (userAnswers, assessment) => {
  // used in full PDF and outcome section modal
  const questions = assessment.content.sections[0].questions
  const filteredUserAnswers = _filterOutOrphanedResponses(
    userAnswers,
    questions
  )
  return filteredUserAnswers.map((userAnswer, i) => {
    // get question object for current response + get copy for title
    const question = questions.find(q => q.key === userAnswer.key)
    const isFreeText =
      question.type === 'free_text' || question.type === 'number'
    const formatted_question = question ? question.title : ''

    //  get all answer objects bound to the question
    // by default, sections of questions have an answer set they all share
    let possibleQuestionAnswers = assessment.content.sections[0].answers
    // however some questions have custom answers, this checks and overrides the answer set if neccessary
    if (question && question.answers) {
      possibleQuestionAnswers = question.answers
    }

    const maxQuestionScore = possibleQuestionAnswers.reduce(
      (max, q) => (q.value > max ? q.value : max),
      0
    )

    // get the copy for the users selected response to the question
    let formatted_answer = ''
    // skipped questions to "Skipped"
    if (question.skippable && userAnswer.value === null) {
      formatted_answer = 'Skipped'
    } else if (isFreeText) {
      formatted_answer = userAnswer.value
    } else {
      let answerTitles = []
      if (userAnswer.answerValue) {
        const userAnswerIds =
          question.type === 'multi-select'
            ? userAnswer.answerValue.answerIds
            : [userAnswer.answerValue.answerId]
        answerTitles = userAnswerIds.map(answerId => {
          const match = possibleQuestionAnswers.find(a => a.id === answerId)
          return match.title
        })
      } else {
        // put single-response question values in arrays so they can be itereated over
        const userAnswerValues =
          (question.type === 'multi-select' &&
            Array.isArray(userAnswer.value)) ||
          (question.type !== 'multi-select' && Array.isArray(userAnswer.value))
            ? userAnswer.value
            : [userAnswer.value]
        // produce copy for all answers that were selected
        answerTitles = userAnswerValues.map(userAnswerValue => {
          const match = possibleQuestionAnswers.find(
            a => a.value === userAnswerValue
          )
          return match.title
        })
      }
      // join with semicolon delimter
      formatted_answer = answerTitles.join('; ')
    }
    return {
      index: i,
      key: formatted_question,
      value: formatted_answer,
      score: userAnswer.value,
      isFreeText,
      maxScore: maxQuestionScore,
      sinceLast: userAnswer.pointChange.sinceLast,
      sinceBaseline: userAnswer.pointChange.sinceBaseline,
      isReverseValence: (question && question.isReverseValence) || false
    }
  })
}

const calculateAge = DoB => {
  return moment().diff(DoB, 'years')
}

// use enum?
const doesPassAgeRule = (rule, age) => {
  const { value, operator } = rule
  switch (operator) {
    case 'equal_to':
      return age === value
    case 'greater_than':
      return age > value
    case 'less_than':
      return age < value
    case 'greater_than_or_equal_to':
      return age >= value
    case 'less_than_or_equal_to':
      return age <= value
    default:
      return undefined // or null? or something else?
  }
}

const mergeStyles = (baseStyles, customStyles) => {
  return mergeWith(baseStyles, customStyles, (oldValue, newValue) => {
    return oldValue && oldValue.indexOf(newValue) > -1
      ? oldValue
      : `${oldValue} ${newValue}`
  })
}

const mergeStyleObjects = (theme, baseStyles, customStyles) => {
  const base = baseStyles(theme)
  const custom = customStyles(theme)
  const merged = merge(base, custom)
  return merged
}

const getHostname = url => {
  return new URL(url).hostname
}

/*
 * Need to fetch PDF as a blob due to HTML embeds not having the capability of sending authorization headers with token based auth patterns.
 * More info here: https://blog.bguiz.com/post/90559955303/file-download-with-http-request-header/
 */
function fetchPdf(url) {
  return api.GET(url, { responseType: 'blob' }).then(res => {
    if (res.status === 404) {
      throw new Error('No PDF found.')
    }

    if (res.status !== 200) {
      throw new Error('Could not load PDF.')
    }

    return URL.createObjectURL(res.data)
  })
}

function openLinkInNewTab(url) {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function isDateObjectValidISO(dob) {
  return dob instanceof Date && !isNaN(dob)
}

function isDateObjectInPast(dob) {
  return dob < new Date()
}

function isPatientMissingContactInfo(patient) {
  return !patient.email && !patient.phone_number
}

function formatDateOfInvite(date) {
  return `${moment(date).format('dddd MMMM Do, YYYY')} at ${moment(date).format(
    'h:mma'
  )}`
}

function toSnakeCaseKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    const snakeCasedKey = key.replace(
      /[A-Z]/g,
      letter => `_${letter.toLowerCase()}`
    )
    acc[snakeCasedKey] = obj[key]

    return acc
  }, {})
}

const cloudinaryAssetToUrl = (asset, options = {}) => {
  return asset && asset.publicId && buildImageUrl(asset.publicId, options)
}

// update this to isolate original scores + point diffs
const dedupeAssessmentScores = assessment => {
  const groupedByDay = groupBy(assessment.scores, 'day')
  const { avgScoreByDay, assessmentScoresBreakdownByDay } = Object.keys(
    groupedByDay
  ).reduce(
    (acc, day) => {
      const dayScores = groupedByDay[day]
      const avgTotal = meanBy(dayScores, 'total_score')
      acc.avgScoreByDay[day] = avgTotal

      // an array of scores, score diffs and assignee users for that assessment on that day
      const additionalData = dayScores.map(score => ({
        score: score.total_score,
        point_change: score.pointChange,
        assignee_user: score.assigneeUser
      }))
      acc.assessmentScoresBreakdownByDay[day] = additionalData

      return acc
    },
    { avgScoreByDay: {}, assessmentScoresBreakdownByDay: {} }
  )

  const scoresWithAdditionalData = assessment.scores.map(score => ({
    ...score,

    additional_data: assessmentScoresBreakdownByDay[score.day],
    total_score: avgScoreByDay[score.day]
  }))

  // reverse the list so the latest assessment score on each day is kepy rather than the first
  const uniqueScoresPerDay = uniqBy(reverse(scoresWithAdditionalData), 'day')

  return {
    ...assessment,
    scores: sortBy(uniqueScoresPerDay, 'created_at')
  }
}

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const capitalizeWords = string => {
  return string
    .split(' ')
    .map(w => capitalizeFirstLetter(w))
    .join(' ')
}

const snakeToCapital = string => {
  return string
    .split('_')
    .map(w => capitalizeFirstLetter(w))
    .join(' ')
}

const convertWeeksToCorrectUnit = cadence => {
  const { unit, value } = cadence
  if (unit !== CadenceUnits.WEEKS) return cadence

  if (value === 12) {
    return { value: 3, unit: CadenceUnits.MONTHS }
  } else if (value === 26) {
    return { value: 6, unit: CadenceUnits.MONTHS }
  } else if (value === 52) {
    return { value: 1, unit: CadenceUnits.YEARS }
  } else {
    return cadence
  }
}

const formatCadence = (value, unit) => {
  if (unit === CadenceUnits.TIME && value === 1) {
    return 'One time'
  } else if (unit === CadenceUnits.WEEKS) {
    if (value === 1) {
      return 'Weekly'
    } else {
      return `Every ${value} weeks`
    }
  } else if (unit === CadenceUnits.DAYS) {
    if (value === 1) {
      return 'Daily'
    } else {
      return `Every ${value} days`
    }
  } else if (unit === CadenceUnits.MONTHS) {
    if (value === 1) {
      return 'Monthly'
    } else {
      return `Every ${value} months`
    }
  } else if (unit === CadenceUnits.YEARS) {
    if (value === 1) {
      return 'Yearly'
    } else {
      return `Every ${value} years`
    }
  } else if (unit === 'integration_driven') {
    return 'Managed via Integration'
  } else {
    return 'Daily'
  }
}

const getClientStatusLabel = status => {
  const map = {
    [ClientStatusBuckets.PENDING]: 'Pending',
    [ClientStatusBuckets.AWAITING_INVITE]: 'Awaiting Invite',
    [ClientStatusBuckets.INVITE_SENT]: 'Invite Sent',
    [ClientStatusBuckets.ACTIVE]: 'In Treatment',
    [ClientStatusBuckets.ARCHIVED]: 'Discharged',
    [ClientStatusBuckets.DECLINED]: 'Declined',
    [ClientStatusBuckets.UNKNOWN]: 'Unknown'
  }
  return map[status] || 'Unknown'
}

const formatScoreToInterpretation = (assessment, total_score) => {
  const mapping =
    assessment?.mapping || assessment?.content?.sections[0]?.mapping || null
  if (mapping && mapping.length > 0) {
    for (var i = 0; i < mapping.length; i++) {
      if (total_score >= mapping[i].min && total_score < mapping[i].max) {
        const label = mapping[i].title || mapping[i].label
        return label
      }
    }
  }
  return defaultFormatScoreToLabel(assessment, total_score)
}

const defaultFormatScoreToLabel = (assessment, total_score) => {
  const max_score = assessment.max_score || assessment.totalScore
  let normalized_score = parseFloat((total_score / max_score).toFixed(2))

  // To handle asseessments with a max_score of 0,
  // need to catch the divide-by-zero and make the interpretation
  // consistent with the tooltip in the graphs.
  if (isNaN(normalized_score)) return 'Low'

  if (normalized_score <= 0.24) return 'Low'
  if (normalized_score <= 0.49) return 'Mild'
  if (normalized_score <= 0.74) return 'Moderate'
  return 'High'
}

const copyToClipboard = copy => {
  return navigator.clipboard.writeText(copy)
}

function rotateArray(arr, idx) {
  return arr.slice(idx).concat(arr.slice(0, idx))
}

function formatPhone(phone) {
  const phoneNumber = phoneUtil.parseAndKeepRawInput(phone, 'US')
  return '+1' + phoneNumber.getNationalNumber()
}

function buildAssessmentTakeNowUrl(
  userId,
  assessmentIds,
  includeBaseUrl = true
) {
  const path = `/user/${userId}/assessments?context=clinician_app&assessments=${assessmentIds}`
  if (includeBaseUrl) {
    return `${process.env.REACT_APP_CLIENT_APP_URL}${path}`
  }
  return path
}

function buildAssessmentPreviewUrl(assessmentId) {
  return `${process.env.REACT_APP_CLIENT_APP_URL}/assessment-preview?assessment=${assessmentId}`
}

function buildCheckInPreviewUrl(checkInId, versionId) {
  let url = `${process.env.REACT_APP_CLIENT_APP_URL}/check-ins/${checkInId}/preview`

  if (versionId) {
    url += `?versionId=${versionId}`
  }

  return url
}

function formatDollarAmount(amountStr) {
  // Convert the string to a number
  let amount = parseFloat(amountStr)

  // Check if the conversion was successful
  if (isNaN(amount)) {
    return '0'
  }

  // Determine if the amount has cents
  const hasCents = amount % 1 !== 0

  // Convert the number back to a string with or without decimal places based on the cents
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: hasCents ? 2 : 0,
    maximumFractionDigits: hasCents ? 2 : 0
  })
}

export {
  buildCheckInPreviewUrl,
  buildAssessmentTakeNowUrl,
  buildAssessmentPreviewUrl,
  isPatientMissingContactInfo,
  fetchPdf,
  formatDateForPatientList,
  formatPhone,
  renderSubstringAsLink,
  generateCopy,
  formatScoreToLabel,
  formatAssessmentAnswers,
  calculateAge,
  doesPassAgeRule,
  mergeStyles,
  mergeStyleObjects,
  getHostname,
  openLinkInNewTab,
  isDateObjectValidISO,
  isDateObjectInPast,
  formatDateOfInvite,
  toSnakeCaseKeys,
  cloudinaryAssetToUrl,
  dedupeAssessmentScores,
  capitalizeFirstLetter,
  capitalizeWords,
  snakeToCapital,
  formatCadence,
  getClientStatusLabel,
  formatScoreToInterpretation,
  convertWeeksToCorrectUnit,
  copyToClipboard,
  rotateArray,
  formatDollarAmount
}
