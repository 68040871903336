import React from 'react'
import { Box, Flex, Container } from '@chakra-ui/react'
import NoteBox from './note-box'
import { useStoreState } from 'easy-peasy'

const DocSnippets = ({ session, handleTrackEvent }: any) => {
  const { height: paywallBannerHeight } = useStoreState(
    state => state.paywallBanner
  )

  const docSnippets = session.docSnippets || session.progressNotes
  return (
    <Container
      overflowY="scroll"
      height={`calc(100vh - 141px - ${paywallBannerHeight}px)`}
    >
      <Flex flexDirection="column" flex="1">
        <NoteBox
          type="docSnippet"
          field="criticalEvents"
          buttonId="pendo-copy-critical-events-tag"
          label="Critical Events"
          value={docSnippets.criticalEvents}
          emptyMessage="No critical events"
          trackEvent={handleTrackEvent}
        />
        <NoteBox
          type="docSnippet"
          field="checkIns"
          buttonId="pendo-copy-checkin-tag"
          label="Check-In"
          value={docSnippets.checkIns}
          emptyMessage="No mood scores reported"
          trackEvent={handleTrackEvent}
        />
        <NoteBox
          type="docSnippet"
          field="lastSessionRecap"
          buttonId="pendo-copy-recap-last-session-tag"
          label="Recap Last Session"
          value={docSnippets.lastSessionRecap}
          emptyMessage="No previous session to recap"
          trackEvent={handleTrackEvent}
        />
        <NoteBox
          type="docSnippet"
          field="completedInterventions"
          buttonId="pendo-copy-completed-interventions-tag"
          label="Completed Interventions"
          value={docSnippets.completedInterventions}
          emptyMessage="No interventions completed during session"
          trackEvent={handleTrackEvent}
        />
        <NoteBox
          type="docSnippet"
          field="assessmentProgress"
          buttonId="pendo-copy-assessment-progress-tag"
          label="Assessment Progress"
          value={docSnippets.assessmentProgress}
          emptyMessage="No assessment progress"
          trackEvent={handleTrackEvent}
        />
        <NoteBox
          type="docSnippet"
          field="plan"
          buttonId="pendo-copy-plan-tag"
          label="Plan"
          value={docSnippets.plan}
          emptyMessage="No plan"
          trackEvent={handleTrackEvent}
        />
        <Flex pb="1px" />
      </Flex>
    </Container>
  )
}

export default DocSnippets
