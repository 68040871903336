import React from 'react'
import { Alert, AlertIcon, Flex } from '@chakra-ui/react'

const TranscriptionError = ({ isExtension = false, isWidget = false }) => (
  <Alert
    status="info"
    borderRadius="md"
    colorScheme={isExtension || isWidget ? 'white' : '#EB516414'}
    p="small"
    mb="16px"
  >
    <AlertIcon style={{ color: '#EB5164' }} marginRight="sm" />
    <Flex flexDirection="column">
      Unfortunately we could not process a transcript for this session. This
      session will not be counted towards your usage.
    </Flex>
  </Alert>
)

export default TranscriptionError
