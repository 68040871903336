import React, { useState } from 'react'
import {
  Button,
  Textarea,
  useToast,
  IconButton,
  Box,
  Flex,
  Text
} from '@chakra-ui/react'
import { useSessionControllerTrackEvent } from '~/clinician-api'
import { ThumbUpIcon, ThumbDownIcon } from '@icons'

type FeedbackType = 'Poor' | 'Okay' | 'Great'

interface ProgressNoteFeedbackProps {
  sessionId: string
  hasMultipleVersions: boolean
  progressNotes: any[]
  selectedProgressNoteId: string
  setSelectedProgressNoteId: any
}

const numericalRatings: Record<FeedbackType, number> = {
  Poor: 0,
  Okay: 1,
  Great: 2
}

const placeholders: Record<FeedbackType, string> = {
  Poor: 'What was off about the note?',
  Okay: 'How could this note be improved?',
  Great: "We'd love to hear more!"
}

const ProgressNoteFeedback: React.FC<ProgressNoteFeedbackProps> = props => {
  const {
    hasMultipleVersions,
    sessionId,
    progressNotes,
    selectedProgressNoteId,
    setSelectedProgressNoteId
  } = props
  const [selected, setSelected] = useState<FeedbackType | string | undefined>(
    undefined
  )
  const [feedback, setFeedback] = useState<string>('')
  const toast = useToast()

  const {
    mutate: trackEvent,
    isLoading: isSubmittingTrackEvent
  } = useSessionControllerTrackEvent()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    trackEvent(
      {
        id: sessionId,
        data: {
          eventType: 'progress_note_feedback_submitted',
          payload: {
            feedback,
            rating: numericalRatings[selected as FeedbackType],
            noteType: 'soap'
          }
        }
      },
      {
        onSuccess: () => {
          toast({
            title: 'Feedback submitted',
            description: 'Thanks for the feedback!',
            status: 'success',
            duration: 3000,
            isClosable: true
          })
          localStorage.setItem(`feedback-submitted/${sessionId}`, 'true')
        }
      }
    )
  }

  const hasSubmittedFeedback = localStorage.getItem(
    `feedback-submitted/${sessionId}`
  )

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        borderRadius="4px"
        border="1px solid"
        borderColor="pale_gray"
        h="35px"
        alignItems="center"
        {...(hasSubmittedFeedback && !hasMultipleVersions
          ? { visibility: 'hidden' }
          : {})}
      >
        {hasMultipleVersions && (
          <Box
            {...(hasSubmittedFeedback
              ? {}
              : { borderRight: '1px solid #E4E5E6' })}
            p="0 6px"
          >
            <select
              style={{
                border: 'none',
                height: '33px',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
                paddingLeft: '5px'
              }}
              value={selectedProgressNoteId}
              onChange={e => setSelectedProgressNoteId(e.target.value)}
            >
              {progressNotes.map((pn: any, index: number) => {
                return (
                  <option key={pn.id} value={pn.id}>
                    {`v${progressNotes.length - index} `}
                  </option>
                )
              })}
            </select>
          </Box>
        )}
        {!hasSubmittedFeedback && (
          <Flex alignItems="center">
            <Text ml={2} display={{ base: 'none', sm: 'block'}}>Rate Note:</Text>
            <IconButton
              aria-label="thumbs-up"
              variant="ghost"
              minWidth="0"
              color={selected === 'Great' ? 'primary' : 'light_gray'}
              _focus={{
                boxShadow: 'none'
              }}
              marginLeft="8px"
              onClick={() => {
                if (selected === 'Great') {
                  setSelected(undefined)
                } else {
                  setSelected('Great')
                }
              }}
              icon={<ThumbUpIcon fill="#2D54E8" />}
            />
            <IconButton
              aria-label="thumbs-down"
              variant="ghost"
              minWidth="0"
              color={selected === 'Poor' ? 'primary' : 'light_gray'}
              _focus={{
                boxShadow: 'none'
              }}
              marginRight="8px"
              onClick={() => {
                if (selected === 'Poor') {
                  setSelected(undefined)
                } else {
                  setSelected('Poor')
                }
              }}
              icon={<ThumbDownIcon fill="#2D54E8" />}
            />
          </Flex>
        )}
      </Flex>
      {selected && !hasSubmittedFeedback && (
        <Box
          position="absolute"
          top="-190px"
          bg="white"
          p="small"
          mt="24px"
          borderRadius="4px"
          border="1px solid"
          borderColor="pale_gray"
          boxShadow="0px 2px 8px 0px #00000014"
          style={{
            marginInlineStart: '0px'
          }}
          width="301px"
        >
          <Textarea
            placeholder={placeholders[selected as FeedbackType]}
            borderColor="pale_gray"
            onChange={e => setFeedback(e.target.value)}
          />
          <Flex mt="16px">
            <Button
              flex="1"
              type="submit"
              isDisabled={!selected}
              isLoading={isSubmittingTrackEvent}
              variant="outline"
              _hover={{ opacity: 0.7 }}
              _focus={{ outline: 'none' }}
            >
              Skip
            </Button>
            <Button
              flex="1"
              type="submit"
              colorScheme="primary"
              isDisabled={!selected}
              isLoading={isSubmittingTrackEvent}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      )}
    </form>
  )
}

export default ProgressNoteFeedback
