import React from 'react'
import Select, { components } from 'react-select'

type Option = {
  value: string
  label: string | undefined
}

type GroupOption = {
  label: string
  options: Option[]
}

type Props = {
  placeholder: string
  value: Option | undefined
  options: Option[] | GroupOption[] | undefined
  onChange: any
  onSearch?: any
  errors: any
  isOptionDisabled?: Function
  isDisabled?: boolean
}

const DropdownIcon = () => (
  <div
    style={{
      width: '1.5rem',
      height: '100%',
      fontSize: '1.25rem',
      marginRight: '8px'
    }}
  >
    <svg
      viewBox="0 0 24 24"
      role="presentation"
      focusable="false"
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z"
      ></path>
    </svg>
  </div>
)

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon />
    </components.DropdownIndicator>
  )
}

export const SearchSelect = ({
  placeholder,
  options = [],
  onChange,
  onSearch,
  errors,
  value,
  isOptionDisabled,
  isDisabled
}: Props) => {
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      value={value}
      options={options}
      onChange={onChange}
      onInputChange={onSearch}
      isDisabled={isDisabled}
      isOptionDisabled={isOptionDisabled}
      components={{
        DropdownIndicator
      }}
      placeholder={placeholder}
      styles={{
        indicatorSeparator: () => ({
          display: 'none'
        }),
        dropdownIndicator: (baseStyles: any) => ({
          ...baseStyles,
          color: '#000000'
        }),
        container: (baseStyles: any) => ({
          ...baseStyles,
          width: '100%'
        }),
        valueContainer: (baseStyles: any) => ({
          ...baseStyles,
          padding: '6px'
        }),
        control: (baseStyles: any) => ({
          ...baseStyles,
          borderRadius: '0.375rem',
          borderColor: errors ? '#B5342A' : '#C9C9C9',
          marginTop: '8px',
          paddingInlineStart: '8px',
          paddingTop: '1px',
          paddingBottom: '1px'
        }),
        menu: (baseStyles: any) => ({
          ...baseStyles,
          zIndex: 9999
        })
      }}
    />
  )
}
