import { Formik, Field, Form, FormikState, FieldProps } from 'formik'
import React, { useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Textarea,
  Box,
  useToast
} from '@chakra-ui/react'
import { TextField } from '@blueprinthq/joy'
import { authedApi } from '../../../api/authed-api'
import { Workflow } from './workflow-editor/workflow-editor'
import { endpoints } from '@api'

interface WorkflowModalProps {
  workflow?: Workflow
  isOpen: boolean
  onClose: Function
  selectedOrganizationId?: string | undefined
}

interface CreateWorkflowFormValues {
  name: string | undefined
  description: string | undefined
}

interface CreateWorkflowDto {
  organizationId: string
  name: string | undefined
  description: string | undefined
}

const workflowFormValidationSchema = Yup.object().shape({
  name: Yup.string().required('You must provide a workflow name'),
  description: Yup.string().optional()
})

const createWorkflow = async (values: CreateWorkflowDto) => {
  return authedApi.POST(
    `/organizations/${values.organizationId}/workflows`,
    values
  )
}

export const WorkflowModal = ({
  workflow,
  onClose,
  isOpen,
  selectedOrganizationId
}: WorkflowModalProps) => {
  const history = useHistory()
  const { mutateAsync } = useMutation(createWorkflow)
  const { user } = useStoreState(state => state.auth)
  const queryClient = useQueryClient()
  const toast = useToast()
  const nameInputRef = useRef(null)

  const handleSubmit = async (values: CreateWorkflowFormValues) => {
    if (!workflow) {
      const dto: CreateWorkflowDto = {
        organizationId: selectedOrganizationId || user?.clinic?.organization_id,
        name: values.name,
        description: values.description
      }

      if (dto.description === '') {
        dto.description = undefined
      }

      const response = await mutateAsync(dto)
      history.push(
        `/organizations/${user?.clinic?.organization_id}/workflows/${response.id}`
      )
    } else {
      updateWorkflow({
        organizationId: workflow?.organizationId,
        workflowId: workflow?.id,
        data: { name: values.name, description: values.description }
      })
    }
  }

  const { mutate: updateWorkflow }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.updateOrganizationWorkflow.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getOrganizationWorkflow.getCacheId()
        )
        toast({
          description: 'Workflow updated!',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        onClose()
      }
    }
  )

  const { mutate: deleteWorkflow }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.deleteOrganizationWorkflow.request,
    {
      onSuccess: () => {
        toast({
          description: 'Workflow deleted!',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        history.push('/settings/workflows')
      }
    }
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={{
            name: workflow?.name,
            description: workflow?.description
          }}
          onSubmit={handleSubmit}
          validationSchema={workflowFormValidationSchema}
        >
          {({
            errors,
            touched,
            isSubmitting
          }: FormikState<CreateWorkflowFormValues>) => (
            <Form>
              <Box
                display="flex"
                justifyContent="space-between"
                padding="medium"
                borderTopRadius="4px"
                borderBottom="1px solid #E4E5E6"
                mb="small"
              >
                <Text as="b" fontSize="lg" alignSelf="center">
                  {workflow ? 'Edit Workflow' : 'Create New Workflow'}
                </Text>
                <ModalCloseButton
                  _focus={{ outline: 'none' }}
                  position="static"
                  borderRadius="24px"
                />
              </Box>
              <ModalBody>
                <Text as="b">Workflow name</Text>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      mt="small"
                      label={''}
                      ref={nameInputRef}
                      placeholder="Add Workflow name"
                      isInvalid={Boolean(errors.name && touched.name)}
                      errorText={errors.name}
                    />
                  )}
                </Field>
                <Box mb="medium" />
                <Text as="b" mt="large">
                  Workflow description
                </Text>
                <Field name="description">
                  {({ field }: FieldProps) => (
                    <Textarea
                      mt="small"
                      {...field}
                      isInvalid={
                        errors.description && touched.description ? true : false
                      }
                      placeholder="Add Workflow description"
                      borderColor="light_gray"
                      borderRadius="md"
                    />
                  )}
                </Field>
              </ModalBody>
              <ModalFooter
                borderTop="1px solid #E4E5E6"
                display="flex"
                justifyContent="space-between"
                mt="small"
              >
                <Text
                  color="error"
                  onClick={() =>
                    deleteWorkflow({
                      organizationId: workflow?.organizationId,
                      workflowId: workflow?.id
                    })
                  }
                  _hover={{ cursor: 'pointer' }}
                  visibility={workflow ? 'visible' : 'hidden'}
                >
                  Delete Workflow
                </Text>
                <Box>
                  <Button
                    size="lg"
                    variant="outline"
                    mr={3}
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="primary"
                    variant="solid"
                    type="submit"
                    isLoading={isSubmitting}
                    size="lg"
                  >
                    {workflow ? 'Save' : 'Create Workflow'}
                  </Button>
                </Box>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
