import React from 'react'
import {
  Box,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import { Download, Print } from '@blueprinthq/joy'

import moment from 'moment'

export const ReviewConsentModal = ({
  isOpen,
  onClose,
  data
}: {
  isOpen: boolean
  onClose: () => void
  data: any
}) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay bg="blackAlpha.900" />
      <ModalContent>
        <ModalHeader fontSize="20px" textAlign="center" p={5}>
          Review Consent
          <Box>
            <ModalCloseButton _focus={{ outline: 'none' }} />
          </Box>
        </ModalHeader>
        <Divider />
        <ModalBody id="modal-body">
          <Text pt="small">{data.consentCopy}</Text>
          <HStack alignItems="flex-start" spacing="xlarge" my="small">
            <Box>
              <Text fontWeight="bold">Signed</Text>
              <Text>{data.signedBy}</Text>
              <Text fontFamily="'Adventures Unlimited', cursive, sans-serif">
                {data.signedBy}
              </Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Date Signed</Text>
              <Text>{moment.utc(data.dateObtained).format('M/DD/YYYY')}</Text>
            </Box>
          </HStack>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Flex w="100%" justify="space-between">
            <HStack spacing="medium">
              <HStack
                spacing="xsmall"
                _hover={{
                  cursor: 'pointer'
                }}
                onClick={() => window.print()}
              >
                <Print fill="primary" />
                <Text color="primary">Print</Text>
              </HStack>
              <HStack
                spacing={1}
                _hover={{
                  cursor: 'pointer'
                }}
                onClick={() => window.print()}
              >
                <Download fill="primary" />
                <Text color="primary">Download</Text>
              </HStack>
            </HStack>
            <Text
              color="primary"
              _hover={{
                cursor: 'pointer'
              }}
              onClick={onClose}
            >
              Close
            </Text>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
