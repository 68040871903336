import { authModel } from './auth'
import { patientListModel } from './patient-list'
import { insightsModel } from './insights'
import { quickEnrollModel } from './quick-enroll'
import { modalsModel } from './modals'
import { snackbarModel } from './snackbar'
import { patientProfileModal } from './patient-profile'
import { billingModel } from './billing'
import { enrollModel } from './enroll'
import { clinicianSignupModel } from './clinician-signup'
import { chimeModel } from './chime'
import { workflowsModel } from './workflows'
import { paywallBannerModel } from './paywall-banner'

export default {
  ...authModel,
  ...patientListModel,
  ...insightsModel,
  ...quickEnrollModel,
  ...modalsModel,
  ...snackbarModel,
  ...patientProfileModal,
  ...billingModel,
  ...enrollModel,
  ...clinicianSignupModel,
  ...chimeModel,
  ...workflowsModel,
  ...paywallBannerModel
}
