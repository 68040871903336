import React from 'react'
import { Stack, Text, Box } from '@chakra-ui/react'
import moment from 'moment'
import { useTreatmentPlan } from '@hooks'

const ClientDetails = () => {
  const { client, treatmentPlan } = useTreatmentPlan()
  const { first_name, last_name, date_of_birth } = client
  const { updatedAt } = treatmentPlan


  return (
    <Stack spacing="0">
      <Text>
        <b>Name: </b>
        {`${first_name} ${last_name}`}
      </Text>
      <Text>
        <b>DOB: </b>
        {date_of_birth ? moment(date_of_birth).format('M/D/YYYY') : 'N/A'}
      </Text>
      <Text>
        <b>Last Updated: </b>
        {updatedAt ? moment(updatedAt).format('M/D/YYYY') : 'N/A'}
      </Text>
    </Stack>
  )
}

export default ClientDetails
