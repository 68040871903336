import React from 'react'
import {
  Box,
  Heading,
  Text,
  Flex,
  Select,
  Link,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import { Button, Select as JoySelect } from '@blueprinthq/joy'
import { Link as RouterLink } from 'react-router-dom'

import { usePermissions } from '../../../../hooks'
import { useClinicSelectControl } from '@hooks'

const AddUserButton = ({ openUpsertClinicianModal }) => {
  const { hasPermission } = usePermissions()

  return (
    hasPermission('create:org:providers:account') && (
      <Button size={'lg'} onClick={() => openUpsertClinicianModal(null)}>
        Invite User
      </Button>
    )
  )
}

const HeaderText = ({ boxWidth, user, openUpsertClinicianModal, show }) => {
  const { hasPermission } = usePermissions()
  return (
    <Box>
      <Flex justify="space-between" alignItems="center" flexDirection="row">
        <Box w="100%">
          <Heading mb="xxsmall">Users</Heading>
          <Text display={'inline-flex'}>
            Manage who has access to this organization
            {hasPermission('*:any:*:*') && (
              <>
                <Text>.</Text>{' '}
                <Link
                  as={RouterLink}
                  color="primary"
                  ml="5px"
                  to={{
                    pathname: `/settings/users/upload`
                  }}
                >
                  <Text>Bulk Uploader</Text>
                </Link>
              </>
            )}
          </Text>
        </Box>
        {show && !user.organization.isDirectorySync ? (
          <AddUserButton openUpsertClinicianModal={openUpsertClinicianModal} />
        ) : null}
      </Flex>
    </Box>
  )
}

export const UsersHeader = ({
  setSelectedClinic,
  selectedClinic,
  clinics,
  openUpsertClinicianModal,
  user
}) => {
  const { hasPermission } = usePermissions()
  const { clinicSelectEnabled } = useClinicSelectControl(clinics.length)

  const isAdmin =
    user &&
    user?.roles?.some(
      r =>
        r.name === 'orgadmin' ||
        r.name === 'clinicadmin' ||
        r.name === 'superadmin'
    )

  if (clinicSelectEnabled) {
    return (
      <>
        <HeaderText
          boxWidth="100%"
          openUpsertClinicianModal={openUpsertClinicianModal}
          user={user}
          show={true}
        />

        <Flex
          justify="space-between"
          alignItems="center"
          flexDirection="column"
        >
          <Flex justify="space-between" alignItems="center" w="100%">
            <Box w="100%" mb="small" mt="medium">
              {hasPermission('read:any:clinics:list') ? (
                <Select
                  value={selectedClinic.id}
                  _focus={{
                    outline: 'none'
                  }}
                  onChange={e =>
                    setSelectedClinic(
                      clinics.find(c => c.id === e.target.value)
                    )
                  }
                  style={{ border: '1px solid #E4E5E6' }}
                  borderRadius="4px"
                  height="48px"
                >
                  {clinics.map(c => {
                    return (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    )
                  })}
                </Select>
              ) : (
                <JoySelect
                  value={selectedClinic}
                  options={clinics}
                  valueKey="id"
                  labelKey="name"
                  onChange={value => setSelectedClinic(value)}
                />
              )}
            </Box>
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Flex justify="space-between" alignItems="center" mb="medium">
      <HeaderText
        user={user}
        openUpsertClinicianModal={openUpsertClinicianModal}
        show={false}
        boxWidth="auto"
      />
      {isAdmin && !user.organization.isDirectorySync ? (
        <AddUserButton openUpsertClinicianModal={openUpsertClinicianModal} />
      ) : null}
    </Flex>
  )
}
