import React, { useMemo } from 'react'
import moment from 'moment'
import { Button, Box, VStack, HStack } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { TextField, Select } from '@blueprinthq/joy'
import {
  DialogContainer,
  DialogBody,
  DialogHeader
} from '@handlers/sessions/components/dialog'
import { BPDateTimePicker } from '@components'

import { CLIENT_PRONOUNS } from '@constants/clientPronouns'
import { useExperienceManager } from '@hooks'

interface AddClientDialogProps {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onSubmit: ({
    firstName,
    lastName,
    dateOfBirth,
    pronoun
  }: {
    firstName: string
    lastName: string
    dateOfBirth: string | null
    pronoun: string | null
  }) => void
}

const initialValues = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  pronoun: null
}

const pronounTypes = Object.keys(CLIENT_PRONOUNS)

const baseSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  pronoun: Yup.string()
    .optional()
    .nullable()
})

type FormValues = {
  firstName: string
  lastName: string
  dateOfBirth: string | null
  pronoun: string | null
}

export const AddClientDialog = ({
  isOpen,
  isLoading,
  onClose,
  onSubmit
}: AddClientDialogProps) => {
  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  const validationSchema = useMemo(() => {
    let schema = baseSchema

    if (isEvidenceBasedCareEnabled) {
      schema = schema.shape({
        dateOfBirth: Yup.date()
          .max(moment(), 'Date of birth must be in the past')
          .required('Required')
      })
    }
  }, [isEvidenceBasedCareEnabled])

  return (
    <DialogContainer isOpen={isOpen} onClose={onClose}>
      <DialogHeader text="Add Client" onClose={onClose} />
      <DialogBody>
        <Formik<FormValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            isValid,
            dirty
          }: {
            errors: any
            touched: any
            isValid: boolean
            dirty: boolean
          }) => (
            <Form style={{ width: '100%' }}>
              <VStack spacing={4}>
                <HStack
                  spacing="16px"
                  w="100%"
                  pb={
                    (errors.firstName && touched.firstName) ||
                    (errors.lastName && touched.lastName)
                      ? 'small'
                      : '0'
                  }
                >
                  <Field name="firstName">
                    {({ field }: { field: any }) => (
                      <TextField
                        {...field}
                        label="First Name"
                        isInvalid={errors.firstName && touched.firstName}
                        errorText={errors.firstName}
                        isRequired
                      />
                    )}
                  </Field>
                  <Field name="lastName">
                    {({ field }: { field: any }) => (
                      <TextField
                        {...field}
                        label="Last Name"
                        isInvalid={errors.lastName && touched.lastName}
                        errorText={errors.lastName}
                        isRequired
                      />
                    )}
                  </Field>
                </HStack>
                <Box
                  pb={errors.dateOfBirth && touched.dateOfBirth ? 'small' : '0'}
                  width="100%"
                >
                  <HStack
                    spacing="16px"
                    w="100%"
                    pb={
                      (errors.dateOfBirth && touched.dateOfBirth) ||
                      (errors.pronoun && touched.pronoun)
                        ? 'small'
                        : '0'
                    }
                  >
                    {isEvidenceBasedCareEnabled && (
                      <Field name="dateOfBirth">
                        {({ field }: { field: any }) => (
                          <BPDateTimePicker
                            {...field}
                            showDate
                            isRequired
                            label="Date of Birth"
                            isInvalid={
                              errors.dateOfBirth && touched.dateOfBirth
                            }
                            errorText={
                              (errors.dateOfBirth &&
                                errors.dateOfBirth.includes('Invalid') &&
                                'Invalid date') ||
                              errors.dateOfBirth
                            }
                          />
                        )}
                      </Field>
                    )}
                    <Field name="pronoun">
                      {({ field, form }: { field: any; form: any }) => (
                        <Select
                          label="Pronouns"
                          borderColor="lightgrey"
                          fontSize="medium"
                          value={field.value}
                          onChange={value =>
                            form.setFieldValue('pronoun', value)
                          }
                          options={pronounTypes}
                        />
                      )}
                    </Field>
                  </HStack>
                </Box>
                <Button
                  type="submit"
                  isFullWidth
                  isLoading={isLoading}
                  isDisabled={!isValid || !dirty}
                >
                  Add Client
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </DialogBody>
    </DialogContainer>
  )
}
