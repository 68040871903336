import { LayoutGrid } from '@blueprinthq/joy'
import {
  GridItem,
  Text,
  Box,
  HStack,
  useDisclosure,
  useToast,
  Flex
} from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import React, { useMemo, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { UAParser } from 'ua-parser-js'
import {
  SessionAgenda,
  PsychotherapyNoteEditor,
  Footer,
  AINotetakerPanel,
  StartRecordingDialog,
  PanelHeader,
  PanelBody,
  DictationTipsPanel,
  InSessionAssistPanel,
  InSessionAssistWaitlistPanel
} from './components'
import { SessionRecapResponse } from '~/clinician-api/models'
import {
  useSessionControllerTrackEvent,
  useSessionControllerPostMarkComplete,
  useSessionControllerPostDiscardSession
} from '~/clinician-api'
import { HighRiskToggle } from '@components'
import { CompassOutlined } from '@components/icons'
import { usePanelManager, useAudioCapture, useExperienceManager } from '@hooks'
import { datadogLogs } from '@datadog/browser-logs'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { SessionWrapper } from './session-wrapper'
import { trackEvent } from '@lib/clinician-tracking'
import { EndSessionModalV2 } from './components/end-session-modal-v2'

type SessionActivityV2PropsT = {
  client: {
    id: string
    first_name: string
    last_name: string
    isChildAge: boolean
    isHighRisk: boolean
    is_demo: boolean
    clinic_id: string
  }
  recap?: SessionRecapResponse
  sessionId: string
  aiNotetakerEnabled?: boolean
  refetchRecap: () => void
  isDictation: boolean
}

type RouteParams = {
  id: string
  sessionId: string
}

function determineGridColumns({
  isAssistOpen,
  isSessionAgendaOpen,
  isSessionNotesOpen,
  isDictationTipsOpen,
  isDictation
}: {
  isAssistOpen: boolean
  isSessionAgendaOpen: boolean
  isSessionNotesOpen: boolean
  isDictationTipsOpen: boolean
  isDictation: boolean
}) {
  const columns = {
    aiNotetaker: {
      start: { base: 1, sm: 1, md: 1 },
      end: { base: 13, sm: 13, md: 13 }
    },
    sessionAgenda: {
      start: { base: 1, sm: 1, md: 4 },
      end: { base: 13, sm: 13, md: 10 }
    },
    psychotherapyNotes: {
      start: { base: 1, sm: 1, md: 10 },
      end: { base: 13, sm: 13, md: 13 }
    },
    dictationTips: {
      start: { base: 1, sm: 1, md: 10 },
      end: { base: 13, sm: 13, md: 13 }
    }
  }

  if (isDictation && !isDictationTipsOpen) {
    columns.aiNotetaker.end.md = 13
  } else if (isDictation && isDictationTipsOpen) {
    columns.aiNotetaker.end.md = 8
    columns.dictationTips.start.md = 8
  }

  if (isSessionAgendaOpen && !isSessionNotesOpen) {
    columns.aiNotetaker.end.md = 4
    columns.sessionAgenda.start.md = 4
    columns.sessionAgenda.end.md = 13
  } else if (!isSessionAgendaOpen && isSessionNotesOpen) {
    columns.aiNotetaker.end.md = 10
    columns.psychotherapyNotes.start.md = 10
  } else if (isSessionAgendaOpen && isSessionNotesOpen) {
    columns.aiNotetaker.end.md = 4
    columns.sessionAgenda.start.md = 4
    columns.sessionAgenda.end.md = 10
    columns.psychotherapyNotes.start.md = 10
  }

  return columns
}

const LOCAL_STORAGE_STOP_RECORDING_AFTER = 'stopRecordingAfter'

export const SessionActivityV2 = ({
  client,
  recap,
  sessionId,
  aiNotetakerEnabled = false,
  isDictation = false
}: SessionActivityV2PropsT) => {
  const { data: user }: { data: any } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const history = useHistory()
  const toast = useToast()
  const { mutate: trackSessionEvent } = useSessionControllerTrackEvent()

  const handleTrackEvent = (event: string, payload: object) => {
    trackSessionEvent({ id: sessionId, data: { eventType: event, payload } })
  }

  const [isEndingSession, setIsEndingSession] = useState(false)
  const [isEndModalOpen, setEndModalOpen] = useState(false)
  const [recordingDuration, setRecordingDuration] = useState(0)
  const [noteType, setNoteType] = useState('soap')
  const [wakeLockEnabled, setWakeLockEnabled] = useState(false)

  const {
    documentationAutomationFreeTierSessionLimitReached,
    isEvidenceBasedCareEnabled,
    isAdmin,
    isSafari,
    isExtension,
    isWidget,
    isExtensionMinimized,
    isAssistEnabled
  } = useExperienceManager()

  const {
    startRecording,
    testAudioInputs,
    hasMicAccess,
    isMuted,
    disableEchoCancelation,
    isRecording,
    isRecordingLoading,
    startContentShare,
    stopRecording,
    logData: baseLogData,
    hasDetectedAudio,
    showMicWarning,
    isUsingHeadphones,
    setIsUsingHeadphones,
    isTelehealth,
    setIsTelehealth,
    isUploadComplete,
    setRecordingCutoffTime,
    selectedAudioInputLabel,
    stopRecordingAfter,
    setStopRecordingAfter,
    isStopRecordingAfterEnabled,
    setIsStopRecordingAfterEnabled
  } = useAudioCapture()

  const deviceInfo = new UAParser().getResult()

  const logData = {
    ...baseLogData,
    isHidden: document.hidden,
    clientId: client.id,
    recordingDuration,
    wakeLockEnabled,
    deviceInfo: new UAParser().getResult()
  }

  const {
    isSessionAgendaOpen,
    isSessionNotesOpen,
    toggleSessionNotes,
    toggleSessionAgenda,
    isDictationTipsOpen,
    toggleDictationTips,
    isAssistOpen,
    toggleAssist
  } = usePanelManager()

  const {
    isOpen: isStartRecordingOpen,
    onOpen: openStartRecording,
    onClose: closeStartRecording
  } = useDisclosure()

  const { mutateAsync: endSession } = useSessionControllerPostMarkComplete()

  const {
    mutateAsync: discardSession,
    isLoading: isDiscarding
  } = useSessionControllerPostDiscardSession()

  const { id: clientId } = useParams<RouteParams>()

  const { data: lastSession }: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId()],
    () =>
      endpoints.getClinicianClientLastSession.request({
        id: clientId
      })
  )

  useEffect(() => {
    const handleVisibilityChange = () => {
      const parser = new UAParser()

      if (document.hidden) {
        datadogLogs.logger.warn('[Session] Page is hidden', {
          ...logData
        })
      } else {
        datadogLogs.logger.info('[Session] Page is visible', {
          ...logData
        })
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    // set the default recording duration for a given client ID
    const config = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_STOP_RECORDING_AFTER) || '{}'
    )
    const clientConfig = config[clientId] || {}
    setStopRecordingAfter(clientConfig.duration || '60')
    setIsStopRecordingAfterEnabled(clientConfig.isEnabled || false)
  }, [])

  useEffect(() => {
    // update recording duration for client ID
    const config = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_STOP_RECORDING_AFTER) || '{}'
    )

    const clientConfig = config[clientId] || {}

    clientConfig.duration = stopRecordingAfter
    clientConfig.isEnabled = isStopRecordingAfterEnabled

    localStorage.setItem(
      LOCAL_STORAGE_STOP_RECORDING_AFTER,
      JSON.stringify({
        ...config,
        [clientId]: clientConfig
      })
    )
  }, [stopRecordingAfter, isStopRecordingAfterEnabled])

  useEffect(() => {
    if (lastSession && !recap?.recordingStartedAt) {
      setIsTelehealth(lastSession.isTelehealth)
      setIsUsingHeadphones(lastSession.isUsingHeadphones)
    }
  }, [lastSession, recap?.recordingStartedAt])

  useEffect(() => {
    const requestWakeLock = async () => {
      try {
        if ('wakeLock' in navigator) {
          // @ts-ignore
          await navigator.wakeLock.request('screen')
          setWakeLockEnabled(true)
        }
      } catch (err) {
        datadogLogs.logger.warn('[Session] Unable to request wake lock', {
          err,
          ...logData
        })
        setWakeLockEnabled(false)
      }
    }

    requestWakeLock()
  }, [])

  useEffect(() => {
    if (user) {
      setNoteType(user?.clinic?.organization?.default_note_type || 'soap')
    }
  }, [user])

  useEffect(() => {
    const fetchData = async () => {
      if (recap?.chimeMeetingId) {
        await startRecording({
          isTelehealth,
          isUsingHeadphones,
          noteType
        })
      }
    }
    fetchData()

    if (recap?.recordingStartedAt) {
      setRecordingCutoffTime(
        moment(recap.recordingStartedAt)
          .add(4, 'hours')
          .toString()
      )
    }
  }, [recap?.recordingStartedAt])

  const openDocAutomationPlanModal = useStoreActions(
    (actions: any) => actions.modals.manageScribePlan.openModal
  )

  const openDocAutomationSessionLimitRecachedModal = useStoreActions(
    (actions: any) => actions.modals.docAutomationSessionLimitReached.openModal
  )

  useEffect(() => {
    if (!hasMicAccess && isStartRecordingOpen) {
      closeStartRecording()
    }
  }, [hasMicAccess, isStartRecordingOpen])

  useEffect(() => {
    if (recap?.isTelehealth) {
      setIsTelehealth(true)
    }
  }, [recap?.isTelehealth])

  useEffect(() => {
    if (isTelehealth && !isMobile && !isSafari && !isDictation) {
      disableEchoCancelation(true)
    } else {
      disableEchoCancelation(false)
    }
  }, [isTelehealth, isMobile])

  useEffect(() => {
    let interval: undefined | NodeJS.Timeout

    if (recap?.recordingStartedAt) {
      const startingSeconds = moment
        .utc()
        .diff(moment(recap.recordingStartedAt), 'seconds')
      setRecordingDuration(startingSeconds)
    }

    if (isRecording) {
      interval = setInterval(() => {
        setRecordingDuration(prev => prev + 1)
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [isRecording, recap?.recordingStartedAt])

  useEffect(() => {
    // auto-stop recording if enabled
    const interval = setInterval(() => {
      if (
        recap?.recordingStartedAt &&
        isRecording &&
        isStopRecordingAfterEnabled &&
        stopRecordingAfter
      ) {
        const cutoff = moment
          .utc(recap.recordingStartedAt)
          .add(stopRecordingAfter, 'minutes')
        if (cutoff < moment.utc()) {
          datadogLogs.logger.info('[Session] Timer auto-ending', logData)
          handleEndSession({
            noteType
          })
        }
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [recap?.recordingStartedAt, isRecording, isStopRecordingAfterEnabled])

  useEffect(() => {
    if (isMuted) {
      datadogLogs.logger.info('[Session] Microphone muted', logData)
    } else {
      datadogLogs.logger.info('[Session] Microphone unmuted', logData)
    }
  }, [isMuted])

  useEffect(() => {
    if (hasDetectedAudio) {
      handleTrackEvent('audio_detected', {
        selectedAudioInputLabel,
        deviceInfo
      })
      datadogLogs.logger.info('[Session] Audio detected', logData)
    }
  }, [hasDetectedAudio])

  useEffect(() => {
    if (showMicWarning) {
      datadogLogs.logger.info('[Session] Showing Mic Warning', logData)
    } else {
      datadogLogs.logger.info('[Session] Hiding Mic Warning', logData)
    }
  }, [showMicWarning])

  useEffect(() => {
    ;(window as any).Intercom?.('update', {
      hide_default_launcher: true
    })

    return () => {
      ;(window as any).Intercom?.('update', {
        hide_default_launcher: false
      })
    }
  }, [])

  const handleBeforeUnload = useMemo(() => {
    return () => {
      datadogLogs.logger.info('[Session] Page refresh attempt', logData)
      return 'Did you finish the session?'
    }
  }, [logData])

  useEffect(() => {
    window.onbeforeunload = handleBeforeUnload
    return () => {
      window.onbeforeunload = () => {}
    }
  }, [handleBeforeUnload])

  const handleStartRecordingClick = async () => {
    if (documentationAutomationFreeTierSessionLimitReached && !isRecording) {
      if (isAdmin) {
        openDocAutomationPlanModal()
      } else {
        openDocAutomationSessionLimitRecachedModal()
      }

      return
    }

    await testAudioInputs()

    openStartRecording()
  }

  const handleStartRecording = async () => {
    closeStartRecording()

    await startRecording({
      noteType,
      isTelehealth,
      isUsingHeadphones
    })

    trackEvent('Session -> Started Recording')
  }

  const handleCloseStartRecording = async () => {
    await stopRecording()
    closeStartRecording()
  }

  const columns = determineGridColumns({
    isAssistOpen,
    isSessionAgendaOpen,
    isSessionNotesOpen,
    isDictationTipsOpen,
    isDictation
  })

  const handleEndSession = async ({
    noteType,
    useDemoNotesWithEBC,
    treatmentApproach
  }: any) => {
    setIsEndingSession(true)
    try {
      await stopRecording()
      await endSession(
        {
          id: sessionId,
          data: {
            noteType,
            useDemoNotesWithEBC,
            shouldGenerateTxPlan: true,
            assistEnabled: isAssistEnabled,
            treatmentApproach
          }
        },
        {
          onSuccess() {
            trackEvent('Completed Session', { sessionId })
          }
        }
      )
    } catch (err) {
      datadogLogs.logger.info('[Session] Error ending session', logData)
    }

    setIsEndingSession(false)
    if (isExtension) {
      history.push(
        `/extension/patient/${client.id}/completed-session/${sessionId}`
      )
    } else if (isWidget)
      history.push(
        `/widget/patient/${client.id}/completed-session/${sessionId}`
      )
    else {
      history.push(
        `/patient/${client.id}/completed-session/${sessionId}${
          client.is_demo ? '?isDemo=true' : ''
        }`
      )
    }
  }

  const handleDiscardSession = async () => {
    setIsEndingSession(true)
    try {
      await stopRecording()
      await discardSession({ id: sessionId })
    } catch (err) {
      datadogLogs.logger.info('[Session] Error resetting meeting', logData)
    }
    setIsEndingSession(false)

    if (isExtension) {
      history.replace('/extension/start-session')
    } else if (isWidget) history.replace('/widget/start-session')
    else {
      history.replace('/sessions')
    }
    toast({
      title: 'Session discarded',
      status: 'success',
      isClosable: true,
      duration: 2000
    })
  }

  const onOpenEndModal = () => setEndModalOpen(true)
  const onCloseEndModal = () => setEndModalOpen(false)

  return (
    <SessionWrapper
      isExtension={isExtension}
      isWidget={isWidget}
      isExtensionMinimized={isExtensionMinimized}
      hasStartedRecording={isRecording}
    >
      <Box
        h={{ base: '100%', md: '100dvh' }}
        overflowY={{ base: 'auto', md: 'hidden' }}
        style={{
          margin: 'auto 16px'
        }}
      >
        {!isEvidenceBasedCareEnabled || isAssistEnabled || isDictation ? (
          <Flex
            h="100%"
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row'
            }}
            gap="32px"
          >
            <Flex margin="auto 0" justifyContent="center" flex="1">
              <AINotetakerPanel
                sessionId={sessionId}
                hasStartedRecording={isRecording}
                isRecordingLoading={isRecordingLoading || isEndingSession}
                startRecording={handleStartRecordingClick}
                aiNotetakerEnabled={aiNotetakerEnabled}
                recordingDuration={recordingDuration}
                isDictation={isDictation}
                recordingStartedAt={recap?.recordingStartedAt}
                clientId={client.id}
              />
            </Flex>
            {!isExtension &&
              !isWidget &&
              !isDictation &&
              !isAssistEnabled &&
              isAssistOpen && (
                <Flex
                  bg="#F5F5F7"
                  borderRadius="8px"
                  overflow="hidden"
                  flexDirection="column"
                  w={{
                    base: 'auto',
                    sm: 'auto',
                    md: '400px'
                  }}
                  maxWidth={{
                    base: '400px',
                    sm: '400px',
                    md: 'none'
                  }}
                  margin={{
                    base: '0 auto',
                    sm: '0 auto',
                    md: '0'
                  }}
                >
                  <PanelHeader title="" onClose={toggleAssist} p="16px" />
                  <PanelBody
                    px="32px"
                    height={{
                      base: 'auto',
                      md: 'auto'
                    }}
                  >
                    <InSessionAssistWaitlistPanel />
                  </PanelBody>
                </Flex>
              )}
            {!isExtension &&
              !isDictation &&
              isAssistEnabled &&
              isAssistOpen && (
                <InSessionAssistPanel
                  client={client}
                  toggleAssist={toggleAssist}
                />
              )}
            {isDictationTipsOpen && (
              <GridItem
                colStart={columns.dictationTips.start}
                colEnd={columns.dictationTips.end}
                borderRadius="8px"
                overflow="hidden"
                bg="#FEFAF3"
                w={{
                  base: 'auto',
                  sm: 'auto',
                  md: '490px'
                }}
              >
                <PanelHeader title="Tips" onClose={toggleDictationTips} />
                <PanelBody>
                  <DictationTipsPanel />
                </PanelBody>
              </GridItem>
            )}
          </Flex>
        ) : (
          <LayoutGrid style={{ gap: '16px', height: '100%', margin: 'auto 0' }}>
            <GridItem
              colStart={columns.aiNotetaker.start}
              colEnd={columns.aiNotetaker.end}
              margin="auto 0"
            >
              <AINotetakerPanel
                sessionId={sessionId}
                hasStartedRecording={isRecording}
                isRecordingLoading={isRecordingLoading || isEndingSession}
                startRecording={handleStartRecordingClick}
                aiNotetakerEnabled={aiNotetakerEnabled}
                recordingDuration={recordingDuration}
                isDictation={isDictation}
                recordingStartedAt={recap?.recordingStartedAt}
                clientId={client.id}
              />
            </GridItem>
            {!isAssistEnabled &&
              isEvidenceBasedCareEnabled &&
              isSessionAgendaOpen &&
              !isExtension &&
              !isWidget &&
              !isDictation && (
                <GridItem
                  colStart={columns.sessionAgenda.start}
                  colEnd={columns.sessionAgenda.end}
                  bg="white"
                  borderRadius="8px"
                  overflow="hidden"
                >
                  <PanelHeader
                    title="Session plan"
                    onClose={toggleSessionAgenda}
                  />
                  <PanelBody>
                    <HStack gap="8px">
                      <Text
                        fontSize="32px"
                        as="h2"
                        fontWeight="bold"
                        lineHeight="normal"
                      >
                        {`${client.first_name} ${client.last_name}`}
                      </Text>
                      <HighRiskToggle
                        clientId={client.id}
                        isHighRisk={client.isHighRisk}
                        isInert
                      />
                    </HStack>
                    <Box h="24px" />
                    <SessionAgenda
                      client={client}
                      recap={recap}
                      sessionId={sessionId}
                    />
                  </PanelBody>
                </GridItem>
              )}
            {!isAssistEnabled &&
              isEvidenceBasedCareEnabled &&
              isSessionNotesOpen &&
              !isExtension &&
              !isWidget &&
              !isDictation && (
                <GridItem
                  colStart={columns.psychotherapyNotes.start}
                  colEnd={columns.psychotherapyNotes.end}
                  borderRadius="8px"
                  overflow="hidden"
                  bg="#FEFAF3"
                >
                  <PanelHeader
                    title="Psychotherapy note"
                    onClose={toggleSessionNotes}
                  />
                  <PanelBody>
                    <PsychotherapyNoteEditor
                      recap={recap}
                      sessionId={sessionId}
                      v2Enabled
                    />
                  </PanelBody>
                </GridItem>
              )}
            {isDictationTipsOpen && (
              <GridItem
                colStart={columns.dictationTips.start}
                colEnd={columns.dictationTips.end}
                borderRadius="8px"
                overflow="hidden"
                bg="#FEFAF3"
              >
                <PanelHeader title="Tips" onClose={toggleDictationTips} />
                <PanelBody>
                  <DictationTipsPanel />
                </PanelBody>
              </GridItem>
            )}
          </LayoutGrid>
        )}
      </Box>
      <Box minH="76px" style={{ marginTop: 0 }} />
      <Footer
        aiNotetakerEnabled={aiNotetakerEnabled}
        onSessionEnd={onOpenEndModal}
        isRecordingLoading={isRecordingLoading || isEndingSession}
        hasStartedRecording={isRecording}
        startRecording={handleStartRecordingClick}
        onShareAudio={startContentShare}
        isDictation={isDictation}
      />
      <StartRecordingDialog
        isOpen={isStartRecordingOpen}
        onClose={handleCloseStartRecording}
        startRecording={handleStartRecording}
        onShareAudio={startContentShare}
        isDictation={isDictation}
      />
      <EndSessionModalV2
        isOpen={isEndModalOpen}
        onClose={onCloseEndModal}
        noteType={noteType}
        treatmentApproach={lastSession?.treatmentApproach}
        onEnd={handleEndSession}
        onDiscard={handleDiscardSession}
        isEnding={isEndingSession}
        isUploadingAudio={!isUploadComplete}
      />
    </SessionWrapper>
  )
}
