import React from 'react'

const ArrowLeft = ({ fill = 'black' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_1200_11440)">
      <path
        d="M16 16.59L11.42 12L16 7.41L14.59 6L8.59 12L14.59 18L16 16.59Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1200_11440">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ArrowLeft
