import React from 'react'
import { useQueryClient } from 'react-query'
import { HStack, Stack, useToast } from '@chakra-ui/react'
import { AssistEnrollmentForm, AssistEnrollmentFormFields } from '../../shared/assist/assist-enrollment-form'
import { Button } from '@blueprinthq/joy'
import { useTreatmentPlan } from '@hooks'
import { useAssistPanelContext } from '../context'
import { usePatientControllerAssistEnroll, getSessionControllerGetSuggestedContentQueryKey } from '~/clinician-api'
import { AssistEnrollDto } from '~/clinician-api/models'
import { endpoints } from '@api'

const AssignAssessment = () => {
  const toast = useToast()
  const { setOverlayPanel, data } = useAssistPanelContext()
  const { client } = useTreatmentPlan()
  const onCancel = () => setOverlayPanel(null)
  const queryClient = useQueryClient()

  const { suggestion, sessionId } = data
  const { mutateAsync: enrollInAssist, isLoading: isEnrolling } = usePatientControllerAssistEnroll()
  const handleSubmit = (values: AssistEnrollDto) => {
    enrollInAssist({
      patientId: client.id,
      data: values
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries([
          endpoints.getClinicianUserAccount.getCacheId(),
          client.id
        ])
        if (sessionId) {
          const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
            sessionId
          )
          queryClient.invalidateQueries({ queryKey: suggestionListKey })
        }
        
        toast({
          title: 'Assessment assigned',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        setOverlayPanel(null)
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'There was an error assigning this measure',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    })
  }
  const assessments = [{
    clinicAssessmentId: suggestion.clinicAssessmentId,
    cadenceValue: suggestion.cadenceValue,
    cadenceUnit: suggestion.cadenceUnit,
    suggestionId: suggestion.contentSuggestionId
  }]

  return (
    <AssistEnrollmentForm
      client={client}
      assessments={assessments}
      onSubmit={handleSubmit}
    >
      {({ formikProps, allAssessments }: any) => (
        <Stack flex="1" spacing="0" overflowY="hidden">
          <Stack p="24px" h="calc(100vh - 262px)" overflowY="scroll">
            <AssistEnrollmentFormFields
              formikProps={formikProps}
              client={client}
              assessments={allAssessments}
            />
          </Stack>
          <HStack
            p="24px"
            borderTop="1px solid"
            borderColor="pale_gray"
            position="sticky"
          >
            <Button
              variant="outline"
              m="0"
              size="lg"
              isFullWidth
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              m="0"
              size="lg"
              isFullWidth
              isLoading={isEnrolling}
              isDisabled={!formikProps.isValid || !formikProps.dirty}
              onClick={formikProps.submitForm}
            >
              Assign
            </Button>
          </HStack>
        </Stack>
      )}
    </AssistEnrollmentForm>
  )
}

export default AssignAssessment
