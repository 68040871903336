import React from 'react'
import { HStack, Text, Input } from '@chakra-ui/react'
import { Checkbox } from '@blueprinthq/joy'

type Props = {
  isEnabled: boolean
  setIsEnabled: (isEnabled: boolean) => void
  value: string
  onChange: (minutes: string) => void
}

const StopRecordingAfter = ({
  isEnabled,
  setIsEnabled,
  value,
  onChange
}: Props) => {
  const handleBlur = (e: any) => {
    const value = e.target.value.trim()
    if (!value || value === '0') {
      onChange('60')
    }
  }

  return (
    <HStack spacing={2} alignItems="center" h="100%">
      <Checkbox
        isChecked={isEnabled}
        onChange={e => setIsEnabled(e.target.checked)}
      >
        <Text>Stop recording after</Text>
      </Checkbox>
      <Input
        type="tel"
        w="50px"
        size="sm"
        value={value}
        onBlur={handleBlur}
        onChange={e => onChange(e.target.value)}
      />
      <Text>min</Text>
    </HStack>
  )
}

export default StopRecordingAfter
