import React, { useState } from 'react'
import {
  Flex,
  Box,
  Text,
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import RefreshIcon from '@material-ui/icons/Refresh'
import { RadioSelectedIcon, RadioOutlineIcon } from '@blueprinthq/joy'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { useExperienceManager } from '@hooks'

interface PropsT {
  isOpen: boolean
  onClose: () => void
  initialValue: string
  onSubmit: (noteType: string) => void
  isSubmitting: boolean
}

export const ChangeNoteTypeModal = ({
  isOpen = true,
  onClose = () => {},
  initialValue,
  onSubmit,
  isSubmitting
}: PropsT) => {
  const [noteType, setNoteType] = useState<string>(initialValue)
  const { isExtension, isWidget } = useExperienceManager()
  const { data: user }: any = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const {
    data: { progressNoteTypes }
  }: any = useQuery(
    [
      endpoints.getProgressNoteTypes.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteTypes.request({
        organizationId: user.clinic.organization_id,
        individualOnly: false
      }),
    {
      initialData: {
        progressNoteTypes: []
      },
      enabled: !!user
    }
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isExtension || isWidget ? 'full' : 'md'}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="20px" textAlign="center" p={4}>
          Change Note Type
        </ModalHeader>
        <ModalCloseButton _focus={{ outline: 'none' }} top={4} />
        <Divider />
        <ModalBody p={0}>
          <Flex flexDirection="column">
            <Box p={6} maxH="500px" overflowY="scroll">
              {progressNoteTypes.map((type: any) => {
                const isSelected = type.id === noteType
                return (
                  <Box
                    key={type.id}
                    py={2}
                    px={3}
                    borderRadius="8px"
                    border={
                      isSelected ? '2px solid #2D54E8' : '2px solid transparent'
                    }
                    bg={isSelected ? 'rgba(45, 84, 232, 0.04)' : 'white'}
                    cursor="pointer"
                    onClick={() => setNoteType(type.id)}
                  >
                    <Flex>
                      <Box>
                        {isSelected ? (
                          <RadioSelectedIcon fill="primary" />
                        ) : (
                          <RadioOutlineIcon fill="pale_gray" />
                        )}
                      </Box>
                      <Box ml={3}>
                        <Text>{type.display}</Text>
                      </Box>
                    </Flex>
                  </Box>
                )
              })}
            </Box>
          </Flex>
          <Divider />
          <Flex p={6} justifyContent="flex-end">
            <Button
              variant="outline"
              size="lg"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              ml={4}
              size="lg"
              leftIcon={<RefreshIcon style={{ fontSize: 16 }} />}
              onClick={() => onSubmit(noteType)}
              isLoading={isSubmitting}
              disabled={initialValue === noteType || isSubmitting}
            >
              {isExtension || isWidget ? 'Regenerate' : 'Generate new note'}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
