import CryptoJS from 'crypto-js'
import { store } from '../store'
import axios from 'axios'
import { datadogLogs } from '@datadog/browser-logs'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  config => {
    const {
      auth: { accessToken, isAuthenticated, isSSOUser }
    } = store.getState()
    if (isAuthenticated) {
      if (isSSOUser) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      } else {
        config.headers['Access-Token'] = accessToken
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  res => {
    const contentType = res.headers['content-type']
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return res.data
    } else {
      return res
    }
  },
  async err => {
    // anything above 2xx status
    const {
      auth: { isAuthenticated, isSSOUser, user, isRefreshingSSO }
    } = store.getState()

    const {
      auth: { logout, refreshAccessToken, resumeSession, setIsRefreshingSSO }
    } = store.getActions()
    const { config, response } = err

    if (!response) {
      const context = { user, isAuthenticated, isSSOUser, config }
      datadogLogs.logger.info('axios_error_with_no_response', context, err)
      return Promise.reject(err)
    }

    const contentType = response.headers['content-type']

    if (response.status === 401 && isAuthenticated && !isRefreshingSSO) {
      if (!config._retry) {
        config._retry = true
        try {
          if (isSSOUser) {
            setIsRefreshingSSO(true)
            await refreshAccessToken()
            setIsRefreshingSSO(false)
          } else {
            await resumeSession() // attempt to refresh cognito token
          }
          return axiosInstance(config) // retry the call
        } catch (_error) {
          await logout()
          return Promise.reject(_error)
        }
      } else {
        await logout()
      }
    } else if (contentType && contentType.indexOf('application/json') !== -1) {
      const jsonError = err.response.data
      return Promise.reject(jsonError)
    } else {
      return Promise.reject(err.response.status)
    }
  }
)

class Api {
  encode = string => {
    try {
      const encryptedString = CryptoJS.AES.encrypt(
        string,
        process.env.REACT_APP_SECRET_KEY
      ).toString()
      const encodedString = window.btoa(encryptedString)
      return encodedString
    } catch (e) {
      return string
    }
  }

  decode = string => {
    try {
      const decodedString = window.atob(string)
      const decryptedString = CryptoJS.AES.decrypt(
        decodedString,
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
      return decryptedString
    } catch (e) {
      return null
    }
  }

  makeRequest = async (path, fetchOptions) => {
    return await axiosInstance({
      url: path,
      ...fetchOptions
    })
  }

  buildHeaders = () => {
    var headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'joy-app-name': 'clinician_app',
      'joy-platform': 'web',
      'joy-user-agent': global.navigator.userAgent
    }
    return headers
  }

  GET = (path, options = {}) => {
    const headers = this.buildHeaders()
    const fetchOptions = {
      method: 'GET',
      headers,
      ...options
    }
    return this.makeRequest(path, fetchOptions)
  }

  POST = (path, body, options = { isFormData: false }) => {
    const headers = this.buildHeaders()

    if (options.isFormData) {
      delete headers['Content-Type']
    }

    const fetchOptions = {
      method: 'POST',
      headers: {
        ...headers
      },
      data: body
    }
    return this.makeRequest(path, fetchOptions)
  }

  PATCH = (path, body) => {
    const headers = this.buildHeaders()
    const fetchOptions = {
      method: 'PATCH',
      headers,
      data: body
    }

    return this.makeRequest(path, fetchOptions)
  }

  PUT = (path, body) => {
    const headers = this.buildHeaders()
    const fetchOptions = {
      method: 'PUT',
      headers,
      data: body
    }

    return this.makeRequest(path, fetchOptions)
  }

  DELETE = (path, body) => {
    const headers = this.buildHeaders()
    const fetchOptions = {
      method: 'DELETE',
      headers,
      data: body
    }
    return this.makeRequest(path, fetchOptions)
  }
}

export const api = new Api()
