import React, { useState } from 'react'
import { useStoreState } from 'easy-peasy'
import {
  Heading,
  Text,
  Box,
  VStack,
  HStack,
  Divider,
  useTheme
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { SocIICertified, HipaaCompliant } from '@components/icons'
import { loadStripe } from '@stripe/stripe-js'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const CheckoutForm = ({
  storePaymentAndCreateAccount,
  isSubmitting,
  setIsSubmitting,
  isError,
  resetFormError
}) => {
  const { formData } = useStoreState(state => state.clinicianSignup)
  const [error, setError] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const stripe = useStripe()
  const elements = useElements()

  const stripeStyle = {
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#1a202c',
        fontWeight: '500',
        fontFamily: 'TofinoPersonal, Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#1a202c'
        },
        '::placeholder': {
          color: '#999999'
        }
      },
      invalid: {
        iconColor: '#e02031',
        color: '#e02031'
      }
    }
  }

  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    resetFormError()
    setDisabled(event.empty)
    setError(event.error ? event.error.message : null)
  }

  const handleSubmit = async ev => {
    ev.preventDefault()

    setIsSubmitting(true)
    const {
      paymentMethod,
      paymentMethodError
    } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)
    })

    if (paymentMethodError || !paymentMethod) {
      setIsSubmitting(false)
      setError('Please complete all of the fields above and try again')
    } else {
      setError(null)
      storePaymentAndCreateAccount(formData, paymentMethod.id)
    }
  }

  return (
    <form id="stripe-payment-form" onSubmit={handleSubmit}>
      {isError && (
        <div className="errorMessage" role="alert">
          {isError}
        </div>
      )}
      <VStack mt="24px" w="100%">
        <Box
          w="100%"
          padding="14px"
          style={{
            border: '1px solid #C9C9C9',
            borderRadius: '6px',
            height: '48px'
          }}
        >
          <CardNumberElement options={stripeStyle} onChange={handleChange} />
        </Box>
        <HStack
          spacing="16px"
          style={{ marginInlineStart: 0, WebkitMarginStart: 0, marginTop: 0 }}
          pt={'small'}
          pb={'small'}
          w="100%"
        >
          <Box
            w="100%"
            padding="14px"
            style={{
              border: '1px solid #C9C9C9',
              borderRadius: '6px',
              height: '48px'
            }}
          >
            <CardExpiryElement options={stripeStyle} onChange={handleChange} />
          </Box>
          <Box
            w="100%"
            padding="14px"
            style={{
              border: '1px solid #C9C9C9',
              borderRadius: '6px',
              height: '48px'
            }}
          >
            <CardCvcElement options={stripeStyle} onChange={handleChange} />
          </Box>
        </HStack>
        <Box
          w="100%"
          style={{
            marginInlineStart: 0,
            WebkitMarginStart: 0,
            marginTop: 0,
            height: '48px'
          }}
          padding="14px"
          bg="#F7F8FE"
          borderRadius="6px"
        >
          <Text align="center" fontWeight="medium" w="100%">
            Total due today: $0
          </Text>
        </Box>
        {error && (
          <div className="errorMessage" role="alert">
            {error}
          </div>
        )}
        <Box
          w="100%"
          style={{ marginInlineStart: 0, WebkitMarginStart: 0, marginTop: 0 }}
          pt={'medium'}
        >
          <Button
            size="lg"
            m={0}
            isFullWidth
            type="submit"
            isLoading={isSubmitting}
            isDisabled={disabled}
          >
            Start your 30 day trial
          </Button>
        </Box>
      </VStack>
    </form>
  )
}

export const Payment = ({
  storePaymentAndCreateAccount,
  isSubmitting,
  setIsSubmitting,
  isError,
  resetFormError
}) => {
  const { formData } = useStoreState(state => state.clinicianSignup)
  const theme = useTheme()
  let demo_link = 'https://www.blueprint-health.com/demo'
  if (formData.firstName) {
    demo_link =
      demo_link +
      '?firstname=' +
      formData.firstName +
      '&lastname=' +
      formData.lastName +
      '&email=' +
      formData.email +
      '&company=' +
      formData.organizationName
  }

  const options = {
    fonts: [
      {
        family: 'TofinoPersonal',
        src:
          'url(https://res.cloudinary.com/hellojoy/raw/upload/v1631114007/Fonts/tofino-personal/TofinoPersonal-Regular.otf)'
      }
    ]
  }
  const thirty_days_from_now = DateTime.now()
    .plus({ days: 30 })
    .toLocaleString(DateTime.DATE_FULL)

  return (
    <Box w="100%">
      <Box w="100%">
        <Heading w="100%" mb="4px" size="lg">
          Start your 30 day trial
        </Heading>
        <Text>Please add a credit card to start your trial.</Text>
      </Box>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          isError={isError}
          storePaymentAndCreateAccount={storePaymentAndCreateAccount}
          resetFormError={resetFormError}
        />
      </Elements>
      <Text fontSize="md" mt="24px">
        After your trial ends, you will be charged $49 per clinician per month
        starting {thirty_days_from_now ?? 'in 30 days'}. You can always cancel
        before then.
      </Text>
      <Divider mt="24px" />
      <Text display={'flex'} fontSize="md" mt="24px">
        Not ready to add a card?{' '}
        <Link
          style={{ color: theme.colors.primary, marginLeft: 2, marginRight: 2 }}
          to={`${demo_link}`}
          target="_blank"
        >
          <Text _hover={{ textDecoration: 'underline' }}>Schedule a demo</Text>
        </Link>
        instead.
      </Text>
      <Box height="24px" />
      <HStack justifyContent="center" gap="24px">
        <Box w="59px" h="32px">
          <HipaaCompliant />
        </Box>

        <HStack w="119px" opacity="0.5" style={{ marginInlineStart: '0px' }}>
          <Box>
            <SocIICertified />
          </Box>

          <Text
            lineHeight="normal"
            fontFamily="Copperplate"
            fontSize="12px"
            style={{ marginInlineStart: '2px' }}
          >
            SOC II TYPE 2 CERTIFIED
          </Text>
        </HStack>
      </HStack>
    </Box>
  )
}
