import React from 'react'
import {
  Flex,
  Text,
  Box,
  Avatar,
  Tooltip,
  Button,
  useBreakpointValue
} from '@chakra-ui/react'

import { ArrowRightIcon } from '@blueprinthq/joy'
import { formatDuration } from '@handlers/sessions/util'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { MicAIOn } from '@components/icons'

export const Session = ({
  patientId,
  sessionId,
  fullName,
  startedAt,
  completedAt,
  isUpload,
  hasAudio,
  clinicianName,
  inProgress,
  isDictation,
  sx = {}
}) => {
  const buttonSize = useBreakpointValue({
    base: 'sm',
    sm: 'md'
  })
  return (
    <Box
      display="flex"
      w="100%"
      background="transparent"
      cursor="pointer"
      as={Link}
      to={
        inProgress
          ? isDictation
            ? `/patient/${patientId}/dictate/session/${sessionId}`
            : `/patient/${patientId}/session/${sessionId}`
          : `/patient/${patientId}/completed-session/${sessionId}`
      }
      sx={sx}
    >
      <Flex py={2} w="100%" alignItems="center">
        <Box>
          <Link to={`/patient/${patientId}`}>
            <Avatar
              name={fullName}
              sx={{
                marginRight: 'small',
                background: 'rgba(45, 84, 232, 0.04)',
                color: 'black',
                fontWeight: 'bold'
              }}
            />
          </Link>
        </Box>
        <Flex grow="1" wrap="wrap" justifyContent="space-between">
          <Flex
            w="100%"
            sx={{
              paddingBottom: 'xsmall'
            }}
          >
            <Box>
              <Text
                sx={{
                  fontSize: 'md',
                  fontWeight: 'bold',
                  display: 'inline',
                  wordBreak: 'break-word'
                }}
              >
                {fullName}
              </Text>
              <Text fontSize="xsm">
                <Flex flexDirection="row">
                  <Text as="span" fontSize="inherit">
                    {clinicianName}
                  </Text>
                  <Text
                    as="span"
                    fontSize="inherit"
                    mx="10px"
                    color="light_gray"
                  >
                    |
                  </Text>
                  {!isUpload && (
                    <>
                      <Text as="span" fontSize="inherit">
                        {moment(startedAt).format('h:mma')}
                      </Text>
                      <Text
                        as="span"
                        fontSize="inherit"
                        mx="10px"
                        color="light_gray"
                      >
                        |
                      </Text>
                      <Text as="span" fontSize="inherit">
                        {formatDuration(
                          startedAt,
                          completedAt || moment.utc().format()
                        )}
                      </Text>
                    </>
                  )}
                  {hasAudio && !inProgress && (
                    <Tooltip label="AI-generated note">
                      <Flex mx={isUpload ? '0px' : '10px'}>
                        <MicAIOn fill="black" height="16px" width="16px" />
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Box>
          {inProgress ? (
            <Button size={buttonSize} m={0}>
              {isDictation ? 'Resume Dictation' : 'Resume Session'}
            </Button>
          ) : (
            <ArrowRightIcon />
          )}
        </Box>
      </Flex>
    </Box>
  )
}
