import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Flex, Box, Text } from '@chakra-ui/react'
import { DateTime } from 'luxon'

import { usePatientControllerGetSessionList } from '~/clinician-api'
import { useExperienceManager } from '@hooks'
import { Chevron } from '@components/icons'
import { NavBar } from '@handlers/extension'
import { Loading } from '@components'

export const ClientSessions = () => {
  const history = useHistory()
  const { clientId } = useParams<{ clientId: string }>()
  const { isWidget } = useExperienceManager()

  const { data: sessionList, isLoading } = usePatientControllerGetSessionList(
    clientId,
    {
      query: {
        initialData: []
      }
    }
  )

  if (isLoading) return <Loading />

  return (
    <>
      <NavBar showBackButton />
      {sessionList?.map((session: any, i: number) => {
        const startedAt = DateTime.fromISO(session.startedAt)
        const completedAt = DateTime.fromISO(session.completedAt)
        const duration = completedAt.diff(startedAt, 'minutes').toObject()
          .minutes

        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            m="8px"
            p="8px"
            borderBottom={i !== sessionList.length - 1 ? '1px solid' : 'none'}
            borderColor="pale_gray"
            _hover={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(
                `${isWidget &&
                  '/widget'}/patient/${clientId}/completed-session/${
                  session.id
                }`
              )
            }
          >
            <Box>
              <Text as="b">
                {DateTime.fromISO(session.startedAt).toFormat('EEE MMM dd')}
              </Text>
              <Text>{`${DateTime.fromISO(session.startedAt).toFormat(
                'h:mma'
              )} | ${Math.round(duration as number)} min`}</Text>
            </Box>
            <Box w="16px">
              <Chevron />
            </Box>
          </Flex>
        )
      })}
    </>
  )
}
