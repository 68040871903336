import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useQueryClient } from 'react-query'

import { PersonAddIcon } from '@blueprinthq/joy'
import {
  Container,
  Button,
  Text,
  Box,
  Heading,
  Link,
  useToast
} from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { ClinicianOverride } from '@components'
import { usePermissions } from '@hooks'
import { CLIENT_PRONOUNS } from '@constants/clientPronouns'

import { getClinicControllerGetClientListQueryKey } from '~/clinician-api'

import { endpoints } from '@api'
import { AddClientDialog } from './add-client-dialog'
import { ClientTable } from './client-table'

export const ClientListHandler: React.FC = () => {
  const queryClient = useQueryClient()
  const onClinicianSelectChange = (
    clinicianId: string | null,
    clinicId: string
  ) => {
    setClinicianId(clinicianId)
    setClinicId(clinicId)
  }

  const user = useStoreState(state => state.auth.user)
  const { hasPermission } = usePermissions()

  const showClinicSelect = hasPermission('read:clinic:activeclients:*')

  const [clinicianId, setClinicianId] = useState<string | null>(user.id)
  const [clinicId, setClinicId] = useState<string>(user.clinic_id)

  const [isAddClientOpen, setIsAddClientOpen] = useState(false)
  const toast = useToast()
  const history = useHistory()

  const { mutateAsync: enrollClient, isLoading: isEnrolling } = useMutation(
    endpoints.postSessionEnrollClient.request,
    {
      onError: () => {
        toast({
          title: 'Error creating client',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      },
      onSuccess: () => {
        const clientListQueryKey = getClinicControllerGetClientListQueryKey(
          user.clinic.id
        )
        queryClient.invalidateQueries({ queryKey: clientListQueryKey })
      }
    }
  )

  const handleEnrollClient = async ({
    firstName,
    lastName,
    dateOfBirth,
    pronoun
  }: {
    firstName: string
    lastName: string
    dateOfBirth: string | null
    pronoun: string | null
  }) => {
    const result: any = await enrollClient({
      data: {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth
          ? moment(dateOfBirth).format('YYYY-MM-DD')
          : null,
        pronoun: CLIENT_PRONOUNS[pronoun as keyof typeof CLIENT_PRONOUNS]
      }
    })
    setIsAddClientOpen(false)
    toast({
      status: 'success',
      isClosable: true,
      duration: 3000,
      render: () => (
        <Box color="white" p={3} bg="success" display="flex">
          <Text mr={2}>Client successfully created</Text>
          <Link
            onClick={() => history.push(`/patient/${result.patientId}`)}
            color="white"
            fontWeight="bold"
          >
            View profile
          </Link>
        </Box>
      )
    })
  }

  return (
    <Container mt="xlarge" position="relative" border="none">
      <Box mb={'medium'} w={'100%'}>
        <Heading
          mb="small"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          Clients
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            {showClinicSelect && (
              <ClinicianOverride
                user={user}
                isSuperAdmin={hasPermission('*:any:*:*')}
                onChange={(clinicianId: string | null, clinicId: string) =>
                  onClinicianSelectChange(clinicianId, clinicId)
                }
                canViewOrgDropdown={false}
                allowAllClinicians={hasPermission(
                  'read:clinic:activeclients:*'
                )}
              />
            )}
            <Button
              h="40px"
              size="lg"
              ml="medium"
              onClick={() => setIsAddClientOpen(true)}
            >
              <PersonAddIcon size="sm" fill="white" mr="5px" mt="5px" />
              {'Add Client'}
            </Button>
          </Box>
        </Heading>
        {clinicId && (
          <ClientTable clinicId={clinicId} clinicianId={clinicianId} />
        )}
      </Box>
      <AddClientDialog
        isOpen={isAddClientOpen}
        onClose={() => setIsAddClientOpen(false)}
        onSubmit={handleEnrollClient}
        isLoading={isEnrolling}
      />
    </Container>
  )
}
