import React, { forwardRef, useEffect } from 'react'
import { useQuery } from 'react-query'
import _ from 'lodash'
import {
  CloseIcon,
  SearchIcon,
  IconButton,
  AutocompleteInput
} from '@blueprinthq/joy'
import { useBoolean } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { SearchInputSuggestionList } from './suggestion-list'
import { endpoints } from '../../../api'

export const SearchInput = forwardRef(
  (
    { value, isMobile = false, onSearchClose, onSearchResults, sx, ...rest },
    ref
  ) => {
    const history = useHistory()
    const [open, setOpen] = useBoolean(false)
    const { refetch, data } = useQuery(
      [endpoints.getPatientSearch.getCacheId(), value],
      ({ queryKey }) =>
        endpoints.getPatientSearch.request({
          search: queryKey[1]
        }),
      {
        enabled: false,
        keepPreviousData: true,
        initialData: [],
        onSuccess(data) {
          if (onSearchResults) {
            onSearchResults(
              data.map(patient => {
                patient.name = `${patient.first_name} ${patient.last_name}`
                return patient
              })
            )
          }
        }
      }
    )

    const throttledSearch = _.throttle(() => refetch(), 150)

    useEffect(() => {
      if (value.length >= 1) {
        throttledSearch()
      } else if (onSearchResults) {
        onSearchResults([])
      }
    }, [value])

    useEffect(() => {
      if (!open && value.length >= 1) {
        setOpen.on()
      }
    }, [open, value])

    const CloseSearchNode = (
      <IconButton
        margin="inherit"
        variant="ghost"
        marginRight={isMobile ? '0px' : '8px'}
        icon={<CloseIcon fill={isMobile && 'white'} />}
        onClick={() => {
          if (onSearchClose) {
            onSearchClose()
          }
        }}
      />
    )
    const OpenSearchNode = (
      <SearchIcon
        style={{
          cursor: 'pointer',
          fill: isMobile && 'white'
        }}
      />
    )

    return (
      <AutocompleteInput
        onSelectSuggestion={({ selectedItem }) => {
          if (onSearchClose) {
            onSearchClose()
          }
          history.push(`/patient/${selectedItem.id}`)
          window.location.reload()
        }}
        SuggestionList={SearchInputSuggestionList}
        suggestionToString={suggestion => suggestion.name}
        suggestions={
          value.length
            ? data.map(patient => {
                patient.name = `${patient.first_name} ${patient.last_name}`
                return patient
              })
            : []
        }
        placeholder="Search clients"
        size={'lg'}
        showClear={value && value.length}
        ref={ref}
        leftIcon={OpenSearchNode}
        rightIcon={CloseSearchNode}
        value={value}
        sx={{
          fontSize: 'md',
          fontWeight: 'bold',
          outline: 'none',
          border: 'none',
          borderBottomLeftRadius:
            value.length && data && data.length ? 0 : undefined,
          borderBottomRightRadius:
            value.length && data && data.length ? 0 : undefined,
          _focus: {},
          ...sx
        }}
        listContainerSx={{
          boxShadow: 'rgb(0 0 0 / 26%) 0px 10px 16px',
          borderBottomRadius: '6px'
        }}
        dividerColor={isMobile ? '#F5F5F5' : '#C9C9C9'}
        noRightPadding
        {...rest}
      />
    )
  }
)

SearchInput.displayName = 'SearchInput'
