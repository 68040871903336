import { componentConfig } from './components'

export const baseConfig = {
  appName: 'Blueprint',
  allowedDomains: ['form.jotform.com'],
  mobileAppName: 'Blueprint',
  mobileAppInviteURL: 'https://app.blueprint-health.com/app-invite',
  showLinkToSignUp: false,
  termsAndConditionsURL: 'https://www.blueprint-health.com/platform-services-agreement',
  privacyPolicyURL: 'https://www.blueprint-health.com/privacy',
  blurredImageURLs: {
    engagement:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605042795/Blurred/Engagement_blur.png',
    lifestyle:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605042794/Blurred/Lifestyle_blur.png',
    topSymptoms:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605042793/Blurred/Top_symptoms_blur.png',
    worksheets:
      'https://res.cloudinary.com/hellojoy/image/upload/v1605042878/Blurred/Worksheets_blur.png',
    outcomeMeasures:
      'https://res.cloudinary.com/hellojoy/image/upload/v1625243896/Blurred/Outcome_measures_blue3.png'
  },
  helpEmail: 'help@blueprint-health.com',
  components: componentConfig,
  baaURL: 'https://www.blueprint-health.com/baa'
}
