import React, { useMemo } from 'react'
import { Flex, Text, VStack, HStack, Link } from '@chakra-ui/react'
import { AddCircleIcon, CheckCircle } from '@blueprinthq/joy'
import { CheckInDtoV2 } from '~/clinician-api/models'
import { buildCheckInPreviewUrl } from '@utilities'

interface CheckInRowProps {
  checkIn: CheckInDtoV2
  isSelected: boolean
  isAssigned: boolean
  onSelect: (checkInId: string) => void
}

export const CheckInRow = ({
  checkIn,
  isSelected,
  onSelect,
  isAssigned
}: CheckInRowProps) => {
  const icon = useMemo(() => {
    let i = (
      <AddCircleIcon
        onClick={() => onSelect(checkIn.id)}
        onKeyDown={e => {
          // @ts-ignore
          const key = e.code
          if (key === 13 || key === 32) {
            onSelect(checkIn.id)
          }
        }}
        role="button"
        aria-label="Select Assessment"
        tabIndex={0}
        cursor="pointer"
      />
    )

    if (isAssigned) {
      i = <CheckCircle w="22px" h="22px" fill="#E4E5E6" />
    } else if (isSelected) {
      i = (
        <CheckCircle
          onClick={() => onSelect(checkIn.id)}
          onKeyDown={e => {
            // @ts-ignore
            const key = e.code
            if (key === 13 || key === 32) {
              onSelect(checkIn.id)
            }
          }}
          w="22px"
          h="22px"
          role="button"
          aria-label="Deselect Assessment"
          tabIndex={0}
          cursor="pointer"
          fill="primary"
        />
      )
    }

    return i
  }, [checkIn.id, isAssigned, isSelected, onSelect])

  return (
    <Flex
      minHeight="100px"
      py="10px"
      borderTop="1px solid #E4E5E6"
      align="center"
      justify="space-between"
    >
      <VStack spacing="none" align="left" maxWidth="400px">
        <Text fontWeight="bold" mb="xsmall">
          {checkIn.title}
        </Text>
        <Text>{checkIn.subtitle}</Text>
        <Text>{checkIn.questionCount} questions</Text>
      </VStack>
      <HStack spacing="small">
        <Link
          as="a"
          target="_blank"
          mr="xsmall"
          color="primary"
          textDecoration="underline"
          href={buildCheckInPreviewUrl(checkIn.id)}
        >
          Preview
        </Link>
        {icon}
      </HStack>
    </Flex>
  )
}
