import React from 'react'
import {
  VStack,
  Box,
  Text,
  Divider,
  Button,
  Heading,
  Flex,
  HStack
} from '@chakra-ui/react'
import { useHistory, Link, useRouteMatch } from 'react-router-dom'
import { DateTime } from 'luxon'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { useExperienceManager } from '@hooks'
import {
  usePatientControllerGetSessionList,
  usePatientControllerGetCompletedSessions,
  useSessionControllerPostSession
} from '~/clinician-api'

export function SessionList() {
  const match: any = useRouteMatch('/patient/:clientId/settings/sessions')

  const {
    data: sessionList,
    isLoading: isSessionListLoading
  } = usePatientControllerGetSessionList(match.params.clientId, {
    query: {
      initialData: []
    }
  })

  const history = useHistory()
  const { isExtension, isAssistEnabled } = useExperienceManager()
  const isChimeDisabled = flagsmith.hasFeature(FlagsmithFeatures.CHIME_DISABLED)

  const { mutateAsync: createSessionDraft } = useSessionControllerPostSession()

  const onStartSessionClick = async () => {
    const sessionDraft = await createSessionDraft({
      data: {
        patientId: match.params.clientId,
        isChime: !isChimeDisabled,
        isExtension,
        extensionInitiatedFrom: isExtension ? document.referrer : null,
        resumeInProgress: true,
        isDictation: false,
        isAssistEnabled
      }
    })
    history.push(`/patient/${match.params.clientId}/session/${sessionDraft.id}`)
  }

  return (
    <Box w="100%" id="pendo-session-list-container-tag">
      <Box mb="small">
        <HStack justify={'space-between'}>
          <Heading>Sessions</Heading>
          <Button onClick={onStartSessionClick}>Start Session</Button>
        </HStack>
      </Box>

      <Box
        border="solid 1px"
        borderColor="pale_gray"
        w="100%"
        borderRadius="8px"
      >
        {!isSessionListLoading && sessionList?.length === 0 ? (
          <Flex align="center" justify="center" height="250px">
            <Flex align="center" direction="column">
              <Flex
                borderRadius="50%"
                align="center"
                justify="center"
                width="40px"
                height="40px"
                bg="primary"
                sx={{
                  '*': {
                    fill: 'white'
                  }
                }}
              >
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.208374 13.4075L10.2917 13.4167C10.9059 13.4167 11.4559 13.1142 11.7859 12.6467L15.7917 7.00004L11.7859 1.35337C11.4559 0.885874 10.9059 0.583374 10.2917 0.583374L0.208374 0.592541L4.64504 7.00004L0.208374 13.4075Z"
                    fill="white"
                  />
                </svg>
              </Flex>
              <Text fontWeight="bold" mt="xsmall" mb="xsmall">
                No sessions yet
              </Text>
              <Text align="center" maxWidth="350px" whiteSpace="break-spaces">
                Ready to run a great therapy session and save time on writing
                progress notes?
              </Text>
              <Button
                mt="medium"
                id="pendo-start-session-tag"
                paddingLeft="37.5px"
                paddingRight="37.5px"
                _focus={{
                  boxShadow: 'none'
                }}
                onClick={onStartSessionClick}
              >
                Start Session
              </Button>
            </Flex>
          </Flex>
        ) : (
          <VStack w="100%" divider={<Divider />}>
            {sessionList?.map(session => {
              return (
                <Box key={`session-list-${session.id}`} w="100%">
                  <Box
                    p="medium"
                    w="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Text fontWeight="bold">
                        {DateTime.fromISO(session.date).toFormat('LLL dd')}
                      </Text>
                      <Text>{session.clinicianFullName}</Text>
                    </Box>
                    <Box>
                      {/* @ts-ignore */}
                      {session.isRecordingInProgress ? (
                        <Button
                          as={Link}
                          w="177px"
                          to={
                            // @ts-ignore
                            session.isDictation
                              ? `/patient/${session.patientId}/dictate/session/${session.id}`
                              : `/patient/${session.patientId}/session/${session.id}`
                          }
                        >
                          {/* @ts-ignore */}
                          {session.isDictation
                            ? 'Resume Dictation'
                            : 'Resume Session'}
                        </Button>
                      ) : (
                        <Button
                          variant="outline"
                          as={Link}
                          w="177px"
                          to={`/patient/${session.patientId}/completed-session/${session.id}`}
                        >
                          View Progress Note
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </VStack>
        )}
      </Box>
    </Box>
  )
}
