import React, { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Checkbox } from '@blueprinthq/joy'
import { Box, Text, VStack, Heading } from '@chakra-ui/react'
import _ from 'lodash'

import { endpoints } from '../../../api'
import { Loading } from '../../../components'
import { usePermissions } from '../../../hooks'

export const NotificationSettings = () => {
  const { hasPermission } = usePermissions()
  const queryClient = useQueryClient()
  const { data, isLoading } = useQuery(
    [endpoints.getClinicianSettings.getCacheId()],
    () => endpoints.getClinicianSettings.request()
  )

  const { mutateAsync: executeUpdateSettings } = useMutation(
    endpoints.patchClinicianSettings.request,
    {
      onMutate: async ({ data: newSettings }) => {
        const cacheId = endpoints.getClinicianSettings.getCacheId()
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([cacheId])

        // Snapshot the previous value
        const previousSettings = queryClient.getQueryData([cacheId])

        // Optimistically update to the new value
        queryClient.setQueryData([cacheId], newSettings)

        return { previousSettings, newSettings }
      },
      onError: (err, newSettings, context) => {
        queryClient.setQueryData(
          [endpoints.getClinicianSettings.getCacheId()],
          context.previousSettings
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          endpoints.getClinicianSettings.getCacheId()
        ])
      }
    }
  )

  const updateSettings = useCallback(
    async (property, value) => {
      const settings = _.cloneDeep(data)
      settings.notifications[property] = value
      await executeUpdateSettings({ data: settings })
    },
    [data]
  )

  const HeaderText = () => {
    return (
      <Box mb={'medium'} w={'100%'}>
        <Heading mb="xxsmall">Notifications</Heading>
        <Text>Select when you'll be notified</Text>
      </Box>
    )
  }

  if (isLoading) return <Loading />

  return (
    <Box sx={{ marginBottom: 'xxlarge' }}>
      <HeaderText boxWidth="100%" />
      <Box sx={{ marginBottom: 'small' }}>
        <Text fontWeight={'bold'}>Email me when:</Text>
      </Box>

      <VStack spacing="small" alignItems="flex-start">
        <Checkbox
          isChecked={data.notifications.first_assessment_completed}
          onChange={event =>
            updateSettings('first_assessment_completed', event.target.checked)
          }
        >
          Client completes their first assessment
        </Checkbox>
        <Checkbox
          isChecked={data.notifications.assessment_completed}
          onChange={event =>
            updateSettings('assessment_completed', event.target.checked)
          }
        >
          Client completes any assessment
        </Checkbox>
        <Checkbox
          isChecked={data.notifications.patient_transferred_to_you}
          onChange={event =>
            updateSettings('patient_transferred_to_you', event.target.checked)
          }
        >
          Client is transferred to you
        </Checkbox>
        {hasPermission('update:own:settings:receiveintakerequests') && (
          <Checkbox
            isChecked={data.notifications.intake_request_received}
            onChange={event =>
              updateSettings('intake_request_received', event.target.checked)
            }
          >
            Client intake request is received
          </Checkbox>
        )}
      </VStack>
    </Box>
  )
}
