import React from 'react'
import { Button, Text, VStack } from '@chakra-ui/react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import { MicrophoneSelector } from './microphone-selector'
import { useExperienceManager } from '@hooks'
import { useContentShareState } from 'amazon-chime-sdk-component-library-react'
import { isMobile } from 'react-device-detect'

export const SettingsDialog = ({
  isOpen,
  onClose,
  onShareAudio,
  isDictation
}: any) => {
  const { isSafari, isExtension, isWidget } = useExperienceManager()

  const { isLocalUserSharing } = useContentShareState()

  const handleShareAudio = () => {
    onShareAudio()
    onClose()
  }

  return (
    <DialogContainer onClose={onClose} isOpen={isOpen}>
      <DialogHeader text="Audio Settings" onClose={onClose} />
      <DialogBody>
        <VStack spacing={4}>
          <MicrophoneSelector />
          {!isLocalUserSharing && !isSafari && !isMobile && !isDictation && (
            <VStack spacing={2} align="start">
              <Text fontWeight="bold" paddingBottom="xsmall">
                Share audio from another browser tab
              </Text>
              {!isExtension && !isWidget && (
                <Text>
                  This is especially helpful if you are using headphones for a
                  telehealth session and want the AI Notetaker to capture what
                  your client is saying.
                </Text>
              )}
              <Button
                variant="link"
                onClick={handleShareAudio}
                m={0}
                textDecoration="none"
                _hover={{ textDecoration: 'none' }}
                _focus={{ outline: 'none', textDecoration: 'none' }}
              >
                Share browser audio
              </Button>
            </VStack>
          )}
        </VStack>
      </DialogBody>
    </DialogContainer>
  )
}
