import React, { useMemo, useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Box,
  Flex,
  GridItem,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Divider,
  Link,
  useBreakpointValue
} from '@chakra-ui/react'
import {
  useSessionControllerTrackEvent,
  useSessionControllerGetCompletedSessionDetails,
  useSessionControllerGetProgressNotesForSession
} from '~/clinician-api'
import { copyToClipboard } from '@utilities'
import { LayoutGrid } from '@blueprinthq/joy'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { useExperienceManager } from '@hooks'
import { useHistory } from 'react-router-dom'
import { useQueryParams } from '../../hooks/use-query-params'
import { SessionNotesV2 } from './session-notes-v2'

import {
  Details,
  ProgressNoteGenerationSteps,
  ProgressNoteExplanation,
  ProgressNoteHeader,
  DocSnippets,
  ProgressNote,
  PrivateNotes,
  TranscriptionError,
  SessionSummary,
  Transcript
} from './components'
import * as clinicianTracking from '../../lib/clinician-tracking'
import { useStoreState } from 'easy-peasy'

import _ from 'lodash'

const Overview = ({ session, refetchSession, client, progressNote }: any) => {
  const [parentWidth, setParentWidth] = useState(0)
  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateWidth = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth)
      }
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return (
    <Flex gap="24px" flexDirection="column" w="100%" ref={parentRef}>
      <Details
        session={session}
        progressNote={progressNote}
        client={client}
        refresh={refetchSession}
      />
      <Link
        textDecoration="none"
        _hover={{ cursor: 'pointer' }}
        href="https://blueprint-health.canny.io/ai-notetaker"
        isExternal
        _focus={{ outline: 'none' }}
      >
        <Flex
          p="16px"
          border="1px solid"
          borderColor="pale_gray"
          borderRadius="8px"
          gap="16px"
          bg="white"
          boxShadow="0px 2px 8px 0px #00000014"
          flexDirection={parentWidth < 275 ? 'column' : 'row'}
          alignItems={parentWidth < 275 ? 'center' : 'flex-start'}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            h="44px"
            w="44px"
            bg="primary"
            borderRadius="8px"
          >
            <svg
              width="22"
              height="24"
              viewBox="0 0 22 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.04 2.5L19.45 3.91L17.66 5.71L16.25 4.3L18.04 2.5ZM10 3H12V0H10V3ZM19 12H22V10H19V12ZM10 14.99V17.95H12V14.99L13 14.41C14.23 13.69 15 12.37 15 10.95C15 8.74 13.21 6.95 11 6.95C8.79 6.95 7 8.74 7 10.95C7 12.37 7.76 13.69 9 14.41L10 14.99ZM14 19.95L8 19.95V16.14C6.21 15.1 5 13.17 5 10.95C5 7.64 7.69 4.95 11 4.95C14.31 4.95 17 7.64 17 10.95C17 13.17 15.79 15.1 14 16.14V19.95ZM0 12H3L3 10H0V12ZM3.97 2.49L5.76 4.29L4.36 5.69L2.56 3.9L3.97 2.49ZM8 23.0001C8 23.5501 8.45 24.0001 9 24.0001H13C13.55 24.0001 14 23.5501 14 23.0001V22.0001H8V23.0001Z"
                fill="white"
              />
            </svg>
          </Flex>
          <Flex
            flexDirection="column"
            textAlign={parentWidth < 275 ? 'center' : 'left'}
          >
            <Text>Have a feature request?</Text>
            <Text color="primary">Submit your idea</Text>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  )
}

export function SessionNotes({
  flagsmithFlagsLoaded
}: {
  flagsmithFlagsLoaded: boolean
}) {
  const {
    sessionId,
    id: clientId
  }: { sessionId: string; id: string } = useParams()
  const queryParams = useQueryParams()
  const isDemo = queryParams.get('isDemo') === 'true'
  const history = useHistory()
  const [selectedProgressNoteId, setSelectedProgressNoteId] = useState('')
  const [demoGenerationStepStatuses, setDemoGenerationStepStatuses] = useState([
    { step: 'PROCESS_AUDIO', status: 'STARTED' },
    { step: 'TRANSCRIBING_AUDIO', status: 'NOT_STARTED' },
    { step: 'GENERATE_NOTE', status: 'NOT_STARTED' }
  ])

  const { height: paywallBannerHeight } = useStoreState(
    state => state.paywallBanner
  )
  const toast = useToast()
  const {
    isEvidenceBasedCareEnabled,
    isDocumentationAutomationEnabled
  } = useExperienceManager()

  const [tabIndex, setTabIndex] = useState<number>(0)
  const overviewTabVisible = useBreakpointValue({
    base: true,
    sm: true,
    md: false
  })

  useEffect(() => {
    if (!overviewTabVisible) {
      setTabIndex(prevIndex => prevIndex - 1)
    } else {
      setTabIndex(prevIndex => prevIndex + 1)
    }
  }, [overviewTabVisible])

  const {
    data: session,
    isLoading: isSessionLoading,
    refetch: refetchSession
  } = useSessionControllerGetCompletedSessionDetails(sessionId)

  const {
    data: progressNoteData,
    isLoading: isProgressNotesLoading
  } = useSessionControllerGetProgressNotesForSession(sessionId, {
    query: {
      refetchInterval: (data: any) => {
        if (data?.sessionProgressNotes.some((pn: any) => pn.note?.isLoading)) {
          return 5000
        }

        return false
      }
    }
  })

  const isAnyNoteLoading = useMemo(
    () =>
      progressNoteData?.sessionProgressNotes?.some(
        (pn: any) => pn.note?.isLoading
      ),
    [progressNoteData?.sessionProgressNotes]
  )

  useEffect(() => {
    if (!isDemo) return

    const timeToLoad = 1500
    setTimeout(() => {
      setDemoGenerationStepStatuses([
        { step: 'PROCESS_AUDIO', status: 'COMPLETED' },
        { step: 'TRANSCRIBING_AUDIO', status: 'STARTED' },
        { step: 'GENERATE_NOTE', status: 'NOT_STARTED' }
      ])
      setTimeout(() => {
        setDemoGenerationStepStatuses([
          { step: 'PROCESS_AUDIO', status: 'COMPLETED' },
          { step: 'TRANSCRIBING_AUDIO', status: 'COMPLETED' },
          { step: 'GENERATE_NOTE', status: 'STARTED' }
        ])
        setTimeout(() => {
          setDemoGenerationStepStatuses([
            { step: 'PROCESS_AUDIO', status: 'COMPLETED' },
            { step: 'TRANSCRIBING_AUDIO', status: 'COMPLETED' },
            { step: 'GENERATE_NOTE', status: 'COMPLETED' }
          ])
          setTimeout(() => {
            history.replace(
              `/patient/${clientId}/completed-session/${sessionId}`
            )
          }, 500)
        }, timeToLoad)
      }, timeToLoad)
    }, timeToLoad)
  }, [isDemo])

  useEffect(() => {
    if (isAnyNoteLoading) {
      const loadingNote = progressNoteData?.sessionProgressNotes?.find(
        (pn: any) => pn.note?.isLoading
      )
      setSelectedProgressNoteId(loadingNote?.id)
    }
  }, [isAnyNoteLoading, progressNoteData?.sessionProgressNotes])

  const progressNote = useMemo(
    () =>
      progressNoteData?.sessionProgressNotes?.find(
        (pn: any) => pn.id === selectedProgressNoteId
      ) || progressNoteData?.sessionProgressNotes[0],
    [progressNoteData, selectedProgressNoteId]
  )

  const { mutate: trackEvent } = useSessionControllerTrackEvent()

  const { data: client, isLoading: isClientLoading }: any = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), clientId],
    () => endpoints.getClinicianUserAccount.request({ id: clientId })
  )

  return <SessionNotesV2 />

  if (
    isProgressNotesLoading ||
    isSessionLoading ||
    _.isEmpty(progressNote) ||
    isClientLoading ||
    !flagsmithFlagsLoaded
  )
    return null

  const noteType = progressNote?.noteType || 'soap'

  const handleTrackEvent = (event: string, payload: object) => {
    trackEvent({ id: sessionId, data: { eventType: event, payload } })
  }

  const handleCopyAllToClipboard = ({ isNext = false }) => {
    let values: string[] = []

    if (isNext) {
      progressNote?.template?.sections.forEach(
        ({ key, displayName }: { key: string; displayName: string }) => {
          if (progressNote?.nextNote[key]) {
            values.push(`${displayName}\n${progressNote?.nextNote[key]}`)
          }
        }
      )
    } else {
      progressNote?.template?.sections.forEach(
        ({ key, displayName }: { key: string; displayName: string }) => {
          if (progressNote?.note[key]) {
            values.push(`${displayName}\n${progressNote?.note[key]}`)
          }
        }
      )
    }

    const value = values.join('\n\n')

    copyToClipboard(value)
    clinicianTracking.trackEvent('Copied Progress Note', {
      noteType,
      noteId: progressNote?.id
    })
    handleTrackEvent('copy_all_to_clipboard_clicked', {
      noteType
    })
    toast({
      title: 'Copied to clipboard!',
      status: 'success',
      duration: 1500
    })
  }

  let TabConfig = [
    ...(overviewTabVisible ? [{ name: 'Overview' }] : []),
    { name: 'Note' },
    ...(isDocumentationAutomationEnabled ? [{ name: 'Summary' }] : []),
    ...(isDocumentationAutomationEnabled ? [{ name: 'Transcript' }] : []),
    { name: 'Psychotherapy note' }
  ]

  if (isEvidenceBasedCareEnabled) {
    TabConfig = [...TabConfig, { name: 'Doc snippets' }]
  }

  const transcriptionError = progressNote?.generationStepStatuses?.some(
    ({ step, status }: any) =>
      step === 'TRANSCRIBING_AUDIO' && status === 'ERROR'
  )

  const renderDeletedNote = () => {
    return (
      <Flex
        p="16px"
        border="1px solid"
        height="200px"
        borderColor="pale_gray"
        borderRadius="8px"
        justifyContent="center"
        alignItems="center"
        color="#757575"
        my="24px"
      >
        <Text>
          Notes for this session have been deleted and are no longer available.
        </Text>
      </Flex>
    )
  }

  return (
    <LayoutGrid
      height={`calc(100vh - ${80 + paywallBannerHeight}px)`}
      gridColumnGap="0"
    >
      <GridItem
        display={{
          base: 'none',
          sm: 'none',
          md: 'block'
        }}
        colStart={{
          base: 1,
          sm: 1,
          md: 1
        }}
        colEnd={{
          base: 6,
          sm: 13,
          md: 4
        }}
        paddingTop={{
          base: 'large',
          sm: 'large'
        }}
        bg="#FCFCFC"
        height="100%"
        borderRight="1px solid"
        borderColor="pale_gray"
      >
        <Flex position="sticky" top={`${112 + paywallBannerHeight}px`}>
          <Container>
            <Overview
              session={session}
              refetchSession={refetchSession}
              client={client}
              progressNote={progressNote}
            />
          </Container>
        </Flex>
      </GridItem>
      <GridItem
        colStart={{
          base: 1,
          sm: 1,
          md: 4
        }}
        colEnd={{
          base: 6,
          sm: 13,
          md: 13
        }}
        paddingBottom={{
          base: 'medium',
          sm: 'medium',
          md: 'xxlarge'
        }}
      >
        <Tabs
          isLazy
          lazyBehavior="keepMounted"
          index={tabIndex}
          onChange={index => setTabIndex(index)}
        >
          <Flex
            flexDirection="column"
            position="sticky"
            bg="white"
            zIndex="4"
            top={`${79 + paywallBannerHeight}px`}
          >
            <Box h="30px" />
            <Container>
              <TabList
                sx={{
                  display: 'flex',
                  textWrap: 'nowrap'
                }}
                gap="32px"
                borderBottom="none"
                overflowX="auto"
                overflowY="hidden"
              >
                {TabConfig.map(tab => (
                  <Tab
                    key={tab.name}
                    justifyContent="flex-start"
                    px="0"
                    color="dark_gray"
                    fontWeight="460"
                    borderBottom="6px solid"
                    whiteSpace="nowrap"
                    _focus={{ outline: 'none' }}
                    _selected={{
                      color: '#282828',
                      borderBottomColor: 'primary'
                    }}
                  >
                    {tab.name}
                  </Tab>
                ))}
              </TabList>
            </Container>
            <Divider color="#E4E5E6" />
          </Flex>
          <Container>
            <TabPanels maxWidth="800px">
              {overviewTabVisible && (
                <TabPanel padding={0}>
                  <Box mt="24px">
                    <Overview
                      session={session}
                      refetchSession={refetchSession}
                      client={client}
                      progressNote={progressNote}
                    />
                  </Box>
                </TabPanel>
              )}
              <TabPanel padding={0}>
                {isAnyNoteLoading || isDemo ? (
                  <Box mt="24px">
                    <ProgressNoteGenerationSteps
                      progressNoteGenerationStepStatuses={
                        isDemo
                          ? demoGenerationStepStatuses
                          : progressNoteData?.generationStepStatuses || []
                      }
                    />
                    <Box h="24px" />
                  </Box>
                ) : (
                  <Box>
                    {progressNote?.deletedAt ? (
                      <Box>{renderDeletedNote()}</Box>
                    ) : (
                      <>
                        <Flex
                          flexDirection="column"
                          position="sticky"
                          bg="white"
                          zIndex="4"
                          paddingBottom="16px"
                          gap="16px"
                          top={`${153 + paywallBannerHeight}px`}
                        >
                          <ProgressNoteHeader
                            transcriptGeneratedTimestamp={
                              progressNoteData.transcriptGeneratedTimestamp
                            }
                            progressNoteId={progressNote?.id}
                            header={progressNote.template.displayName}
                            noteType={noteType}
                            handleCopyAllToClipboard={handleCopyAllToClipboard}
                            isCopyAllDisabled={
                              Object.keys(progressNote?.note).length === 0
                            }
                            progressNotes={
                              progressNoteData.sessionProgressNotes
                            }
                            setSelectedProgressNoteId={
                              setSelectedProgressNoteId
                            }
                            selectedProgressNoteId={selectedProgressNoteId}
                            sessionId={sessionId}
                          />
                        </Flex>
                        {transcriptionError && <TranscriptionError />}
                        <ProgressNote
                          progressNote={progressNote}
                          handleTrackEvent={handleTrackEvent}
                        />
                        <Box h="4px" />
                      </>
                    )}
                  </Box>
                )}
                <ProgressNoteExplanation />
                <Box h="32px" />
              </TabPanel>
              {isDocumentationAutomationEnabled && (
                <TabPanel padding={0}>
                  <Box mt="24px">
                    <SessionSummary trackEvent={handleTrackEvent} />
                  </Box>
                </TabPanel>
              )}
              {isDocumentationAutomationEnabled && (
                <TabPanel padding={0}>
                  <Transcript sessionId={sessionId} client={client} />
                </TabPanel>
              )}
              <TabPanel padding={0}>
                <Box mt="24px">
                  <PrivateNotes
                    sessionId={sessionId}
                    psychotherapyNote={session?.psychotherapyNote}
                    refresh={refetchSession}
                    top={`${183 + paywallBannerHeight}px`}
                  />
                </Box>
              </TabPanel>
              <TabPanel padding={0}>
                <Box mt="16px">
                  <DocSnippets
                    session={session}
                    handleTrackEvent={handleTrackEvent}
                  />
                </Box>
              </TabPanel>
            </TabPanels>
          </Container>
        </Tabs>
      </GridItem>
    </LayoutGrid>
  )
}
