import React, { useState } from 'react'
import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
  Text,
  Box,
  DrawerCloseButton,
  DrawerFooter,
  DrawerBody,
  Button,
  FormControl,
  Select,
  Flex,
  Link,
  VStack,
  Checkbox,
  HStack,
  Tooltip,
  useRadio,
  useRadioGroup
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { Loading } from '@components'
import * as Yup from 'yup'
import { Field, Form, Formik, FieldArray } from 'formik'
import { Refresh } from '@blueprinthq/joy'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { NoteSettings, CircleQuestion } from '@components/icons'
import { getSessionControllerGetProgressNotesForSessionQueryKey } from '~/clinician-api'

const Schema = Yup.object().shape({
  selfReference: Yup.string().required('Required'),
  patientReference: Yup.string().required('Required'),
  noteLength: Yup.string().required('Required'),
  diagnosticSymptoms: Yup.string().required('Required'),
  medicalNecessity: Yup.string().required('Required'),
  actionItems: Yup.string().required('Required'),
  directQuotes: Yup.string().required('Required'),
  formatting: Yup.string().required('Required'),
  mentalStatusExam: Yup.array()
    .of(Yup.string())
    .required('Required')
})

function RadioGroup({ onChange, value, options = [] }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange
  })

  const group = getRootProps()

  return (
    <HStack gap="8px" {...group}>
      {options.map(({ label, value }) => {
        const radio = getRadioProps({ value })
        return (
          <RadioCard key={value} {...radio}>
            {label}
          </RadioCard>
        )
      })}
    </HStack>
  )
}
function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" w="100%">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        border="1px solid #E4E5E6"
        borderRadius="md"
        _checked={{
          bg: 'rgba(45, 84, 232, 0.04)',
          color: 'black',
          border: '2px solid #2D54E8'
        }}
        _focus={{
          boxShadow: 'none'
        }}
        px="20px"
        py="9px"
        textAlign="center"
      >
        {props.children}
      </Box>
    </Box>
  )
}

export const NoteSettingsDrawer = ({
  isOpen,
  onClose,
  progressNoteId,
  sessionId,
  progressNote
}) => {
  const [rememberSettings, setRememberSettings] = useState(true)
  const { user } = useStoreState(state => state.auth)
  const organizationId = user?.clinic?.organization?.id
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(
    [endpoints.getProgressNoteSettings.getCacheId(), organizationId],
    () => endpoints.getProgressNoteSettings.request({ organizationId }),
    {
      enabled: !!organizationId
    }
  )

  const {
    mutateAsync: regenerateNote,
    isLoading: isRegenerating
  } = useMutation(endpoints.postRegenerateWithPreferences.request)

  const handleRegeneration = async values => {
    await regenerateNote(
      {
        progressNoteId,
        preferences: values,
        saveAsDefault: rememberSettings
      },
      {
        onSuccess: async data => {
          onClose()
          await queryClient.invalidateQueries(
            getSessionControllerGetProgressNotesForSessionQueryKey(sessionId)
          )
          await queryClient.invalidateQueries([
            endpoints.getUserAccount.getCacheId()
          ])
          await queryClient.invalidateQueries([
            endpoints.getProgressNoteSettings.getCacheId()
          ])
        }
      }
    )
  }
  if (isLoading || !progressNote) return null

  const preferences = data?.preferences || {}
  const preferenceOptions = data?.preferenceOptions || {}

  const initialValues = {
    ...preferences,
    ...progressNote.preferences
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={handleRegeneration}
    >
      {({ handleChange, handleSubmit, setValues, dirty }) => {
        return (
          <ChakraDrawer isOpen={isOpen} onClose={onClose} size="md">
            <DrawerOverlay />
            <DrawerContent>
              <Box
                height="80px"
                backgroundColor="primary"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px="small"
              >
                <Flex align="center">
                  <NoteSettings fill="white" height="20" width="20" />
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="white"
                    paddingLeft="xsmall"
                    ml={2}
                  >
                    Note Preferences
                  </Text>
                </Flex>
                <Flex align="center">
                  <Link
                    mr={4}
                    color="white"
                    onClick={() => setValues(data.defaultPreferences)}
                  >
                    Reset to defaults
                  </Link>
                  <DrawerCloseButton
                    color="white"
                    position="static"
                    _focus={{ border: 'none' }}
                  />
                </Flex>
              </Box>
              <DrawerBody p={0}>
                <Form>
                  <Box px={8} py={6} overflowY="scroll" height="100%">
                    <FormControl>
                      <VStack spacing="20px" align="stretch">
                        <HStack gap="20px" alignItems="center">
                          <Box flex="1">
                            <Text mb={2}>How you refer to yourself:</Text>
                            <Flex gap="16px" flexDirection="column">
                              <Field name="selfReference">
                                {({ field }) => (
                                  <Select
                                    {...field}
                                    value={field.value}
                                    style={{ border: '1px solid #E4E5E6' }}
                                    _focus={{
                                      outline: 'none'
                                    }}
                                    borderRadius="4px"
                                    height="48px"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  >
                                    {preferenceOptions[
                                      'selfReference'
                                    ]?.options?.map(type => (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </option>
                                    ))}
                                  </Select>
                                )}
                              </Field>
                            </Flex>
                          </Box>
                          <Box flex="1">
                            <Text mb={2}>Those you care for:</Text>
                            <Flex gap="16px" flexDirection="column">
                              <Field name="patientReference">
                                {({ field }) => (
                                  <Select
                                    {...field}
                                    value={field.value}
                                    style={{ border: '1px solid #E4E5E6' }}
                                    _focus={{
                                      outline: 'none'
                                    }}
                                    borderRadius="4px"
                                    height="48px"
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  >
                                    {preferenceOptions[
                                      'patientReference'
                                    ]?.options?.map((type, index) => (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </option>
                                    ))}
                                  </Select>
                                )}
                              </Field>
                            </Flex>
                          </Box>
                        </HStack>
                        {/* <Box>
                          <Text mb={2}>Formatting</Text>
                          <Field name="formatting">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['formatting']?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box> */}
                        <Box>
                          <Text mb={2}>Length:</Text>
                          <Field name="noteLength">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['noteLength']?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Text mb={2}>Medical Necessity:</Text>
                          <Field name="medicalNecessity">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['medicalNecessity']?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Flex alignItems="center" mb={2}>
                            <Text mr="3px">Diagnostic Symptoms:</Text>
                            <Tooltip
                              hasArrow
                              label="Use brief or clinical language and describe symptoms and behaviors."
                              maxW="250px"
                            >
                              <Flex alignItems="center">
                                <CircleQuestion fill="#A1A1A1" />
                              </Flex>
                            </Tooltip>
                          </Flex>
                          <Field name="diagnosticSymptoms">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['diagnosticSymptoms']
                                    ?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Text mb={2}>Action Items:</Text>
                          <Field name="actionItems">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['actionItems']?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Flex alignItems="center" mb={2}>
                            <Text mr="3px">Direct Quotes:</Text>
                            <Tooltip
                              hasArrow
                              label="Include direct quotes or summarize information"
                              maxW="250px"
                            >
                              <Flex alignItems="center">
                                <CircleQuestion fill="#A1A1A1" />
                              </Flex>
                            </Tooltip>
                          </Flex>
                          <Field name="directQuotes">
                            {({ field, form }) => (
                              <RadioGroup
                                value={field.value}
                                options={
                                  preferenceOptions['directQuotes']?.options
                                }
                                onChange={value =>
                                  form.setFieldValue(field.name, value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Flex alignItems="center" mb={3}>
                            <Text mr="3px">
                              Include Sections of the Mini Mental Status Exam
                              (MMSE):
                            </Text>
                            <Tooltip
                              hasArrow
                              label="Applies to non-intake notes only. At least 3 is recommended for most insurance billing."
                              maxW="250px"
                            >
                              <Flex alignItems="center">
                                <CircleQuestion fill="#A1A1A1" />
                              </Flex>
                            </Tooltip>
                          </Flex>
                          <FieldArray name="mentalStatusExam">
                            {({ push, remove, form }) => (
                              <Flex
                                justifyContent="space-between"
                                flexWrap="wrap"
                                rowGap="15px"
                              >
                                {preferenceOptions[
                                  'mentalStatusExam'
                                ]?.options?.map(option => {
                                  const isChecked = form.values.mentalStatusExam.includes(
                                    option.value
                                  )
                                  return (
                                    <Box>
                                      <Checkbox
                                        key={option.value}
                                        flex="1"
                                        size="md"
                                        isChecked={isChecked}
                                        onChange={() => {
                                          if (isChecked) {
                                            remove(
                                              form.values.mentalStatusExam.indexOf(
                                                option.value
                                              )
                                            )
                                          } else {
                                            push(option.value)
                                          }
                                        }}
                                      >
                                        {option.label}
                                      </Checkbox>
                                    </Box>
                                  )
                                })}
                              </Flex>
                            )}
                          </FieldArray>
                        </Box>
                      </VStack>
                    </FormControl>
                  </Box>
                </Form>
              </DrawerBody>
              <DrawerFooter p={0} width="100%" borderTop="1px #E4E5E6 solid">
                <Box m="small" width="100%">
                  <Box mb={4}>
                    <Checkbox
                      isChecked={rememberSettings}
                      onChange={e => setRememberSettings(e.checked)}
                    >
                      Remember these settings for future notes
                    </Checkbox>
                  </Box>
                  <Button
                    colorScheme="primary"
                    variant="solid"
                    size="lg"
                    w="100%"
                    m={0}
                    leftIcon={<Refresh fill="White" h="16px" w="16px" />}
                    onClick={handleSubmit}
                    isLoading={isRegenerating}
                    isDisabled={isRegenerating || !dirty}
                  >
                    Generate new note
                  </Button>
                </Box>
              </DrawerFooter>
            </DrawerContent>
          </ChakraDrawer>
        )
      }}
    </Formik>
  )
}
