import React, { createContext, useCallback, useState, useContext } from 'react'
import { PanelKey } from '../overlay-panel'

interface AssistPanelContextType {
  switchTab: (index: number) => void
  activeTab: number
  panel: PanelKey | null
  data?: any
  setOverlayPanel: (panel: PanelKey | null, data?: any) => void
}

export const AssistPanelContext = createContext<AssistPanelContextType>({
  switchTab: () => {},
  activeTab: 0,
  panel: null,
  data: null,
  setOverlayPanel: () => {}
})

export const useAssistPanelContext = () => useContext(AssistPanelContext)

export const AssistPanelProvider: React.FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [panel, setPanel] = useState<PanelKey | null>(null)
  const [panelData, setPanelData] = useState<any>(null)

  const switchTab = useCallback((index: number) => {
    setActiveTab(index)
  }, [])

  const setOverlayPanel = (panel: PanelKey | null, data?: any) => {
    setPanel(panel)
    setPanelData(data)
  }

  return (
    <AssistPanelContext.Provider
      value={{ switchTab, activeTab, panel, data: panelData, setOverlayPanel }}
    >
      {children}
    </AssistPanelContext.Provider>
  )
}

export default AssistPanelProvider
