import React, { createContext, FC, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { endpoints } from '@api'
import { useLocation } from 'react-router-dom'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'

interface ExperienceManagerContextType {
  isEvidenceBasedCareEnabled: boolean
  isDocumentationAutomationEnabled: boolean
  isAssistEnabled: boolean
  isAssistAssessmentAssignmentEnabled: boolean
  isAssistSuggestionStrengthEnabled: boolean
  isDocumentationAutomationFreePlan: boolean
  documentationAutomationSessionsRemaining: number
  documentationAutomationSessionsUsed: number
  documentationAutomationSessionsAllotted: number
  hasDocumentationAutomationSubscription: boolean
  documentationAutomationFreeTierSessionLimitReached: boolean
  clinicianCount: number
  isAdmin: boolean
  isSuperAdmin: boolean
  isDocumentationAutomationEnterprise: boolean
  isSafari: boolean
  isExtension: boolean
  isExtensionMinimized: boolean
  toggleIsExtensionMinimized: () => void
  isWidget: boolean
}

export const ExperienceManagerContext = createContext<
  ExperienceManagerContextType
>({} as ExperienceManagerContextType)

interface ExperienceManagerProviderProps {
  children: React.ReactNode
}

export const ExperienceManagerProvider: FC<ExperienceManagerProviderProps> = ({
  children
}) => {
  const { user } = useStoreState(state => state.auth)
  const location = useLocation()
  const isAssistEnabled = flagsmith.hasFeature(FlagsmithFeatures.ASSIST)
  const isAssistAssessmentAssignmentEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.ASSIST_ASSESSMENT_ASSIGNMENT_ENABLED
  )
  const isAssistSuggestionStrengthEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.ASSIST_SUGGESTION_STRENGTH_ENABLED
  )

  const [isExtension, setIsExtension] = useState(false)
  const [isWidget, setIsWidget] = useState(false)
  const [isExtensionMinimized, setIsExtensionMinimized] = useState(false)

  useEffect(() => {
    const extension = location.pathname.includes('/extension')
    const widget = location.pathname.includes('/widget')
    if (extension) {
      setIsExtension(true)
    }

    if (widget) {
      setIsWidget(true)
    }
  }, [])

  const { data, isLoading } = useQuery(
    endpoints.getPaywallValidation.getCacheId(),
    endpoints.getPaywallValidation.request
  )

  if (isLoading) return null

  const {
    documentationAutomationSessionsRemaining,
    documentationAutomationSessionsUsed,
    documentationAutomationSessionsAllotted,
    isDocumentationAutomationFree,
    isDocumentationAutomationEnabled: isDocAutomationEnabled,
    clinicianCount,
    isDocumentationAutomationEnterprise,
    hasDocumentationAutomationSubscription,
    isEBCEnabled: isEvidenceBasedCareEnabled
  } = data as any

  const isDocumentationAutomationEnabled = isDocAutomationEnabled

  const isAdmin = !!user?.roles.find(
    (role: { name: string }) =>
      role.name === 'clinicadmin' ||
      role.name === 'superadmin' ||
      role.name === 'orgadmin'
  )

  const isSuperAdmin = !!user?.roles.find(
    (role: { name: string }) => role.name === 'superadmin'
  )

  const isSafari =
    navigator.userAgent.indexOf('Safari') != -1 &&
    navigator.userAgent.indexOf('Chrome') == -1 &&
    navigator.userAgent.indexOf('CriOS') == -1

  const toggleIsExtensionMinimized = () => {
    setIsExtensionMinimized((isMinimized: boolean) => !isMinimized)
    const eventType = !isExtensionMinimized ? 'BP_MINIMIZE' : 'BP_EXPAND'
    // eslint-disable-next-line
    parent.postMessage({ type: eventType }, '*')
  }

  return (
    <ExperienceManagerContext.Provider
      value={{
        isEvidenceBasedCareEnabled: isEvidenceBasedCareEnabled,
        isDocumentationAutomationEnabled: isDocumentationAutomationEnabled,
        isAssistEnabled,
        isAssistAssessmentAssignmentEnabled,
        isAssistSuggestionStrengthEnabled,
        hasDocumentationAutomationSubscription,
        documentationAutomationSessionsRemaining,
        documentationAutomationSessionsUsed,
        documentationAutomationSessionsAllotted,
        isDocumentationAutomationFreePlan: isDocumentationAutomationFree,
        documentationAutomationFreeTierSessionLimitReached:
          isDocumentationAutomationFree &&
          documentationAutomationSessionsRemaining < 1,
        clinicianCount: clinicianCount,
        isAdmin,
        isSuperAdmin,
        isDocumentationAutomationEnterprise,
        isSafari,
        isExtension,
        isExtensionMinimized,
        toggleIsExtensionMinimized,
        isWidget
      }}
    >
      {children}
    </ExperienceManagerContext.Provider>
  )
}
