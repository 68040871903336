import React from 'react'

const MicUpload = ({ width, height, padding }) => {
  return (
    <svg
      width={width || '25'}
      height={height || '30'}
      {...(padding && { style: { padding } })}
      viewBox="0 0 25 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_169_4176)">
        <path
          d="M18.2845 15.9206C17.6862 15.9206 17.1975 16.3682 17.1048 16.9679C16.574 20.625 13.2878 23.0997 9.74049 23.0997C6.19313 23.0997 2.90699 20.625 2.37615 16.9679C2.29189 16.3767 1.79475 15.9206 1.1965 15.9206C0.480293 15.9206 -0.0926764 16.5456 0.0084358 17.2551C0.615109 21.3345 3.96866 24.5693 7.98788 25.3209C8.1901 25.3547 8.33334 25.5236 8.33334 25.7348V28.8091C8.33334 29.4679 8.86418 30 9.52141 30H9.94271C10.5999 30 11.1308 29.4679 11.1308 28.8091V25.7348C11.1308 25.5321 11.274 25.3632 11.4762 25.3209C15.4955 24.5777 18.849 21.3345 19.4557 17.2551C19.5652 16.5456 18.9838 15.9206 18.2676 15.9206H18.2845Z"
          fill="white"
        />
        <path
          d="M9.74048 20.1436C12.0745 20.1436 13.9366 18.2601 13.9366 15.9206V12.576C12.5548 11.5118 11.5521 10.0422 11.0886 8.31925C10.6589 6.72297 10.7432 5.07601 11.2909 3.56419C10.8106 3.36993 10.2882 3.26013 9.73205 3.26013C7.39805 3.26013 5.51904 5.14358 5.51904 7.4831V15.9291C5.51904 18.2686 7.39805 20.152 9.73205 20.152L9.74048 20.1436Z"
          fill="white"
        />
        <path
          d="M21.8823 0.836149C20.8964 0.27027 19.8263 0 18.7647 0C16.6076 0 14.5095 1.12331 13.3467 3.13345C11.6194 6.13176 12.6474 9.95777 15.6302 11.6892C16.616 12.2551 17.6861 12.5253 18.7478 12.5253C20.9049 12.5253 23.0029 11.402 24.1657 9.39189C25.8931 6.39358 24.8651 2.56757 21.8823 0.836149ZM19.9022 9.78885H17.6271C17.2901 9.78885 17.012 9.51858 17.012 9.1723C17.012 8.82601 17.2817 8.55574 17.6271 8.55574H19.9022C20.2392 8.55574 20.5173 8.82601 20.5173 9.1723C20.5173 9.51858 20.2476 9.78885 19.9022 9.78885ZM20.9386 4.82264C20.8206 4.94088 20.6605 5 20.5088 5C20.3572 5 20.1971 4.94088 20.0791 4.82264L19.3798 4.12162V6.73986C19.3798 7.0777 19.1101 7.35642 18.7647 7.35642C18.4192 7.35642 18.1496 7.08615 18.1496 6.73986V4.09628L17.4249 4.82264C17.307 4.94088 17.1469 5 16.9952 5C16.8435 5 16.6834 4.94088 16.5655 4.82264C16.3295 4.58615 16.3295 4.19764 16.5655 3.9527L18.3097 2.20439C18.436 2.0777 18.5961 2.02703 18.7562 2.02703C18.8405 2.02703 18.9248 2.03547 19.0006 2.06926C19.0764 2.10304 19.1438 2.14527 19.2028 2.20439L20.947 3.9527C21.1829 4.18919 21.1829 4.5777 20.947 4.82264H20.9386Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_169_4176">
          <rect width="25" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MicUpload
