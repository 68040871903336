import React from 'react'

const GraphicEq = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3365_30208)">
        <path
          d="M7 17C7 17.5523 7.44772 18 8 18C8.55228 18 9 17.5523 9 17V7C9 6.44771 8.55228 6 8 6C7.44772 6 7 6.44772 7 7V17ZM11 21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V21ZM3 13C3 13.5523 3.44772 14 4 14C4.55228 14 5 13.5523 5 13V11C5 10.4477 4.55228 10 4 10C3.44772 10 3 10.4477 3 11V13ZM15 17C15 17.5523 15.4477 18 16 18C16.5523 18 17 17.5523 17 17V7C17 6.44771 16.5523 6 16 6C15.4477 6 15 6.44772 15 7V17ZM20 10C19.4477 10 19 10.4477 19 11V13C19 13.5523 19.4477 14 20 14C20.5523 14 21 13.5523 21 13V11C21 10.4477 20.5523 10 20 10Z"
          fill="#282828"
        />
      </g>
      <defs>
        <clipPath id="clip0_3365_30208">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GraphicEq
