import { endpoints } from '@api'
import { AddCircleIcon } from '@blueprinthq/joy'
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Button,
  HStack
} from '@chakra-ui/react'
import { Loading, SuperAdminOrganizationSelector } from '@components'
import { EditPencil } from '@components/icons'
import { TrashIcon } from '@icons'
import { useStoreState } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Program } from 'src/api/endpoints'
import ProgramModal from './program-modal'
import { ProgramDeleteModal } from './program-delete-modal'
import { usePermissions } from '@hooks'

export const ProgramEditor: React.FC = () => {
  const { user } = useStoreState(state => state.auth)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null)
  const { hasPermission } = usePermissions()
  const hasEditPermission = hasPermission('*:org:settings:*')
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose
  } = useDisclosure()

  const [organizationId, setOrganizationId] = useState(
    user?.clinic?.organization_id
  )

  useEffect(() => {
    if (user) {
      setOrganizationId(user?.clinic?.organization_id)
    }
  }, [user])

  const { data, isLoading } = useQuery(
    [endpoints.getPrograms.getCacheId(), organizationId],
    () =>
      endpoints.getPrograms.request({
        organizationId: organizationId
      }),
    {
      initialData: { programs: [] }
    }
  )
  const programs = data?.programs || []

  const toast = useToast()

  const refreshProgramsList = () =>
    queryClient.invalidateQueries(endpoints.getPrograms.getCacheId())

  const queryClient = useQueryClient()
  const { mutate: createProgram } = useMutation(
    endpoints.createProgram.request,
    {
      onSuccess: () => {
        toast({
          title: 'Successfully created!',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        refreshProgramsList()
      }
    }
  )

  const { mutate: updateProgram } = useMutation(
    endpoints.updateProgram.request,
    {
      onSuccess: () => {
        toast({
          title: 'Successfully updated!',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        refreshProgramsList()
      }
    }
  )

  const { mutate: deleteProgram } = useMutation(
    endpoints.deleteProgram.request,
    {
      onSuccess: () => {
        toast({
          title: 'Successfully deleted!',
          status: 'success',
          isClosable: true,
          duration: 1200
        })
        refreshProgramsList()
      },
      onError: () => {
        toast({
          title: `Can't delete a program with enrolled clients`,
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  const onClickEdit = (program: Program) => {
    setSelectedProgram(program)
    onOpen()
  }

  const onClickDelete = (program: Program) => {
    setSelectedProgram(program)
    onDeleteModalOpen()
  }

  const confirmDelete = () => {
    if (selectedProgram) {
      deleteProgram({ programId: selectedProgram.id, organizationId })
      onDeleteModalClose()
    }
  }

  const onClickCreate = () => {
    setSelectedProgram(null)
    onOpen()
  }

  const handleCreateProgram = (name: string) => {
    createProgram({ organizationId: organizationId, name })
    onClose()
  }

  const handleUpdateProgram = (name: string) => {
    if (!selectedProgram?.id) {
      console.error('cannot update program name: selected program is undefined')
      return
    }
    updateProgram({
      organizationId: organizationId,
      programId: selectedProgram?.id,
      name
    })
    setSelectedProgram(null)
    onClose()
  }

  const HeaderText = () => {
    return (
      <Box mb="medium">
        <Flex justify="space-between" alignItems="center" flexDirection="row">
          <Box w="100%">
            <Heading mb="xxsmall">Programs</Heading>
            <Text display={'inline-flex'}>
              Manage your organizations treatment programs
            </Text>
          </Box>
          {hasEditPermission && (
            <Button
              size={'lg'}
              onClick={() => onClickCreate()}
              data-intercom-target={`create-workflow`}
            >
              Add Program
            </Button>
          )}
        </Flex>
      </Box>
    )
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <HeaderText />
      <SuperAdminOrganizationSelector
        selectedOrganizationId={organizationId}
        setSelectedOrganizationId={setOrganizationId}
      />
      <Box
        w="100%"
        border="1px solid"
        borderColor="#E4E5E6"
        borderRadius="4px"
        mt={'small'}
        mb={'small'}
        bg="white"
        verticalAlign={'middle'}
      >
        <Table variant="unstyled">
          <Thead>
            <Tr borderBottom={'1px solid #E4E5E6'}>
              <Th letterSpacing={'none'}>
                <Box height="100%" display="flex" alignItems="center">
                  <Heading textTransform={'none'} fontSize="16px">
                    Name
                  </Heading>
                </Box>
              </Th>
              {hasEditPermission && (
                <Th style={{ textAlign: 'right' }}>
                  {' '}
                  <Box height="100%">
                    <Heading textTransform={'none'} fontSize="16px">
                      Actions
                    </Heading>
                  </Box>
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {programs.map(program => (
              <Tr
                key={program.id}
                _last={{ borderBottom: 'none' }}
                borderBottom={'1px solid #E4E5E6'}
              >
                <Td>{program.name}</Td>
                <Td style={{ textAlign: 'right' }}>
                  {hasEditPermission && (
                    <>
                      <IconButton
                        aria-label="Edit program"
                        isRound={true}
                        icon={<EditPencil />}
                        variant="ghost"
                        _hover={{ bg: 'pale_gray' }}
                        onClick={() => onClickEdit(program)}
                      />
                      <IconButton
                        aria-label="Edit program"
                        isRound={true}
                        icon={<TrashIcon />}
                        variant="ghost"
                        _hover={{ bg: 'pale_gray' }}
                        onClick={() => onClickDelete(program)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {!isLoading && programs.length === 0 && (
          <HStack justifyContent="center" mb={4} mt={4} spacing="3">
            <Text color={'medium_gray'} display={'inline'}>
              No programs added yet.
            </Text>
          </HStack>
        )}
      </Box>
      <ProgramModal
        isOpen={isOpen}
        onClose={onClose}
        handleCreateProgram={handleCreateProgram}
        handleUpdateProgram={handleUpdateProgram}
        program={selectedProgram}
      />
      <ProgramDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        onDeleteConfirm={confirmDelete}
        itemName={selectedProgram ? selectedProgram.name : ''}
      />
    </Box>
  )
}
