import React from 'react'
import { HStack, Stack, Flex, Text } from '@chakra-ui/react'
import { ArrowRightIcon } from '@blueprinthq/joy'
import { useExperienceManager } from '@hooks'

const ProgressCircle = ({
  complete,
  total
}: {
  complete: number
  total: number
}) => (
  <svg
    id="progress-circle"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="18" fill="white" />
    <rect
      x="2"
      y="2"
      width="32"
      height="32"
      rx="16"
      stroke="#2D54E8"
      stroke-opacity="0.25"
      stroke-width="4"
    />
    <circle cx="18" cy="18" r="16" stroke="#E0E0E0" stroke-width="4" />
    {complete > 0 && (
      <circle
        id="progress-path"
        cx="18"
        cy="18"
        r="16"
        stroke="#2D54E8"
        stroke-width="4"
        stroke-dasharray="100, 100"
        stroke-dashoffset={100 - (complete / total) * 100}
        transform="rotate(-90 18 18)"
      />
    )}
    <text
      id="progress-text"
      x="18"
      y="21"
      text-anchor="middle"
      font-size="10"
      fill="#2D54E8"
    >
      {complete}/{total}
    </text>
  </svg>
)

const SuggestionStrengthCard = () => {
  const { isAssistSuggestionStrengthEnabled } = useExperienceManager()
  const complete = 1
  const total = 6
  const remaining = total - complete

  if (!isAssistSuggestionStrengthEnabled) return null

  return (
    <HStack
      spacing="0"
      bg="#F9F9F9"
      border="1px solid"
      borderColor="pale_blue"
      borderRadius="8px"
      py="12px"
      px="16px"
      _hover={{ bg: 'pale_blue', cursor: 'pointer' }}
    >
      <HStack spacing="0" flex="1" gap="16px">
        <ProgressCircle complete={complete} total={total} />
        <Stack spacing="0">
          <Text fontWeight="bold">Improve suggestions</Text>
          <Text color="dark_gray" fontSize="12px" lineHeight="16px">
            Answer {remaining} more questions
          </Text>
        </Stack>
      </HStack>
      <ArrowRightIcon />
    </HStack>
  )
}

export default SuggestionStrengthCard
