import React from 'react'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { Box, Text, Divider, Button, Flex, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import Loading from '../../../components/loading'
import Notification, { NotificationVariants } from './notification'
import { endpoints } from '../../../api'

const NotificationContainer = ({ overrideClinicianId }) => {
  const queryClient = useQueryClient()

  const eventTypes = Object.keys(NotificationVariants).map(e => e.toLowerCase())
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    [
      endpoints.getClinicianDashboardNotifications.getCacheId(),
      overrideClinicianId
    ],
    ({ pageParam = 1 }) =>
      endpoints.getClinicianDashboardNotifications.request({
        page: pageParam,
        eventTypes,
        overrideClinicianId
      }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.meta.totalPageCount > pages.length
          ? pages.length + 1
          : undefined
    }
  )

  const { mutateAsync: markAsRead } = useMutation(
    endpoints.putClinicianNotificationRead.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getClinicianDashboardNotifications.getCacheId()
        )
        queryClient.invalidateQueries(
          endpoints.getUnreadNotificationCount.getCacheId(),
          { refetchInactive: true }
        )
      }
    }
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <Text as="h2" fontSize="lg" fontWeight="bold" mb={8}>
        Notifications
      </Text>
      <Box
        shadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
        p="medium"
        mb="large"
        borderRadius="8px"
      >
        {data.pages[0].nodes.length === 0 ? (
          <EmptyState />
        ) : (
          data.pages.map((page, i) => (
            <React.Fragment key={i}>
              {page.nodes.map((n, j) => {
                const isLast =
                  j === page.nodes.length - 1 && i === data.pages.length - 1
                return (
                  <React.Fragment key={j}>
                    <MemoizedNotification {...n} markAsRead={markAsRead} />
                    {!isLast && <Divider my="small" />}
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          ))
        )}
        {hasNextPage && (
          <Flex justify="center" mt="small">
            <Button
              onClick={() => fetchNextPage()}
              variant="outline"
              bg="rgba(45, 84, 232, 0.08)"
              color="#2D54E8"
              border="1px solid rgba(45, 84, 232, 0.6)"
              m={0}
            >
              Load more
            </Button>
          </Flex>
        )}
      </Box>
    </>
  )
}

const EmptyState = () => (
  <Box mx="auto" textAlign="center" w="100%" maxW="500px">
    <Image
      mx="auto"
      maxW="256px"
      sx={{
        WebkitFilter: 'grayscale(100%)',
        filter: 'grayscale(100%)',
        transition: 'all 180ms ease-in',

        '&:hover': {
          WebkitFilter: 'grayscale(0%)',
          filter: 'grayscale(0%)'
        }
      }}
      alt="Future clients"
      src="https://res.cloudinary.com/hellojoy/image/upload/v1541260001/empty_state_people_circle_speble.png"
    />
    <Text fontSize="md" my="small">
      No notifications yet.
    </Text>

    <Button as={Link} to="/patients/active">
      View and Enroll Clients
    </Button>
  </Box>
)

const MemoizedNotification = React.memo(Notification, (props, nextProps) =>
  _.isEqual(props, nextProps)
)

export default NotificationContainer
