import React, { useState, useEffect } from 'react'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import {
  Link,
  VStack,
  Container,
  GridItem,
  Box,
  Text,
  Image,
  Flex,
  Heading,
  useToast
} from '@chakra-ui/react'
import { Button, LayoutGrid, TextField } from '@blueprinthq/joy'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import BlueprintLogoRound from '../images/brand/blueprint_logo_round.svg'
import qs from 'query-string'
import { api } from '@api'
import { Loading } from '@components'

export const SingleSignOnCallback = () => {
  const history = useHistory()
  const location = useLocation()
  const toast = useToast()
  const { code, state } = qs.parse(location.search)
  const { next: nextUrl, popup, ...searchQuery } = qs.parse(api.decode(state))
  const queryParams = qs.stringify(searchQuery)
  const { validateSSOCode } = useStoreActions(actions => actions.auth)

  const redirectUrl = nextUrl
    ? `${nextUrl}?${queryParams}`
    : `/dashboard?${queryParams}`

  useEffect(async () => {
    try {
      await validateSSOCode({ code })
      if (popup === '1') {
        window.close()
      } else {
        history.push(redirectUrl)
      }
    } catch (err) {
      toast({
        title: err.error.message,
        status: 'error',
        isClosable: true
      })
      history.push('/login')
    }
  }, [code])

  return <Loading />
}

export default function SingleSignOn() {
  const location = useLocation()
  const { fetchSSOAuthURL } = useStoreActions(actions => actions.auth)
  const [loginError, setLoginError] = useState(null)

  const handleSubmit = async values => {
    const query = {
      email: values.email,
      redirectUrl: `${window.location.origin}/sso/callback`,
      state: api.encode(location.search)
    }
    try {
      const { redirect_url } = await fetchSSOAuthURL(query)
      if (redirect_url) {
        window.location.href = redirect_url
      }
    } catch (err) {
      setLoginError(err.error.message)
    }
  }

  return (
    <Container
      marginTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box mb="medium">
            <Heading size="lg">Log in with Single-Sign On (SSO)</Heading>
          </Box>
          <Formik
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Invalid email')
                .required('Required')
            })}
            initialValues={{
              email: (location.state && location.state.email) || ''
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={() => setLoginError(null)}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <VStack spacing="small" mb="medium" alignItems="flex-start">
                  {loginError && <Text color="error">{loginError}</Text>}
                  <Box w="100%" mb={errors.email && touched.email && 'small'}>
                    <Field name="email">
                      {({ field }) => (
                        <TextField
                          {...field}
                          isRequired
                          label="Email"
                          isInvalid={errors.email && touched.email}
                          errorText={errors.email}
                        />
                      )}
                    </Field>
                  </Box>
                  <Button
                    size="lg"
                    my="small"
                    isFullWidth
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Log in
                  </Button>
                  <Link
                    color="primary"
                    alignSelf="center"
                    as={RouterLink}
                    to="/login"
                  >
                    Return to login
                  </Link>
                </VStack>
              </Form>
            )}
          </Formik>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
