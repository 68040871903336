import React from 'react'
import { HStack, Button } from '@chakra-ui/react'
import { AssistEnrollmentForm, AssistEnrollmentFormFields } from '../shared/assist/assist-enrollment-form'
import {
  DialogContainer,
  DialogHeader,
  DialogBody,
  DialogFooter
} from '@handlers/sessions/components/dialog'
import { AssessmentSuggestionDTO } from '~/clinician-api/models'

interface AssessmentAssignmentModalProps {
  onSubmit: (values: any) => void
  isSubmitting: boolean
  isOpen: boolean
  onClose: () => void
  suggestion: AssessmentSuggestionDTO
  client: {
    id: string
    user_id: string
    first_name: string
    enrollment_timestamp: string
  }
}

export const AssessmentAssignmentModal = ({
  isOpen,
  isSubmitting,
  onClose,
  suggestion,
  client,
  onSubmit
}: AssessmentAssignmentModalProps) => {
  const assessments = [{
    clinicAssessmentId: suggestion.clinicAssessmentId,
    cadenceValue: suggestion.cadenceValue,
    cadenceUnit: suggestion.cadenceUnit,
    suggestionId: suggestion.contentSuggestionId
  }]

  return (
    <AssistEnrollmentForm
      client={client}
      assessments={assessments}
      onSubmit={onSubmit}
    >
      {({ formikProps, allAssessments }: any) => (
        <DialogContainer isOpen={isOpen} onClose={onClose} size="2xl">
          <DialogHeader text="Enroll & Assign" onClose={onClose} />
          <DialogBody>
            <AssistEnrollmentFormFields
              formikProps={formikProps}
              assessments={allAssessments}
              client={client}
            />
          </DialogBody>
          <DialogFooter>
            <HStack spacing={4} w="100%">
              <Button onClick={onClose} variant="outline" isFullWidth>
                Cancel
              </Button>
              <Button
                isFullWidth
                isLoading={isSubmitting}
                isDisabled={!formikProps.isValid || !formikProps.dirty}
                onClick={formikProps.submitForm}
              >
                Enroll & Assign
              </Button>
            </HStack>
          </DialogFooter>
        </DialogContainer>
      )}
    </AssistEnrollmentForm>
  )
}
