import React from 'react'
import { Box, Heading, Text, Badge, Tooltip, HStack } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { Skeleton } from '@chakra-ui/react'
import classes from '../billing.module.css'
import moment from 'moment'
import { openLinkInNewTab } from '@utilities'

export const BillingTable = ({
  billing,
  isBillingLoading,
  loadAll,
  hasMore
}) => {
  function formatDollarAmount(amountStr) {
    // Convert the string to a number
    let amount = parseFloat(amountStr)

    // Check if the conversion was successful
    if (isNaN(amount)) {
      return '0'
    }

    // Determine if the amount has cents
    const hasCents = amount % 1 !== 0

    // Convert the number back to a string with or without decimal places based on the cents
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: hasCents ? 2 : 0,
      maximumFractionDigits: hasCents ? 2 : 0
    })
  }

  return (
    <>
      <Box
        w="100%"
        border="1px solid"
        borderColor="#E4E5E6"
        borderRadius="4px"
        mt={'small'}
        mb={'small'}
        bg="white"
        verticalAlign={'middle'}
      >
        <Table variant="unstyled">
          <Thead>
            <Tr borderBottom={'1px solid #E4E5E6'}>
              <Th letterSpacing={'none'}>
                <Box height="100%" display="flex" alignItems="center">
                  <Heading textTransform={'none'} fontSize="16px">
                    Date
                  </Heading>
                </Box>
              </Th>
              <Th letterSpacing={'none'}>
                <Box height="100%" display="flex" alignItems="center">
                  <Heading textTransform={'none'} fontSize="16px">
                    Total
                  </Heading>
                </Box>
              </Th>
              <Th pr={0} letterSpacing={'none'}>
                <Box height="100%" display="flex" alignItems="center">
                  <Heading textTransform={'none'} fontSize="16px">
                    Status
                  </Heading>
                </Box>
              </Th>
              <Th pr={0} letterSpacing={'none'}>
                <Box height="100%" display="flex" alignItems="center">
                  <Heading textTransform={'none'} fontSize="16px">
                    Invoice
                  </Heading>
                </Box>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {billing.invoices &&
              billing.invoices.invoices.map((invoice, index) => {
                return (
                  <Tr
                    _last={{ borderBottom: !hasMore ? 'none' : '' }}
                    borderBottom={'1px solid #E4E5E6'}
                    key={index}
                  >
                    <Td className={classes.tablechange}>
                      {moment
                        .unix(invoice.invoice.period_end)
                        .format('MMM D, YYYY')}
                    </Td>
                    <Td className={classes.tablechange}>
                      {formatDollarAmount(invoice.invoice.amount_due / 100)}
                    </Td>
                    <Td className={classes.tablechange}>
                      <Text pr={2} display={'inline'}>
                        <Tooltip
                          isDisabled={invoice.paymentStatus !== 'failed'}
                          label={invoice.paymentErrorMessage}
                        >
                          <Badge
                            colorScheme={
                              invoice.paymentStatus === null ||
                              invoice.paymentStatus === 'paid'
                                ? 'green'
                                : invoice.paymentStatus === 'failed'
                                ? 'red'
                                : 'gray'
                            }
                          >
                            {invoice.paymentStatus}
                          </Badge>
                        </Tooltip>
                      </Text>
                    </Td>
                    <Td pr={0} className={classes.tablechange}>
                      <Text
                        color={'primary'}
                        _hover={{
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        display={'inline'}
                        onClick={() =>
                          openLinkInNewTab(invoice.invoice.invoice_pdf ?? null)
                        }
                      >
                        View invoice &rarr;
                      </Text>
                    </Td>
                  </Tr>
                )
              })}
            {isBillingLoading && (
              <>
                <Tr
                  _last={{ borderBottom: 'none' }}
                  borderBottom={'1px solid #E4E5E6'}
                  key={1}
                >
                  <Td className={classes.tablechange}>
                    <Skeleton height="40px" />
                  </Td>
                  <Td className={classes.tablechange}>
                    <Skeleton height="40px" />
                  </Td>
                  <Td className={classes.tablechange}>
                    <Skeleton height="40px" />
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
        {!isBillingLoading && !billing.invoices && (
          <HStack justifyContent="center" mb={4} mt={4} spacing="3">
            <Text color={'medium_gray'} display={'inline'}>
              No invoices yet.
            </Text>
          </HStack>
        )}

        {!isBillingLoading && hasMore && (
          <HStack justifyContent="center" mb={4} mt={4} spacing="3">
            <Text
              color={'primary'}
              _hover={{
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              display={'inline'}
              onClick={() => loadAll()}
            >
              Load all invoices
            </Text>
          </HStack>
        )}
      </Box>
    </>
  )
}
