import React, { useCallback, useMemo } from 'react'
import { Text, Flex, Box, VStack, Divider } from '@chakra-ui/react'
import { useStoreActions } from 'easy-peasy'
import {
  Button,
  IconButton,
  CloseIcon,
  InterventionIcon,
  WorksheetIcon
} from '@blueprinthq/joy'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { endpoints } from '@api'
import {
  getSessionControllerGetSessionRecapQueryKey,
  useSessionControllerPostPlannedInterventions
} from '~/clinician-api'
import { SessionRecapResponse } from '~/clinician-api/models'
import { useAssignCheckInsModal } from '@containers/assign-check-ins-modal'

interface PlanForNextSessionProps {
  sessionId: string
  clientId: string
  recap: SessionRecapResponse | undefined
  firstName: string
  clinicId: string
}

export const PlanForNextSession = ({
  sessionId,
  clientId,
  recap,
  firstName,
  clinicId
}: PlanForNextSessionProps) => {
  const queryClient = useQueryClient()

  const assignCheckInsModal = useAssignCheckInsModal()

  const { data: worksheets, refetch }: any = useQuery(
    [endpoints.getCheckInModulesForUser.getCacheId(), clientId],
    // @ts-ignore
    () => endpoints.getCheckInModulesForUser.request({ id: clientId }),
    {
      // @ts-ignore
      placeholderData: [],
      select: (worksheets: any) =>
        worksheets.filter((m: any) => m.type === 'treatment_activity')
    }
  )

  const {
    mutate: setPlannedInterventions
  } = useSessionControllerPostPlannedInterventions({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(
          getSessionControllerGetSessionRecapQueryKey(sessionId)
        )
      }
    }
  })

  // @ts-ignore
  const { mutateAsync: unassignCheckInModules } = useMutation(
    // @ts-ignore
    endpoints.deleteClientUnassignCheckInModules.request,
    {
      onSuccess() {
        refetch()
      }
    }
  )

  const onUnassignWorksheet = useCallback(
    async (moduleId: string) => {
      // @ts-ignore
      await unassignCheckInModules({
        clientId,
        checkInModuleIds: [moduleId]
      })
    },
    [clientId]
  )

  const activeWorksheets = useMemo(
    () => worksheets.filter((w: any) => w.isActive),
    [worksheets]
  )

  // @ts-ignore
  const openInterventionListModal = useStoreActions(
    // @ts-ignore
    actions => actions.modals.interventionList.openModal
  )

  const _openModal = () => {
    openInterventionListModal({
      clientId,
      selectedInterventionIds: recap?.plannedInterventions.map(pi => pi.id),
      // @ts-ignore
      onAssign: interventionIds => {
        // setPlannedInterventionIds()
        setPlannedInterventions({
          data: {
            interventionIds: [
              // @ts-ignore
              ...recap?.plannedInterventions.map(pi => pi.id),
              ...interventionIds
            ]
          },
          id: sessionId
        })
      }
    })
  }

  const onRemovePlannedIntervention = (interventionId: string) => {
    if (recap?.plannedInterventions.length) {
      setPlannedInterventions({
        data: {
          interventionIds: recap?.plannedInterventions
            .filter(pi => pi.id !== interventionId)
            .map(pi => pi.id)
        },
        id: sessionId
      })
    }
  }

  return (
    <Box>
      <Text mt="xxsmall" color="gray">
        Tell us which intervention(s) you plan on delivering next and assign
        homework for {firstName} to complete between sessions.
      </Text>
      <Box mt="small">
        <Text fontWeight="bold" mb="small">
          Intervention
        </Text>
        <Box my="small">
          {recap?.plannedInterventions.length ? (
            <Box border="1px solid" borderColor="pale_gray" borderRadius="8px">
              <VStack
                w="100%"
                divider={<Divider color="pale_gray" />}
                spacing="none"
              >
                {recap?.plannedInterventions.map(plannedIntervention => (
                  <Flex
                    key={plannedIntervention.id}
                    w="100%"
                    align="center"
                    justify="space-between"
                    py="small"
                    px="small"
                  >
                    <Flex flex={1} align="center">
                      <Box mr="small" bg="pale_gray" p="3px" borderRadius="50%">
                        <Flex
                          align="center"
                          justify="center"
                          width="24px"
                          height="24px"
                          borderRadius="50%"
                        >
                          <InterventionIcon />
                        </Flex>
                      </Box>
                      <Text>{plannedIntervention.name}</Text>
                    </Flex>
                    <IconButton
                      variant="ghost"
                      size="small"
                      aria-label="close"
                      icon={<CloseIcon />}
                      onClick={() =>
                        onRemovePlannedIntervention(plannedIntervention.id)
                      }
                    />
                  </Flex>
                ))}
              </VStack>
            </Box>
          ) : (
            <Box
              w="100%"
              p="small"
              borderRadius="8px"
              border="1px solid"
              borderColor="pale_gray"
            >
              <Text color="dark_gray">
                No interventions planned for next session
              </Text>
            </Box>
          )}
        </Box>
        <Button variant="link" color="primary" onClick={_openModal}>
          Add Intervention
        </Button>
      </Box>

      <Box mt="medium">
        <Text fontWeight="bold" mb="small">
          Homework
        </Text>
        <Box my="small">
          {activeWorksheets.length ? (
            <Box border="1px solid" borderColor="pale_gray" borderRadius="8px">
              <VStack
                w="100%"
                divider={<Divider color="pale_gray" />}
                spacing="none"
              >
                {activeWorksheets.map((worksheet: any) => (
                  <Flex
                    key={worksheet.id}
                    w="100%"
                    align="center"
                    justify="space-between"
                    py="small"
                    px="small"
                  >
                    <Flex flex={1} align="center">
                      <Box mr="small" bg="pale_gray" p="4px" borderRadius="50%">
                        <Flex
                          align="center"
                          justify="center"
                          width="24px"
                          height="24px"
                          borderRadius="50%"
                          bg="black"
                        >
                          <WorksheetIcon fill="white" size="sm" />
                        </Flex>
                      </Box>
                      <Box>
                        <Text fontWeight="bold">{worksheet.title}</Text>
                        <Text>{worksheet.subtitle}</Text>
                      </Box>
                    </Flex>
                    <Flex
                      alignItems="center"
                      justify="space-between"
                      w="150px"
                      ml="large"
                    >
                      <Text fontWeight="bold" mr="small" whiteSpace="nowrap">
                        {worksheet.num_questions} questions
                      </Text>
                      <IconButton
                        variant="ghost"
                        size="small"
                        aria-label="close"
                        icon={<CloseIcon />}
                        onClick={() => onUnassignWorksheet(worksheet.id)}
                      />
                    </Flex>
                  </Flex>
                ))}
              </VStack>
            </Box>
          ) : (
            <Box
              w="100%"
              p="small"
              borderRadius="8px"
              border="1px solid"
              borderColor="pale_gray"
            >
              <Text color="dark_gray">No homework assigned</Text>
            </Box>
          )}
        </Box>
        <Button
          variant="link"
          color="primary"
          onClick={() => {
            assignCheckInsModal.open({
              clinicId,
              activeCheckInIds: activeWorksheets.map(
                (aw: { id: any }) => aw.id
              ),
              patientId: clientId,
              type: 'worksheet'
            })
          }}
        >
          Add Worksheets
        </Button>
      </Box>
    </Box>
  )
}
