import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import React, { useState, useEffect } from 'react'
import {
  Flex,
  Button,
  Text,
  Input,
  Stack,
  FormControl,
  FormLabel,
  CloseButton,
  Progress,
  Box
} from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { useQuery, useMutation } from 'react-query'
import { endpoints } from '@api'
import { useHistory } from 'react-router-dom'
import {
  useSessionControllerStartUpload,
  useSessionControllerCompleteUpload,
  useSessionControllerCreateFromUploadedAudio
} from '~/clinician-api'
import moment from 'moment'
import pLimit from 'p-limit'
import { RadioButtonLoadingComplete } from '@components/icons'
import { Select } from '@blueprinthq/joy'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { datadogLogs } from '@datadog/browser-logs'
import { Upload, GraphicEq } from '@components/icons'
import { SearchSelect, Loading } from '@components'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { debounce, uniqBy } from 'lodash'

import * as clinicianTracking from '@lib/clinician-tracking'

export function useFileUpload() {
  const [countOfUploadedParts, setCountOfUploadedParts] = useState(0)
  const {
    mutateAsync: startUpload,
    data: uploadData
  } = useSessionControllerStartUpload()
  const { mutateAsync: completeUpload } = useSessionControllerCompleteUpload()
  const { mutateAsync: uploadPart } = useMutation(
    async ({ body, uploadUrl, file, partNumber }) => {
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type
        },
        body
      })

      if (!response.ok) {
        throw new Error('Upload failed')
      }

      return {
        PartNumber: partNumber,
        ETag: response.headers.get('Etag')
      }
    },
    {
      retry: 3,
      onSuccess: data => {
        setCountOfUploadedParts(prev => prev + 1)
      },
      onError: error => {
        datadogLogs.logger.error(
          '[SESSION UPLOAD] Error uploading part:',
          {},
          error
        )
      }
    }
  )

  const uploadFileMutation = useMutation(
    async file => {
      setCountOfUploadedParts(0)

      const { uploadId, chunkSize, uploadUrls, fileId } = await startUpload({
        data: {
          contentType: file.type,
          fileSize: file.size
        }
      })

      const limit = pLimit(5)

      const uploadPromises = uploadUrls.map((uploadUrl, i) => {
        const body = file.slice(i * chunkSize, (i + 1) * chunkSize)
        return limit(() =>
          uploadPart({ body, uploadUrl, file, partNumber: i + 1 })
        )
      })

      const parts = await Promise.all(uploadPromises)
      await completeUpload({ data: { uploadId, parts, fileId } })

      return { fileId }
    },
    {
      onError: (error, variables) => {
        datadogLogs.logger.error(
          '[SESSION UPLOAD] Error uploading file:',
          {},
          error
        )
      }
    }
  )

  const percentage =
    Math.round((countOfUploadedParts / uploadData?.numberOfParts) * 100) || 0
  const fileId = uploadData?.fileId
  const uploadedCount = countOfUploadedParts
  const totalCount = uploadData?.numberOfParts

  return {
    uploadFileMutation,
    percentage,
    uploadedCount,
    totalCount,
    fileId
  }
}

const validationSchema = Yup.object({
  client: Yup.object().required('Client is required'),
  noteType: Yup.object().required('Progress note type is required'),
  sessionDate: Yup.date().required('Session date is required'),
  selectedFile: Yup.mixed().required('Audio file is required'),
  setting: Yup.object().required('Setting is required')
})

const SessionUploadForm = ({ onClose }) => {
  const [clientSearchInput, setClientSearchInput] = useState('')
  const { user } = useStoreState(state => state.auth)
  const history = useHistory()

  const isInternalDemoOrg = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_IS_DEMO_CLIENTS_IN_START_SESSION
  )

  const { data: defaultList, isLoading: isDefaultListLoading } = useQuery(
    [endpoints.getPatientList.getCacheId()],
    () =>
      endpoints.getPatientList.request({
        page: 1,
        status: 'active',
        sortBy: 'patient_name',
        order: 'ASC',
        clinician_id: user.id,
        clinic_id: user.clinic_id
      }),
    {
      select: data => {
        return data.nodes
          .map(n => ({
            ...n,
            id: n.patient_id,
            first_name: n.first_name,
            last_name: n.last_name
          }))
          .filter(n => !n.is_demo)
      }
    }
  )

  const { refetch, data: searchResults } = useQuery(
    [
      endpoints.getPatientSearch.getCacheId(),
      'session_client_search',
      clientSearchInput
    ],
    () =>
      endpoints.getPatientSearch.request({
        search: clientSearchInput,
        include_discharged: false
      }),
    {
      enabled: false,
      initialData: [],
      select: data => {
        return data
          .map(p => ({
            ...p,
            id: p.id,
            first_name: p.first_name,
            last_name: p.last_name
          }))
          .filter(n => !n.is_demo || isInternalDemoOrg)
      }
    }
  )

  const debounceClientSearch = debounce(refetch, 1000)

  useEffect(() => {
    if (!!clientSearchInput) debounceClientSearch()
  }, [clientSearchInput])

  const {
    data: { progressNoteTypes },
    isLoading: isProgressNoteTypesLoading
  } = useQuery(
    [endpoints.getProgressNoteTypes.getCacheId(), user.clinic.organization_id],
    () =>
      endpoints.getProgressNoteTypes.request({
        organizationId: user.clinic.organization_id,
        individualOnly: false
      }),
    {
      initialData: {
        progressNoteTypes: []
      }
    }
  )

  const {
    uploadFileMutation: {
      mutateAsync: uploadFile,
      isSuccess: isUploadSuccess,
      isLoading: isUploadingFile
    },
    fileId,
    percentage,
    uploadedCount,
    totalCount
  } = useFileUpload()

  const {
    mutateAsync: createSessionFromUploadedAudio
  } = useSessionControllerCreateFromUploadedAudio()

  const handleFileChange = async event => {
    const file = event.target.files[0]
    await uploadFile(file)
  }

  const handleSubmit = async values => {
    const session = await createSessionFromUploadedAudio({
      data: {
        patientId: values.client.value,
        fileId,
        noteType: values.noteType.id,
        sessionDate: values.sessionDate,
        isTelehealth: values.setting.id === 'telehealth'
      }
    })

    history.push(
      `/patient/${session.patientId}/completed-session/${session.id}`
    )
  }

  if (isDefaultListLoading || isProgressNoteTypesLoading) {
    return <Loading />
  }

  return (
    <Formik
      initialValues={{
        client: null,
        noteType: {
          id: progressNoteTypes[0]?.id,
          display: progressNoteTypes[0]?.display
        },
        sessionDate: moment().format('YYYY-MM-DD'),
        selectedFile: null,
        setting: { id: 'in-person', display: 'In-Person' }
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, isSubmitting, isValid, values }) => (
        <Form>
          <Stack spacing="small" mt="small">
            <Field name="client">
              {({ field }) => (
                <FormControl>
                  <FormLabel fontWeight="bold">Client name</FormLabel>
                  <SearchSelect
                    {...field}
                    onChange={value => setFieldValue('client', value)}
                    onSearch={setClientSearchInput}
                    placeholder="Select a client"
                    options={uniqBy(
                      [
                        ...searchResults.map(client => ({
                          value: client.id,
                          label: `${client.first_name} ${client.last_name}`
                        })),
                        ...defaultList.map(client => ({
                          value: client.id,
                          label: `${client.first_name} ${client.last_name}`
                        }))
                      ],
                      'value'
                    )}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="noteType">
              {({ field }) => (
                <FormControl>
                  <FormLabel fontWeight="bold">Note type</FormLabel>
                  <Select
                    {...field}
                    onChange={value => setFieldValue(field.name, value)}
                    valueKey="id"
                    labelKey="display"
                    options={progressNoteTypes.map(({ id, display }) => ({
                      id,
                      display: display
                    }))}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="setting">
              {({ field }) => (
                <FormControl>
                  <FormLabel fontWeight="bold">Setting</FormLabel>
                  <Select
                    {...field}
                    onChange={value => setFieldValue(field.name, value)}
                    valueKey="id"
                    labelKey="display"
                    options={[
                      { id: 'in-person', display: 'In-Person' },
                      { id: 'telehealth', display: 'Telehealth' }
                    ]}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="sessionDate" type="date">
              {({ field }) => (
                <FormControl>
                  <FormLabel fontWeight="bold" htmlFor="date-input">
                    Session Date
                  </FormLabel>
                  <Input {...field} type="date" />
                </FormControl>
              )}
            </Field>
            <Field name="selectedFile">
              {({ field }) => (
                <FormControl>
                  <FormLabel fontWeight="bold">Upload audio</FormLabel>
                  <Input
                    {...field}
                    type="file"
                    value=""
                    onChange={event => {
                      setFieldValue('selectedFile', event.target.files[0])
                      handleFileChange(event)
                    }}
                    accept="audio/*"
                    style={{ display: 'none' }}
                    id="file-upload"
                  />
                  <Box
                    width="100%"
                    backgroundColor="#F7F8FE"
                    p="small"
                    borderRadius="8px"
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="pale_gray"
                  >
                    {!field.value ? (
                      <Flex alignItems="center" flexDirection="column">
                        <Box>
                          <Upload fill="black" width="24" />
                        </Box>
                        <FormLabel m="0" htmlFor="file-upload">
                          <Text color="primary" _hover={{ cursor: 'pointer' }}>
                            Choose file
                          </Text>
                        </FormLabel>
                        <Text color="gray" fontSize="small">
                          Maximum size: 1GB
                        </Text>
                      </Flex>
                    ) : (
                      <>
                        <Flex mb="4px" justifyContent="space-between">
                          <Flex gap="16px">
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              border="1px solid"
                              borderColor="light_gray"
                              bg="white"
                              width="32px"
                            >
                              <GraphicEq />
                            </Flex>
                            <Box>
                              <Text color="#282828">{field.value.name}</Text>
                              <Text color="gray" fontSize="small">{`${(
                                field.value.size / 1000000
                              ).toFixed(2)} MB`}</Text>
                            </Box>
                          </Flex>
                          <Flex alignItems="flex-start">
                            {!isUploadingFile && (
                              <CloseButton
                                onClick={event => {
                                  event.stopPropagation()
                                  setFieldValue(field.name, null)
                                }}
                              />
                            )}
                          </Flex>
                        </Flex>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          gap="8px"
                        >
                          <Progress
                            size="sm"
                            color="primary"
                            borderRadius="4px"
                            value={uploadedCount}
                            flex="1 1 80%"
                            max={totalCount}
                          />
                          <Flex
                            flex="0 1 10%"
                            color="dark_gray"
                            justifyContent="center"
                            alignItems="center"
                            visibility={field.value ? 'visible' : 'hidden'}
                          >
                            {isUploadSuccess ? (
                              <RadioButtonLoadingComplete />
                            ) : (
                              <Text color="dark_gray" fontSize="12px">
                                {`${percentage}%`}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      </>
                    )}
                  </Box>
                </FormControl>
              )}
            </Field>
            <Flex>
              <Button
                variant="outline"
                size="lg"
                isFullWidth
                onClick={onClose}
                isDisabled={isUploadingFile}
              >
                Cancel
              </Button>
              <Button
                size="lg"
                type="submit"
                isLoading={isSubmitting}
                isDisabled={!isValid || isUploadingFile || !values.client}
                isFullWidth
              >
                Upload Recording
              </Button>
            </Flex>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

const UploadModal = ({ open, onClose }) => {
  useEffect(() => {
    if (open === true) {
      clinicianTracking.trackEvent('Viewed Upload Recording Modal')
    }
  }, [open])

  return (
    <DialogContainer
      isOpen={open}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <DialogHeader onClose={onClose} text="Upload a session recording" />
      <DialogBody>
        <SessionUploadForm onClose={onClose} />
      </DialogBody>
    </DialogContainer>
  )
}

export default UploadModal
