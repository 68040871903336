import React, { useState } from 'react'
import { Flex, Text, Button, useToast, Box } from '@chakra-ui/react'

import { useHistory } from 'react-router-dom'
import { useSessionControllerCreateSampleSession } from '~/clinician-api'
import { useQueryClient } from 'react-query'

import { endpoints } from '@api'

export const SampleSessionsModule = ({ containerProps = {} }) => {
  const toast = useToast()
  const history = useHistory()
  const [loadingStates, setLoadingStates] = useState({})
  const queryClient = useQueryClient()

  const {
    mutateAsync: executeCreateSampleSession
  } = useSessionControllerCreateSampleSession()

  const createSampleSession = async sampleType => {
    setLoadingStates(prevStates => ({
      ...prevStates,
      [sampleType]: true
    }))

    try {
      const response = await executeCreateSampleSession({
        data: { sampleType }
      })

      queryClient.invalidateQueries(
        endpoints.getClinicianDashboardSessions.getCacheId()
      )

      history.push(
        `/patient/${response.patientId}/completed-session/${response.sessionId}?isDemo=true`
      )
    } catch (err) {
      toast({
        title: 'Error',
        description: 'There was an error creating the sample progress note.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }

    setLoadingStates(prevStates => ({
      ...prevStates,
      [sampleType]: false
    }))
  }

  const Samples = () => (
    <>
      <Button
        id="pendo-sessions-sample-intake"
        data-click-event-name="Clicked Sample Session Link"
        variant="link"
        isLoading={loadingStates.intake}
        color="primary"
        onClick={() => createSampleSession('intake')}
        ml={0}
        mr="xxsmall"
        textDecoration="none"
        _hover={{ textDecoration: 'underline' }}
      >
        Intake
      </Button>
      <Button
        id="pendo-sessions-sample-cbt"
        data-click-event-name="Clicked Sample Session Link"
        variant="link"
        isLoading={loadingStates.cbt}
        color="primary"
        onClick={() => createSampleSession('cbt')}
        ml={0}
        mr="xxsmall"
        textDecoration="none"
        _hover={{ textDecoration: 'underline' }}
      >
        CBT
      </Button>
      <Button
        id="pendo-sessions-sample-dbt"
        data-click-event-name="Clicked Sample Session Link"
        variant="link"
        isLoading={loadingStates.dbt}
        color="primary"
        onClick={() => createSampleSession('dbt')}
        ml={0}
        mr="xxsmall"
        textDecoration="none"
        _hover={{ textDecoration: 'underline' }}
      >
        DBT
      </Button>
      <Button
        id="pendo-sessions-sample-emdr"
        data-click-event-name="Clicked Sample Session Link"
        variant="link"
        isLoading={loadingStates.emdr}
        color="primary"
        onClick={() => createSampleSession('emdr')}
        ml={0}
        textDecoration="none"
        _hover={{ textDecoration: 'underline' }}
      >
        EMDR
      </Button>
    </>
  )

  return (
    <Flex
      alignItems="center"
      direction={['column', 'column', 'column', 'row']}
      mb="xsmall"
      {...containerProps}
    >
      <Text mr="xsmall" sx={{ textWrap: 'nowrap' }}>
        Example Notes:
      </Text>
      <Box>
        <Samples />
      </Box>
    </Flex>
  )
}
