import React, { useMemo } from 'react'
import { InfoIcon, ArrowDownIcon, ArrowUpIcon, Button } from '@blueprinthq/joy'
import {
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Box,
  Text,
  Tooltip,
  Flex,
  Avatar
} from '@chakra-ui/react'
import { DateTime } from 'luxon'
import { useTable, useSortBy } from 'react-table'

import { isCanUserModifyProvider, mapRoleToDisplayName } from '../../utils'
import { cloudinaryAssetToUrl } from '../../../../utilities'

export const UsersTable = ({ data, user, onRowClick, onInviteProvider }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'Name',
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => {
          return (
            <Flex alignItems="center">
              <Avatar
                src={cloudinaryAssetToUrl(row.original.avatar, {
                  transformations: {
                    resize: {
                      height: 128,
                      width: 128,
                      type: 'scale'
                    }
                  }
                })}
                name={`${row.original.first_name} ${row.original.last_name}`}
                bg="pale_gray"
                height={'40px'}
                width={'40px'}
                color="black"
                fontWeight="bold"
                marginRight="12px"
              />
              <Box>
                <Text fontSize="sm" color="primary" fontWeight="bold">
                  {value}
                  {row.original.id === user.id && (
                    <Text
                      as="span"
                      fontSize="sm"
                      fontWeight="normal"
                      color="medium_gray"
                    >
                      {' '}
                      (You)
                    </Text>
                  )}
                </Text>
                <Text
                  fontSize="xs"
                  wordBreak="break-all"
                  textTransform="lowercase"
                >
                  {row.original.email}
                </Text>
              </Box>
            </Flex>
          )
        },
        accessor: row => `${row.first_name} ${row.last_name}`,
        defaultCanSort: true
      },
      {
        Header: 'Role',
        accessor: row => {
          const roles = row.roles.filter(
            role => role.name !== 'supervisor_clinician'
          )
          return roles.map(role => mapRoleToDisplayName(role)).join(', ')
        },
        // Update the Cell renderer to correctly handle commas
        Cell: ({ value }) => (
          <Text wordBreak="break-word" fontSize="sm" mr={1}>
            {value}
          </Text>
        )
      },
      {
        Header: 'Products',
        accessor: row => {
          const products = []
          if (row.has_ebc_access)
            products.push(
              `Evidence-Based Care${row.has_doc_automation_access ? ', ' : ''}`
            )
          if (row.has_doc_automation_access)
            products.push('Documentation Automation')
          return products
        },
        // Update the Cell renderer to display products one on top of the other
        Cell: ({ value }) => (
          <Flex flexDirection="column">
            {value.map((product, index) => (
              <Text key={index} fontSize="sm">
                {product}
              </Text>
            ))}
          </Flex>
        )
      },
      {
        // eslint-disable-next-line react/display-name
        Header: 'Clients',
        accessor: 'total_client_count',
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => {
          return <Text fontSize={'small'}>{value}</Text>
        }
      },
      {
        Header: 'Last Login',
        accessor: 'last_login',
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => {
          if (value)
            return (
              <Box>
                <Tooltip
                  placement="top"
                  label={DateTime.fromISO(value).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                >
                  <Text color="medium_gray" fontSize="sm">
                    {DateTime.fromISO(value).toLocaleString(DateTime.DATE)}
                  </Text>
                </Tooltip>
              </Box>
            )

          return (
            isCanUserModifyProvider(user, row.original) && (
              <Text
                _focus={{ outline: 'none' }}
                fontSize={'sm'}
                color={'primary'}
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={e => {
                  if (isCanUserModifyProvider(user, row.original)) {
                    onInviteProvider(row.original)
                  }
                  e.stopPropagation()
                }}
              >
                Resend invite
              </Text>
            )
          )
        }
      }
    ],
    [user]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: {
        sortBy: [{ id: 'Name', desc: false }]
      }
    },
    useSortBy
  )

  return (
    <Box
      w="100%"
      border="1px solid"
      borderColor="#E4E5E6"
      borderRadius="4px"
      mb={'small'}
      bg="white"
      verticalAlign={'middle'}
    >
      <Table variant="unstyled" {...getTableProps()}>
        <Thead borderBottom={'1px solid #E4E5E6'}>
          {headerGroups.map((headerGroup, i) => (
            <Tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(
                (column, j) =>
                  !column.isHidden && (
                    <Th
                      key={j}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      borderColor="pale_gray"
                      whiteSpace={'nowrap'}
                      paddingLeft={'24px'}
                    >
                      <Flex
                        textTransform={'capitalize'}
                        fontSize="16px"
                        letterSpacing={0}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDownIcon size="sm" fill={'gray.600'} />
                            ) : (
                              <ArrowUpIcon size="sm" fill={'gray.600'} />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </Flex>
                    </Th>
                  )
              )}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Tr
                key={i}
                {...row.getRowProps()}
                onClick={() => onRowClick(row.original)}
                cursor="pointer"
                _hover={{
                  bg: 'pale_gray'
                }}
                borderBottom={'1px solid #E4E5E6'}
                _last={{ borderBottom: 'none' }}
              >
                {row.cells.map(
                  (cell, j) =>
                    !cell.column.isHidden && (
                      <Td
                        key={j}
                        {...cell.getCellProps()}
                        textTransform="capitalize"
                        borderColor="pale_gray"
                        verticalAlign="middle"
                        style={{
                          paddingLeft: '24px',
                          paddingRight: '0px',
                          paddingTop: '8px',
                          paddingBottom: '8px'
                        }}
                      >
                        {cell.render('Cell')}
                      </Td>
                    )
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}
