// @ts-nocheck
import React from 'react'
import { Box, Text, VStack, Stack, Button, HStack, Skeleton } from '@chakra-ui/react'
import OutcomeMeasuresSection from './outcome-measures-section'
import { useAssistPanelContext } from '../../context'
import { CoolKidsAloneTime } from '../../svgs'

const ProgressLoadingSkeleton = () => (
  <Stack spacing="0" gap="8px" h="203px">
    <HStack justifyContent="space-between">
      <Text fontWeight="bold">Progress</Text>
      <Button isDisabled variant="link" textDecoration="none">
        View results
      </Button>
    </HStack>
    <Skeleton height="312px" borderRadius="8px" />
  </Stack>
)

const Progress = ({ allAssessmentScores, patient, isLoading }: any) => {
  const { setOverlayPanel } = useAssistPanelContext()

  if (isLoading) {
    return <ProgressLoadingSkeleton />
  }

  if (allAssessmentScores?.length === 0) {
    return (
      <Stack spacing="0" gap="8px">
        <HStack justifyContent="space-between">
        <Text fontWeight="bold">Progress</Text>
        <Button 
          variant="link"
          textDecoration="none"
          onClick={() => setOverlayPanel('ASSESSMENT_RESULTS')}
        >
          View results
        </Button>
        </HStack>
        <Stack
          flex="1"
          alignItems="center"
          border="1px solid"
          borderColor="pale_gray"
          borderRadius="8px"
          gap="8px"
          p="24px"
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            height="160px"
          >
            <CoolKidsAloneTime />
            <Text fontSize="14px">
              {patient.first_name} has not completed any assessments.
            </Text>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack spacing="0" gap="8px">
      <HStack justifyContent="space-between">
        <Text fontWeight="bold">Progress</Text>
        <Button 
          variant="link"
          textDecoration="none"
          onClick={() => setOverlayPanel('ASSESSMENT_RESULTS')}
        >
          View results
        </Button>
      </HStack>
      <OutcomeMeasuresSection
        openResults={
          (data) => setOverlayPanel('ASSESSMENT_RESULTS', data)
        }
        allAssessmentScores={allAssessmentScores}
        patient={patient}
      />
    </Stack>
  )
}

export default Progress
