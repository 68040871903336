import React, { useCallback, useEffect } from 'react'
import { Dialog } from '@material-ui/core'
import {
  Box,
  Text,
  Stack,
  HStack,
  Button,
  IconButton,
  Select
} from '@chakra-ui/react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { ArrowLeft } from '@components/icons'
import { loadStripe } from '@stripe/stripe-js'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import * as clinicianTracking from '@lib/clinician-tracking'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

const stripeStyle = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#1a202c',
      fontWeight: '500',
      fontFamily: 'TofinoPersonal, Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#1a202c'
      },
      '::placeholder': {
        color: '#999999'
      }
    },
    invalid: {
      iconColor: '#e02031',
      color: '#e02031'
    }
  }
}

const selectStyle = {
  fontFamily: 'TofinoPersonal, Roboto, Open Sans, Segoe UI, sans-serif',
  color: 'rgba(0, 0, 0, 0.87)',
  border: '1px solid ',
  borderColor: 'rgb(228, 229, 230)',
  borderRadius: '4px',
  padding: '16px',
  height: 'auto',
  fontWeight: 'bold',
  _focus: {
    outline: 'none'
  },
  _hover: {
    borderColor: 'rgb(228, 229, 230)'
  }
}

export const ManageScribePlanModal = () => {
  const showNewBillingFlow = true
  const user = useStoreState(state => state.auth.user)
  const elements = useElements()
  const stripe = useStripe()
  const queryClient = useQueryClient()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const [priceId, setPriceId] = React.useState<string | undefined>(undefined)
  const { isOpen, clinicId } = useStoreState(
    state => state.modals.manageScribePlan
  )
  const { closeModal: handleCloseModal } = useStoreActions(
    (actions: any) => actions.modals.manageScribePlan
  )

  useEffect(() => {
    if (isOpen) {
      clinicianTracking.trackEvent('Viewed Scribe Upgrade Modal')
    }
  }, [isOpen])

  const { data } = useQuery(
    endpoints.getProducts.getCacheId(),
    endpoints.getProducts.request,
    {
      onSuccess: (data: any) => {
        const defaultPriceId = data.products[0]?.default_price?.id

        if (defaultPriceId && !priceId) {
          setPriceId(defaultPriceId)
        }
      }
    }
  )

  const {
    mutateAsync: upgrade,
    isLoading: isUpgradeLoading,
    isError: isUpgradeError
  } = useMutation(endpoints.selectDocumentationAutomationPlan.request, {
    onSuccess: (data: any) => {
      handleCloseModal()
      queryClient.invalidateQueries(endpoints.getPaywallValidation.getCacheId())
      queryClient.invalidateQueries(endpoints.getClinicInvoices.getCacheId())
      queryClient.invalidateQueries([
        endpoints.getClinicBilling.getCacheId(),
        user?.clinic_id
      ])
    }
  })

  const products = (data as any)?.products.filter(
    (product: any) => Number(product.default_price.metadata.max_notes) <= 500
  )

  const selectedProduct = products?.find(
    (product: any) => product.default_price.id === priceId
  )

  const handleCloseWrapper = useCallback(() => {
    if (!isUpgradeLoading) {
      handleCloseModal()
    }
  }, [handleCloseModal, isUpgradeLoading])

  const handleChange = (event: any) => {
    setError(event.error ? event.error.message : null)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const cardElement = elements?.getElement(CardNumberElement)

    if (!cardElement) {
      console.error('Card Element not available')
    }

    setIsSubmitting(true)
    try {
      const {
        // @ts-ignore
        paymentMethod,
        // @ts-ignore
        paymentMethodError
        // @ts-ignore
      } = await stripe?.createPaymentMethod({ type: 'card', card: cardElement })

      await upgrade({ paymentMethodId: paymentMethod.id, priceId, clinicId })
    } catch (err) {
      console.log(err)
    }
    setIsSubmitting(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseWrapper}
      maxWidth="sm"
      fullWidth={true}
    >
      <Box padding="24px">
        <form id="stripe-payment-form" onSubmit={handleSubmit}>
          <Stack w="100%" spacing="16px">
            <Box position="relative">
              <Box position="absolute" top="0px" height="40px" width="40px">
                <IconButton
                  variant="ghost"
                  aria-label="Close"
                  style={{ margin: 0 }}
                  icon={<ArrowLeft />}
                  onClick={handleCloseWrapper}
                />
              </Box>
              <Text textAlign="center" fontSize="24px" fontWeight="bold">
                {showNewBillingFlow ? 'Documentation Automation' : 'Upgrade'}
              </Text>
            </Box>
            {error && (
              <div
                style={{ textAlign: 'center' }}
                className="errorMessage"
                role="alert"
              >
                {error}
              </div>
            )}
            <Box>
              <Select
                onChange={e => setPriceId(e.target.value)}
                value={priceId}
                sx={selectStyle}
              >
                {products?.map((product: any) => (
                  <option
                    key={product.id}
                    value={product.default_price.id}
                    style={{ display: 'flex' }}
                  >
                    {`${product.default_price.metadata.max_notes} notes per month`}{' '}
                    -{' '}
                    {`$${(product.default_price?.unit_amount / 100).toFixed(
                      2
                    )}/month`}
                  </option>
                ))}
              </Select>
            </Box>
            <Box
              w="100%"
              padding="16px"
              style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
            >
              <CardNumberElement
                options={stripeStyle}
                onChange={handleChange}
              />
            </Box>
            <HStack spacing="24px" w="100%">
              <Box
                w="100%"
                padding="16px"
                style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
              >
                <CardExpiryElement
                  options={stripeStyle}
                  onChange={handleChange}
                />
              </Box>
              <Box
                w="100%"
                padding="16px"
                style={{ border: '1px solid #E4E5E6', borderRadius: '4px' }}
              >
                <CardCvcElement options={stripeStyle} onChange={handleChange} />
              </Box>
            </HStack>
            {isUpgradeError && (
              <div
                style={{ textAlign: 'center' }}
                className="errorMessage"
                role="alert"
              >
                An error has occurred please check the fields and try again.
              </div>
            )}
            <Button
              size="lg"
              my="small"
              isFullWidth
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!priceId || !!error}
            >
              {showNewBillingFlow ? 'Choose Plan' : 'Upgrade Plan'}
            </Button>
            {selectedProduct && (
              <Text fontSize="12px">
                Each month, you'll be charged $
                {selectedProduct.default_price.unit_amount / 100} until you
                cancel. Plans auto-upgrade to the next pricing tier if you reach
                your limit. Notes can be generated by any user on your account
                and do not rollover from one month to the next.
              </Text>
            )}
          </Stack>
        </form>
      </Box>
    </Dialog>
  )
}

export const ManageScribePlanModalConnected = () => {
  const user = useStoreState(state => state.auth.user)

  return user ? (
    <Elements stripe={stripePromise}>
      <ManageScribePlanModal />
    </Elements>
  ) : null
}
