import React from 'react'
import { VStack, Text, Stack, Container } from '@chakra-ui/react'
import { CoolKidsFeedback } from '../svgs'

const EmptyPrep = () => (
  <Container>
    <Stack flex="1" h="calc(100vh - 208px)" justifyContent="center">
      <VStack h="232px">
        <CoolKidsFeedback />
        <Text textAlign="center" fontWeight="bold" mb="xxsmall">
          No prep available
        </Text>
        <Text color="dark_gray">We don’t have any prep materials yet.</Text>
      </VStack>
    </Stack>
  </Container>
)

export default EmptyPrep
