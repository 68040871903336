import React from 'react'
import { Stack } from '@chakra-ui/react'
import { Suggestions } from './suggestions'

export const Worksheets = () => {
  return (
    <Stack
      px="24px"
      spacing="0"
      flex="1"
      pt="24px"
      gap="24px"
      h="calc(100vh - 208px)"
      overflowY="scroll"
    >
      <Suggestions />
    </Stack>
  )
}
