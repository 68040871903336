import React, { useEffect } from 'react'
import { HStack, Flex, Box, Button, Text } from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import { InfoIcon } from '@blueprinthq/joy'
import { useStoreActions } from 'easy-peasy'
import { useQueryParams } from '../../../hooks/use-query-params'

const SessionNotesRemainingBanner = () => {
  const {
    documentationAutomationSessionsRemaining,
    isDocumentationAutomationFreePlan,
    isDocumentationAutomationEnabled,
    isAdmin
  } = useExperienceManager()

  const openPlansModal = useStoreActions(
    (actions: any) => actions.modals.manageScribePlan.openModal
  )
  const queryParams = useQueryParams()
  const upgrade = queryParams.get('upgrade') === 'true'

  useEffect(() => {
    if (upgrade) openPlansModal()
  }, [upgrade])

  const sessionLimitReached = documentationAutomationSessionsRemaining < 1

  if (!isDocumentationAutomationFreePlan || !isDocumentationAutomationEnabled)
    return null

  return (
    <>
      <HStack
        bg={sessionLimitReached ? '#282828' : 'rgba(45, 84, 232, 0.04)'}
        color={sessionLimitReached ? 'white' : 'inherit'}
        p="16px"
        borderRadius="8px"
        justifyContent={sessionLimitReached ? 'space-between' : 'center'}
      >
        <HStack gap="12px">
          {sessionLimitReached && <InfoIcon fill="white" />}
          <Flex style={{ marginInlineStart: 0 }}>
            {sessionLimitReached
              ? `You've reached your monthly limit on notes`
              : `${documentationAutomationSessionsRemaining} notes left.`}
          </Flex>
        </HStack>
        <Flex>
          {isAdmin ? (
            <Button
              variant="link"
              color={sessionLimitReached ? 'white' : '#2D54E8'}
              textDecoration={sessionLimitReached ? 'underline' : 'none'}
              _focus={{ outline: 'none' }}
              onClick={openPlansModal}
              data-click-event-name="Session Notes Remaining Banner -> Clicked Upgrade"
            >
              Upgrade
            </Button>
          ) : (
            <Text fontStyle="italic">Contact your admin</Text>
          )}
        </Flex>
      </HStack>
      <Box h="16px" />
    </>
  )
}

export default SessionNotesRemainingBanner
