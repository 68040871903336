import React, { useState } from 'react'
import {
  Text,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Box
} from '@chakra-ui/react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import { useQuery } from 'react-query'
import { useExperienceManager } from '@hooks'
import { endpoints } from '@api'
import { Group } from '@components/icons'
import {
  TrashcanIcon,
  Assist,
  Select,
  AccountIcon,
  PeopleIcon
} from '@blueprinthq/joy'
import { IconSelect } from './'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { NoteTypeDropdown } from './note-type-dropdown'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'

type ProgressNoteTypeT = {
  id: string
  sessionType: string
  display: string
  noteGroup: string
}

interface EndSessionModalProps {
  isOpen: boolean
  onClose: () => void
  noteType: string
  treatmentApproach: string | null
  onEnd: (args: any) => Promise<void>
  onDiscard: () => Promise<void>
  isEnding: boolean
  isUploadingAudio: boolean
}

const sessionTypes = [
  {
    value: 'individual',
    title: 'Individual',
    icon: <AccountIcon />
  },
  {
    value: 'couple',
    title: 'Couple',
    icon: <PeopleIcon />
  },
  {
    value: 'group',
    title: 'Group',
    icon: <Group />
  }
]

const EndSessionModalComponent = ({
  isOpen,
  onClose,
  noteType,
  treatmentApproach,
  onEnd,
  onDiscard,
  isEnding,
  isUploadingAudio
}: EndSessionModalProps) => {
  const isNotePreferencesEnabled = flagsmith.hasFeature(
    FlagsmithFeatures.PROGRESS_NOTE_PREFERENCE_SETTINGS
  )

  const [isDiscardSelected, setIsDiscardSelected] = useState<boolean>(false)

  const { data: user }: any = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const {
    data: { progressNoteTypes }
  }: any = useQuery(
    [
      endpoints.getProgressNoteTypes.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteTypes.request({
        organizationId: user.clinic.organization_id
      }),
    {
      initialData: {
        progressNoteTypes: []
      },
      enabled: !!user
    }
  )

  const { data, isLoading } = useQuery(
    [
      endpoints.getProgressNoteSettings.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteSettings.request({
        organizationId: user?.clinic?.organization_id
      }),
    {
      enabled: !!user?.clinic?.organization_id
    }
  )

  const handleEndSession = async (
    noteType: string,
    treatmentApproach: string | null
  ) => {
    await onEnd({
      noteType,
      treatmentApproach: treatmentApproach === 'none' ? null : treatmentApproach
    })
  }

  const handleDeleteSession = async () => {
    await onDiscard()
  }

  const handleClose = () => {
    setIsDiscardSelected(false)
    onClose()
  }

  const noteTypeOptions = progressNoteTypes.map((note: ProgressNoteTypeT) => {
    const id = note.id
    const sessionType = note.sessionType
    const display = note.display
    const noteGroup = note.noteGroup
    return {
      id,
      sessionType,
      display,
      noteGroup
    }
  })

  const treatmentApproachOptions =
    // @ts-ignore
    data?.preferenceOptions?.treatmentApproach?.options?.map((option: any) => {
      return {
        id: option.value,
        display: option.label
      }
    }) || []

  const groupedNoteTypes = _.groupBy(noteTypeOptions, 'sessionType')

  const sessionTypeOptions = sessionTypes.filter(t =>
    Object.keys(groupedNoteTypes).includes(t.value)
  )

  const defaultNoteType = noteTypeOptions.find(
    (t: ProgressNoteTypeT) =>
      t.id === noteType ||
      t.id === user?.clinic?.organization?.default_note_type
  )

  const defaultTreatmentApproach = treatmentApproachOptions.find((o: any) =>
    treatmentApproach ? o.id === treatmentApproach : o.id === 'none'
  )

  const { isDocumentationAutomationEnabled } = useExperienceManager()

  const DiscardContent = () => (
    <VStack spacing={6}>
      <Text textAlign="center">
        This session will be discarded and no progress note summary will be
        generated. This cannot be undone.
      </Text>
      <VStack w="100%">
        <Button
          bg="error"
          isFullWidth
          size="lg"
          onClick={handleDeleteSession}
          isLoading={isEnding}
        >
          Delete session
        </Button>
        <Button
          _focus={{ outline: 'none' }}
          variant="outline"
          isFullWidth
          size="lg"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </VStack>
    </VStack>
  )

  if (!defaultNoteType || isLoading) return null
  return (
    <DialogContainer isOpen={isOpen} onClose={handleClose}>
      <DialogHeader
        text={isDiscardSelected ? 'Are you sure?' : 'End session?'}
        onClose={handleClose}
      />
      <DialogBody>
        {isDiscardSelected ? (
          <DiscardContent />
        ) : (
          <Formik
            initialValues={{
              noteType: defaultNoteType,
              sessionType: defaultNoteType.sessionType,
              treatmentApproach: defaultTreatmentApproach
            }}
            validationSchema={Yup.object({
              noteType: Yup.object().required('Progress note type is required')
            })}
            onSubmit={async values =>
              await handleEndSession(
                values.noteType.id,
                values.treatmentApproach.id
              )
            }
          >
            {({ setFieldValue, values }) => (
              <Form>
                <VStack spacing={6} w="100%">
                  {isDocumentationAutomationEnabled && (
                    <>
                      <Field name="sessionType">
                        {({ field }: any) => (
                          <FormControl>
                            <FormLabel fontWeight="bold">
                              Session Type:
                            </FormLabel>
                            <IconSelect
                              {...field}
                              onChange={(value: string) => {
                                setFieldValue(field.name, value)
                                setFieldValue(
                                  'noteType',
                                  groupedNoteTypes[value][0]
                                )
                              }}
                              selectedValue={field.value}
                              options={sessionTypeOptions}
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field name="noteType">
                        {({ field }: any) => (
                          <FormControl>
                            <FormLabel fontWeight="bold">Note Type:</FormLabel>
                            <NoteTypeDropdown
                              optionValue={field.value.id}
                              onChange={value => {
                                setFieldValue(
                                  'noteType',
                                  progressNoteTypes.find(
                                    (note: ProgressNoteTypeT) =>
                                      note.id === value
                                  )
                                )
                              }}
                              progressNoteTypes={
                                groupedNoteTypes[values.sessionType]
                              }
                            />
                          </FormControl>
                        )}
                      </Field>
                      {isNotePreferencesEnabled && (
                        <Field name="treatmentApproach">
                          {({ field }: any) => (
                            <FormControl>
                              <FormLabel fontWeight="bold">
                                Treatment Approach:
                              </FormLabel>
                              <Select
                                {...field}
                                onChange={(value: string) => {
                                  setFieldValue(field.name, value)
                                  setFieldValue('treatmentApproach', value)
                                }}
                                selectedValue={field.value}
                                options={treatmentApproachOptions}
                                valueKey="id"
                                labelKey="display"
                                menuProps={{
                                  isLazy: true,
                                  placement: 'bottom'
                                }}
                                menuListProps={{
                                  overflowY: 'scroll',
                                  maxHeight: '300px'
                                }}
                              />
                            </FormControl>
                          )}
                        </Field>
                      )}
                    </>
                  )}
                  <VStack w="100%">
                    {isDocumentationAutomationEnabled ? (
                      <Button
                        bg="primary"
                        isFullWidth
                        size="lg"
                        type="submit"
                        isLoading={isEnding}
                        loadingText={
                          isUploadingAudio ? 'Uploading audio...' : ''
                        }
                        leftIcon={<Assist fill="white" />}
                      >
                        Generate note
                      </Button>
                    ) : (
                      <Button
                        bg="primary"
                        isFullWidth
                        size="lg"
                        onClick={() =>
                          handleEndSession(
                            user?.clinic?.organization?.default_note_type ||
                              'soap',
                            null
                          )
                        }
                      >
                        End session
                      </Button>
                    )}
                    <Button
                      variant="ghost"
                      disabled={isEnding}
                      onClick={() => setIsDiscardSelected(true)}
                      leftIcon={<TrashcanIcon fill="error" />}
                    >
                      Delete session
                    </Button>
                  </VStack>
                </VStack>
              </Form>
            )}
          </Formik>
        )}
      </DialogBody>
    </DialogContainer>
  )
}

export const EndSessionModalV2 = React.memo(
  EndSessionModalComponent,
  (props, nextProps) => {
    const { onClose, onEnd, onDiscard, ...restProps } = props

    const {
      onClose: nextOnClose,
      onEnd: nextOnEnd,
      onDiscard: nextOnDiscard,
      ...restNextProps
    } = nextProps

    return _.isEqual(restProps, restNextProps)
  }
)
