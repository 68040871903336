import React, { useState } from 'react'
import {
  Container,
  GridItem,
  Tab,
  TabList,
  Tabs,
  Box,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import { LayoutGrid, Hidden } from '@blueprinthq/joy'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Link, Switch, Redirect, Route } from 'react-router-dom'
import { useQuery } from 'react-query'
import { endpoints } from '@api'

import { EditProfileTab as EditProfilePage } from './profile'
import { AssessmentPreferencesTab as AssessmentPreferencesPage } from './assessment-preferences'
import { NotificationSettings } from './notifications'
import { UsersTab as UsersPage } from './users'
import { ProgressNotes as ProgressNotesPage } from './progress-notes'
import { BulkClinicianUpload } from '@handlers/admin'
import { GeneralTab as GeneralPage } from './general'
import { WorkflowsTab as WorkflowsPage } from './workflows'
import { SafetyNetEditor } from './safety-net/safety-net-editor'
import { BillingTab as BillingPage } from './billing'
import { ConsentPage } from './consent'
import { useNavLinks, usePermissions } from '../../hooks'
import { Loading } from '../../components'
import { ProgramEditor } from './programs'

const Routes = [
  {
    path: '/settings/account',
    RouteComponent: EditProfilePage
  },
  {
    path: '/settings/notifications',
    RouteComponent: NotificationSettings
  },
  {
    path: '/settings/assessments',
    RouteComponent: AssessmentPreferencesPage
  },
  {
    path: '/settings/progress-notes',
    RouteComponent: ProgressNotesPage
  },
  {
    path: '/settings/users',
    RouteComponent: UsersPage
  },
  {
    path: '/settings/programs',
    RouteComponent: ProgramEditor
  },
  {
    path: '/settings/general',
    RouteComponent: GeneralPage
  },
  {
    path: '/settings/workflows',
    RouteComponent: WorkflowsPage
  },
  {
    path: '/settings/safety-net',
    RouteComponent: SafetyNetEditor
  },
  {
    path: '/settings/billing',
    RouteComponent: BillingPage
  },
  {
    path: '/settings/users/upload',
    RouteComponent: BulkClinicianUpload
  },
  {
    path: '/settings/consent',
    RouteComponent: ConsentPage
  }
]

const TabGroup = ({ groupLabel, links, location }) => {
  const openPlansModal = useStoreActions(
    actions => actions.modals.choosePlan.openModal
  )

  const selectedTabIndex = links.findIndex(e =>
    location.pathname.includes(e.path)
  )
  const orientation = useBreakpointValue({
    base: 'horizontal',
    md: 'vertical'
  })

  return (
    <Box mb="5">
      <Text color="#757575" as="b">
        {groupLabel}
      </Text>
      <Tabs orientation={orientation} index={selectedTabIndex} mt="3">
        <TabList flex={1}>
          {links.map((link, i) => (
            <Tab
              key={i}
              as={Link}
              to={link.path}
              justifyContent="flex-start"
              p={{
                base: 'small',
                md: 'xsmall'
              }}
              whiteSpace="nowrap"
              _focus={{ boxShadow: 'none' }}
              onClick={() => {
                link.key === 'plans' && openPlansModal()
              }}
            >
              {link.display}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </Box>
  )
}

export const UserSettings = ({ location }) => {
  const { user } = useStoreState(state => state.auth)
  const { hasPermission } = usePermissions()
  const { data: paywallData } = useQuery(
    endpoints.getPaywallValidation.getCacheId(),
    endpoints.getPaywallValidation.request,
    {
      initialData: {}
    }
  )
  const { isLegacyPlan } = paywallData
  const { settingsLinkGroups } = useNavLinks(isLegacyPlan)

  const [selectedClinic, setSelectedClinic] = useState(user?.clinic)

  const { data: clinics } = useQuery(
    [
      endpoints.getOrganizationClinics.getCacheId(),
      selectedClinic && selectedClinic.organization_id
    ],
    () =>
      endpoints.getOrganizationClinics.request({
        organizationId: user.clinic.organization_id
      }),
    {
      initialData: [],
      enabled: hasPermission('read:org:clinics:list'),
      onSuccess(data) {
        // default to the currently selected clinic, otherwise use the user's clinic
        setSelectedClinic(
          data.find(clinic => clinic.id === selectedClinic?.id) ||
            data.find(clinic => clinic.id === user.clinic.id)
        )
      }
    }
  )

  return (
    <Container mt="xlarge" position="relative">
      <LayoutGrid>
        <Hidden sm>
          <GridItem
            colSpan={{
              base: 4,
              sm: 8,
              md: 2
            }}
            mb={{
              base: 'medium',
              md: '0px'
            }}
          >
            <Box
              position="sticky"
              top={{
                base: '0px',
                md: '120px',
                xl: '120px'
              }}
              boxSizing="border-box"
              overflowX={{
                base: 'auto',
                md: 'initial'
              }}
            >
              {settingsLinkGroups.map((linkGroup, idx) => (
                <TabGroup
                  key={idx}
                  groupLabel={linkGroup.group}
                  links={linkGroup.links}
                  location={location}
                />
              ))}
            </Box>
          </GridItem>
        </Hidden>
        <Switch>
          {Routes.map((route, i) => (
            <Route
              key={i}
              path={route.path}
              render={() =>
                !selectedClinic ? (
                  <Loading />
                ) : (
                  <GridItem
                    colSpan={{
                      base: 4,
                      sm: 8,
                      md: 10,
                      lg: 8
                    }}
                  >
                    <route.RouteComponent
                      clinics={clinics}
                      selectedClinic={selectedClinic}
                      setSelectedClinic={setSelectedClinic}
                    />
                  </GridItem>
                )
              }
              exact
            />
          ))}
          <Route>
            <Redirect to="/settings/account" />
          </Route>
        </Switch>
      </LayoutGrid>
    </Container>
  )
}
