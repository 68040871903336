import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext
} from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Input,
  Text,
  useBoolean
} from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'

import { CheckInRow } from './components/check-in-row'
import { MeasureTypes } from '@constants/measureTypes'
import { useClinicControllerGetAssignedCheckIns } from '~/clinician-api'
import { endpoints } from '@api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CheckInDtoV2 } from '~/clinician-api/models'

interface AssignCheckInsModalContextProps {
  open: (props: ModalOpenProps) => void
  close: () => void
}

interface ModalOpenProps {
  patientId: string
  clinicId: string
  type: 'symptom' | 'worksheet'
  activeCheckInIds: string[]
  onAssign?: (checkIns: CheckInDtoV2[]) => void
  disableAssignment?: boolean
}

export const AssignCheckInsModalContext = createContext<
  AssignCheckInsModalContextProps
>({} as AssignCheckInsModalContextProps)

export function AssignCheckInsModalProvider({
  children
}: {
  children: React.ReactNode
}) {
  const queryClient = useQueryClient()

  const [isOpen, openAction] = useBoolean()
  const [modalProps, setModalProps] = useState<ModalOpenProps | null>(null)

  const [selectedMeasures, setSelectedMeasures] = useState<string[]>([])
  const [searchText, setSearchText] = useState('')

  const { data: checkIns = [] } = useClinicControllerGetAssignedCheckIns(
    modalProps?.clinicId as string,
    {
      type: modalProps?.type === 'worksheet' ? 'treatment_activity' : 'symptom'
    },
    {
      query: {
        enabled: !!modalProps?.clinicId
      }
    }
  )

  const { mutateAsync: assignCheckInModules } = useMutation(
    endpoints.postClientAssignCheckInModules.request,
    {
      onSuccess() {
        queryClient.invalidateQueries([
          endpoints.getCheckInModulesForUser.getCacheId(),
          modalProps?.patientId
        ])
      }
    }
  )

  const {
    isLoading: areCheckinModsLoading,
    data: activeCheckInIds = []
  } = useQuery(
    [endpoints.getCheckInModulesForUser.getCacheId(), modalProps?.patientId],
    () =>
      endpoints.getCheckInModulesForUser.request({ id: modalProps?.patientId }),
    {
      enabled: !!modalProps?.patientId,
      // @ts-ignore
      select: mods => mods.filter(m => m.isActive).map(m => m.id)
    }
  )

  const modifiedData = useMemo(() => {
    return checkIns
      .map(ci => ({
        ...ci,
        isSelected: selectedMeasures.includes(ci.id)
      }))
      .filter(m =>
        `${m.title.toLowerCase()} ${m.subtitle.toLowerCase()}`.includes(
          searchText.toLowerCase()
        )
      )
  }, [selectedMeasures, searchText, checkIns])

  const measureTypeText = useMemo(() => {
    switch (modalProps?.type) {
      case MeasureTypes.SYMPTOM:
        return 'Symptom Trackers'
      case MeasureTypes.WORKSHEET:
        return 'Worksheets'
      default:
        return 'Measures'
    }
  }, [modalProps?.type])

  const buttonText = useMemo(() => {
    const numSelected = selectedMeasures.length
    const first = `Assign ${numSelected ? numSelected : ''}`
    const second = `${
      numSelected === 1
        ? measureTypeText.slice(0, measureTypeText.length - 1)
        : measureTypeText
    }`
    return `${first} ${second}`
  }, [selectedMeasures, measureTypeText])

  const onSelect = useCallback(
    id => {
      const updatedSelectedMeasures = selectedMeasures.includes(id)
        ? selectedMeasures.filter(m => m !== id) // deselect
        : [...selectedMeasures, id] // select
      setSelectedMeasures(updatedSelectedMeasures)
    },
    [selectedMeasures]
  )

  const _handleCloseModal = useCallback(() => {
    setSearchText('')
    setSelectedMeasures([])
    openAction.off()
    setModalProps(null)
  }, [])

  const onAssign = async () => {
    if (!modalProps?.disableAssignment) {
      await assignCheckInModules({
        clientId: modalProps?.patientId,
        checkInModuleIds: selectedMeasures
      })
    }

    if (modalProps?.onAssign) {
      modalProps.onAssign(
        checkIns.filter(ci => selectedMeasures.includes(ci.id))
      )
    }

    _handleCloseModal()
  }

  return (
    <AssignCheckInsModalContext.Provider
      value={{
        open(props: ModalOpenProps) {
          openAction.on()
          setModalProps(props)
        },
        close() {
          _handleCloseModal()
        }
      }}
    >
      <>
        {children}
        <Modal
          size="2xl"
          scrollBehavior="inside"
          isOpen={isOpen}
          onClose={_handleCloseModal}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="24px" borderBottom="1px solid #E4E5E6">
              Assign {measureTypeText}
            </ModalHeader>
            <ModalCloseButton mr="xsmall" mt="10px" borderRadius="25px" />
            <ModalBody pt="0">
              <Flex h="100px" align="center">
                <Input
                  placeholder={`Search ${
                    modalProps?.type === MeasureTypes.SYMPTOM
                      ? 'symptom tracker'
                      : modalProps?.type
                  }s`}
                  onChange={e => setSearchText(e.currentTarget.value)}
                  value={searchText}
                />
              </Flex>
              {modifiedData.length ? (
                <Flex direction="column" w="100%">
                  {modifiedData.map(ci => (
                    <CheckInRow
                      key={ci.id}
                      checkIn={ci}
                      isSelected={selectedMeasures.includes(ci.id)}
                      onSelect={onSelect}
                      isAssigned={(
                        modalProps?.activeCheckInIds || activeCheckInIds
                      ).includes(ci.id)}
                    />
                  ))}
                </Flex>
              ) : (
                <Text fontWeight="bold">No results found</Text>
              )}
            </ModalBody>

            <ModalFooter
              h="large"
              py="small"
              pr="small"
              borderTop="1px solid #E4E5E6"
            >
              <Button
                size="lg"
                mr="small"
                variant="outline"
                onClick={_handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                disabled={!selectedMeasures.length}
                size="lg"
                onClick={onAssign}
              >
                {buttonText}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </AssignCheckInsModalContext.Provider>
  )
}

export const useAssignCheckInsModal = () =>
  useContext(AssignCheckInsModalContext)
