import React from 'react'
import { Button, HStack, Stack, Text } from '@chakra-ui/react'

interface BeforeYouBeginItemProps {
  textColor: string | undefined
  subtextColor: string | undefined
  backgroundColor: string
  icon: JSX.Element
  buttonText: string
  text: string
  subtext: string
  handleClick: () => void
}

const BeforeYouBeginItem: React.FC<BeforeYouBeginItemProps> = ({
  backgroundColor,
  textColor,
  subtextColor,
  icon,
  buttonText,
  text,
  subtext,
  handleClick
}) => {
  return (
    <HStack
      justifyContent="space-between"
      py="12px"
      px="16px"
      borderRadius="8px"
      bg={backgroundColor}
    >
      <HStack gap="8px">
        {icon}
        <Stack spacing="0">
          <Text fontWeight="medium" color={textColor}>
            {text}
          </Text>
          <Text fontSize="12px" color={subtextColor}>
            {subtext}
          </Text>
        </Stack>
      </HStack>
      <Button
        variant="link"
        onClick={handleClick}
        textDecoration="none"
        color={textColor}
        _hover={{ textDecoration: 'none' }}
        _focus={{ outline: 'none' }}
      >
        {buttonText}
      </Button>
    </HStack>
  )
}

export default BeforeYouBeginItem
