import React from 'react'
import { Box, Container, Flex } from '@chakra-ui/react'
import NoteBox from './note-box'
import { ProgressNoteExplanation, TranscriptionError } from '.'
import { useExperienceManager } from '@hooks'

const ProgressNote = ({
  progressNote,
  handleTrackEvent,
  isNext,
  staticHeight,
  emptyNote,
  transcriptionError,
  paywallBannerHeight,
  uploadAlert
}: any) => {
  const {
    id,
    note: v1Note,
    nextNote: v2Note,
    noteType,
    template
  } = progressNote
  const { isExtension, isWidget } = useExperienceManager()

  const note = isNext ? v2Note : v1Note

  return (
    <Container
      height={
        isExtension || isWidget
          ? 'calc(100vh - 261px)'
          : `calc(100vh - 271px - ${paywallBannerHeight}px)`
      }
      overflowY="scroll"
    >
      <Flex flexDirection="column" flex="1">
        {transcriptionError && (
          <Box>
            <TranscriptionError />
          </Box>
        )}
        {uploadAlert && <Box>{uploadAlert()}</Box>}
        {template.sections.map((section: any) => (
          <NoteBox
            noteId={id}
            key={`${progressNote.id}-${section.key}`}
            type={noteType}
            field={section.key}
            isEditable={!isNext}
            buttonId={`pendo-copy-${noteType}-${section.key}-tag`}
            label={section.displayName}
            value={note[section.key] || ''}
            emptyMessage={`No ${section.key} note`}
            trackEvent={handleTrackEvent}
            isNext={isNext}
            staticHeight={staticHeight}
          />
        ))}
        {!emptyNote && (
          <Box mb="16px">
            <ProgressNoteExplanation />
          </Box>
        )}
      </Flex>
    </Container>
  )
}

export default ProgressNote
