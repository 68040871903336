import React, { useMemo } from 'react'
import { State, useStoreActions, useStoreState } from 'easy-peasy'
import { useQuery } from 'react-query'
import {
  Text,
  Box,
  Menu,
  MenuList,
  MenuItemOption,
  MenuOptionGroup
} from '@chakra-ui/react'
import { CustomMenuButton } from '../../components/filters'
import { keyBy } from 'lodash'
import { endpoints } from '@api'
import { InsightsModel } from 'src/store/model/insights'

interface AssessmentSelectorProps {
  hideCounts?: boolean
}

export const AssessmentSelector: React.FC<AssessmentSelectorProps> = ({
  hideCounts
}) => {
  const state = useStoreState(
    (state: State<InsightsModel>) => state.insights.filtersV2
  )
  const { clinicIds, assessmentId } = state || {}
  const { updateFiltersV2, setSelectedAssessment } = useStoreActions(
    actions => actions.insights
  ) as any

  const { data, isLoading, isPlaceholderData } = useQuery(
    [endpoints.getInsightsAssessments.getCacheId(), JSON.stringify(clinicIds)],
    () =>
      endpoints.getInsightsAssessments.request({ clinicIds: clinicIds || [] }),
    {
      placeholderData: { assessments: [] },
      refetchOnWindowFocus: false,
      enabled: !!clinicIds?.length,
      onSuccess: data => {
        if (data.assessments.length) {
          updateFiltersV2({ assessmentId: data.assessments[0].id })
          setSelectedAssessment(data.assessments[0])
        } else {
          updateFiltersV2({ assessmentId: null })
          setSelectedAssessment(null)
        }
      }
    }
  )

  const keyedAssessments = useMemo(() => keyBy(data?.assessments, 'id'), [data])

  const handleChange = (assessmentId: string) => {
    updateFiltersV2({ assessmentId })
    const assessment = keyedAssessments[assessmentId]
    setSelectedAssessment(assessment)
  }

  const selectedAssessment = assessmentId
    ? keyedAssessments[assessmentId]
    : null
  const label =
    isLoading || isPlaceholderData
      ? 'Loading...'
      : !isPlaceholderData && !selectedAssessment
      ? 'No Assessments'
      : `${selectedAssessment?.name}: ${selectedAssessment?.disorder}`

  return (
    <Box sx={{ maxWidth: 300 }}>
      <Menu size="sm">
        <CustomMenuButton
          isMenu
          label={label}
          sx={{
            paddingLeft: 0,
            textAlign: 'start',
            whiteSpace: 'normal',
            fontWeight: 'bold'
          }}
          chips={[]}
          onClick={() => {}}
        />
        <MenuList sx={{ width: 400, maxHeight: 500, overflow: 'scroll' }}>
          <MenuOptionGroup
            type="radio"
            onChange={e => handleChange(e as string)}
          >
            {data?.assessments?.map(assessment => (
              <MenuItemOption
                key={assessment.id}
                isChecked={assessment.id === assessmentId}
                value={assessment.id}
                p="2"
              >
                <Text as="span">
                  {assessment.name} {assessment.disorder}
                </Text>
                {!hideCounts && (
                  <Text color="gray.500" as="span">
                    {' '}
                    ({assessment.count?.toLocaleString()})
                  </Text>
                )}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  )
}
