import React, { ReactNode } from 'react'
import { Stack, Text, Button, HStack, Collapse } from '@chakra-ui/react'
import { ArrowUpIcon, ArrowDownIcon } from '@blueprinthq/joy'
import { AssessmentProgress } from './assessment-progress'
import { CriticalEvents } from './critical-events'
import { DeliverInterventions } from './deliver-interventions'
import { LifestyleCorrelatesChart } from './lifestyle-correlates-chart'
import { PlanForNextSession } from './plan-for-next-session'
import { RecapLastSession } from './recap-last-session'
import { ReviewHomework } from './review-homework'
import { SessionRecapResponse } from '~/clinician-api/models'

type CollapsibleComponentProps = {
  children: ReactNode
  defaultShow?: boolean
  position: string | number
  title: string
}

type SessionAgendaProps = {
  client: {
    id: string
    first_name: string
    isChildAge: boolean
    clinic_id: string
  }
  recap?: SessionRecapResponse
  sessionId: string
}

const CollapsibleComponent = ({
  children,
  defaultShow,
  position,
  title
}: CollapsibleComponentProps) => {
  const [show, setShow] = React.useState(defaultShow || false)

  const handleToggle = () => {
    setShow(!show)
  }

  return (
    <Stack
      border="1px solid"
      borderColor="pale_gray"
      borderRadius="lg"
      p="medium"
      style={{ marginTop: 0 }}
    >
      <Button
        _focus={{ outline: 'none' }}
        variant="unstyled"
        onClick={handleToggle}
      >
        <HStack>
          <HStack
            width="100%"
            lineHeight="48px"
            gap="16px"
            justifyContent="space-between"
          >
            <HStack gap="16px">
              <Stack
                height="40px"
                width="40px"
                padding="8px"
                justifyContent="center"
                alignItems="center"
                bg="rgba(45, 84, 232, 0.04)"
                borderRadius="full"
              >
                <Text color="primary" fontWeight="bold">
                  {position}
                </Text>
              </Stack>
              <Text style={{ marginLeft: 0 }} fontWeight="bold" fontSize="lg">
                {title}
              </Text>
            </HStack>
            {show ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </HStack>
        </HStack>
      </Button>
      <Collapse in={show}>{children}</Collapse>
    </Stack>
  )
}

const SessionAgenda = ({ client, recap, sessionId }: SessionAgendaProps) => {
  const clientId = client.id

  return (
    <Stack gap="16px">
      <CollapsibleComponent position={1} defaultShow={true} title="Check-in">
        <Stack>
          <Text color={'#757575'}>
            {`Review the latest update on ${client.first_name}'s progress`}
          </Text>
          <Stack>
            <CriticalEvents
              clientId={clientId}
              recap={recap}
              containerProps={{
                mt: '16px'
              }}
            />

            {!client.isChildAge && (
              <LifestyleCorrelatesChart
                recap={recap}
                containerProps={{
                  mt: '16px'
                }}
              />
            )}
            <AssessmentProgress
              recap={recap}
              clientId={clientId}
              containerProps={{
                mt: '16px'
              }}
            />
          </Stack>
        </Stack>
      </CollapsibleComponent>
      <CollapsibleComponent position={2} title="Recap Last Session">
        <RecapLastSession clientId={clientId} recap={recap} />
      </CollapsibleComponent>
      <CollapsibleComponent position={3} title="Review Homework">
        <ReviewHomework clientId={clientId} recap={recap} />
      </CollapsibleComponent>
      <CollapsibleComponent position={4} title="Deliver Interventions">
        <DeliverInterventions sessionId={sessionId} clientId={clientId} />
      </CollapsibleComponent>
      <CollapsibleComponent position={5} title="Plan for Next Session">
        <PlanForNextSession
          firstName={client.first_name}
          sessionId={sessionId}
          clientId={clientId}
          recap={recap}
          clinicId={client.clinic_id}
        />
      </CollapsibleComponent>
    </Stack>
  )
}

export default SessionAgenda
