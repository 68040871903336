import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  Button,
  TextField,
  Checkbox,
  Select as JoySelect
} from '@blueprinthq/joy'
import {
  FormControl,
  FormErrorMessage,
  Flex,
  HStack,
  VStack,
  Box,
  Text,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useToast,
  useBreakpointValue,
  Select,
  Avatar,
  Tooltip
} from '@chakra-ui/react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import flagsmith from 'flagsmith'

import { FlagsmithFeatures } from '@constants/flagsmith'
import { endpoints } from '../../../../api'
import { usePermissions, useExperienceManager } from '../../../../hooks'
import {
  roles,
  mapLocalRoleToOrganizationRole,
  roleWeights,
  getHighestRoleWeight
} from '../../utils'
import { SupervisorGroupsModal } from '@components'
import { cloudinaryAssetToUrl } from '@utilities'

const UpsertProviderSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  send_invite: Yup.boolean().notRequired(),
  roles: Yup.array()
    .min(1, 'Must assign at least one role.')
    .required('Required'),
  clinic: Yup.object()
    .shape({
      id: Yup.string().required()
    })
    .required('Must assign user to clinic')
    .nullable(),
  has_doc_automation_access: Yup.boolean().notRequired(),
  has_ebc_access: Yup.boolean().notRequired()
})

export const UpsertClinicianModal = ({
  provider,
  onClose,
  onClickDeleteUser,
  selectedClinic,
  clinicList,
  user,
  isUpgradingToGroup,
  providerListForPage,
  page,
  perPage,
  totalCount,
  handlePageChange,
  isProvidersLoading,
  handleClinicianSearch,
  clinicianSearchQuery,
  refetchProviders,
  fullProviderList,
  ...rest
}) => {
  const ebcBillingEnabled = flagsmith.hasFeature(FlagsmithFeatures.EBC_BILLING)
  const clinicians =
    providerListForPage &&
    providerListForPage.filter(provider =>
      provider.roles.some(role => role.name === 'clinician')
    )

  const showClinicDropdown = user.roles.some(
    role => role.name === 'orgadmin' || role.name === 'superadmin'
  )

  const {
    isEvidenceBasedCareEnabled,
    hasDocumentationAutomationSubscription
  } = useExperienceManager()
  const queryClient = useQueryClient()
  const toast = useToast()
  const { hasPermission } = usePermissions()
  const isSuperAdmin = hasPermission('*:any:*:*')
  const showBilling = user.organization.showBilling
  const [showSupervisor, setShowSupervisor] = useState(false)
  const [selectedClinicians, setSelectedClinicians] = useState([])

  const [maxAvatars] = useState(8)

  const { data: licenseLevels } = useQuery(
    [endpoints.getLicenseLevels.getCacheId()],
    endpoints.getLicenseLevels.request,
    {
      initialData: []
    }
  )

  const { data: supervisorClinicians, refetch } = useQuery(
    [endpoints.getOrganizationSupervisors.getCacheId()],
    () =>
      endpoints.getOrganizationSupervisors.request({
        providerId: provider && provider.id
      }),
    {
      initialData: []
    }
  )

  const { data: organizationRoles } = useQuery(
    [endpoints.getOrganizationRoles.getCacheId()],
    endpoints.getOrganizationRoles.request,
    {
      initialData: [],
      enabled: hasPermission('read:org:providers:roles')
    }
  )

  const { data: fullCliniciansList } = useQuery(
    [
      endpoints.getOrganizationProviders.getCacheId(),
      selectedClinic.organization_id,
      selectedClinic.id
    ],
    () =>
      endpoints.getOrganizationProviders.request({
        organizationId: selectedClinic.organization_id,
        clinicId: selectedClinic.id,
        cliniciansOnly: true,
        includeCounts: false
      }),
    {
      initialData: []
    }
  )

  const {
    mutateAsync: createProvider,
    isLoading: isCreateProviderLoading
  } = useMutation(endpoints.postOrganizationProviders.request, {
    onSuccess() {
      queryClient.invalidateQueries([
        endpoints.getOrganizationProvidersV2.getCacheId(),
        page,
        clinicianSearchQuery,
        selectedClinic && selectedClinic.organization_id,
        selectedClinic && selectedClinic.id
      ])
      onClose()
      if (isUpgradingToGroup) {
        upgradeToGroupTrial({})
      } else {
        toast({
          title: 'Create User',
          description: 'Successfully created user.',
          status: 'info',
          isClosable: true
        })
      }
    },
    onError({ error }) {
      toast({
        title: 'Error',
        description: error.messsage,
        status: 'error',
        isClosable: true
      })
    }
  })

  const {
    mutateAsync: updateProvider,
    isLoading: isProviderUpdateLoading
  } = useMutation(endpoints.patchOrganizationProviders.request, {
    onSuccess() {
      queryClient.invalidateQueries([
        endpoints.getOrganizationProvidersV2.getCacheId(),
        page,
        clinicianSearchQuery,
        selectedClinic && selectedClinic.organization_id,
        selectedClinic && selectedClinic.id
      ])
      queryClient.invalidateQueries(endpoints.getPaywallValidation.getCacheId())
      onClose()
      toast({
        title: 'Edit User',
        description: 'Successfully edited user.',
        status: 'info',
        isClosable: true
      })
    },
    onError({ error }) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        isClosable: true
      })
    }
  })

  const {
    mutateAsync: updateSupervisorGroups,
    isLoading: updateSupervisorGroupsLoading
  } = useMutation(endpoints.postOrganizationSupervisors.request, {
    onSuccess() {
      queryClient.invalidateQueries([
        endpoints.getOrganizationProvidersV2.getCacheId(),
        page,
        clinicianSearchQuery,
        selectedClinic && selectedClinic.id
      ])

      refetchProviders()
      handleClinicianSearch('')
      setShowSupervisor(false)
      onClose()
    },
    onError({ error }) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        isClosable: true
      })
    }
  })

  const {
    mutateAsync: removeExistingClinicianSupervisors,
    isLoading: isRemoveExistingClinicianSupervisorsLoading
  } = useMutation(endpoints.removeExistingOrganizationSupervisors.request, {
    onSuccess() {
      queryClient.invalidateQueries([
        endpoints.getOrganizationProvidersV2.getCacheId(),
        page,
        clinicianSearchQuery,
        selectedClinic && selectedClinic.id
      ])

      refetchProviders()
      handleClinicianSearch('')

      setShowSupervisor(false)
      onClose()
      toast({
        title: 'Edit User',
        description: 'Successfully edited user.',
        status: 'info',
        isClosable: true
      })
    },
    onError({ error }) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        isClosable: true
      })
    }
  })

  const {
    mutateAsync: removeSupervisorGroups,
    isLoading: isRemoveSupervisorGroupsLoading
  } = useMutation(endpoints.removeOrganizationSupervisors.request, {
    onSuccess() {
      queryClient.invalidateQueries([
        endpoints.getOrganizationProvidersV2.getCacheId(),
        page,
        clinicianSearchQuery,
        selectedClinic && selectedClinic.id
      ])

      refetchProviders()
      handleClinicianSearch('')
      setShowSupervisor(false)
      onClose()
      if (selectedClinicians.length !== 0) {
        toast({
          title: 'Edit User',
          description: 'Successfully edited user.',
          status: 'info',
          isClosable: true
        })
      }
    },
    onError({ error }) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        isClosable: true
      })
    }
  })

  const { mutateAsync: upgradeToGroupTrial } = useMutation(
    endpoints.postUpgradeToGroupTrial.request,
    {
      onSuccess() {
        toast({
          title: 'Create User',
          description:
            'You’ve been upgraded to the Groups plan, which allows you to invite additional users. You have 29 days remaining on your Free Trial before being billed $75 per user per month.',
          status: 'success',
          isClosable: true
        })
      },
      onError({ error }) {
        toast({
          title: 'Error',
          description: error.messsage,
          status: 'error',
          isClosable: true
        })
      }
    }
  )

  const initialClinic = useMemo(() => {
    if (!provider) return selectedClinic
    const providerClinic = clinicList.find(
      clinic => provider.clinic_id === clinic.id
    )
    return providerClinic ? providerClinic : selectedClinic
  }, [provider, clinicList, selectedClinic])

  const title = provider ? 'Edit user' : 'Invite a new user'
  const buttonTxt = provider ? 'Update' : 'Submit'

  const onClickDelete = useCallback(
    clinician => {
      onClose()
      onClickDeleteUser(clinician)
    },
    [onClose, onClickDeleteUser]
  )

  const modalSize = useBreakpointValue({
    base: 'full',
    md: '2xl'
  })

  const allowedRoles = useMemo(() => {
    // Filter out biller role if !showBilling
    const filteredRoles = showBilling
      ? roles
      : roles.filter(role => role.name !== 'biller')

    if (hasPermission('*:any:*:*')) return filteredRoles

    const userRoleWeight = getHighestRoleWeight(user.roles)

    if (userRoleWeight === roleWeights.orgadmin) {
      return filteredRoles.filter(role =>
        [
          'clinician',
          'supervisor',
          'biller',
          'clinicadmin',
          'orgadmin'
        ].includes(role.name)
      )
    }

    if (userRoleWeight === roleWeights.clinicadmin) {
      return filteredRoles.filter(role =>
        ['clinician', 'supervisor', 'biller', 'clinicadmin'].includes(role.name)
      )
    }

    if (userRoleWeight === roleWeights.supervisor) {
      return filteredRoles.filter(role =>
        ['clinician', 'supervisor'].includes(role.name)
      )
    }

    return []
  }, [hasPermission, user])

  function clinicianExistsById(clinicians, clinicianId) {
    return clinicians.some(clinician => clinician.id === clinicianId)
  }

  const handleSelect = clinician => {
    const clinicianExists = clinicianExistsById(
      selectedClinicians,
      clinician.id
    )
    if (clinicianExists) {
      setSelectedClinicians(
        selectedClinicians.filter(
          selectedClinician => selectedClinician.id !== clinician.id
        )
      )
      return
    } else {
      setSelectedClinicians([...selectedClinicians, clinician])
    }
  }

  const handleSupervisorClick = isSelected => {
    if (isSelected) {
      setSelectedClinicians([])
    } else {
      setSelectedClinicians([])
    }
  }

  function findMatchingClinicians(users, relationships) {
    return users.filter(user =>
      relationships.some(relation => relation.clinician_id === user.id)
    )
  }

  useEffect(() => {
    if (provider && provider.roles) {
      const supervisorRole = provider.roles.find(
        role => role.name === 'supervisor'
      )
      if (supervisorRole && supervisorClinicians.length === 0) {
        setSelectedClinicians([])
      } else {
        const foundClinicians = findMatchingClinicians(
          fullCliniciansList,
          supervisorClinicians
        )
        setSelectedClinicians(foundClinicians)
      }
      refetch()
    } else {
      setSelectedClinicians([])
    }
  }, [provider])

  useEffect(() => {
    if (provider && provider.roles) {
      const supervisorRole = provider.roles.find(
        role => role.name === 'supervisor'
      )
      if (supervisorRole && supervisorClinicians.length === 0) {
        setSelectedClinicians([])
      } else {
        const foundClinicians = findMatchingClinicians(
          fullCliniciansList,
          supervisorClinicians
        )
        setSelectedClinicians(foundClinicians)
      }
      refetch()
    } else {
      setSelectedClinicians([])
    }
  }, [supervisorClinicians])

  function filterOutExistingClinicians(
    supervisorClinicianArray,
    fullCliniciansList
  ) {
    const clinicianIds = supervisorClinicianArray.map(item => item.clinician_id)
    return fullCliniciansList.filter(
      clinician => !clinicianIds.includes(clinician.id)
    )
  }

  function findRemovedClinicians(supervisorClinicianArray, fullCliniciansList) {
    const idsInSecondArray = new Set(fullCliniciansList.map(item => item.id))

    return supervisorClinicianArray.filter(
      item => !idsInSecondArray.has(item.clinician_id)
    )
  }

  const updateSupervisorClinicians = async () => {
    const hasSupervisorClinicianRole = provider.roles
      .map(role => role.name)
      .includes('supervisor_clinician')
    const newClinicians = filterOutExistingClinicians(
      supervisorClinicians,
      selectedClinicians
    )
    const removedClinicians = findRemovedClinicians(
      supervisorClinicians,
      selectedClinicians
    )
    if (removedClinicians.length !== 0) {
      if (selectedClinicians.length === 0) {
        await removeSupervisorGroups({
          supervisorClinicianId: provider.id,
          roleId: provider.roles.find(
            role => role.name === 'supervisor_clinician'
          ).id
        }).then(() => {
          refetch()
          setSelectedClinicians([])
        })
      } else {
        await removeExistingClinicianSupervisors({
          supervisorClinicianId: provider.id,
          data: {
            clinicianIds: removedClinicians.map(
              clinician => clinician.clinician_id
            )
          }
        }).then(() => {
          refetch()
          setSelectedClinicians([])
        })
      }
    }
    if (newClinicians.length !== 0) {
      const mappedRoles = provider.roles.map(role => ({
        id: mapLocalRoleToOrganizationRole(role.name, organizationRoles).id
      }))

      const hasSupervisorRole = provider.roles
        .map(role => role.name)
        .includes('supervisor')
      if (
        hasSupervisorRole &&
        !hasSupervisorClinicianRole &&
        selectedClinicians.length !== 0
      ) {
        const supervisorClinicianRole = mapLocalRoleToOrganizationRole(
          'supervisor_clinician',
          organizationRoles
        )
        if (supervisorClinicianRole) {
          mappedRoles.push({ id: supervisorClinicianRole.id })
        }
      }

      const data = {
        email: provider.email,
        first_name: provider.first_name,
        last_name: provider.last_name,
        clinic_id: provider.clinic_id,
        roles: mappedRoles
      }
      await updateProvider({
        organizationId: selectedClinic.organization_id,
        providerId: provider.id,
        data
      })
      await updateSupervisorGroups({
        supervisorClinicianId: provider.id,
        data: {
          supervisor_ids: newClinicians.map(clinician => clinician.id)
        }
      }).then(() => {
        refetch()
        setSelectedClinicians([])
      })
    }
  }

  const handleAllClinciansSubmit = async () => {
    await removeSupervisorGroups({
      supervisorClinicianId: provider.id,
      roleId: provider.roles.find(role => role.name === 'supervisor_clinician')
        .id
    }).then(() => {
      refetch()
      setSelectedClinicians([])
    })
  }

  const isUpdateSupervisorModalLoading =
    updateSupervisorGroupsLoading ||
    isRemoveSupervisorGroupsLoading ||
    isRemoveExistingClinicianSupervisorsLoading ||
    isProviderUpdateLoading ||
    isCreateProviderLoading

  return (
    <Modal
      onClose={() => {
        refetchProviders()
        handleClinicianSearch('')
        setShowSupervisor(false)
        onClose()
      }}
      size={modalSize}
      preserveScrollBarGap
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        {!showSupervisor && (
          <ModalHeader
            fontSize={20}
            p={'16px 16px 0px 24px'}
            textAlign={'center'}
          >
            {title}
          </ModalHeader>
        )}
        {!showSupervisor && <ModalCloseButton p={'24px'} />}
        <Formik
          validationSchema={UpsertProviderSchema}
          validate={props => {
            const errors = {}
            if (
              provider !== null &&
              !props.roles.includes('clinician') &&
              provider.total_client_count > 0
            ) {
              errors.roles =
                'Cannot remove Clinician role from user with assigned clients.'
            }
            if (
              ebcBillingEnabled &&
              !props.has_doc_automation_access &&
              !props.has_ebc_access
            ) {
              errors.products = 'User must have access to at least one product.'
            }
            return errors
          }}
          initialValues={{
            email: provider ? provider.email : '',
            first_name: provider ? provider.first_name : '',
            last_name: provider ? provider.last_name : '',
            clinic: initialClinic,
            send_invite: true,
            roles: provider
              ? provider.roles.map(role => role.name)
              : ['clinician'],
            license_level: provider
              ? licenseLevels.find(level => level.id === provider.license_level)
              : null,
            has_doc_automation_access: provider
              ? provider.has_doc_automation_access
              : hasDocumentationAutomationSubscription
              ? true
              : false,
            has_ebc_access: provider ? provider.has_ebc_access : false
          }}
          enableReinitialize
          onSubmit={async (values, actions) => {
            const mappedRoles = values.roles.map(role => ({
              id: mapLocalRoleToOrganizationRole(role, organizationRoles).id
            }))

            if (
              values.roles.includes('supervisor') &&
              selectedClinicians.length !== 0
            ) {
              const supervisorClinicianRole = mapLocalRoleToOrganizationRole(
                'supervisor_clinician',
                organizationRoles
              )
              if (supervisorClinicianRole) {
                mappedRoles.push({ id: supervisorClinicianRole.id })
              }
            }

            const data = {
              email: values.email,
              first_name: values.first_name,
              last_name: values.last_name,
              clinic_id: values.clinic.id,
              roles: mappedRoles,
              has_doc_automation_access: isSuperAdmin
                ? values.has_doc_automation_access
                : hasDocumentationAutomationSubscription
                ? values.has_doc_automation_access
                : false,
              has_ebc_access: ebcBillingEnabled
                ? values.has_ebc_access
                : isEvidenceBasedCareEnabled
            }

            if (!provider) {
              await createProvider({
                organizationId: selectedClinic.organization_id,
                data: {
                  providerParams: data,
                  sendInvite: values.send_invite
                }
              }).then(async res => {
                if (selectedClinicians.length !== 0) {
                  await updateSupervisorGroups({
                    supervisorClinicianId: res.id,
                    data: {
                      supervisor_ids: selectedClinicians.map(
                        clinician => clinician.id
                      )
                    }
                  })
                }
              })
            } else {
              const wasSupervisor = provider.roles
                .map(role => role.name)
                .includes('supervisor')
              const hasSupervisorClinicianRole = provider.roles
                .map(role => role.name)
                .includes('supervisor_clinician')
              const includesSupervisorRole = values.roles.includes('supervisor')
              await updateProvider({
                organizationId: selectedClinic.organization_id,
                providerId: provider.id,
                data
              })
              if (includesSupervisorRole && !wasSupervisor) {
                if (selectedClinicians.length !== 0) {
                  await updateSupervisorGroups({
                    supervisorClinicianId: provider.id,
                    data: {
                      supervisor_ids: selectedClinicians.map(
                        clinician => clinician.id
                      )
                    }
                  })
                }
              } else if (
                !includesSupervisorRole &&
                wasSupervisor &&
                hasSupervisorClinicianRole
              ) {
                await removeSupervisorGroups({
                  supervisorClinicianId: provider.id,
                  roleId: provider.roles.find(
                    role => role.name === 'supervisor_clinician'
                  ).id
                })
              }
            }

            actions.setSubmitting(false)
          }}
        >
          {({ values, errors, touched, isSubmitting, isValid, dirty }) =>
            !showSupervisor && (
              <Form>
                <ModalBody p={'medium'}>
                  <Text fontWeight="bold" mb="small">
                    Info
                  </Text>
                  <VStack spacing="large" alignItems="flex-start">
                    <HStack spacing="small" width="100%">
                      <Field name="first_name">
                        {({ field }) => (
                          <TextField
                            {...field}
                            label="First Name"
                            isInvalid={errors.first_name && touched.first_name}
                            errorText={errors.first_name}
                            isRequired
                          />
                        )}
                      </Field>
                      <Field name="last_name">
                        {({ field }) => (
                          <TextField
                            {...field}
                            label="Last Name"
                            isInvalid={errors.last_name && touched.last_name}
                            errorText={errors.last_name}
                            isRequired
                          />
                        )}
                      </Field>
                    </HStack>
                    <Field name="email">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Email"
                          isInvalid={errors.email && touched.email}
                          errorText={errors.email}
                          isRequired
                        />
                      )}
                    </Field>
                    {!provider && (
                      <Field name="send_invite" type="checkbox">
                        {({ field }) => (
                          <FormControl
                            isInvalid={
                              errors.send_invite && touched.send_invite
                            }
                          >
                            <Checkbox
                              {...field}
                              isChecked={field.checked}
                              fontWeight="bold"
                            >
                              Send an invite email
                            </Checkbox>
                            <Text ml="medium">
                              {
                                "An invite can be sent later if you aren't ready"
                              }
                            </Text>
                            <FormErrorMessage>
                              {errors.send_invite}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    )}
                  </VStack>
                  <Box mt="medium">
                    <Text fontWeight="bold">Role</Text>
                    <Text>
                      Each user can have multiple roles. If a Supervisor or
                      Admin also sees clients, grant them a Clinician role.
                    </Text>
                  </Box>
                  <VStack mt="medium" spacing="small">
                    {allowedRoles.map((role, i) => (
                      <Field key={i} name="roles" type="checkbox">
                        {({ field, form }) => (
                          <FormControl isInvalid={errors.roles}>
                            <Checkbox
                              {...field}
                              value={role.name}
                              isChecked={values.roles.includes(role.name)}
                              fontWeight="bold"
                              textTransform="capitalize"
                              isDisabled={role.name === 'superadmin'}
                              onChange={e => {
                                // Determine if the role being changed is 'supervisor'
                                const isSupervisor = role.name === 'supervisor'

                                // Handle adding or removing the role
                                if (e.target.checked) {
                                  form.setFieldValue('roles', [
                                    ...form.values.roles,
                                    role.name
                                  ])
                                  if (isSupervisor) {
                                    handleSupervisorClick(true)
                                  }
                                } else {
                                  form.setFieldValue(
                                    'roles',
                                    form.values.roles.filter(
                                      r => r !== role.name
                                    )
                                  )
                                  if (isSupervisor) {
                                    handleSupervisorClick(false)
                                  }
                                }
                              }}
                            >
                              {role.display}
                            </Checkbox>
                            <Text ml="medium">{role.description}</Text>
                            {role.name === 'supervisor' && (
                              <Box
                                borderRadius={'4px'}
                                ml="medium"
                                mt={'12px'}
                                p={'xsmall'}
                                border={'1px solid'}
                                borderColor={'pale_gray'}
                                opacity={
                                  values.roles.includes('supervisor') ? 1 : 0.5
                                }
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <div
                                  id="avatar-container"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {selectedClinicians.length !==
                                    fullCliniciansList.length &&
                                    selectedClinicians
                                      .slice(0, maxAvatars)
                                      .map((user, index) => {
                                        return user.avatar ? (
                                          <Tooltip
                                            key={index}
                                            label={`${user.first_name} ${user.last_name}`}
                                            aria-label="users name"
                                          >
                                            <Avatar
                                              src={cloudinaryAssetToUrl(
                                                user.avatar,
                                                {
                                                  transformations: {
                                                    resize: {
                                                      height: 128,
                                                      width: 128,
                                                      type: 'scale'
                                                    }
                                                  }
                                                }
                                              )}
                                              name={`${user.first_name} ${user.last_name}`}
                                              bg="pale_gray"
                                              height={'40px'}
                                              width={'40px'}
                                              style={{ marginRight: '-8px' }}
                                              color="black"
                                              fontWeight="bold"
                                            />
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            key={index}
                                            label={`${user.first_name} ${user.last_name}`}
                                            aria-label="users name"
                                          >
                                            <Avatar
                                              key={index}
                                              width={'40px'}
                                              height={'40px'}
                                              name={`${user.first_name} ${user.last_name}`}
                                              bg="pale_gray"
                                              size="md"
                                              mr="xsmall"
                                              color="black"
                                              fontWeight="bold"
                                              style={{ marginRight: '-8px' }}
                                            />
                                          </Tooltip>
                                        )
                                      })}
                                  {(selectedClinicians.length ===
                                    fullCliniciansList.length ||
                                    selectedClinicians.length === 0) && (
                                    <>
                                      <Text>All Clinicans</Text>
                                    </>
                                  )}
                                  {!isProvidersLoading &&
                                    selectedClinicians.length > maxAvatars && (
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginLeft: '15px',
                                          color: '#A1A1A1'
                                        }}
                                      >
                                        +
                                        {selectedClinicians.length - maxAvatars}{' '}
                                        more
                                      </div>
                                    )}
                                </div>

                                <Button
                                  outline={'none'}
                                  color={'primary'}
                                  onClick={() => {
                                    if (values.roles.includes('supervisor')) {
                                      refetchProviders()
                                      handleClinicianSearch('')
                                      setShowSupervisor(true)
                                    }
                                  }}
                                  backgroundColor={'white'}
                                >
                                  Manage
                                </Button>
                              </Box>
                            )}

                            {provider !== null &&
                            role.name === 'clinician' &&
                            provider.total_client_count > 0 ? (
                              <Text ml="medium" color="medium_gray">
                                (Currently assigned{' '}
                                {provider.total_client_count} clients)
                              </Text>
                            ) : null}
                          </FormControl>
                        )}
                      </Field>
                    ))}
                  </VStack>
                  {errors.roles && (
                    <Text mt="small" color="error">
                      {errors.roles}
                    </Text>
                  )}
                  {provider === null && showClinicDropdown && (
                    <Box mt="small">
                      <Text fontWeight="bold" mb="xsmall">
                        Clinic
                      </Text>
                      <Field name="clinic">
                        {({ field, form }) =>
                          hasPermission('read:any:clinics:list') ? (
                            <Select
                              {...field}
                              style={{ border: '1px solid #E4E5E6' }}
                              borderRadius="4px"
                              height="48px"
                              _focus={{
                                outline: 'none'
                              }}
                              label="Choose clinic"
                              isInvalid={errors.clinic && touched.clinic}
                              errorText={errors.clinic}
                              isRequired
                              value={values.clinic.id}
                              onChange={e =>
                                form.setFieldValue(
                                  'clinic',
                                  clinicList.find(c => c.id === e.target.value)
                                )
                              }
                            >
                              {clinicList.map(c => (
                                <option key={c.id} value={c.id}>
                                  {c.name}
                                </option>
                              ))}
                            </Select>
                          ) : (
                            <JoySelect
                              {...field}
                              onChange={value =>
                                form.setFieldValue('clinic', value)
                              }
                              options={clinicList}
                              valueKey="id"
                              labelKey="name"
                              label="Choose clinic"
                              isInvalid={errors.clinic && touched.clinic}
                              errorText={errors.clinic}
                              isRequired
                            />
                          )
                        }
                      </Field>
                    </Box>
                  )}
                  <>
                    <Box mt="medium">
                      <Text fontWeight="bold">Products</Text>
                      <Text>Choose products</Text>
                    </Box>
                    <Box mt="small">
                      <Box mb={1}>
                        <Field name="has_doc_automation_access" type="checkbox">
                          {({ field }) => (
                            <FormControl>
                              <Checkbox
                                {...field}
                                isChecked={
                                  isSuperAdmin
                                    ? values.has_doc_automation_access
                                    : hasDocumentationAutomationSubscription
                                    ? values.has_doc_automation_access
                                    : false
                                }
                                fontWeight="bold"
                                isDisabled={
                                  isSuperAdmin
                                    ? false
                                    : (isEvidenceBasedCareEnabled &&
                                        !hasDocumentationAutomationSubscription) ||
                                      (hasDocumentationAutomationSubscription &&
                                        !isEvidenceBasedCareEnabled)
                                }
                              >
                                Documentation Automation
                              </Checkbox>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box mb={1}>
                        <Field name="has_ebc_access" type="checkbox">
                          {({ field }) => (
                            <FormControl>
                              <Checkbox
                                {...field}
                                isChecked={
                                  ebcBillingEnabled
                                    ? values.has_ebc_access
                                    : isEvidenceBasedCareEnabled
                                }
                                fontWeight="bold"
                                isDisabled={
                                  ebcBillingEnabled &&
                                  isEvidenceBasedCareEnabled
                                    ? false
                                    : true
                                }
                              >
                                Evidence-based Care{' '}
                              </Checkbox>
                            </FormControl>
                          )}
                        </Field>
                        {errors.products && (
                          <Text mt="small" color="error">
                            {errors.products}
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </>
                </ModalBody>
                <ModalFooter
                  p={'20px'}
                  pt={0}
                  borderTop={'1px solid'}
                  borderColor={'pale_gray'}
                  display="block"
                >
                  <Flex flex={1}>
                    {provider && (
                      <Button
                        type="button"
                        size="lg"
                        backgroundColor="white"
                        color="black"
                        border={'1px solid'}
                        borderColor={'pale_gray'}
                        isFullWidth
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      size="lg"
                      isFullWidth
                      isLoading={isSubmitting}
                      isDisabled={!isValid || !dirty}
                      type="submit"
                    >
                      {buttonTxt}
                    </Button>
                  </Flex>
                  {provider && provider.id !== user.id && (
                    <Flex mt="small" justify="flex-end">
                      <Button
                        type="button"
                        color="severe"
                        variant="ghost"
                        onClick={() => onClickDelete(provider)}
                      >
                        Delete User
                      </Button>
                    </Flex>
                  )}
                </ModalFooter>
              </Form>
            )
          }
        </Formik>
        {showSupervisor && (
          <SupervisorGroupsModal
            setShowSupervisor={() => setShowSupervisor(false)}
            clinicians={clinicians}
            provider={provider}
            existing={supervisorClinicians}
            handleSelectClinician={clinician => handleSelect(clinician)}
            selectedClinicians={selectedClinicians}
            setSelectedClinicians={setSelectedClinicians}
            submitSupervisor={() => setShowSupervisor(false)}
            updateSupervisorClinicians={() => updateSupervisorClinicians()}
            handleAllClinciansSubmit={() => handleAllClinciansSubmit()}
            perPage={perPage}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
            isProvidersLoading={isProvidersLoading}
            page={page}
            handleClinicianSearch={handleClinicianSearch}
            clinicianSearchQuery={clinicianSearchQuery}
            isUpdateSupervisorModalLoading={isUpdateSupervisorModalLoading}
          />
        )}
      </ModalContent>
    </Modal>
  )
}
