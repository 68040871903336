import React, { useEffect } from 'react'
import {
  VStack,
  Stack,
  Flex,
  Text,
  Box,
  HStack,
  IconButton,
  Image,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { ArrowLeftIcon, ArrowRightIcon } from '@blueprinthq/joy'
import { useExperienceManager } from '@hooks'
import ContentSuggestionCard from '@handlers/sessions/components/shared/assist/assessments/suggestion-card'
import { AssessmentAssignmentModal } from './assessment-assignment-modal'
import CoolKidsBust from '@handlers/sessions/components/shared/assist/svgs/cool-kids-bust'
import {
  usePatientControllerAssistEnroll,
  useSessionControllerGetSuggestedContent,
  getSessionControllerGetSuggestedContentQueryKey,
  useContentSuggestionControllerAccept
} from '~/clinician-api'
import Waitlist from '../in-session-assist-waitlist-panel'
import { endpoints } from '@api'
import { useCadenceOptions } from '../shared/use-cadence-options'
import SuggestionStrengthCard from './suggestion-strength-card'

const SuggestionLoader = () => {
  return (
    <Stack spacing="0" gap="24px" flex="1">
      <SuggestionStrengthCard />
      <VStack gap="16px" spacing="0" flex="1">
        <Flex justifyContent="center">
          <Image
            height="150"
            width="100"
            src="https://res.cloudinary.com/hellojoy/image/upload/v1723750496/Coffee_djkv99.gif"
            alt="Coffee"
          />
        </Flex>
        <VStack spacing="0" gap="4px">
          <Text fontWeight="bold">Generating suggestions...</Text>
          <Text color="dark_gray">This process could take 1-2 min</Text>
        </VStack>
      </VStack>
    </Stack>
  )
}

const NoSuggestedContent = () => {
  return (
    <Stack spacing="0" gap="24px" flex="1">
      <SuggestionStrengthCard />
      <VStack spacing="0" gap="16px" flex="1">
        <CoolKidsBust />
        <VStack spacing="0" gap="4px">
          <Text fontWeight="bold">No suggestions at this time</Text>
          <Text color="dark_gray">We'll try again next session</Text>
        </VStack>
      </VStack>
    </Stack>
  )
}

interface AssistPanelProps {
  sessionId: string
  client: {
    id: string
    user_id: string
    first_name: string
    enrollment_timestamp: string
  }
}

interface PaginationProps {
  current: number
  total: number
  onNext: () => void
  onPrev: () => void
}

const Pagination = ({ current, total, onNext, onPrev }: PaginationProps) => {
  return (
    <HStack columnGap="12px" spacing="0">
      <Text>
        {current} of {total}
      </Text>
      <IconButton
        m={0}
        size="xs"
        disabled={current <= 1}
        aria-label="back"
        variant="outline"
        borderRadius="0px"
        icon={<ArrowLeftIcon />}
        onClick={onPrev}
        _focus={{ outline: 'none' }}
      />
      <IconButton
        m={0}
        size="xs"
        disabled={current >= total}
        aria-label="back"
        variant="outline"
        borderRadius="0px"
        icon={<ArrowRightIcon />}
        onClick={onNext}
        _focus={{ outline: 'none' }}
      />
    </HStack>
  )
}

const AssistPanel = ({ sessionId, client }: AssistPanelProps) => {
  const [assessmentIdx, setAssessmentIdx] = React.useState<number>(0)
  const [worksheetIdx, setWorksheetIdx] = React.useState<number>(0)

  const toast = useToast()
  const queryClient = useQueryClient()
  const { cadenceMapping } = useCadenceOptions()

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal
  } = useDisclosure()

  const {
    data: suggestions,
    isLoading
  } = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      }
    }
  })

  const assessmentSuggestion = suggestions?.suggestedAssessments[assessmentIdx]
  const worksheetSuggestion = suggestions?.suggestedWorksheets[worksheetIdx]

  const { mutate: acceptSuggestion } = useContentSuggestionControllerAccept({
    mutation: {
      onSuccess: () => {
        const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
          sessionId
        )
        queryClient.invalidateQueries({ queryKey: suggestionListKey })
      }
    }
  })
  const {
    mutateAsync: enrollInAssist,
    isLoading: isEnrolling
  } = usePatientControllerAssistEnroll()

  const handleEnroll = (values: any) => {
    const assessments = values.assessments.map((a: any) => ({
      clinicAssessmentId: a.clinicAssessmentId,
      cadence: cadenceMapping[a.cadence],
      suggestionId: a.suggestionId
    }))

    enrollInAssist(
      {
        patientId: client.id,
        data: {
          email: values.email,
          phone: values.phone,
          assessments
        }
      },
      {
        onSuccess: () => {
          toast({
            title: 'Assessment assigned',
            status: 'success',
            isClosable: true,
            duration: 1200
          })
          const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
            sessionId
          )
          queryClient.invalidateQueries({ queryKey: suggestionListKey })
          queryClient.invalidateQueries([
            endpoints.getClinicianUserAccount.getCacheId(),
            client.id
          ])
          closeModal()
        },
        onError: () => {
          toast({
            title: 'Error',
            description: 'There was an error assigning this measure',
            status: 'error',
            isClosable: true,
            duration: 2000
          })
        }
      }
    )
  }

  const { mutate: assignAssessment, isLoading: isAssigning } = useMutation(
    endpoints.postClientAssignAssessments.request,
    {
      onSuccess: async () => {
        if (assessmentSuggestion) {
          await acceptSuggestion({
            contentSuggestionId: assessmentSuggestion.contentSuggestionId
          })
        }

        toast({
          title: 'Assessment assigned',
          status: 'success',
          isClosable: true,
          duration: 1200
        })

        const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
          sessionId
        )
        queryClient.invalidateQueries({ queryKey: suggestionListKey })
      }
    }
  )

  const { isAssistEnabled } = useExperienceManager()

  if (!isAssistEnabled) {
    return <Waitlist />
  }

  if (isLoading || suggestions?.isGenerationComplete === false) {
    return <SuggestionLoader />
  }

  if (
    suggestions?.suggestedAssessments.length === 0 &&
    suggestions?.suggestedWorksheets.length === 0
  ) {
    return <NoSuggestedContent />
  }

  const worksheetCount = suggestions?.suggestedWorksheets.length || 0
  const assessmentCount = suggestions?.suggestedAssessments.length || 0

  const handleAssign = () => {
    if (client.enrollment_timestamp) {
      if (assessmentSuggestion) {
        assignAssessment({
          clientId: client.id,
          data: {
            assignments: [
              {
                clinicAssessmentId: assessmentSuggestion.clinicAssessmentId,
                cadence: null
              }
            ]
          }
        })
      }
    } else {
      openModal()
    }
  }

  return (
    <Stack spacing="0" gap="32px" flex="1">
      <Box>
        <SuggestionStrengthCard />
        {assessmentSuggestion && (
          <Stack spacing="0">
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Text fontWeight="bold">Assessments</Text>
              {assessmentCount > 1 && (
                <Flex justifyContent="center">
                  <Pagination
                    current={assessmentIdx + 1}
                    total={assessmentCount || 0}
                    onNext={() => setAssessmentIdx(prev => prev + 1)}
                    onPrev={() => setAssessmentIdx(prev => prev - 1)}
                  />
                </Flex>
              )}
            </Flex>
            <ContentSuggestionCard
              type="assessment"
              suggestion={{
                ...assessmentSuggestion,
                name: assessmentSuggestion.assessmentName
              }}
              sessionId={sessionId}
              onAssign={handleAssign}
              isLoading={isAssigning}
            />
          </Stack>
        )}
        {assessmentSuggestion && (
          <AssessmentAssignmentModal
            onSubmit={handleEnroll}
            isSubmitting={isEnrolling}
            client={client}
            isOpen={isModalOpen}
            onClose={closeModal}
            suggestion={assessmentSuggestion}
          />
        )}
      </Box>
      <Box>
        {worksheetSuggestion && (
          <Stack spacing="0">
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Text fontWeight="bold">Worksheets</Text>
              {worksheetCount > 1 && (
                <Flex justifyContent="center">
                  <Pagination
                    current={worksheetIdx + 1}
                    total={worksheetCount || 0}
                    onNext={() => setWorksheetIdx(prev => prev + 1)}
                    onPrev={() => setWorksheetIdx(prev => prev - 1)}
                  />
                </Flex>
              )}
            </Flex>
            <ContentSuggestionCard
              type="worksheet"
              suggestion={{
                ...worksheetSuggestion,
                name: worksheetSuggestion?.worksheetName
              }}
              sessionId={sessionId}
            />
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

export default AssistPanel
