import React, { useEffect } from 'react'
import { Hidden } from '@blueprinthq/joy'
import { useQuery } from 'react-query'
import { NavBarMobileView } from './mobile-view'
import { NavBarDesktopView } from './desktop-view'
import { usePermissions, useExperienceManager } from '../../hooks'
import { endpoints } from '@api'
import { PaywallBanner } from '@components'
import { Flex, Box } from '@chakra-ui/react'
import { UnpaidBanner } from '@components'
import { useStoreActions } from 'easy-peasy'

export const NavBar = ({ focusMode }) => {
  const { setHeight } = useStoreActions(actions => actions.paywallBanner)

  const { data: user } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const { data: paywallData } = useQuery(
    endpoints.getPaywallValidation.getCacheId(),
    endpoints.getPaywallValidation.request,
    {
      initialData: {}
    }
  )

  const { data: invoices, isFetching: isInvoicesLoading } = useQuery(
    [
      endpoints.getClinicFailedInvoices.getCacheId(),
      user?.clinic_id // This ensures the query key changes when the user or clinic_id changes
    ],
    () =>
      endpoints.getClinicFailedInvoices.request({
        clinicId: user.clinic_id
      }),
    {
      initialData: [],
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!user && !!user.clinic_id, // This query runs only if user and user.clinic_id are truthy
      onSuccess(data) {
        // Handle success
        return data
      }
    }
  )

  const showOwesBanner =
    !isInvoicesLoading &&
    invoices.invoices &&
    parseInt(invoices.invoices.totalAmountOwed, 10) > 0

  const { hasPayingSubscription, isTrialing } = paywallData
  const displayPaywallBanner =
    Object.keys(paywallData).length === 0 ||
    (hasPayingSubscription && isTrialing)
      ? true
      : false

  useEffect(() => {
    if (displayPaywallBanner || showOwesBanner) {
      setHeight(40)
    } else {
      setHeight(0)
    }
  }, [showOwesBanner, displayPaywallBanner])

  const { hasPermission } = usePermissions()

  if (!user) return null

  return (
    <div>
      <Box
        w="100%"
        bg={hasPermission('*:any:*:*') ? '#282828' : 'primary'}
        position="fixed"
        top="0"
        right="0"
        left="auto"
        zIndex={5}
        h={displayPaywallBanner || showOwesBanner ? '121px' : '80px'}
      >
        {!isInvoicesLoading &&
          invoices.invoices &&
          showOwesBanner &&
          invoices.invoices.nextDueInvoice && (
            <UnpaidBanner
              user={user}
              adminContact={invoices.invoices.nextDueInvoice.customer_email}
            />
          )}

        {displayPaywallBanner && (
          <PaywallBanner user={user} paywallData={paywallData} />
        )}
        <Flex align="center" pl="large" pr="large" h="80px">
          <Hidden sm>
            <NavBarDesktopView
              paywallData={paywallData}
              user={user}
              focusMode={focusMode}
            />
          </Hidden>
          <Hidden md lg xl>
            <NavBarMobileView
              paywallData={paywallData}
              user={user}
              focusMode={focusMode}
            />
          </Hidden>
        </Flex>
      </Box>
      <Box
        h={displayPaywallBanner || showOwesBanner ? '121px' : '80px'}
        position="relative"
      />
    </div>
  )
}
