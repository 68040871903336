import React, { ReactNode } from 'react'
import { useExperienceManager } from '@hooks'
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Divider,
  AlertDialogFooter,
  Box,
  useBreakpointValue
} from '@chakra-ui/react'

export const DialogContainer = ({
  children,
  onClose,
  isOpen,
  size,
  scrollBehavior
}: {
  children: ReactNode
  onClose: () => void
  isOpen: boolean
  closeOnOverlayClick?: boolean
  size?: string
  scrollBehavior?: string
}) => {
  const { isExtension, isWidget } = useExperienceManager()
  const modalSize = useBreakpointValue({
    base: 'full',
    sm: 'md'
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isExtension || isWidget ? 'full' : size || modalSize}
      returnFocusOnClose={false}
      isCentered
      // @ts-ignore
      scrollBehavior={
        scrollBehavior
          ? scrollBehavior
          : size || modalSize === 'full'
          ? 'inside'
          : 'outside'
      }
    >
      <ModalOverlay />
      <ModalContent borderRadius={modalSize === 'full' ? '0px' : '4px'}>
        {children}
      </ModalContent>
    </Modal>
  )
}

export const DialogBody = ({
  children,
  p = 6
}: {
  children: ReactNode
  p?: number | string
}) => {
  return <ModalBody p={p}>{children}</ModalBody>
}

export const DialogHeader = ({
  text,
  onClose
}: {
  text: string
  onClose?: () => void
}) => {
  return (
    <>
      <ModalHeader fontSize="20px" textAlign="center" p={5}>
        {text}
      </ModalHeader>
      <Box>
        {onClose && <ModalCloseButton _focus={{ outline: 'none' }} top={5} />}
      </Box>
      <Divider />
    </>
  )
}

export const DialogFooter = ({
  children,
  p = 4
}: {
  children: ReactNode
  p?: number | string
}) => {
  return (
    <AlertDialogFooter
      height={'80px'}
      boxShadow="0px -4px 6px -1px rgba(0, 0, 0, 0.08), 0px -2px 4px -1px rgba(0, 0, 0, 0.08)"
      p={p}
    >
      {children}
    </AlertDialogFooter>
  )
}
