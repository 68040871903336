import React, { useRef, useState, useEffect } from 'react'
import TreatmentPlanEmpty from '../../../treatment-plan'
import { useTreatmentPlan } from '@hooks'
import { Button } from '../Button'
import { Container, Text, VStack, Stack } from '@chakra-ui/react'
import { Assist } from '@blueprinthq/joy'
import { Upload } from '@components/icons'
import { CoolKidsLivingRoom } from '@handlers/sessions/components/in-session-assist-panel/svgs'

const View = () => {
  const [file, setFile] = useState<File | null | undefined>(null)
  const { generateMutation,  uploadMutation } = useTreatmentPlan()
  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (file) {
      uploadMutation.mutate(file)
    }
  }, [file])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event?.target?.files?.[0])
  }

  return (
    <VStack flex="1" h="calc(100vh - 208px)" justifyContent="center" gap="24px">
      <CoolKidsLivingRoom />
      <VStack spacing="0" gap="8px">
        <Text fontWeight="bold">
          No treatment plan
        </Text>
        <Text color="dark_gray">We don’t have a treatment plan yet.</Text>
      </VStack>
      <Stack w="300px" spacing="0" gap="16px"> 
        <Button 
          colorScheme="primary" 
          onClick={() => generateMutation.mutate()}
          isLoading={generateMutation.isLoading}
          isDisabled={uploadMutation.isLoading}
          leftIcon={<Assist size="sm" fill="white" />}
        >
          Generate Treatment Plan
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          accept="application/pdf"
        />
        <Button 
          onClick={
            () => {
              if (fileInputRef.current) {
                fileInputRef.current.click()
              }
            }
          }
          isLoading={uploadMutation.isLoading}
          isDisabled={generateMutation.isLoading}
          isFullWidth 
          variant="outline"
          leftIcon={<Upload width="16px" fill="black" />}
        >
          or upload your own
        </Button>
      </Stack>
      {/* <TreatmentPlanEmpty client={client} /> */}
    </VStack>
  )
}

export default View
