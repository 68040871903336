import React from 'react'
import { Box, Divider, Stack, Text, Textarea, ButtonGroup, Button, useToast } from '@chakra-ui/react'
import FeedbackButton from './feedback-button'
import { ThumbUpIcon, ThumbDownIcon } from '@icons'
import { Neutral } from '@components/icons'

interface FeedbackProps {
  rating: string | null
  onFeedback: (value: string) => void
  commentsOpen: boolean
  setCommentsOpen: React.Dispatch<React.SetStateAction<boolean>>
  comments: string
  setComments: React.Dispatch<React.SetStateAction<string>>
  submitFeedback: (data: { contentSuggestionId: string, data: { rating: string | null, comments?: string } }) => void
  suggestionId: string
}

const ratingToPlaceholder = {
  good: 'Why is this suggestion relevant, appropriate, etc. for this client session?',
  bad: 'Why is this suggestion irrelevant, inappropriate, etc. for this client session?',
  neutral: 'What would make this suggestion better?'
}

const Feedback: React.FC<FeedbackProps> = ({
  rating,
  onFeedback,
  commentsOpen,
  setCommentsOpen,
  comments,
  setComments,
  submitFeedback,
  suggestionId
}) => {
  const feedbackToast = useToast()

  const handleSubmitFeedback = () => {
    submitFeedback({
      contentSuggestionId: suggestionId,
      data: { rating, comments }
    })
    setCommentsOpen(false)
    feedbackToast({
      title: 'Feedback submitted',
      status: 'success',
      duration: 3000,
      isClosable: true
    })
  }

  return (
    <>
      <ButtonGroup display="flex" spacing="0">
        <FeedbackButton
          value="good"
          label="Good"
          icon={ThumbUpIcon}
          color="#2D54E8"
          onClick={() => onFeedback('good')}
          isActive={rating === 'good' || !rating}
        />
        <Divider orientation="vertical" h="auto" />
        <FeedbackButton
          value="bad"
          label="Bad"
          icon={ThumbDownIcon}
          color="#2D54E8"
          onClick={() => onFeedback('bad')}
          isActive={rating === 'bad' || !rating}
        />
        <Divider orientation="vertical" h="auto" />
        <FeedbackButton
          value="neutral"
          label="Neutral"
          icon={Neutral}
          color="#2D54E8"
          onClick={() => onFeedback('neutral')}
          isActive={rating === 'neutral' || !rating}
        />
      </ButtonGroup>
      {commentsOpen && rating && (
        <Box>
          <Divider />
          <Stack p={4} spacing="0" gap="16px">
            <Text>
              <Text as="span" fontSize="12px">
                Tell us more.
              </Text>
              <Text fontSize="12px" color="medium_gray" as="span">
                {' '}
                Optional
              </Text>
            </Text>
            <Textarea
              border="1px solid"
              borderColor="pale_gray"
              placeholder={ratingToPlaceholder[rating as 'good' | 'bad' | 'neutral']}
              _placeholder={{ color: 'medium_gray', fontSize: '12px' }}
              onChange={e => setComments(e.target.value)}
              value={comments}
            />
            <ButtonGroup
              display="flex"
              spacing="0"
              justifyContent="flex-end"
              gap="12px"
            >
              <Button
                m="0"
                p="0"
                variant="link"
                fontSize="12px"
                textDecoration="none"
                color="medium_gray"
                _focus={{ outline: 'none' }}
                _hover={{ textDecoration: 'none' }}
                onClick={() => setCommentsOpen(false)}
              >
                No thanks
              </Button>
              <Button
                m="0"
                p="0"
                variant="link"
                fontSize="12px"
                textDecoration="none"
                _focus={{ outline: 'none' }}
                _hover={{ textDecoration: 'none' }}
                onClick={handleSubmitFeedback}
              >
                Submit feedback
              </Button>
            </ButtonGroup>
          </Stack>
        </Box>
      )}
    </>
  )
}

export default Feedback
