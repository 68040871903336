import React from 'react'

const ChevronDown = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      role="presentation"
      class="chakra-select__icon"
      focusable="false"
      aria-hidden="true"
      style={{ width: '20px', height: '20px', color: 'currentcolor' }}
    >
      <path
        fill="currentColor"
        d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
      ></path>
    </svg>
  )
}

export default ChevronDown
