import React, { useState, useEffect } from 'react'
import { Text, Box, Select, VStack, Button, Switch } from '@chakra-ui/react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody
} from '@handlers/sessions/components/dialog'
import { useQuery } from 'react-query'
import { useExperienceManager } from '@hooks'
import { endpoints } from '@api'
import { useSessionControllerPostDiscardSession } from '~/clinician-api'

type ProgressNoteType = {
  id: string
  display: string
}

interface EndSessionModalProps {
  isOpen: boolean
  onClose: () => void
  noteType: string
  sessionId: string
  onEnd: (args: any) => void
  onDiscard: () => void
  isEnding: boolean
  isUploadingAudio: boolean
}

export const EndSessionModal = ({
  isOpen,
  onClose,
  noteType,
  sessionId,
  onEnd,
  onDiscard,
  isEnding,
  isUploadingAudio
}: EndSessionModalProps) => {
  const [isDiscardSelected, setIsDiscardSelected] = React.useState(false)
  const [selectedNoteType, setSelectedNoteType] = useState(noteType)
  const [isIntake, setIsIntake] = useState(noteType === 'intake')

  const { data: user }: any = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const {
    data: { progressNoteTypes }
  }: any = useQuery(
    [
      endpoints.getProgressNoteTypes.getCacheId(),
      user?.clinic?.organization_id
    ],
    () =>
      endpoints.getProgressNoteTypes.request({
        organizationId: user.clinic.organization_id
      }),
    {
      initialData: {
        progressNoteTypes: []
      },
      enabled: !!user
    }
  )

  const {
    mutateAsync: discardSession,
    isLoading: isDiscarding
  } = useSessionControllerPostDiscardSession()

  const handleEndSession = async () => {
    onEnd({
      noteType: isIntake ? 'intake' : selectedNoteType
    })
  }

  const handleDeleteSession = async () => {
    await discardSession(
      {
        id: sessionId
      },
      {
        onSuccess() {
          if (onDiscard) {
            onDiscard()
          }
        }
      }
    )
  }

  useEffect(() => {
    if (user && !selectedNoteType) {
      setSelectedNoteType(
        user?.clinic?.organization?.default_note_type || 'soap'
      )
    }
  }, [user?.clinic?.organization?.default_note_type, selectedNoteType])

  const handleClose = () => {
    setIsDiscardSelected(false)
    onClose()
  }

  const handleIntakeToggle = (evt: any) => {
    setIsIntake(evt.target.checked)
  }

  const { isDocumentationAutomationEnabled } = useExperienceManager()

  return (
    <DialogContainer onClose={handleClose} isOpen={isOpen}>
      <DialogBody>
        {isDiscardSelected ? (
          <>
            <DialogHeader text="Are you sure?" onClose={handleClose} />
            <VStack spacing={6}>
              <Text textAlign="center">
                This session will be discarded and no progress note summary will
                be generated. This cannot be undone.
              </Text>
              <VStack w="100%">
                <Button
                  bg="error"
                  isFullWidth
                  size="lg"
                  onClick={handleDeleteSession}
                  isLoading={isDiscarding}
                >
                  Delete session
                </Button>
                <Button
                  _focus={{ outline: 'none' }}
                  variant="outline"
                  isFullWidth
                  size="lg"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </VStack>
            </VStack>
          </>
        ) : (
          <>
            <VStack spacing={6} w="100%">
              <Box>
                <DialogHeader text="End session?" onClose={handleClose} />
                <Text textAlign="center">
                  When you end the session we will generate a progress note
                  summary.
                </Text>
              </Box>
              {isDocumentationAutomationEnabled && (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text fontWeight="bold">Intake session</Text>
                    <Switch
                      size="lg"
                      isChecked={isIntake}
                      onChange={handleIntakeToggle}
                      _focus={{ outline: 'none' }}
                    />
                  </Box>
                  {!isIntake && (
                    <Box mt={6} w="100%">
                      <Text fontWeight="bold" mb={2}>
                        Select Note Type
                      </Text>
                      <Select
                        onChange={e => setSelectedNoteType(e.target.value)}
                        value={selectedNoteType}
                        border="1px solid"
                        height="40px"
                        borderColor="pale_gray"
                        _focus={{ outline: 'none' }}
                      >
                        {progressNoteTypes
                          .filter((o: any) => !o.hideFromDefaultOptions)
                          .map((type: any) => (
                            <option key={type.id} value={type.id}>
                              {type.display}
                            </option>
                          ))}
                      </Select>
                    </Box>
                  )}
                </>
              )}
              <VStack w="100%">
                <Button
                  bg="primary"
                  isFullWidth
                  size="lg"
                  onClick={handleEndSession}
                  isLoading={isEnding}
                  loadingText={isUploadingAudio ? 'Uploading audio...' : ''}
                >
                  End session
                </Button>
                <Button
                  _focus={{ outline: 'none' }}
                  variant="outline"
                  isFullWidth
                  size="lg"
                  disabled={isEnding}
                  onClick={onClose}
                >
                  Continue session
                </Button>
                <Button
                  variant="ghost"
                  color="error"
                  disabled={isEnding}
                  onClick={() => setIsDiscardSelected(true)}
                >
                  Delete session
                </Button>
              </VStack>
            </VStack>
          </>
        )}
      </DialogBody>
    </DialogContainer>
  )
}
