import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error)

    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2 style={{ maxWidth: '375px', margin: '100px auto', color: 'red' }}>
            This page was unable to load. Please refresh your browser page to
            try again.
            <br />
            If the page still doesn&apos;t load, send us an email
            help@blueprint-health.com -- we&apos;re here to help!
          </h2>
        </div>
      )
    }

    return this.props.children
  }
}
